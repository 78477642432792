import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { BreakdownsTypesDetailsState } from '../../types';

type BreakdownsTypesDetailsActions = ActionType<typeof actions>;

const initialState: BreakdownsTypesDetailsState = {
  id: 0,
  name: '',
  state: '',
  isUsed: false
};

const breakdownsTypesDetailsReducer = createReducer<
  BreakdownsTypesDetailsState,
  BreakdownsTypesDetailsActions
>(initialState).handleAction(
  actions.getBreakdownsTypes.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default breakdownsTypesDetailsReducer;
