import { get } from 'lodash';
import { RootState } from 'typesafe-actions';
import { Option } from '../types';

export const selectIsDialogShown = (editableSelectName: string) => (
  state: RootState
) => get(state, `ui.${editableSelectName}.isOptionEditDialogShown`);

export const selectEditableOption = (editableSelectName: string) => (
  state: RootState
) => get(state, `ui.${editableSelectName}.editableOption`);

export const selectCustomOptions = (editableSelectName: string) => (
  state: RootState
) => get(state, `ui.${editableSelectName}.options.custom`) as Option[];

export const selectActiveOptions = (editableSelectName: string) => (
  state: RootState
) => get(state, `ui.${editableSelectName}.options.active`) as Option[];
