import { createAsyncAction } from 'typesafe-actions';
import { InspectorDocumentsRequest, InspectionResponse } from './types';

export const updateInspectionDocument = createAsyncAction(
  '@INSPECTION_DOCUMENTS/UPDATE_INSPECTION_DOCUMENTS_REQUEST',
  '@INSPECTION_DOCUMENTS/UPDATE_INSPECTION_DOCUMENTS_SUCCESS',
  '@INSPECTION_DOCUMENTS/UPDATE_INSPECTION_DOCUMENTS_FAILURE'
)<InspectorDocumentsRequest, undefined, Error>();

export const getInspectionDocument = createAsyncAction(
  '@INSPECTION_DOCUMENTS/GET_INSPECTION_DOCUMENTS_REQUEST',
  '@INSPECTION_DOCUMENTS/GET_INSPECTION_DOCUMENTS_SUCCESS',
  '@INSPECTION_DOCUMENTS/GET_INSPECTION_DOCUMENTS_FAILURE'
)<undefined, InspectionResponse, Error>();
