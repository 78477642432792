import { createReducer, ActionType } from 'typesafe-actions';
import { KpiDTO } from '@eticket/clearing-api-contracts';
import * as actions from './actions';

type KpiEditActions = ActionType<typeof actions>;

const initialState = {
  id: 0,
  name: '',
  type: {
    id: 0,
    name: ''
  },
  isPredefined: false
};

const kpiEditReducers = createReducer<KpiDTO, KpiEditActions>(
  initialState
).handleAction(actions.getKpi.success, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default kpiEditReducers;
