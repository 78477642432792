import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { call, put, all, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { navigateTo } from 'src/store/actions/app';
import * as actions from './actions';

export function* deleteItem({
  payload
}: ReturnType<typeof actions.deleteItem.request>): SagaIterator {
  const { id, name, apiUrl, deleteUrl, deleteSnackbarMessages } = payload;

  yield call(apiClient.delete, `${apiUrl}/${id}`, {
    retryAction: actions.deleteItem.request(payload)
  });
  yield put(
    enqueueSnackbar({
      key: 'delete_row_success',
      message: deleteSnackbarMessages || `${name} видалено`,
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.deleteItem.success());
  yield put(navigateTo({ url: deleteUrl }));
}

export function* changeItemStatusToDraft({
  payload
}: ReturnType<typeof actions.changeItemStatusToDraft.request>): SagaIterator {
  const { id, apiUrl } = payload;

  yield call(
    apiClient.put,
    `${apiUrl}/${id}/change-status`,
    // statusId is 0 because changing to Draft, from other statuses like Fixed/Completed
    { statusId: 0 },
    { retryAction: actions.changeItemStatusToDraft.request(payload) }
  );
  payload.getItemData && (yield call(payload.getItemData));
  yield put(actions.changeItemStatusToDraft.success());
}

export default function* DetailsActionsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.deleteItem.request),
      safe(deleteItem, actions.deleteItem.failure)
    ),
    takeEvery(
      getType(actions.changeItemStatusToDraft.request),
      safe(changeItemStatusToDraft, actions.changeItemStatusToDraft.failure)
    )
  ]);
}
