import { combineReducers } from 'redux';
import { createNamedWrapperReducer } from 'src/utils/reducers';
import tableWithPaginationReducers from 'src/components/Tables/TableWithPagination/store/reducers';
import { KPIS_TABLE_NAME } from 'src/views/clearing/kpis/KpisListView/const';
import kpiEditReducers from 'src/views/clearing/kpis/KpiEditView/store/reducers';
import { PENALTIES_TABLE_NAME } from 'src/views/clearing/penalties/PenaltiesListView/const';
import penaltyEditReducers from 'src/views/clearing/penalties/PenaltyEditView/store/reducers';
import kpiParametersFormReducer from 'src/views/clearing/kpiParameters/KpiParametersForm/store/reducers';
import kpiParametersGroupEditReducer from 'src/views/clearing/kpiParameters/KpiParametersEditView/store/reducer';
import tariffDetailsReducers from 'src/views/clearing/tariffs/TariffDetailsView/store/reducers';
import { KPI_PARAMETERS_TABLE_NAME } from 'src/views/clearing/kpiParameters/KpiParametersListView/const';
import { TARIFFS_TABLE_NAME } from 'src/views/clearing/tariffs/const';
import tariffEditReducers from 'src/views/clearing/tariffs/TariffEditView/store/reducer';
import selectReducers from 'src/components/Select/store/reducers';
import {
  COUNTERAGENTS_VEHICLES_AUTOCOMPLETE_NAME,
  PENALTY_DOCUMENTS_SELECT_NAME,
  ROUTE_NUMBERS_SELECT_NAME
} from 'src/views/clearing/penaltyDocuments/const';
import penaltyDocumentEditReducers from 'src/views/clearing/penaltyDocuments/PenaltyDocumentEditView/store/reducers';
import { KPIS_SELECT_NAME } from 'src/views/clearing/kpiParameters/const';
import { PENALTY_DOCUMENTS_TABLE_NAME } from 'src/views/clearing/penaltyDocuments/PenaltyDocumentListView/const';
import coefficientDetailsReducers from 'src/views/clearing/coefficientsAdjustments/CoefficientsAdjustmentsDetailsView/store/reducers';
import formAutocompleteReducers from 'src/components/Form/FormAutocomplete/store/reducer';
import kpiParametersDetailsReducers from 'src/views/clearing/kpiParameters/KpiParametersDetailsView/store/reducers';
import { editCoefficientsAdjustmentsReducer } from 'src/views/clearing/coefficientsAdjustments/CoefficientsAdjustmentsEditView/store/reducer';
import {
  CLEARING_INSPECTIONS_COUNTERAGENT_SELECT_NAME,
  CLEARING_INSPECTIONS_VEHICLE_AUTOCOMPLETE_NAME,
  CLEARING_INSPECTIONS_INSPECTOR_AUTOCOMPLETE_NAME,
  KPI_INDICATOR_SELECT_NAME
} from 'src/views/clearing/inspectionDocuments/InspectionDocumentsForm/const';
import inspectionDocumentEditReducers from 'src/views/clearing/inspectionDocuments/InspectionDocumentsEditView/store/reducers';
import {
  INSPECTION_DOCUMENTS_TABLE_NAME,
  VEHICLES_AUTOCOMPLETE_NAME
} from 'src/views/clearing/inspectionDocuments/InspectionDocumentsListView/const';
import inspectionDocumentDetailsReducer from 'src/views/clearing/inspectionDocuments/InspectionDocumentDetailsView/store/reducers';
import {
  COEFFICIENTS_ADJUSTMENTS_ROUTES_NAME,
  TIME_COEFFICIENTS_TABLE_NAME
} from 'src/views/clearing/coefficientsAdjustments/const';
import { COEFFICIENTS_ADJUSTMENT_TABLE_NAME } from 'src/views/clearing/coefficientsAdjustments/CoefficientsAdjustmentsListView/const';
import billingDocumentDetailsReducers from 'src/views/clearing/billingDocuments/BillingDocumentsDetailsView/store/reducers';
import { BILLING_DOCUMENTS_TABLE_NAME } from 'src/views/clearing/billingDocuments/BillingDocumentsListView/const';
import billingDocumentEditReducers from 'src/views/clearing/billingDocuments/BillingDocumentsEditView/store/reducers';
import {
  BILLING_DETAILS_REDUCER_NAME,
  BILLING_DOCUMENT_NAME
} from 'src/views/clearing/billingDocuments/BillingDetailsListView/const';
import tableReducers from 'src/components/Tables/Table/store/reducers';
import billingDocumentNextDateReducers from '../../views/clearing/billingDocuments/BillingDocumentCreateView/store/reducers';

const clearingReducers = combineReducers({
  kpis: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      KPIS_TABLE_NAME
    ),
    edit: kpiEditReducers,
    select: createNamedWrapperReducer(selectReducers, KPIS_SELECT_NAME)
  }),
  penalties: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      PENALTIES_TABLE_NAME
    ),
    edit: penaltyEditReducers,
    autocomplete: createNamedWrapperReducer(
      formAutocompleteReducers,
      PENALTY_DOCUMENTS_SELECT_NAME
    )
  }),
  kpiParameters: combineReducers({
    form: kpiParametersFormReducer,
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      KPI_PARAMETERS_TABLE_NAME
    ),
    details: kpiParametersDetailsReducers,
    edit: kpiParametersGroupEditReducer
  }),
  tariffs: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      TARIFFS_TABLE_NAME
    ),
    edit: tariffEditReducers,
    details: tariffDetailsReducers
  }),
  penaltyDocuments: combineReducers({
    routeNumbersSelect: createNamedWrapperReducer(
      selectReducers,
      ROUTE_NUMBERS_SELECT_NAME
    ),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      PENALTY_DOCUMENTS_TABLE_NAME
    ),
    edit: penaltyDocumentEditReducers,
    vehiclesAutocomplete: createNamedWrapperReducer(
      formAutocompleteReducers,
      COUNTERAGENTS_VEHICLES_AUTOCOMPLETE_NAME
    )
  }),
  coefficientsAdjustments: combineReducers({
    details: coefficientDetailsReducers,
    timeTable: createNamedWrapperReducer(
      tableReducers,
      TIME_COEFFICIENTS_TABLE_NAME
    ),
    edit: combineReducers({
      details: editCoefficientsAdjustmentsReducer,
      routesAutocomplete: createNamedWrapperReducer(
        formAutocompleteReducers,
        COEFFICIENTS_ADJUSTMENTS_ROUTES_NAME
      )
    }),
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      COEFFICIENTS_ADJUSTMENT_TABLE_NAME
    )
  }),
  inspectionDocuments: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      INSPECTION_DOCUMENTS_TABLE_NAME
    ),
    edit: inspectionDocumentEditReducers,
    counteragentSelect: createNamedWrapperReducer(
      selectReducers,
      CLEARING_INSPECTIONS_COUNTERAGENT_SELECT_NAME
    ),
    vehicleAutocomplete: createNamedWrapperReducer(
      formAutocompleteReducers,
      CLEARING_INSPECTIONS_VEHICLE_AUTOCOMPLETE_NAME
    ),
    listVehiclesAutocomplete: createNamedWrapperReducer(
      formAutocompleteReducers,
      VEHICLES_AUTOCOMPLETE_NAME
    ),
    inspectorAutocomplete: createNamedWrapperReducer(
      formAutocompleteReducers,
      CLEARING_INSPECTIONS_INSPECTOR_AUTOCOMPLETE_NAME
    ),
    kpiIndicatorSelect: createNamedWrapperReducer(
      selectReducers,
      KPI_INDICATOR_SELECT_NAME
    ),
    details: inspectionDocumentDetailsReducer
  }),
  billingDocuments: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      BILLING_DOCUMENTS_TABLE_NAME
    ),
    details: billingDocumentDetailsReducers,
    edit: billingDocumentEditReducers,
    billingDocumentNextDate: billingDocumentNextDateReducers,
    detailsTable: createNamedWrapperReducer(
      tableWithPaginationReducers,
      BILLING_DETAILS_REDUCER_NAME
    ),
    billingDocumentAutocomplete: createNamedWrapperReducer(
      formAutocompleteReducers,
      BILLING_DOCUMENT_NAME
    )
  })
});

export default clearingReducers;
