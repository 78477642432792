import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';

export function* getOptions({
  config
}: ReturnType<typeof actions.getOptionsRequest>): SagaIterator {
  const { optionsApiUrl } = config;

  const { data } = yield call(apiClient.get, optionsApiUrl!, {
    retryAction: actions.getOptionsRequest(config)
  });

  yield put(actions.getOptionsSuccess(config, data));
}

export default function* SelectSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getOptionsRequest),
    safe(getOptions, actions.getOptionsFailure)
  );
}
