import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { get, isBoolean } from 'lodash';

const matchSpaceParams = createMatchSelector('/clearing/penalties/:id');

export const selectPenalty = (state: RootState) =>
  get(state, `ui.clearing.penalties.edit`);

export const selectIsLoadingPenalty = (state: RootState) => {
  const loading = get(state, `loading.@PENALTIES/GET_PENALTY`);

  return isBoolean(loading) ? loading : true;
};

export const selectPenaltyId = createSelector(
  matchSpaceParams,
  (match: any) => {
    return match ? match.params.id : '';
  }
);
