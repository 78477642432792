import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { FC } from 'react';
import { Row } from 'src/components/Tables/types';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  typography: {
    margin: spacing(2)
  },
  hyphen: {
    margin: spacing(0, 4)
  }
}));

interface Props {
  row: Row;
}

const CounteragentNameColumn: FC<Props> = ({ row }) => {
  const classes = useStyles();

  const isShownCounteragent =
    (row.userRoles.length && row.employeeName === 'Не налаштований') ||
    (row.counteragentName && row.hasAccessToAllCounteragents);

  return isShownCounteragent ? (
    <Box className={classes.hyphen}>
      <Typography>-</Typography>
    </Box>
  ) : (
    <Box className={classes.root}>
      <Typography className={classes.typography}>
        {row.counteragentName}
      </Typography>
    </Box>
  );
};

export default CounteragentNameColumn;
