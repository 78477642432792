import { createAsyncAction } from 'typesafe-actions';
import { InboundStatusEnum } from 'src/views/asset/inbound/const';
import { InspectionDetails } from '../../InspectionDetailsView/types';

export interface Payload {
  id: number;
  status: InboundStatusEnum;
}

export const getInspectionPdfDetails = createAsyncAction(
  '@INSPECTIONS_LIST/GET_INSPECTION_DETAILS_REQUEST',
  '@INSPECTIONS_LIST/GET_INSPECTION_DETAILS_SUCCESS',
  '@INSPECTIONS_LIST/GET_INSPECTION_DETAILS_FAILURE'
)<string, InspectionDetails, Error>();

export const getSelectedInspectionsData = createAsyncAction(
  '@INSPECTIONS/GET_SELECTED_INSPECTIONS_REQUEST',
  '@INSPECTIONS/GET_SELECTED_INSPECTIONS_SUCCESS',
  '@INSPECTIONS/GET_SELECTED_INSPECTIONS_FAILURE'
)<{ id: number }[], InspectionDetails[], Error>();

export const updateInspectionStatus = createAsyncAction(
  '@INSPECTION/UPDATE_INSPECTION_STATUS_REQUEST',
  '@INSPECTION/UPDATE_INSPECTION_STATUS_SUCCESS',
  '@INSPECTION/UPDATE_INSPECTION_STATUS_FAILURE'
)<Payload, undefined, Error>();
