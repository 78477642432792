import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { HAS_IMEI_STRING_VALUE, NO_IMEI_STRING_VALUE } from '../../const';

const matchSpaceParams = createMatchSelector(
  '/asset/dictionaries/equipment/:id'
);

export const selectEquipment = (state: RootState) => {
  const { hasImei, ...rest } = get(state, `ui.asset.equipment.edit`);
  return {
    hasImei: hasImei ? HAS_IMEI_STRING_VALUE : NO_IMEI_STRING_VALUE,
    ...rest
  };
};

export const selectIsLoadingEquipment = (state: RootState) => {
  const loading = get(state, `loading.@EQUIPMENT_EDIT/GET_EQUIPMENT`);

  return isBoolean(loading) ? loading : true;
};

export const selectEquipmentId = createSelector(
  matchSpaceParams,
  (match: any) => match?.params.id || ''
);
