import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { convertUtcToLocal } from 'src/utils/date';

export const selectPassportSettings = (state: RootState) =>
  state.ui.avl.routes.settings.details.values;

export const selectPassportSettingsValues = createSelector(
  selectPassportSettings,
  ({ generalSettings, ...rest }) => ({
    ...rest,
    generalSettings: {
      ...generalSettings,
      contractEndDate: generalSettings.contractEndDate
        ? convertUtcToLocal(generalSettings.contractEndDate)
        : null,
      contractStartDate: generalSettings.contractStartDate
        ? convertUtcToLocal(generalSettings.contractStartDate)
        : null,
      effectiveDate: generalSettings.effectiveDate
        ? convertUtcToLocal(generalSettings.effectiveDate)
        : null
    }
  })
);

export const selectRouteName = (state: RootState) =>
  state.ui.avl.routes.settings.details.values.generalSettings.name;

export const selectTender = (state: RootState) =>
  state.ui.avl.routes.settings.details.values.generalSettings.isTenderCompleted;

export const selectTariffGroup = (state: RootState) =>
  state.ui.avl.routes.settings.details.values.generalSettings.tariffGroupId;

export const selectIsSettingsFormDirty = (state: RootState) =>
  state.ui.avl.routes.settings.details.isFormDirty;

export const selectValidationStatus = (state: RootState) =>
  state.ui.avl.routes.settings.details.validationStatus;

export const selectIsPassportValid = createSelector(
  selectValidationStatus,
  ({ hasApprovedRouteSchema, hasApprovedRouteTimetable }) =>
    hasApprovedRouteSchema && hasApprovedRouteTimetable
);

export const selectIsPassportDeletable = createSelector(
  selectValidationStatus,
  ({ hasActiveRouteTimetable, wasPassportApproved }) =>
    (wasPassportApproved && !hasActiveRouteTimetable) || !wasPassportApproved
);
