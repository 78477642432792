import {
  makeStyles,
  Theme,
  Typography,
  Box,
  colors,
  Tooltip
} from '@material-ui/core';
import { FC, useState } from 'react';
import { MoreHorizontal as MoreInfoIcon } from 'react-feather';
import { Row } from 'src/components/Tables/types';

const useStyles = makeStyles<Theme>({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 250
  },
  tooltipText: {
    maxWidth: 500
  },
  typography: {
    marginRight: 10,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  iconWrapper: {
    height: 20,
    width: 20,
    padding: 3,
    border: 1,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.grey[300],
    borderColor: colors.grey[900]
  }
});

interface Props {
  row: Row;
}

const UserRolesColumn: FC<Props> = ({ row }) => {
  const classes = useStyles();
  const [isOverflowing, toggleIsOverflowing] = useState(false);

  const { usersRoles } = row;

  return (
    <Box className={classes.root}>
      <Typography
        variant="h6"
        className={classes.typography}
        onMouseEnter={event => {
          const { scrollWidth, clientWidth } = event.currentTarget;
          toggleIsOverflowing(scrollWidth > clientWidth);
        }}
      >
        {usersRoles || 'Не налаштований'}
      </Typography>

      {isOverflowing ? (
        <Tooltip
          classes={{ tooltip: classes.tooltipText }}
          title={isOverflowing ? usersRoles : ''}
        >
          <Box className={classes.iconWrapper}>
            <MoreInfoIcon />
          </Box>
        </Tooltip>
      ) : null}
    </Box>
  );
};

export default UserRolesColumn;
