import { put, takeEvery, call, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INBOUNDS } from 'src/config/assetUrls';
import * as actions from './actions';
import { InboundDetailsState } from '../../InboundDetailsView/types';

export function* getInboundRowData({
  payload
}: ReturnType<typeof actions.getInboundRowData.request>): SagaIterator {
  const { id } = payload;

  const { data } = yield call(apiClient.get, `${INBOUNDS}/${id}`, {
    retryAction: actions.getInboundRowData.request
  });

  yield put(actions.getInboundRowData.success(data));
}

export function* getSelectedInboundsData({
  payload
}: ReturnType<typeof actions.getSelectedInboundsData.request>): SagaIterator {
  const requests = payload.map(({ id }) =>
    call(apiClient.get, `${INBOUNDS}/${id}`, {
      retryAction: actions.getSelectedInboundsData.request(payload)
    })
  );

  const response = yield all(requests);

  const inbounds = response.map(
    ({ data }: { data: InboundDetailsState[] }) => data
  );

  yield put(actions.getSelectedInboundsData.success(inbounds));
}

export function* updateInboundState({
  payload
}: ReturnType<typeof actions.updateInboundStatus.request>): SagaIterator {
  yield call(
    apiClient.put,
    `${INBOUNDS}/${payload.id}/change-status`,
    { status: payload.status },
    { retryAction: actions.updateInboundStatus.request(payload) }
  );
  yield put(actions.updateInboundStatus.success());
}

export default function* InboundListViewSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getInboundRowData.request),
      safe(getInboundRowData, actions.getInboundRowData.failure)
    ),
    takeEvery(
      getType(actions.updateInboundStatus.request),
      safe(updateInboundState, actions.updateInboundStatus.failure)
    ),
    takeEvery(
      getType(actions.getSelectedInboundsData.request),
      safe(getSelectedInboundsData, actions.getSelectedInboundsData.failure)
    )
  ]);
}
