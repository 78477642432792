import { AlertTriangle } from 'react-feather';
import { colors } from '@material-ui/core';
import TextWithIcon from '../TextWithIcon';

export interface AlertIconProps {
  title: string;
  destination?: string;
}

const AlertIcon = ({ title, destination = '' }: AlertIconProps) => {
  return (
    <TextWithIcon
      destination={destination}
      title={title}
      disabled={false}
      icon={<AlertTriangle size={18} color={colors.amber[700]} />}
    />
  );
};

export default AlertIcon;
