import { createAsyncAction, createCustomAction } from 'typesafe-actions';
import {
  CreatePassportPayload,
  GetPassportValidationStatusPayload
} from '../../types';

export const getPassportSettings = createAsyncAction(
  '@ROUTES/GET_PASSPORT_SETTINGS_REQUEST',
  '@ROUTES/GET_PASSPORT_SETTINGS_SUCCESS',
  '@ROUTES/GET_PASSPORT_SETTINGS_FAILURE'
)<undefined, CreatePassportPayload, Error>();

export const updateSettings = createAsyncAction(
  '@ROUTES/UPDATE_PASSPORT_SETTINGS_REQUEST',
  '@ROUTES/UPDATE_PASSPORT_SETTINGS_SUCCESS',
  '@ROUTES/UPDATE_PASSPORT_SETTINGS_FAILURE'
)<CreatePassportPayload, undefined, Error>();

export const setSettingsFormDirty = createCustomAction(
  '@ROUTES/SET_SETTINGS_FORM_DIRTY',
  (payload: boolean) => ({ payload })
);

export const getPassportValidationStatus = createAsyncAction(
  '@ROUTES/GET_PASSPORT_SETTINGS_VALIDATION_STATUS_REQUEST',
  '@ROUTES/GET_PASSPORT_SETTINGS_VALIDATION_STATUS_SUCCESS',
  '@ROUTES/GET_PASSPORT_SETTINGS_VALIDATION_STATUS_FAILURE'
)<undefined, GetPassportValidationStatusPayload, Error>();

export const resetPassportValidationStatus = createCustomAction(
  '@ROUTES/RESET_PASSPORT_VALIDATION_STATUS'
);
