import { RootState } from 'typesafe-actions';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { createSelector } from 'reselect';

export const selectBillingDocument = (state: RootState) => {
  return state.ui.clearing.billingDocuments.edit;
};

const matchSpaceParams = createMatchSelector<RouterRootState, { id: string }>(
  '/clearing/billing-documents/:id'
);

export const selectBillingDocumentId = createSelector(matchSpaceParams, match =>
  match ? match.params.id : ''
);
