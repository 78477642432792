import { Tooltip } from '@material-ui/core';
import { AlertTriangle as AlertTriangleIcon } from 'react-feather';
import { useStyles } from '../useStyles';
import { GPS_ICON_TOOLTIP_TITLE } from '../const';

const BillingDocumentsGPSIcon = () => {
  const classes = useStyles();

  return (
    <Tooltip title={GPS_ICON_TOOLTIP_TITLE}>
      <AlertTriangleIcon size={20} className={classes.warningIcon} />
    </Tooltip>
  );
};

export default BillingDocumentsGPSIcon;
