import { EmployeeEditDTO, SystemStatusDTO } from '@eticket/avl-api-contracts';
import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';

type EmployeeEditActions = ActionType<typeof actions>;

const initialState: EmployeeEditDTO = {
  id: 0,
  name: undefined,
  departmentId: 0,
  departmentName: '',
  professionId: 0,
  professionName: '',
  position: '',
  defaultContactIndex: undefined,
  contacts: undefined,
  status: SystemStatusDTO.Disabled,
  code: '',
  description: ''
};

const employeeEditReducers = createReducer<
  EmployeeEditDTO,
  EmployeeEditActions
>(initialState).handleAction(
  actions.getEmployee.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default employeeEditReducers;
