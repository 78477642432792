import { createAsyncAction } from 'typesafe-actions';
import { KpiDTO } from '@eticket/clearing-api-contracts';
import { Values } from '../../types';

export const getKpi = createAsyncAction(
  '@KPIS/GET_KPI_REQUEST',
  '@KPIS/GET_KPI_SUCCESS',
  '@KPIS/GET_KPI_FAILURE'
)<undefined, KpiDTO, Error>();

export const updateKpi = createAsyncAction(
  '@KPIS/UPDATE_KPI_REQUEST',
  '@KPIS/UPDATE_KPI_SUCCESS',
  '@KPIS/UPDATE_KPI_FAILURE'
)<Values, undefined, Error>();
