import { FC } from 'react';
import { Box, makeStyles, Theme, Typography, colors } from '@material-ui/core';
import { Lens as CircleIcon } from '@material-ui/icons';
import { UserStatus } from './types';
import { UserStatusMap } from './const';

const colorStateMap = {
  [UserStatus.Active]: colors.green[600],
  [UserStatus.Inactive]: colors.grey[600]
};

const useStyles = makeStyles<Theme, { status: UserStatus }>({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  circleIcon: ({ status }) => ({
    marginRight: 10,
    fontSize: 8,
    color: colorStateMap[status]
  })
});

interface Props {
  status: UserStatus;
}

const UsersStatusColumn: FC<Props> = ({ status }) => {
  const classes = useStyles({ status });

  return (
    <Box className={classes.root}>
      <CircleIcon className={classes.circleIcon} />
      <Typography variant="h6">{UserStatusMap[status]}</Typography>
    </Box>
  );
};

export default UsersStatusColumn;
