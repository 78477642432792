import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { PENALTIES_DOCUMENT } from 'src/config/clearingUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';
import { selectPenaltyDocumentId } from './selectors';

export function* getPenaltyDocument(): SagaIterator {
  const id = yield select(selectPenaltyDocumentId);

  const { data } = yield call(apiClient.get, `${PENALTIES_DOCUMENT}/${id}`, {
    retryAction: actions.getPenaltyDocument.request()
  });

  yield put(actions.getPenaltyDocument.success(data));
}

export function* editPenaltyDocument({
  payload
}: ReturnType<typeof actions.editPenaltyDocument.request>): SagaIterator {
  const id = yield select(selectPenaltyDocumentId);

  yield call(apiClient.put, `${PENALTIES_DOCUMENT}/${id}`, payload, {
    retryAction: actions.editPenaltyDocument.request(payload)
  });
  yield put(navigateTo({ url: '/clearing/penalty-documents' }));
  yield put(
    enqueueSnackbar({
      key: 'penalty_document_edit',
      message: 'Штраф відредаговано',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.editPenaltyDocument.success());
}

export default function* PenaltyDocumentEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getPenaltyDocument.request),
      safe(getPenaltyDocument, actions.getPenaltyDocument.failure)
    ),
    takeEvery(
      getType(actions.editPenaltyDocument.request),
      safe(editPenaltyDocument, actions.editPenaltyDocument.failure)
    )
  ]);
}
