import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import {
  BILLING_DOCUMENTS,
  BILLING_DOCUMENTS_CHANGE_STATUS
} from 'src/config/clearingUrls';
import * as actions from './actions';
import { selectBillingDocumentId } from './selectors';

export function* getBillingDocument(): SagaIterator {
  const id = yield select(selectBillingDocumentId);

  const { data } = yield call(apiClient.get, `${BILLING_DOCUMENTS}/${id}`, {
    retryAction: actions.getBillingDocument.request()
  });

  yield put(actions.getBillingDocument.success(data));
}

export function* updateBillingDocumentStatus({
  payload: status
}: ReturnType<
  typeof actions.updateBillingDocumentStatus.request
>): SagaIterator {
  const id = yield select(selectBillingDocumentId);

  yield call(
    apiClient.put,
    BILLING_DOCUMENTS_CHANGE_STATUS(id, status),
    {},
    {
      retryAction: actions.getBillingDocument.request()
    }
  );

  yield put(actions.getBillingDocument.request());
  yield put(actions.updateBillingDocumentStatus.success());
}

export default function* BillingDocumentDetailsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getBillingDocument.request),
      safe(getBillingDocument, actions.getBillingDocument.failure)
    ),
    takeEvery(
      getType(actions.updateBillingDocumentStatus.request),
      safe(
        updateBillingDocumentStatus,
        actions.updateBillingDocumentStatus.failure
      )
    )
  ]);
}
