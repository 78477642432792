import { merge } from 'lodash';
import * as MUI from '@material-ui/core';
import typography from './typography';
import { softShadows } from './shadows';
import { THEMES } from '../constants';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: MUI.colors.blue[500]
      },
      root: {
        borderBottom: `1px solid ${MUI.colors.grey[300]}`
      }
    }
  }
};

export const themeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: MUI.colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: MUI.colors.blueGrey[600]
      },
      background: {
        default: MUI.colors.common.white,
        paper: MUI.colors.common.white,
        dark: '#f4f6f8'
      },
      primary: {
        main: '#3c34d1'
      },
      secondary: {
        main: '#2196f3'
      },
      text: {
        primary: '#263238',
        secondary: '#546e7a',
        grey: '#d2d2d2'
      }
    },
    shadows: softShadows
  }
];

export function createTheme(settings: {
  theme: string;
  direction: string;
  responsiveFontSizes: boolean;
}) {
  let themeConfig = themeConfigs.find(item => item.name === settings.theme);

  if (!themeConfig) {
    [themeConfig] = themeConfigs;
  }

  const options = merge({}, baseConfig, themeConfig, {
    direction: settings.direction
  }) as Object;

  let theme = MUI.createTheme(options);

  if (settings.responsiveFontSizes) {
    theme = MUI.responsiveFontSizes(theme);
  }

  return theme;
}
