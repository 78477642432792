import { createCustomAction } from 'typesafe-actions';
import { GetDataSuccessPayload, TableConfig, SortRowsAction } from '../types';

export const getDataRequest = createCustomAction(
  '@TABLE/GET_DATA_REQUEST',
  (config: TableConfig) => ({
    config
  })
);

export const getDataSuccess = createCustomAction(
  '@TABLE/GET_DATA_SUCCESS',
  (config: TableConfig, payload: GetDataSuccessPayload) => ({
    payload,
    config
  })
);

export const getDataFailure = createCustomAction(
  '@TABLE/GET_DATA_FAILURE',
  (config: TableConfig, payload: Error) => ({ payload, config })
);

export const deleteRowRequest = createCustomAction(
  '@TABLE/DELETE_ROW_REQUEST',
  (config: TableConfig, payload: { id: number; name: string }) => ({
    payload,
    config
  })
);

export const deleteRowSuccess = createCustomAction(
  '@TABLE/DELETE_ROW_SUCCESS',
  (config: TableConfig) => ({
    config
  })
);

export const deleteRowFailure = createCustomAction(
  '@TABLE/DELETE_ROW_FAILURE',
  (config: TableConfig, payload: Error) => ({ payload, config })
);

export const sortRows = createCustomAction(
  '@TABLE/SORT_ROWS',
  (config, payload: SortRowsAction) => ({ config, payload })
);
