import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INSPECTIONS } from 'src/config/assetUrls';
import * as actions from './actions';

export function* approveInspection({
  payload: id
}: ReturnType<typeof actions.approveInspection.request>): SagaIterator {
  yield call(apiClient.put, `${INSPECTIONS}/${id}/change-status`, {
    inspectionStatusId: 1
  });

  yield put(actions.approveInspection.success());
}

export default function* InspectionEquipmentsStepSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.approveInspection.request),
    safe(approveInspection, actions.approveInspection.failure)
  );
}
