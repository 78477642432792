import { HourlyDeparture } from '../types';

export const hourlyDepartureReportMock: HourlyDeparture = {
  averagePlannedFactRatioPerDay: 42,
  routes: [
    {
      id: 3,
      name: 'route 1',
      plannedFactRatio: 44,
      timeSpans: [
        {
          from: '00:00',
          to: '01:00',
          fact: 82,
          planned: 92
        },
        {
          from: '01:00',
          to: '02:00',
          fact: 22,
          planned: 42
        },
        {
          from: '02:00',
          to: '03:00',
          fact: 33,
          planned: 12
        }
      ]
    }
  ]
};
