import { SagaIterator } from 'redux-saga';
import { takeEvery, call, select, put, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { addRowsLocally } from 'src/components/Tables/TableView/store/actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INSPECTIONS } from 'src/config/assetUrls';
import * as actions from './actions';
import * as selectors from './selectors';
import {
  parseInspectionFormPayload,
  parseInspectionTablePayload
} from './utils';
import {
  EDIT_INSPECTIONS_TABLE_CONFIG,
  EDIT_INSPECTION_FORM_CONFIG
} from '../const';
import { setFormValues } from '../../InspectionFormStep/store/actions';
import { selectFormEditData } from '../../InspectionFormStep/store/selectors';
import { selectListCreateData } from '../../InspectionsEquipmentStep/store/selectors';
import { StatusEnum } from '../../../../../constants';
import { getInspectionPdfDetails } from '../../InspectionsListView/store/actions';

export function* updateInspection({
  payload
}: ReturnType<typeof actions.updateInspection.request>): SagaIterator {
  const { id, equipmentPath, formPath } = payload;
  const [formData, equipment] = yield all([
    select(selectFormEditData(formPath)),
    select(selectListCreateData(equipmentPath))
  ]);

  yield call(apiClient.put, `${INSPECTIONS}/${id}`, {
    status: StatusEnum.Draft,
    equipment,
    ...formData
  });
  yield put(actions.updateInspection.success());
}

export function* getInspection(): SagaIterator {
  const id = yield select(selectors.selectInspectionId);
  const { data } = yield call(apiClient.get, `${INSPECTIONS}/${id}/edit`);
  const { equipments, ...formData } = data;

  yield put(
    setFormValues(
      EDIT_INSPECTION_FORM_CONFIG,
      parseInspectionFormPayload(formData)
    )
  );
  yield put(
    addRowsLocally(EDIT_INSPECTIONS_TABLE_CONFIG, {
      rows: parseInspectionTablePayload(equipments)
    })
  );
  yield put(getInspectionPdfDetails.success(data));
  yield put(actions.getInspection.success());
}

export default function* InspectionEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.updateInspection.request),
      safe(updateInspection, actions.updateInspection.failure)
    ),
    takeEvery(
      getType(actions.getInspection.request),
      safe(getInspection, actions.getInspection.failure)
    )
  ]);
}
