import { RoutePassportStatus } from 'src/views/avl/Routes/const';

export interface SettingsValues {
  generalSettings: GeneralSettings;
  vehicleRequirements: VehicleRequirements;
  wasApproved?: boolean;
}

export interface GeneralSettings {
  number: string;
  name: string;
  shortName: string;
  contractStartDate: string | null;
  contractEndDate: string | null;
  tariffGroupId: number | '';
  fare: number | string;
  effectiveDate: string | null;
  technicalSpeed: number | '';
  isTenderCompleted: boolean;
  notes: string;
  tariffGroupName?: string;
  counteragentId?: number | '';
}

export interface VehicleRequirements {
  vehicleCategoryId: number | '';
  vehicleCategoryName?: string;
  vehicleCategory: string;
  vehicleClass: string;
}

export interface CreatePassportPayload extends SettingsValues {
  status: RoutePassportStatus;
}

export interface SettingsFormProps {
  initialValues: SettingsValues;
  submitDispatcher?: Dispatcher;
}

export enum PassportMode {
  Create = 'create',
  Edit = 'edit',
  View = 'details'
}

export interface GetPassportValidationStatusPayload {
  wasPassportApproved: boolean;
  hasApprovedRouteSchema: boolean;
  hasApprovedRouteTimetable: boolean;
  hasActiveRouteTimetable: boolean;
  wasPassportApprovedWithTender: boolean;
  manualUpdate: boolean;
}
