import { MAIN_MENU } from 'src/constants';
import { generateModuleID } from 'src/components/Guards';
import {
  assetTrackingCrud,
  avlRoutesCrud,
  administrationCrud,
  clearingTariffsCrud
} from 'src/constants/permissions';

export interface TabConfig {
  label: string;
  value: string;
  moduleAction: string;
  linkAction: string;
  link: string;
}

export interface TabProps extends TabConfig {
  permissions: string[];
}

export const TABS_CONFIG: Array<TabConfig> = [
  {
    label: MAIN_MENU.avl,
    value: 'avl',
    moduleAction: generateModuleID('avl'),
    link: 'routes',
    linkAction: avlRoutesCrud.read
  },
  {
    label: MAIN_MENU.asset,
    value: 'asset',
    moduleAction: generateModuleID('assets'),
    link: 'tracking',
    linkAction: assetTrackingCrud.read
  },
  {
    label: MAIN_MENU.clearing,
    value: 'clearing',
    moduleAction: generateModuleID('clearing'),
    link: 'tariffs',
    linkAction: clearingTariffsCrud.read
  },
  {
    label: MAIN_MENU.administration,
    value: 'administration',
    moduleAction: generateModuleID('administration'),
    link: 'users',
    linkAction: administrationCrud.read
  }
];
