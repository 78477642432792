import { RootState } from 'typesafe-actions';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { createSelector } from 'reselect';
import { get } from 'lodash';
import { PassportMode } from '../../RoutesPassportLayout/Steps/Settings/types';
import { StepType } from '../../const';
import { checkTimetablesEnabled } from '../../utils/checkTimeTable';

interface Params {
  id: string;
  mode: PassportMode;
  step: StepType;
  subId: string;
  subMode: PassportMode;
  extraId: string;
  extraMode: PassportMode;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/avl/(routes|routes-view)/:mode(create|edit|details)/:step(settings|timetable|scheme)/:id?/:subMode(create|edit|details)?/:subId?/:extraMode(create|edit|details)?/:extraId?'
);

export const selectPassportMode = createSelector(matchSpaceParams, match =>
  match ? match.params.mode : ''
);

export const selectPassportId = createSelector(matchSpaceParams, match =>
  match ? match.params.id : ''
);

export const selectPassportStep = createSelector(matchSpaceParams, match =>
  match ? match.params.step : ''
);

export const selectSubId = createSelector(matchSpaceParams, match =>
  match ? match.params.subId : ''
);

export const selectExtraId = createSelector(matchSpaceParams, match =>
  match ? match.params.extraId : ''
);

export const selectExtraMode = createSelector(matchSpaceParams, match =>
  match ? match.params.extraMode : ''
);

export const selectIsTimetablesEnabled = (state: RootState) => {
  const {
    hasApprovedRouteSchema,
    manualUpdate
  } = state.ui.avl.routes.settings.details.validationStatus;
  const { list } = state.ui.avl.routes.schemes.table;

  return checkTimetablesEnabled(hasApprovedRouteSchema, list) || manualUpdate;
};

export const selectRouteParams = createSelector(
  matchSpaceParams,
  match =>
    match?.params || {
      id: '',
      mode: '',
      step: '',
      subId: '',
      subMode: '',
      extraMode: '',
      extraId: ''
    }
);

const matchSpaceRoutePassportHistory = createMatchSelector<
  RouterRootState,
  Params
>('/avl/routes-history/:id');

export const selectRouteHistoryParams = createSelector(
  matchSpaceRoutePassportHistory,
  match =>
    match?.params || {
      id: ''
    }
);

export const selectPassportSettingsLoading = (state: RootState) =>
  get(state, `loading.@ROUTES/GET_PASSPORT_SETTINGS_VALIDATION_STATUS`, true);
