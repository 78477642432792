import { get } from 'lodash';
import { Order } from './types';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  let first = get(a, orderBy);
  let second = get(b, orderBy);
  if (typeof first === 'string') first = first.toLowerCase();
  if (typeof second === 'string') second = second.toLowerCase();

  if (second < first) {
    return -1;
  }
  if (second > first) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'DESC'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export { stableSort, getComparator };
