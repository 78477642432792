export enum Status {
  Active = 'Active',
  Terminated = 'Terminated'
}

export interface ApiKeysTableRow {
  id: number;
  createdAtUtc: string;
  key: string;
  apiKeyName: string;
  requestedAtUtc: string;
  status: Status;
}

export interface ApiKeysValues {
  name: string;
  key: string;
}

export interface CreatePayload {
  name: string;
}
