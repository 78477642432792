import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createReduxPromiseListener from 'redux-promise-listener';
import { loadUser } from 'redux-oidc';
import userManager from 'src/utils/userManager';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

const reduxPromiseListener = createReduxPromiseListener();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const browserHistory = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const rootReducers = combineReducers({
  ...rootReducer,
  router: connectRouter(browserHistory)
});

const store = createStore(
  rootReducers,
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(browserHistory),
      reduxPromiseListener.middleware
    )
  )
);

sagaMiddleware.run(rootSaga);

export const promiseListener = reduxPromiseListener;

loadUser(store, userManager);

export default store;
