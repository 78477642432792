import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { RouteSettingsValues } from '../types';

export const initialState: RouteSettingsValues = {
  topLeftPoint: '',
  bottomRightPoint: '',
  defaultStopRadiusInMeters: 0,
  maxDeviationDistanceFromRouteMeters: 0,
  busStopChangeThreshold: 0,
  routeRideTimeoutThreshold: '00:00',
  routeDeviation: {
    noDeviationDelay: 0,
    noDeviationOvertaking: 0,
    lowDeviationDelay: 0,
    lowDeviationOvertaking: 0
  },
  speedLimitInKmH: 0,
  requiredPercentageOfExecutionHalfCircle: 0
};

const routeSettingsReducer = createReducer<
  RouteSettingsValues,
  ActionType<typeof actions>
>(initialState).handleAction(
  actions.getRouteSettings.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default routeSettingsReducer;
