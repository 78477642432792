import { lazy } from 'react';
import { Redirect } from 'react-router';
import { generateActionID } from 'src/components/Guards';

export const INSPECTIONS_URL = '/asset/inspections';
export const INSPECTIONS_DETAILS_URL = '/asset/inspections/:id/details';
export const INSPECTIONS_EDIT_URL = '/asset/inspections/:id';

const assetRoutes = [
  {
    exact: true,
    path: '/asset',
    component: () => <Redirect to="asset/tracking" />
  },
  {
    exact: true,
    path: ['/asset/dictionaries/equipment'],
    component: lazy(() =>
      import('src/views/asset/equipment/EquipmentListView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'equipment',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/dictionaries/equipment/create'],
    component: lazy(() =>
      import('src/views/asset/equipment/EquipmentCreateView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'equipment',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/asset/dictionaries/equipment/:id'],
    component: lazy(() =>
      import('src/views/asset/equipment/EquipmentEditView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'equipment',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/dictionaries/equipment/:id/details'],
    component: lazy(() =>
      import('src/views/asset/equipment/EquipmentDetailsView')
    )
  },

  {
    exact: true,
    path: ['/asset/dictionaries/states'],
    component: lazy(() => import('src/views/asset/states/StatesListView')),
    action: generateActionID({
      module: 'assets',
      sub: 'states',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/dictionaries/states/create'],
    component: lazy(() => import('src/views/asset/states/StatesCreateView')),
    action: generateActionID({
      module: 'assets',
      sub: 'states',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/asset/dictionaries/states/:id'],
    component: lazy(() => import('src/views/asset/states/StatesEditView')),
    action: generateActionID({
      module: 'assets',
      sub: 'states',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/asset/dictionaries/states/:id/details'],
    component: lazy(() => import('src/views/asset/states/StatesDetailsView')),
    action: generateActionID({
      module: 'assets',
      sub: 'states',
      action: 'read'
    })
  },

  {
    exact: true,
    path: ['/asset/state-changes'],
    component: lazy(() =>
      import('src/views/asset/stateChange/StateChangeListView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'stateChanges',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/state-changes/create/:id?'],
    component: lazy(() =>
      import('src/views/asset/stateChange/StateChangeCreateView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'stateChanges',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/asset/state-changes/:id'],
    component: lazy(() =>
      import('src/views/asset/stateChange/StateChangeEditView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'stateChanges',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/asset/state-changes/:id/details'],
    component: lazy(() =>
      import('src/views/asset/stateChange/StateChangeDetailsView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'stateChanges',
      action: 'read'
    })
  },

  {
    exact: true,
    path: ['/asset/dictionaries/breakdownsTypes'],
    component: lazy(() =>
      import('src/views/asset/breakdownsTypes/BreakdownsTypesListView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'breakdownsTypes',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/dictionaries/breakdownsTypes/create'],
    component: lazy(() =>
      import('src/views/asset/breakdownsTypes/BreakdownsTypesCreateView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'breakdownsTypes',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/asset/dictionaries/breakdownsTypes/:id'],
    component: lazy(() =>
      import('src/views/asset/breakdownsTypes/BreakdownsTypesEditView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'breakdownsTypes',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/asset/dictionaries/breakdownsTypes/:id/details'],
    component: lazy(() =>
      import('src/views/asset/breakdownsTypes/BreakdownsTypesDetailsView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'breakdownsTypes',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/installation'],
    component: lazy(() =>
      import('src/views/asset/installation/InstallationListView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'installation',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/installation/create/:id?'],
    component: lazy(() =>
      import('src/views/asset/installation/InstallationCreateView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'installation',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/asset/installation/:id/details'],
    component: lazy(() =>
      import('src/views/asset/installation/InstallationDetailsView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'installation',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/installation/:id'],
    component: lazy(() =>
      import('src/views/asset/installation/InstallationEditView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'installation',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/asset/uninstallation'],
    component: lazy(() =>
      import('src/views/asset/uninstallation/UninstallationListView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'uninstallation',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/replacement/create'],
    component: lazy(() =>
      import('src/views/asset/replacement/ReplacementCreateView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'installation',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/asset/uninstallation/create/:id?'],
    component: lazy(() =>
      import('src/views/asset/uninstallation/UninstallationCreateView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'uninstallation',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/asset/uninstallation/:id'],
    component: lazy(() =>
      import('src/views/asset/uninstallation/UninstallationEditView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'uninstallation',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/asset/uninstallation/:id/details'],
    component: lazy(() =>
      import('src/views/asset/uninstallation/UninstallationDetailsView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'uninstallation',
      action: 'read'
    })
  },

  {
    exact: true,
    path: ['/asset/transfer'],
    component: lazy(() => import('src/views/asset/transfer/TransferListView')),
    action: generateActionID({
      module: 'assets',
      sub: 'transfer',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/transfer/create/:id?'],
    component: lazy(() =>
      import('src/views/asset/transfer/TransferCreateView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'transfer',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/asset/transfer/:id'],
    component: lazy(() => import('src/views/asset/transfer/TransferEditView')),
    action: generateActionID({
      module: 'assets',
      sub: 'transfer',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/asset/transfer/:id/details'],
    component: lazy(() =>
      import('src/views/asset/transfer/TransferDetailsView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'transfer',
      action: 'read'
    })
  },

  {
    exact: true,
    path: ['/asset/inbound'],
    component: lazy(() => import('src/views/asset/inbound/InboundListView')),
    action: generateActionID({
      module: 'assets',
      sub: 'inbound',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/inbound/create/:id?'],
    component: lazy(() => import('src/views/asset/inbound/InboundCreateView')),
    action: generateActionID({
      module: 'assets',
      sub: 'inbound',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/asset/inbound/:id/details'],
    component: lazy(() => import('src/views/asset/inbound/InboundDetailsView')),
    action: generateActionID({
      module: 'assets',
      sub: 'inbound',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/inbound/:id'],
    component: lazy(() => import('src/views/asset/inbound/InboundEditView')),
    action: generateActionID({
      module: 'assets',
      sub: 'inbound',
      action: 'update'
    })
  },

  {
    exact: true,
    path: ['/asset/inspections/create/:id?'],
    component: lazy(() =>
      import('src/views/asset/inspections/InspectionsCreateView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'inspections',
      action: 'create'
    })
  },
  {
    exact: true,
    path: [INSPECTIONS_EDIT_URL],
    component: lazy(() =>
      import('src/views/asset/inspections/InspectionEditView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'inspections',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/asset/breakdowns/create/:id?'],
    component: lazy(() =>
      import('src/views/asset/breakdowns/BreakdownsCreateView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'breakdowns',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/asset/inspections'],
    component: lazy(() =>
      import('src/views/asset/inspections/InspectionsListView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'inspections',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/tracking'],
    component: lazy(() => import('src/views/asset/tracking/TrackingListView')),
    action: generateActionID({
      module: 'assets',
      sub: 'tracking',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/breakdowns'],
    component: lazy(() =>
      import('src/views/asset/breakdowns/BreakdownsListView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'breakdowns',
      action: 'read'
    })
  },
  {
    exact: true,
    path: [INSPECTIONS_DETAILS_URL],
    component: lazy(() =>
      import('src/views/asset/inspections/InspectionDetailsView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'inspections',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/breakdowns/:id/details'],
    component: lazy(() =>
      import('src/views/asset/breakdowns/BreakdownDetailsView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'breakdowns',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/asset/breakdowns/:id'],
    component: lazy(() =>
      import('src/views/asset/breakdowns/BreakdownsEditView')
    ),
    action: generateActionID({
      module: 'assets',
      sub: 'breakdowns',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/asset/reports'],
    component: lazy(() => import('src/views/asset/reports')),
    action: generateActionID({
      module: 'assets',
      sub: 'reports',
      action: 'read'
    })
  }
];

export default assetRoutes;
