import { put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { BREAKDOWNS_TYPES } from 'src/config/assetUrls';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getBreakdownsTypes(): SagaIterator {
  const id = yield select(selectors.selectBreakdownsTypesId);

  const { data } = yield call(apiClient.get, `${BREAKDOWNS_TYPES}/${id}`, {
    retryAction: actions.getBreakdownsTypes.request
  });

  yield put(actions.getBreakdownsTypes.success(data));
}

export default function* BreakdownsTypesDetailsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getBreakdownsTypes.request),
    safe(getBreakdownsTypes, actions.getBreakdownsTypes.failure)
  );
}
