import { INSTALLATIONS } from 'src/config/assetUrls';

export const EDIT_INSTALLATION_FORM_NAME = 'EDIT_INSTALLATION_FORM';
export const EDIT_INSTALLATION_FORM_PATH = 'asset.installation.edit.data';
export const EDIT_INSTALLATION_FORM_CONFIG = {
  reducerName: EDIT_INSTALLATION_FORM_NAME,
  reducerPath: EDIT_INSTALLATION_FORM_PATH
};

export const EDIT_INSTALLING_EQUIPMENT_NAME =
  'EDIT_INSTALLATION_EQUIPMENT_TABLE_VIEW';
export const EDIT_INSTALLING_EQUIPMENT_PATH = 'asset.installation.edit.table';

export const getEditTableViewConfig = (id: string) => ({
  reducerName: EDIT_INSTALLING_EQUIPMENT_NAME,
  reducerPath: EDIT_INSTALLING_EQUIPMENT_PATH,
  apiUrl: `${INSTALLATIONS}/${id}/equipment`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено реєстрацію'
  },
  enableSetRowsIsLocalProperty: true
});
