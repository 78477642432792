import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import LoadingScreen from 'src/components/LoadingScreen';
import * as selectors from 'src/store/selectors';
import { selectUserPermissions } from 'src/store/selectors';
import TopBar from './TopBar';
import NavBar from './NavBar';

const useStyles = makeStyles<Theme, { isNavbarVisible: boolean }>(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: ({ isNavbarVisible }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    paddingLeft: isNavbarVisible ? 256 : ''
  }),
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

type Props = {
  children?: React.ReactNode;
};

function MainLayout({ children }: Props) {
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up('lg'));
  const permissions = useSelector(selectUserPermissions);
  const hasPermissions = !!permissions.length;

  const classes = useStyles({ isNavbarVisible: hasPermissions && isLaptop });
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const isLoading = useSelector(selectors.selectIsLoading);

  return (
    <div className={classes.root}>
      {hasPermissions && (
        <>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
        </>
      )}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
            {isLoading && <LoadingScreen />}
          </div>
        </div>
      </div>
    </div>
  );
}

MainLayout.defaultProps = {
  children: <></>
};

export default MainLayout;
