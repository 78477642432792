import { createAsyncAction } from 'typesafe-actions';
import { TimetablePdfData } from '../types';

export const getTimetablePdfData = createAsyncAction(
  '@ROUTES_TIMETABLE/GET_TIMETABLE_PDF_DATA_REQUEST',
  '@ROUTES_TIMETABLE/GET_TIMETABLE_PDF_DATA_SUCCESS',
  '@ROUTES_TIMETABLE/GET_TIMETABLE_PDF_DATA_FAILURE'
)<number, TimetablePdfData, Error>();

export const importDeparture = createAsyncAction(
  '@ROUTES_TIMETABLE/IMPORT_DEPARTURE_REQUEST',
  '@ROUTES_TIMETABLE/IMPORT_DEPARTURE_SUCCESS',
  '@ROUTES_TIMETABLE/IMPORT_DEPARTURE_FAILURE'
)<FormData, undefined, Error>();
