import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { COUNTERAGENTS } from 'src/config/avlUrls';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getCounteragent(): SagaIterator {
  const id = yield select(selectors.selectCounteragentId);

  const { data } = yield call(apiClient.get, `${COUNTERAGENTS}/${id}/edit`, {
    retryAction: actions.getCounteragent.request()
  });

  yield put(actions.getCounteragent.success(data));
}

export function* updateCounteragent({
  payload
}: ReturnType<typeof actions.updateCounteragent.request>): SagaIterator {
  const id = yield select(selectors.selectCounteragentId);

  yield call(apiClient.put, `${COUNTERAGENTS}/${id}`, payload, {
    retryAction: actions.updateCounteragent.request(payload)
  });
  yield put(navigateTo({ url: '/avl/geozones/counteragents' }));
  yield put(
    enqueueSnackbar({
      key: 'counteragents_edit',
      message: 'Підрозділ відредаговано',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.updateCounteragent.success());
}

export default function* counteragentsEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getCounteragent.request),
      safe(getCounteragent, actions.getCounteragent.failure)
    ),
    takeEvery(
      getType(actions.updateCounteragent.request),
      safe(updateCounteragent, actions.updateCounteragent.failure)
    )
  ]);
}
