import { array, number, object, string } from 'yup';
import {
  AVL_ERROR_MESSAGES,
  ERROR_MESSAGES,
  phoneNumberRegExp
} from 'src/constants/validation';
import { LAT_LON_REGEX } from 'src/constants/map';
import {
  COUNTERAGENT_CHILDREN,
  COUNTERAGENTS,
  COUNTERAGENTS_CUSTOM_TYPES,
  COUNTERAGENTS_TYPES,
  PARENT_COUNTERGAGENTS
} from 'src/config/avlUrls';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { generateActionCrud } from 'src/components/Guards';
import { maxLength } from 'src/utils/validation';
import { SystemStatusDTO } from '@eticket/avl-api-contracts';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.avl,
    url: '/avl'
  },
  {
    text: NAV_BAR_ITEMS.geozones,
    url: '/avl/geozones/counteragents'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.counteragents,
    url: '/avl/geozones/counteragents'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'avl',
    sub: 'counteragents'
  },
  ['download']
);

export const counteragentsValidationsSchema = object().shape({
  name: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(...maxLength(100)),
  shortName: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(...maxLength(60)),
  code: string()
    .trim()
    .max(...maxLength(20))
    .when('$editMode', {
      is: true,
      then: string().required(ERROR_MESSAGES.REQUIRED)
    }),
  departmentTypeId: number().required(ERROR_MESSAGES.REQUIRED),
  status: string()
    .required(ERROR_MESSAGES.REQUIRED)
    .test({
      name: 'status',
      test(value) {
        // @ts-ignore
        const { counteragentChildren } = this.options.context;

        if (
          value === SystemStatusDTO.Disabled &&
          counteragentChildren?.length
        ) {
          throw this.createError({
            path: this.path,
            message: `Підрозділ використовується як батьківський для: ${counteragentChildren!
              .map((child: { name: string }) => child.name)
              .join(', ')}`
          });
        }

        return true;
      }
    }),
  description: string().max(...maxLength(255)),
  address: string()
    .trim()
    .when('$isDepotSelected', {
      is: true,
      then: string().required(ERROR_MESSAGES.REQUIRED)
    })
    .max(...maxLength(255)),
  coordinates: string()
    .when('$isDepotSelected', {
      is: true,
      then: string().required(ERROR_MESSAGES.REQUIRED)
    })
    .matches(
      LAT_LON_REGEX,
      'Невірний формат координат. Для збереження точки введіть дані у форматі ХХ.ХХХХХХ (широта), ХХ.ХХХХХХ (довгота) або вкажіть точку безпосередньо на мапі'
    ),
  contacts: array().of(
    object().shape({
      email: string()
        .max(...maxLength(60))
        .email(ERROR_MESSAGES.TYPE_ERROR)
        .required(ERROR_MESSAGES.REQUIRED),
      phoneNumber: string()
        .required(ERROR_MESSAGES.REQUIRED)
        .matches(phoneNumberRegExp, ERROR_MESSAGES.TYPE_ERROR),
      description: string().max(...maxLength(255))
    })
  )
});

export const COUNTERAGENTS_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  UniqueError: AVL_ERROR_MESSAGES.uniqueError,
  forbidden: undefined
};

export const COUNTERAGENTS_SNACKBAR_ERRORS = {
  forbidden: 'Даний підрозділ використовується для діючих маршрутів',
  unprocessableEntity:
    'Даний підрозділ використовується як перевізник для діючих маршрутів'
} as Record<string, string>;

export const COUNTERAGENTS_SELECT_NAME = 'COUNTERAGENTS_SELECT';
export const COUNTERAGENTS_SELECT_PATH = 'avl.counteragents.select';
export const COUNTERAGENTS_SELECT_CONFIG = (
  id?: string,
  counteragentTypeId?: number[]
) => {
  const baseUrl = id ? PARENT_COUNTERGAGENTS(id) : COUNTERAGENTS;
  const query = counteragentTypeId
    ?.map((typeId: number) => `counteragentTypeId=${typeId}`)
    .join('&');

  return {
    optionsApiUrl: `${baseUrl}?${query}`,
    reducerName: COUNTERAGENTS_SELECT_NAME,
    reducerPath: COUNTERAGENTS_SELECT_PATH
  };
};

export const COUNTERAGENT_TYPE_SELECT_NAME = 'TYPES_SELECT';
export const COUNTERAGENT_TYPE_SELECT_PATH = 'avl.counteragents.editableSelect';
export const COUNTERAGENTS_TYPES_CONFIG = {
  reducerPath: COUNTERAGENT_TYPE_SELECT_PATH,
  reducerName: COUNTERAGENT_TYPE_SELECT_NAME,
  customOptionsApiUrl: COUNTERAGENTS_CUSTOM_TYPES,
  activeOptionsApiUrl: COUNTERAGENTS_TYPES
};
export const COUNTERAGENTS_DIALOG_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  UniqueError: ERROR_MESSAGES.UNIQUE
};

export const DEPOT = 'Депо';

export const COUNTERAGENT_CHILDREN_NAME = 'COUNTERAGENT_CHILDREN_NAME';
export const COUNTERAGENT_CHILDREN_PATH =
  'avl.counteragents.counteragentChildrenAutocomplete';
export const COUNTERAGENT_CHILDREN_CONFIG = (counteragentId: number) => ({
  optionsApiUrl: COUNTERAGENT_CHILDREN(counteragentId),
  reducerName: COUNTERAGENT_CHILDREN_NAME,
  reducerPath: COUNTERAGENT_CHILDREN_PATH,
  prohibitDynamicLoad: true
});

export const COUNTERAGENT_OFFICE_TYPE = 'Офіс';
export const COUNTERAGENT_DEPOT_TYPE = 'Депо';
export const COUNTERAGENT_WAREHOUSE_TYPE = 'Склад';
