import { ActionType, createReducer } from 'typesafe-actions';
import { Employee } from 'src/views/avl/employees/types';
import * as actions from '../actions/app';

export type UserActions = ActionType<typeof actions>;

interface UserState {
  employee?: Employee | null;
}

export const initialState: UserState = {
  employee: undefined
};

const userReducers = createReducer<UserState, UserActions>(initialState)
  .handleAction(actions.getUserEmployee.success, (state, { payload }) => ({
    ...state,
    employee: payload
  }))
  .handleAction(actions.getUserEmployee.failure, state => ({
    ...state,
    employee: null
  }));

export default userReducers;
