import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { INSTALLATIONS } from 'src/config/assetUrls';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';
import { selectInstallationId } from '../../InstallationStepView/store/selectors';

export function* confirmInstallation({
  payload: installationId
}: ReturnType<typeof actions.confirmInstallation.request>): SagaIterator {
  yield call(apiClient.put, `${INSTALLATIONS}/approve`, {
    installationId
  });
  yield put(actions.confirmInstallation.success());
}

export function* cancelInstallation(): SagaIterator {
  const installationId = yield select(selectInstallationId);

  yield call(apiClient.delete, `${INSTALLATIONS}/${installationId}`);
  yield put(actions.cancelInstallation.success());

  yield put(navigateTo({ url: '/asset/installation' }));
}

export default function* ConfirmInstallationSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.confirmInstallation.request),
      safe(confirmInstallation, actions.confirmInstallation.failure)
    ),
    takeEvery(
      getType(actions.cancelInstallation.request),
      safe(cancelInstallation, actions.cancelInstallation.failure)
    )
  ]);
}
