import { get } from 'lodash';
import { RootState } from 'typesafe-actions';
import { Option } from '../types';

export const selectOptions = (
  reducerPath: string,
  transformFunc?: (options: Option[]) => Option[]
) => (state: RootState): Option[] => {
  const options = get(state, `ui.${reducerPath}`, []);
  return transformFunc ? transformFunc(options) : options;
};
