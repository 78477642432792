import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { ROUTE_TIMETABLE } from 'src/config/avlUrls';
import { selectSubId } from 'src/views/avl/Routes/RoutesPassportView/store/selectors';
import { selectCurrentUrl } from 'src/store/selectors';
import { navigateTo } from 'src/store/actions/app';
import * as actions from './actions';

export function* createDepartureSaga({
  payload
}: ReturnType<typeof actions.createDeparture.request>): SagaIterator {
  const subId = yield select(selectSubId);
  const currentUrl = yield select(selectCurrentUrl);

  const { data } = yield call(
    apiClient.post,
    `${ROUTE_TIMETABLE}/${subId}/route-departures`,
    payload,
    {
      retryAction: actions.createDeparture.request(payload)
    }
  );
  yield put(navigateTo({ url: `${currentUrl}/${data}` }));
  yield put(
    enqueueSnackbar({
      key: 'departure_create',
      message: 'Випуск створено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.createDeparture.success());
}

export default function* PassportCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createDeparture.request),
    safe(createDepartureSaga, actions.createDeparture.failure)
  );
}
