import { createSelector } from 'reselect';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_SLASH } from 'src/config';
import { KpiParametersState } from '../../const';
import KpiStateLabel from '../../KpiParametersListView/KpiStateLabel';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/clearing/kpiParameters/:id/details'
);

export const selectKpiParametersId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectIsLoadingKpiParameters = (state: RootState) => {
  const loading = get(
    state,
    `loading.@KPI_PARAMETERS_DETAILS/GET_KPI_PARAMETERS`
  );

  return isBoolean(loading) ? loading : true;
};

export const selectKpiParametersDetails = (state: RootState) =>
  state.ui.clearing.kpiParameters.details;

export const selectKpiParametersRows = createSelector(
  selectKpiParametersDetails,
  ({ groupName, effectiveDate, state, endsOn }) => {
    return [
      { label: 'Назва групи параметрів', value: groupName },
      {
        label: 'Початок дії',
        value:
          state === KpiParametersState.Expired
            ? `${utcToLocal(effectiveDate, DATE_FORMAT_SLASH)} - ${utcToLocal(
                endsOn,
                DATE_FORMAT_SLASH
              )}`
            : `${utcToLocal(effectiveDate, DATE_FORMAT_SLASH)}`
      },
      {
        label: 'Стан',
        value: <KpiStateLabel state={state} />
      }
    ];
  }
);

export const selectKpiParametersKpis = (state: RootState) =>
  state.ui.clearing.kpiParameters.details.kpis;

export const selectKpiParametersName = (state: RootState) =>
  state.ui.clearing.kpiParameters.details.groupName;
