import { createAsyncAction } from 'typesafe-actions';
import { Values, BusStop } from '../../types';

export const getStop = createAsyncAction(
  '@STOPS_EDIT/GET_STOP_REQUEST',
  '@STOPS_EDIT/GET_STOP_SUCCESS',
  '@STOPS_EDIT/GET_STOP_FAILURE'
)<undefined, BusStop, Error>();

export const updateStop = createAsyncAction(
  '@STOPS_EDIT/UPDATE_STOP_REQUEST',
  '@STOPS_EDIT/UPDATE_STOP_SUCCESS',
  '@STOPS_EDIT/UPDATE_STOP_FAILURE'
)<Values, undefined, Error>();
