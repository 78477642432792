import { createAsyncAction, createCustomAction } from 'typesafe-actions';
import {
  ChangeOptionStatusRequest,
  EditableSelectConfig,
  Option,
  UpdateOptionsRequest
} from '../types';

export const getOptionsRequest = createCustomAction(
  '@EDITABLE_SELECT/GET_ACTIVE_OPTIONS_REQUEST',
  (config: EditableSelectConfig) => ({
    config
  })
);

export const getOptionsSuccess = createCustomAction(
  '@EDITABLE_SELECT/GET_ACTIVE_OPTIONS_SUCCESS',
  (
    config: EditableSelectConfig,
    payload: { active: Option[]; custom: Option[] }
  ) => ({
    config,
    payload
  })
);

export const getOptionsFailure = createCustomAction(
  '@EDITABLE_SELECT/GET_ACTIVE_OPTIONS_FAILURE',
  (config: EditableSelectConfig, payload: Error) => ({
    config,
    payload
  })
);

export const setOptionEditDialogShown = createCustomAction(
  '@EDITABLE_SELECT/SET_OPTION_EDIT_DIALOG_SHOWN',
  (config: EditableSelectConfig, showDialog: boolean) => ({
    config,
    showDialog
  })
);

export const setEditableOption = createCustomAction(
  '@EDITABLE_SELECT/SET_EDITABLE_OPTION',
  (config: EditableSelectConfig, editableOption: Option | null) => ({
    config,
    editableOption
  })
);

export const changeOptionStatusRequest = createCustomAction(
  '@EDITABLE_SELECT/CHANGE_STATUS_REQUEST',
  (config: EditableSelectConfig, payload: ChangeOptionStatusRequest) => ({
    payload,
    config
  })
);

export const changeOptionStatusSuccess = createCustomAction(
  '@EDITABLE_SELECT/CHANGE_STATUS_SUCCESS',
  (config: EditableSelectConfig) => ({ config })
);

export const changeOptionStatusFailure = createCustomAction(
  '@EDITABLE_SELECT/CHANGE_STATUS_FAILURE',
  (config: EditableSelectConfig, payload: Error) => ({ payload, config })
);

export const updateCustomOptions = createAsyncAction(
  '@EDITABLE_SELECT/UPDATE_CUSTOM_OPTIONS_REQUEST',
  '@EDITABLE_SELECT/UPDATE_CUSTOM_OPTIONS_SUCCESS',
  '@EDITABLE_SELECT/UPDATE_CUSTOM_OPTIONS_FAILURE'
)<
  { config: EditableSelectConfig; requestData: UpdateOptionsRequest },
  { config: EditableSelectConfig; id: number | null },
  { config: EditableSelectConfig; error: Error }
>();
