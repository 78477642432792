import { RootState } from 'typesafe-actions';
import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { convertUtcToLocal } from 'src/utils/date';
import { DATE_FORMAT_SLASH } from 'src/config';

export const selectPenaltyDocument = (state: RootState) =>
  state.ui.clearing.penaltyDocuments.edit;

export const selectPenaltyDocumentValues = createSelector(
  selectPenaltyDocument,
  document => ({
    ...document,
    documentDate: document.documentDate
      ? convertUtcToLocal(document.documentDate)
      : null
  })
);

const matchSpaceParams = createMatchSelector('/clearing/penalty-documents/:id');

export const selectPenaltyDocumentId = createSelector(
  matchSpaceParams,
  (match: any) => {
    return match ? match.params.id : '';
  }
);

export const selectPenaltyDocumentRows = createSelector(
  selectPenaltyDocument,
  document => [
    {
      label: 'Дата документа',
      value: document.documentDate
        ? convertUtcToLocal(document.documentDate, DATE_FORMAT_SLASH)
        : ''
    },
    { label: 'Назва документа', value: document.documentName },
    { label: 'Перевізник', value: document.counteragent?.name },
    {
      label: 'Назва маршруту',
      value: `${document.routePassport?.number}, ${document.routePassport?.name}`
    },
    { label: 'Номерний знак', value: document.vehicle?.name },
    { label: 'Штраф', value: document.penalty?.name },
    { label: 'Сума, грн', value: document.amount },
    { label: 'Статус', value: document.penaltyDocumentStatus }
  ]
);
