import { SystemStatusDTO } from '@eticket/avl-api-contracts';
import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { VehicleEditState } from '../types';

type VehicleEditActions = ActionType<typeof actions>;

const initialState: VehicleEditState = {
  id: 0,
  licensePlate: '',
  vehicleModelId: '',
  driver: null,
  counteragent: null,
  status: SystemStatusDTO.Enabled
};

const vehicleEditReducers = createReducer<VehicleEditState, VehicleEditActions>(
  initialState
).handleAction(actions.getVehicle.success, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default vehicleEditReducers;
