import { createAsyncAction } from 'typesafe-actions';
import { ApiKeysValues, CreatePayload } from '../types';

export const createApiKey = createAsyncAction(
  '@API_KEYS/CREATE_API_KEY_REQUEST',
  '@API_KEYS/CREATE_API_KEY_SUCCESS',
  '@API_KEYS/CREATE_API_KEY_FAILURE'
)<CreatePayload, ApiKeysValues, Error>();

export const revokeApiKey = createAsyncAction(
  '@API_KEYS/REVOKE_API_KEY_REQUEST',
  '@API_KEYS/REVOKE_API_KEY_SUCCESS',
  '@API_KEYS/REVOKE_API_KEY_FAILURE'
)<number, undefined, Error>();
