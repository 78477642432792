import { SagaIterator } from 'redux-saga';
import { takeEvery, call, select, put } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { StatusEnum } from 'src/constants';
import { TRANSFERS } from 'src/config/assetUrls';
import * as actions from './actions';
import { selectNormalizedList } from '../../TransferEquipmentsStep/store/selectors';
import { selectFormCreateData } from '../../TransferStep/store/selectors';

export function* createDraftTransfer({
  payload
}: ReturnType<typeof actions.createDraftTransfer.request>): SagaIterator {
  const { formConfig, tablePath } = payload;
  const formData = yield select(selectFormCreateData(formConfig.reducerPath));
  const equipment = yield select(selectNormalizedList(tablePath));

  const { data: transferId } = yield call(
    apiClient.post,
    TRANSFERS,
    {
      ...formData,
      status: StatusEnum.Draft,
      equipment
    },
    {
      retryAction: actions.createDraftTransfer.request(payload)
    }
  );

  yield put(actions.createDraftTransfer.success(transferId));
}

export default function* TransferCreateView(): SagaIterator {
  yield takeEvery(
    getType(actions.createDraftTransfer.request),
    safe(createDraftTransfer, actions.createDraftTransfer.failure)
  );
}
