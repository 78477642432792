import {
  BookOpen as BookOpenIcon,
  Clipboard as ClipboardIcon,
  Codepen as CodepenIcon,
  FileText as FileIcon,
  Grid as GridIcon,
  Map as MapIcon,
  Settings as SettingsIcon,
  TrendingUp as TrendingUpIcon,
  Users as UsersIcon,
  Sliders as SlidersIcon,
  Database as DatabaseIcon,
  Archive as ArchiveIcon
} from 'react-feather';
import {
  Commute as VehicleIcon,
  DriveEta as VehicleTypeIcon
} from '@material-ui/icons';
import {
  NAV_BAR_HEADERS,
  NAV_BAR_ITEMS,
  NAV_BAR_SUB_HEADERS
} from 'src/constants';
import { INSPECTIONS_URL } from 'src/routes/assetRoutes';
import { COEFFICIENTS_ADJUSTMENTS_URL } from 'src/routes/coefficientsAdjustmentsRoutes';
import {
  ADMINISTRATION_PATH,
  API_KEYS_SETTINGS_PATH,
  LOGS_PATH,
  OTHER_SETTINGS_PATH,
  PAYMENTS_SETTINGS_PATH,
  ROUTE_SETTINGS_PATH,
  USERS_PATH
} from 'src/routes/administrationRoutes';
import { avlReportsNavItems } from 'src/views/avl/reports/const';
import { generateActionID } from 'src/components/Guards';
import {
  generateAdministrationAction,
  generateAssetsAction,
  generateAvlAction,
  generateClearingAction
} from './generateAction';

import { PermissionItem } from './types';

const populatePermissions = (menu: PermissionItem[]): Array<any> => {
  return menu.map((menuItem: PermissionItem) => {
    if (menuItem.items) {
      const items = populatePermissions(menuItem.items as []);

      // collect child actions and update the parent one
      return {
        ...menuItem,
        items,
        action: items.map(item => item.action).join('|')
      };
    }

    return menuItem;
  });
};
const config = [
  {
    menuItem: 'avl',
    items: [
      {
        id: '1',
        subheader: NAV_BAR_HEADERS.routes.toUpperCase(),
        items: [
          {
            id: '1.1',
            title: NAV_BAR_ITEMS.routes,
            icon: CodepenIcon,
            href: '/avl/routes',
            exact: false,
            action: generateAvlAction('routes')
          }
        ]
      },
      {
        id: '2',
        subheader: NAV_BAR_HEADERS.scheduling.toUpperCase(),
        items: [
          {
            id: '2.1',
            title: NAV_BAR_ITEMS.schedulePlanning,
            icon: GridIcon,
            href: '/avl/schedule-planning',
            exact: false,
            action: generateAvlAction('schedules')
          },
          {
            id: '2.2',
            title: NAV_BAR_ITEMS.schedulesStatus,
            icon: GridIcon,
            href: '/avl/schedules-status',
            exact: false,
            action: generateActionID({
              module: 'avl',
              sub: 'schedules',
              action: 'viewScheduleStatuses'
            })
          },
          {
            id: '2.3',
            title: NAV_BAR_ITEMS.trafficStadium,
            icon: GridIcon,
            href: '/avl/traffic-stadium',
            exact: false,
            action: generateAvlAction('rides')
          },
          {
            id: '2.4',
            title: NAV_BAR_ITEMS.trafficMap,
            icon: GridIcon,
            href: '/avl/traffic-map',
            exact: false,
            action: generateAvlAction('rides')
          }
        ]
      },
      ...avlReportsNavItems,
      {
        id: '3',
        subheader: NAV_BAR_HEADERS.handbook.toUpperCase(),
        items: [
          {
            id: '3.1',
            title: NAV_BAR_SUB_HEADERS.geozones,
            icon: MapIcon,
            href: '/avl/geozones',
            items: [
              {
                id: '3.1.1',
                title: NAV_BAR_ITEMS.stops,
                href: '/avl/geozones/stops',
                action: generateAvlAction('stops')
              },
              {
                id: '3.1.2',
                title: NAV_BAR_ITEMS.counteragents,
                href: '/avl/geozones/counteragents',
                action: generateAvlAction('counteragents')
              },
              {
                id: '3.1.3',
                title: NAV_BAR_ITEMS.dangerousZones,
                href: '/avl/geozones/dangerous-zones',
                action: generateAvlAction('dangerousZones')
              }
            ]
          },
          {
            id: '3.2',
            title: NAV_BAR_SUB_HEADERS.staff,
            icon: UsersIcon,
            href: '/avl/employees',
            action: generateAvlAction('employees')
          },
          {
            id: '3.3',
            title: NAV_BAR_SUB_HEADERS.vehiclesModels,
            icon: VehicleIcon,
            href: '/avl/vehicles-models',
            action: generateAvlAction('vehicleModels')
          },
          {
            id: '3.4',
            title: NAV_BAR_SUB_HEADERS.vehicles,
            icon: VehicleTypeIcon,
            href: '/avl/vehicles',
            action: generateAvlAction('vehicles')
          }
        ]
      }
    ]
  },
  {
    menuItem: 'asset',
    items: [
      {
        id: '1',
        subheader: '',
        items: [
          {
            id: '1.1',
            title: NAV_BAR_ITEMS.tracking,
            icon: GridIcon,
            href: '/asset/tracking',
            action: generateAssetsAction('tracking')
          }
        ]
      },
      {
        id: '2',
        subheader: '',
        items: [
          {
            id: '2.1',
            title: NAV_BAR_ITEMS.inbound,
            icon: GridIcon,
            href: '/asset/inbound',
            action: generateAssetsAction('inbound')
          }
        ]
      },
      {
        id: '3',
        subheader: '',
        items: [
          {
            id: '3.1',
            title: NAV_BAR_ITEMS.installation,
            icon: GridIcon,
            href: '/asset/installation',
            action: generateAssetsAction('installation')
          }
        ]
      },
      {
        id: '4',
        subheader: '',
        items: [
          {
            id: '4.1',
            title: NAV_BAR_ITEMS.uninstallation,
            icon: GridIcon,
            href: '/asset/uninstallation',
            action: generateAssetsAction('uninstallation')
          }
        ]
      },
      {
        id: '5',
        subheader: '',
        items: [
          {
            id: '5.1',
            title: NAV_BAR_ITEMS.transfer,
            icon: GridIcon,
            href: '/asset/transfer',
            action: generateAssetsAction('transfer')
          }
        ]
      },
      {
        id: '6',
        subheader: '',
        items: [
          {
            id: '6.1',
            title: NAV_BAR_ITEMS.breakdowns,
            icon: GridIcon,
            href: '/asset/breakdowns',
            action: generateAssetsAction('breakdowns')
          }
        ]
      },
      {
        id: '7',
        subheader: '',
        items: [
          {
            id: '7.1',
            title: NAV_BAR_ITEMS.inspections,
            icon: GridIcon,
            href: INSPECTIONS_URL,
            action: generateAssetsAction('inspections')
          }
        ]
      },
      {
        id: '8',
        subheader: '',
        items: [
          {
            id: '8.1',
            title: NAV_BAR_ITEMS.stateChanges,
            icon: GridIcon,
            href: '/asset/state-changes',
            action: generateAssetsAction('stateChanges')
          }
        ]
      },
      {
        id: 'reports',
        subheader: '',
        items: [
          {
            id: 'reports.1',
            title: NAV_BAR_ITEMS.reports,
            icon: GridIcon,
            href: '/asset/reports',
            action: generateAssetsAction('reports')
          }
        ]
      },
      {
        id: '9',
        subheader: '',
        items: [
          {
            id: '9.1',
            title: NAV_BAR_SUB_HEADERS.dictionaries,
            icon: GridIcon,
            href: '/asset/dictionaries',
            items: [
              {
                id: '9.1.1',
                title: NAV_BAR_ITEMS.equipment,
                href: '/asset/dictionaries/equipment',
                action: generateAssetsAction('equipment')
              },
              {
                id: '9.1.2',
                title: NAV_BAR_ITEMS.breakdowns,
                href: '/asset/dictionaries/breakdownsTypes',
                action: generateAssetsAction('breakdownsTypes')
              },
              {
                id: '9.1.3',
                title: NAV_BAR_ITEMS.states,
                href: '/asset/dictionaries/states',
                action: generateAssetsAction('states')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    menuItem: 'clearing',
    items: [
      {
        id: '2',
        subheader: NAV_BAR_HEADERS.settlement.toUpperCase(),
        items: [
          {
            id: '2.1',
            title: NAV_BAR_ITEMS.tariffs,
            icon: BookOpenIcon,
            href: '/clearing/tariffs',
            action: generateClearingAction('tariff')
          },
          {
            id: '2.2',
            title: NAV_BAR_ITEMS.kpiParameters,
            icon: SettingsIcon,
            href: '/clearing/kpiParameters',
            action: generateClearingAction('kpiGroup')
          },
          {
            id: '2.3',
            title: NAV_BAR_ITEMS.coefficientsAdjustments,
            icon: SlidersIcon,
            href: COEFFICIENTS_ADJUSTMENTS_URL,
            action: generateClearingAction('coefficientsAdjustments')
          }
        ]
      },
      {
        id: '3',
        subheader: NAV_BAR_HEADERS.documents.toUpperCase(),
        items: [
          {
            id: '3.1',
            title: NAV_BAR_ITEMS.penaltyDocuments,
            icon: FileIcon,
            href: '/clearing/penalty-documents',
            action: generateClearingAction('penaltyDocument')
          },
          {
            id: '3.2',
            title: NAV_BAR_ITEMS.inspections,
            icon: FileIcon,
            href: '/clearing/inspection-documents',
            action: generateClearingAction('inspectionDocuments')
          },
          {
            id: '3.3',
            title: NAV_BAR_ITEMS.billingDocuments,
            icon: DatabaseIcon,
            href: '/clearing/billing-documents',
            action: generateClearingAction('billingDocuments')
          }
        ]
      },
      {
        id: '5',
        subheader: NAV_BAR_HEADERS.analysis.toUpperCase(),
        items: [
          {
            id: '5.1',
            title: NAV_BAR_ITEMS.billingDetails,
            icon: ArchiveIcon,
            href: '/clearing/billing-details/carrier',
            action: generateClearingAction('billingDetails')
          }
        ]
      },
      {
        id: '1',
        subheader: NAV_BAR_HEADERS.handbook.toUpperCase(),
        items: [
          {
            id: '1.1',
            title: NAV_BAR_ITEMS.kpis,
            icon: TrendingUpIcon,
            href: '/clearing/kpis',
            action: generateClearingAction('kpi')
          },
          {
            id: '1.2',
            title: NAV_BAR_ITEMS.penalties,
            icon: ClipboardIcon,
            href: '/clearing/penalties',
            action: generateClearingAction('penalty')
          }
        ]
      }
    ]
  },
  {
    menuItem: ADMINISTRATION_PATH,
    items: [
      {
        id: '1',
        subheader: '',
        items: [
          {
            id: '1.1',
            title: NAV_BAR_ITEMS.usersSettings,
            icon: GridIcon,
            href: `/${USERS_PATH}`,
            action: generateActionID({
              module: 'userManagement',
              sub: 'usersSettings',
              action: 'read'
            })
          },
          {
            id: '1.2',
            title: NAV_BAR_ITEMS.routeSettings,
            icon: GridIcon,
            href: `/${ROUTE_SETTINGS_PATH}`,
            action: generateAdministrationAction('routeSettings')
          },
          {
            id: '1.3',
            title: NAV_BAR_ITEMS.logs,
            icon: GridIcon,
            href: LOGS_PATH,
            action: generateActionID({
              module: 'userManagement',
              sub: 'usersLogs',
              action: 'read'
            })
          },
          {
            id: '1.4',
            title: NAV_BAR_ITEMS.paymentsSettings,
            icon: GridIcon,
            href: PAYMENTS_SETTINGS_PATH,
            action: generateAdministrationAction('clearingSettings')
          },
          {
            id: '1.5',
            title: NAV_BAR_ITEMS.apiKeysSettings,
            icon: GridIcon,
            href: API_KEYS_SETTINGS_PATH,
            action: generateAdministrationAction('apiKeysSettings')
          },
          {
            id: '1.6',
            title: NAV_BAR_ITEMS.other,
            icon: GridIcon,
            href: `/${OTHER_SETTINGS_PATH}`,
            action: generateAdministrationAction('assetSettings')
          }
        ]
      }
    ]
  }
];

export default populatePermissions(config as []);
