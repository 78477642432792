import { API_VERSION } from '.';
import { SUB_DOMAIN as AVL_SUB_DOMAIN } from './avlUrls';

const SUB_DOMAIN = `/identity/${API_VERSION}`;

export const USER_MANAGEMENT = `${SUB_DOMAIN}/users`;
export const USERS_AUTOCOMPLETE = `${USER_MANAGEMENT}/autocomplete`;
export const USERS_LOGS = `${SUB_DOMAIN}/audit-logs/_search`;
export const ROUTE_SETTINGS = `${AVL_SUB_DOMAIN}/settings`;
export const PAYMENTS_SETTINGS = `/clearing/${API_VERSION}/settings`;
export const OTHER_SETTINGS = `/asset-management/${API_VERSION}/settings`;

// Users Settings
export const ROLES = `${SUB_DOMAIN}/roles`;

// API keys
export const API_MANAGEMENT = `${SUB_DOMAIN}/api-management`;
export const REVOKE_API_KEY = (id: number) => `${API_MANAGEMENT}/${id}/revoke`;
