import { date, object, number, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { maxLength } from 'src/utils/validation';
import { COUNTERAGENTS_AUTOCOMPLETE } from 'src/config/avlUrls';
import { isToday } from 'date-fns';

export const STATE_CHANGING_COUNTERAGENTS_NAME =
  'STATE_CHANGING_COUNTERAGENTS_AUTOCOMPLETE';
export const STATE_CHANGING_COUNTERAGENTS_PATH =
  'asset.stateChange.counteragentsAutocomplete';

export const counteragentConfig = {
  optionsApiUrl: COUNTERAGENTS_AUTOCOMPLETE,
  reducerName: STATE_CHANGING_COUNTERAGENTS_NAME,
  reducerPath: STATE_CHANGING_COUNTERAGENTS_PATH
};

export const STATE_CHANGING_COUNTERAGENTS_STATE_CHANGING_NAME =
  'STATE_CHANGING_COUNTERAGENTS_STATE_CHANGING_AUTOCOMPLETE';
export const STATE_CHANGING_COUNTERAGENTS_STATE_CHANGING_PATH =
  'asset.stateChange.counteragentsStateChangeAutocomplete';

const stateChangeDate = date()
  .required(ERROR_MESSAGES.REQUIRED)
  .test({ message: ERROR_MESSAGES.TYPE_ERROR, test: isToday })
  .typeError(ERROR_MESSAGES.TYPE_ERROR)
  .nullable();
const notes = string().max(...maxLength(255));

export const stateChangeValidationSchema = object().shape({
  stateChangeDate,
  employee: number()
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  counteragent: object()
    .shape({ id: number() })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  notes
});

export const stateChangeDraftValidationSchema = object().shape({
  stateChangeDate,
  employee: number()
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  notes
});
