import { Row } from 'src/components/Tables/types';

export const validateEquipmentRows = (equipmentRows: Row[]) => {
  const isInvalid = equipmentRows.some(
    row => row.previousStateId === row.newStateId
  );

  if (isInvalid) {
    return 'Неможливо зберегти документ. Стан обладнання потрібно змінити на інший';
  }

  return undefined;
};
