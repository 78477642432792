import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { RootState } from 'typesafe-actions';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { Row } from 'src/components/Tables/types';
import { convertUtcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL } from 'src/config';
import { generateSendEmailAttachmentProps } from 'src/components/SendEmailModalForm/utils/generateSendEmailAttachmentProps';
import { generatePdfOfStateChange } from '../../utils/generatePdfOfStateChange';
import { generateJsonOfStateChange } from '../../utils/generateJsonOfStateChange';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/asset/state-changes/:id/details'
);

export const selectStateChangeId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selecteStates = (state: RootState) =>
  state.ui.asset.stateChange.statesSelect;

export const selectStateChangeDetails = (state: RootState) =>
  state.ui.asset.stateChange.details;

export const selectStateChangeEquipment = (state: RootState) =>
  state.ui.asset.stateChange.table;

export const normalizeEquipment = ({
  newStateId,
  previousStateId,
  spareParts,
  assetId
}: Row) => ({
  assetId,
  newStateId,
  previousStateId,
  spareParts
});

export const selectListResult = (reducerPath: string) => (
  state: RootState
): Row[] => get(state, `ui.${reducerPath}.list`, []);

export const selectNormalizedList = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list =>
    list.map(normalizeEquipment)
  );

export const selectBarcodes = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list =>
    list.map(el => el.barcode)
  );

export const selectStates = (state: RootState) =>
  state.ui.asset.stateChange.statesSelect;

export const selectEmailData = createSelector(
  selectStateChangeDetails,
  data => {
    const {
      id,
      documentNumber,
      stateChangeDate,
      employeeName,
      counteragentFullName,
      counteragentAddress,
      notes,
      equipment
    } = data;

    const date = convertUtcToLocal(String(stateChangeDate), DATE_FORMAT_FULL);

    const subject = `Зміна стану №${documentNumber} ${employeeName} ${date}`;
    const bodyText = `Здійснено зміну стану обладнання.\nДокумент зміни стану №${documentNumber} від ${date}`;
    const tags = [`stateChange:${id}`];

    let attachments;
    if (data.id) {
      const pdfData = {
        ...data,
        equipment,
        counteragentFullName,
        counteragentAddress,
        notes
      };

      const pdfFile = generatePdfOfStateChange(pdfData);
      const jsonFile = generateJsonOfStateChange(pdfData);

      attachments = generateSendEmailAttachmentProps(
        subject,
        pdfFile,
        jsonFile
      );
    }

    return { subject, bodyText, tags, attachments };
  }
);
