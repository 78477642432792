import { object, string, array, number } from 'yup';
import {
  AVL_ERROR_MESSAGES,
  ERROR_MESSAGES,
  phoneNumberRegExp
} from 'src/constants/validation';
import {
  COUNTERAGENTS,
  CUSTOM_PROFESSIONS,
  PROFESSIONS
} from 'src/config/avlUrls';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { generateActionCrud } from 'src/components/Guards';
import { maxLength } from 'src/utils/validation';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.avl,
    url: '/avl'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.employees,
    url: '/avl/employees'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'avl',
    sub: 'employees'
  },
  ['download']
);

const nameRegExp = /^[А-ЯЄЇІA-Zа-яєїіa-z]([А-ЯЄЇІа-яєїіA-Za-z\-'\s]{1,99})?$/g;

export const employeeFormValidationSchema = object().shape({
  name: object().shape({
    first: string()
      .max(...maxLength(100))
      .matches(nameRegExp, ERROR_MESSAGES.TYPE_ERROR)
      .required(ERROR_MESSAGES.REQUIRED),
    middle: string()
      .max(...maxLength(100))
      .matches(nameRegExp, ERROR_MESSAGES.TYPE_ERROR),
    last: string()
      .max(...maxLength(100))
      .required(ERROR_MESSAGES.REQUIRED)
      .matches(nameRegExp, ERROR_MESSAGES.TYPE_ERROR)
  }),
  code: string()
    .max(...maxLength(20))
    .when('$editMode', {
      is: true,
      then: string().required(ERROR_MESSAGES.REQUIRED)
    }),
  position: string().max(...maxLength(255)),
  professionId: number().required(ERROR_MESSAGES.REQUIRED),
  department: object()
    .shape({
      id: number(),
      name: string()
    })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED)
    .test('departmentId', ERROR_MESSAGES.REQUIRED, value => !!value.id),
  status: string().required(ERROR_MESSAGES.REQUIRED),
  description: string().max(...maxLength(255)),
  contacts: array().of(
    object().shape({
      email: string()
        .max(...maxLength(60))
        .email(ERROR_MESSAGES.TYPE_ERROR),
      phoneNumber: string()
        .matches(phoneNumberRegExp, ERROR_MESSAGES.TYPE_ERROR)
        .required(ERROR_MESSAGES.REQUIRED)
    })
  )
});

export const PROFESSION_SELECT_NAME = 'PROFESSIONS_SELECT';
export const PROFESSION_SELECT_PATH = 'avl.employees.create.editableSelect';

export const PROFESSIONS_CONFIG = {
  reducerPath: PROFESSION_SELECT_PATH,
  reducerName: PROFESSION_SELECT_NAME,
  customOptionsApiUrl: CUSTOM_PROFESSIONS,
  activeOptionsApiUrl: PROFESSIONS
};

export const ENABLED = 'Enabled';
export const DISABLED = 'Disabled';

export const EMPLOYEE_FORM_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  UniqueError: AVL_ERROR_MESSAGES.uniqueError
};

export const OPTION_EDIT_DIALOG_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  UniqueError: AVL_ERROR_MESSAGES.uniqueError
};

export const EMPLOYEES_COUNTERAGENTS_SELECT_NAME =
  'EMPLOYEES_COUNTERAGENTS_SELECT';
export const EMPLOYEES_COUNTERAGENTS_SELECT_PATH = 'avl.employees.select';
export const EMPLOYEES_COUNTERAGENTS_SELECT_CONFIG = {
  optionsApiUrl: COUNTERAGENTS,
  reducerName: EMPLOYEES_COUNTERAGENTS_SELECT_NAME,
  reducerPath: EMPLOYEES_COUNTERAGENTS_SELECT_PATH,
  prohibitDynamicLoad: true
};
