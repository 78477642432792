import { ActionType, createReducer } from 'typesafe-actions';
import { isEqual, some, uniqBy } from 'lodash';
import {
  getSorters,
  getSelection
} from 'src/components/Tables/TableWithPagination/utils';
import { Sorter } from 'src/components/Tables/TableWithPagination/types';
import { getComparator, stableSort } from 'src/components/Tables/Table/utils';
import * as actions from './actions';
import { Row } from '../../types';

export interface TableState {
  readonly sorters: Sorter[];
  readonly list: Row[];
  readonly selection: {
    selectAll: boolean;
    included: number[];
    excluded: number[];
  };
}

export const initialState: TableState = {
  list: [],
  sorters: [],
  selection: {
    selectAll: false,
    included: [],
    excluded: []
  }
};

type TableActions = ActionType<typeof actions>;

const tableReducers = createReducer<TableState, TableActions>(initialState)
  .handleAction(actions.getDataSuccess, (state, { payload }) => ({
    ...state,
    ...payload
  }))
  .handleAction(actions.sortRows, (state, { payload: { column, rows } }) => {
    const { sorters } = state;
    const newSorters = getSorters(sorters, column);
    const [{ order, propertyName }] = newSorters;
    const newRows = stableSort(rows, getComparator(order, propertyName));

    return {
      ...state,
      list: newRows,
      sorters: newSorters
    };
  })
  .handleAction(actions.setSelected, (state, { payload: { checked, id } }) => {
    const { selection, list } = state;
    const newSelection = getSelection(checked, id, selection, list.length);

    return {
      ...state,
      selection: newSelection
    };
  })
  .handleAction(actions.setSelectAll, (state, { payload }) => ({
    ...state,
    selection: {
      selectAll: payload,
      included: [],
      excluded: []
    }
  }))
  .handleAction(actions.addRowLocally, (state, { payload: { row } }) => ({
    ...state,
    list: some(state.list, row) ? state.list : [...state.list, row]
  }))
  .handleAction(actions.addRowsLocally, (state, { payload: { rows } }) => ({
    ...state,
    list: uniqBy([...state.list, ...rows], 'id')
  }))
  .handleAction(actions.updateRowLocally, (state, { payload }) => {
    if (isEqual(payload.oldRow, payload.newRow)) {
      return state;
    }

    const updatedList = state.list.map(item =>
      isEqual(item, payload.oldRow) ? payload.newRow : item
    );

    const filteredList = state.list.filter(el => !isEqual(el, payload.oldRow));

    return {
      ...state,
      list: some(state.list, payload.newRow) ? filteredList : updatedList
    };
  })
  .handleAction(actions.deleteRowLocally, (state, { payload: { row } }) => ({
    ...state,
    list: state.list.filter(item => !isEqual(item, row))
  }))
  .handleAction(actions.setRowsIsLocalProperty, (state, { payload }) => {
    const { ids, strategy } = payload;

    const updatedList = state.list.map(item => {
      if (
        (strategy === 'AllExcept' && !ids.includes(item.id)) ||
        (strategy === 'OnlySpecified' && ids.includes(item.id))
      ) {
        return { ...item, isLocal: true };
      }

      return item;
    });

    return {
      ...state,
      list: updatedList
    };
  })
  .handleAction(actions.clearTableState, () => initialState);

export default tableReducers;
