import { createAsyncAction } from 'typesafe-actions';
import {
  DangerousZoneDetailDTO,
  UpdateDangerousZoneCommand
} from '@eticket/avl-api-contracts';

export const getDangerousZone = createAsyncAction(
  '@DANGEROUS_ZONES_EDIT/GET_DANGEROUS_ZONE_REQUEST',
  '@DANGEROUS_ZONES_EDIT/GET_DANGEROUS_ZONE_SUCCESS',
  '@DANGEROUS_ZONES_EDIT/GET_DANGEROUS_ZONE_FAILURE'
)<undefined, DangerousZoneDetailDTO, Error>();

export const updateDangerousZone = createAsyncAction(
  '@DANGEROUS_ZONES_EDIT/UPDATE_DANGEROUS_ZONE_REQUEST',
  '@DANGEROUS_ZONES_EDIT/UPDATE_DANGEROUS_ZONE_SUCCESS',
  '@DANGEROUS_ZONES_EDIT/UPDATE_DANGEROUS_ZONE_FAILURE'
)<UpdateDangerousZoneCommand, undefined, Error>();
