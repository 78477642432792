import { createAsyncAction } from 'typesafe-actions';
import { BreakdownPageItemDTO } from '@eticket/asset-management-api-contracts';

export const getBreakdownsTypes = createAsyncAction(
  '@BREAKDOWNS_TYPES_EDIT/GET_BREAKDOWN_REQUEST',
  '@BREAKDOWNS_TYPES_EDIT/GET_BREAKDOWN_SUCCESS',
  '@BREAKDOWNS_TYPES_EDIT/GET_BREAKDOWN_FAILURE'
)<undefined, BreakdownPageItemDTO, Error>();

export const updateBreakdownsTypes = createAsyncAction(
  '@BREAKDOWNS_TYPES_EDIT/UPDATE_BREAKDOWN_REQUEST',
  '@BREAKDOWNS_TYPES_EDIT/UPDATE_BREAKDOWN_SUCCESS',
  '@BREAKDOWNS_TYPES_EDIT/UPDATE_BREAKDOWN_FAILURE'
)<Omit<BreakdownPageItemDTO, 'id'>, undefined, Error>();
