import { PM } from 'leaflet';

// eslint-disable-next-line max-len
export const LAT_LON_REGEX = /^[-+]?([1-8]?\d\.\d+?|90(\.0+)?),\s*[-+]?([1-8]?\d\.\d+?|90(\.0+)?)$/;

export const SHAPES = {
  CIRCLE: 'Circle',
  POLYGON: 'Polygon'
};

// @ts-ignore
export const MAP_SETTINGS: PM.DrawControlOptions = {
  position: 'topright',
  drawMarker: false,
  cutPolygon: false,
  drawPolyline: false,
  rotateMode: false,
  drawRectangle: false,
  drawCircleMarker: false
};

export const GLOBAL_OPTIONS = {
  minRadiusCircle: 20,
  maxRadiusCircle: 20
};

// radius in kilometers(e.g. 0.01 => 0.01 * 1000 === 10 meters)
export const NEAREST_POINTS_RADIUS = 0.01;

// Lviv center
export const MAP_POSITION: L.LatLngTuple = [49.839684, 24.029716];

// default values
export const CIRCLE_RADIUS = 20;
export const DEFAULT_MAP_ZOOM = 15;
export const MAX_MAP_ZOOM = 22;

export const CIRCLE_STYLES = {
  radius: 7,
  color: 'white',
  stroke: true,
  fillOpacity: 1
};

export const MAP_CACHE = {
  key: 'MAP_CONFIGURATION',
  ttl: 1800
};
