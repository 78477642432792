import { createReducer, ActionType } from 'typesafe-actions';
import {
  VehicleModelEditDTO,
  SystemStatusDTO
} from '@eticket/avl-api-contracts';
import * as actions from './actions';

type VehicliesModelEditActions = ActionType<typeof actions>;

export const initialState: VehicleModelEditDTO = {
  id: 0,
  name: '',
  vehicleCategoryId: 0,
  tariffGroupId: 0,
  numberOfSeats: 0,
  numberOfStandPlaces: 0,
  numberOfDisabilitySeats: 0,
  code: '',
  notes: '',
  equipmentIds: [],
  status: SystemStatusDTO.Disabled
};

const VehiclesModelEditReducers = createReducer<
  VehicleModelEditDTO,
  VehicliesModelEditActions
>(initialState).handleAction(
  actions.getVehiclesModel.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default VehiclesModelEditReducers;
