import { createCustomAction } from 'typesafe-actions';

interface EditInboundConfig {
  dataReducerPath: string;
  tableReducerPath: string;
}

export const updateInboundRequest = createCustomAction(
  '@INBOUND/UPDATE_INBOUND_REQUEST',
  (config: EditInboundConfig, resolve: (id: string) => void) => ({
    config,
    resolve
  })
);

export const updateInboundSuccess = createCustomAction(
  '@INBOUND/UPDATE_INBOUND_SUCCESS',
  (config: EditInboundConfig) => ({ config })
);

export const updateInboundFailure = createCustomAction(
  '@INBOUND/UPDATE_INBOUND_FAILURE',
  (config: EditInboundConfig, payload: Error) => ({ config, payload })
);
