import { array, object, lazy, ValidationError } from 'yup';
import moment from 'moment';
import { MAIN_MENU, EmployeeProfessionEnum } from 'src/constants';
import { TIME_FORMAT } from 'src/config';
import { COUNTERAGENTS } from 'src/config/avlUrls';
import { AVL_ERROR_MESSAGES, ERROR_MESSAGES } from 'src/constants/validation';
import {
  EMPLOYEES_NAME,
  EMPLOYEES_PATH,
  VEHICLE_NUMBERS_NAME,
  VEHICLE_NUMBERS_PATH
} from 'src/views/asset/installation/InstallationCreateView/InstallationStepView/const';
import { generateActionID } from 'src/components/Guards';
import { ScheduleShift, ExtendedDriverAutocompleteOption } from './types';

export const breadcrumbs = [
  {
    text: MAIN_MENU.avl,
    url: '/avl'
  }
];

export const CONFLICT_ERROR = 'Виявлено накладку в часі';
export const PLANNED_ROUTE_ERROR = 'Даний водій має запланований маршрут';

const testShiftsOverlap = (
  workShift: ScheduleShift,
  flattenedWorkShifts: Array<{ key: string; shift: ScheduleShift }>,
  type: 'driver' | 'vehicle',
  path: string
) => {
  if (!workShift.driver || !workShift.vehicle) {
    return true;
  }

  const isError = flattenedWorkShifts
    .filter(
      flattenedWorkShift =>
        flattenedWorkShift.shift.driver &&
        flattenedWorkShift.shift.vehicle &&
        workShift.workShiftId !== flattenedWorkShift.shift.workShiftId
    )
    .some(({ shift }) => {
      const departureA = moment(workShift.departureTime, TIME_FORMAT);
      const arrivalA = moment(workShift.arrivalTime, TIME_FORMAT);

      const departureB = moment(shift.departureTime, TIME_FORMAT);
      const arrivalB = moment(shift.arrivalTime, TIME_FORMAT);

      // if the workshift is the same, it overlaps with one of the shifts
      return (
        workShift[type]!.id === shift[type]!.id &&
        (departureA.isBetween(departureB, arrivalB) || // departure during another shift
        arrivalB.isBetween(departureA, arrivalA) || // arrival during another shift
          (departureA.isSame(departureB) && arrivalA.isSame(arrivalB))) // shift has identical times
      );
    });

  if (isError) {
    return new ValidationError(CONFLICT_ERROR, '', `${path}.${type}`);
  }

  return true;
};

export const schedulesSchema = object().shape({
  schedules: lazy(value => {
    const workShiftRoutes = value as Record<
      number,
      Record<number, ScheduleShift>
    >;

    const flattenedWorkShifts: Array<{
      key: string;
      shift: ScheduleShift;
    }> = [];

    Object.keys(workShiftRoutes).forEach(routeId => {
      Object.keys(workShiftRoutes[+routeId]).forEach(workShiftKey => {
        flattenedWorkShifts.push({
          key: `schedules[${routeId}][${workShiftKey}]`,
          shift: workShiftRoutes[+routeId][+workShiftKey]
        });
      });
    });

    const keys = Object.keys(value as Record<number, ScheduleShift[]>);

    const rule = array().of(
      object()
        .test({
          message: CONFLICT_ERROR,
          name: 'Unique driver',
          test(workShift: ScheduleShift) {
            const driver = workShift.driver as ExtendedDriverAutocompleteOption;

            if (driver?.isAssignedToRide) {
              const errorPath = `${this.path}.driver`;

              return new ValidationError(PLANNED_ROUTE_ERROR, '', errorPath);
            }

            return testShiftsOverlap(
              workShift,
              flattenedWorkShifts,
              'driver',
              this.path
            );
          }
        })
        .test({
          message: CONFLICT_ERROR,
          name: 'Unique vehicle',
          test(workShift: ScheduleShift) {
            return testShiftsOverlap(
              workShift,
              flattenedWorkShifts,
              'vehicle',
              this.path
            );
          }
        })
        .test({
          message: ERROR_MESSAGES.REQUIRED,
          name: 'Required field',
          test(workShift: ScheduleShift) {
            const { path } = this;

            if (workShift.vehicle === null && workShift.driver !== null) {
              return new ValidationError(
                ERROR_MESSAGES.REQUIRED,
                '',
                `${path}.vehicle`
              );
            }

            if (workShift.driver === null && workShift.vehicle !== null) {
              return new ValidationError(
                ERROR_MESSAGES.REQUIRED,
                '',
                `${path}.driver`
              );
            }

            return true;
          }
        })
    );

    const rules = keys.reduce(
      (acc, val) => ({
        ...acc,
        [val]: rule
      }),
      {}
    );

    return object().shape(rules);
  })
});

interface Config {
  id: number | string;
  from?: string;
  to?: string;
  workShiftId?: number;
}

export const employeesConfig = {
  optionsApiUrl: '',
  reducerName: EMPLOYEES_NAME,
  reducerPath: EMPLOYEES_PATH
};

export const vehicleNumbersConfig = {
  optionsApiUrl: '',
  reducerName: VEHICLE_NUMBERS_NAME,
  reducerPath: VEHICLE_NUMBERS_PATH
};

export const getEmployeesConfig = ({ id, from, to }: Config) => ({
  ...employeesConfig,
  optionsApiUrl: `${COUNTERAGENTS}/${id}/employees-autocomplete?from=${from}&to=${to}&professionId=${EmployeeProfessionEnum.Driver}`,
  prohibitInitialLoad: true
});

export const getVehicleNumbersConfig = ({
  id,
  from,
  to,
  workShiftId
}: Config) => ({
  ...vehicleNumbersConfig,
  optionsApiUrl: `${COUNTERAGENTS}/${id}/vehicles-autocomplete?status=Enabled&from=${from}&to=${to}&workShiftId=${workShiftId}`,
  prohibitInitialLoad: true
});

export const SCHEDULES_LOADING_KEY = '@SCHEDULES_DETAILS/GET_SCHEDULES';

export const SCHEDULES_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  conflict: CONFLICT_ERROR
};

export const updateAction = generateActionID({
  module: 'avl',
  sub: 'schedules',
  action: 'update'
});

export const exportAction = generateActionID({
  module: 'avl',
  sub: 'schedules',
  action: 'export'
});
