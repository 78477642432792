import { UnmappedGPSDataReport } from '../types';

export const UnmappedGPSDataReportMock: UnmappedGPSDataReport = [
  {
    timestamp: '21/08/2021T00:00:00',
    consoleBarcode: '1234567890',
    avl: {
      lineRegistrationNumber: '3A',
      vehicleRegistrationNumber: 'BC2143BX',
      driverPersonNumber: '234'
    },
    afc: {
      lineRegistrationNumber: '3A',
      vehicleRegistrationNumber: 'BC2143BX',
      driverPersonNumber: '234'
    },
    error: 'error'
  }
];
