import {
  Column,
  ColumnGroup
} from 'src/components/Tables/TableWithPagination/types';

export enum BillingDocumentStatusEnum {
  Fixed = 'Fixed',
  Unfixed = 'Unfixed'
}

export interface BillingDocument {
  id?: number;
  documentDate?: string | null;
  dateFrom?: string | null;
  dateTo?: string | null;
  status?: BillingDocumentStatusEnum;
  totalAmount?: number | null;
  billingDocument?: string | null;
  documentName?: string | null;
}

export interface BillingDocumentValues extends BillingDocument {
  counteragent?: AutocompleteOption | null;
}

export interface CreateBillingDocumentPayload extends BillingDocument {
  counteragentId?: number | null;
}

export type GetBillingDocumentPayload = BillingDocumentValues;
export type EditBillingDocumentPayload = CreateBillingDocumentPayload;

export enum BillingType {
  Automatic = 'Automatic',
  Manual = 'Manual'
}

export enum BillingStatusEnum {
  Draft = 0,
  Fixed = 1
}

export interface BillingDetailsColumn extends Column {
  isFirstCell?: boolean;
  isLastCell?: boolean;
}

export interface BillingDetailsColumnGroup extends Omit<ColumnGroup, 'value'> {
  value: BillingDetailsColumn[];
}

export interface BillingFilterData {
  counteragentId: number;
  dateFrom: string | null;
  dateTo: string | null;
  documentId: number;
}

export interface BillingFilterValues {
  counteragent: AutocompleteOption | null;
  documentDateFrom: string | null;
  documentDateTo: string | null;
  billingDocument: AutocompleteOption | null;
  routePassportId: AutocompleteOption | null;
}

export enum BillingDetailsLevels {
  Carrier = 'carrier',
  Routes = 'routes',
  Vehicles = 'vehicles'
}

export interface NextBillingDocumentDate {
  date: string | null;
}
