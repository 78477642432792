import { RootState } from 'typesafe-actions';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { normalizeFormRequest } from '../../TransferEditView/store/utils';

export const selectFormValues = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}`, {});

export const selectFormCreateData = (reducerPath: string) =>
  createSelector(selectFormValues(reducerPath), formData =>
    normalizeFormRequest(formData)
  );
