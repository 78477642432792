import { SagaIterator } from 'redux-saga';
import { takeEvery, call, select, put } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { StatusEnum } from 'src/constants';
import { UNINSTALLATIONS } from 'src/config/assetUrls';
import * as actions from './actions';
import { selectListCreateData } from '../../UninstallationEquipmentsStep/store/selectors';
import { selectFormCreateData } from '../../UninstallationStep/store/selectors';

export function* createDraftUninstallation({
  payload
}: ReturnType<typeof actions.createDraftUninstallation.request>): SagaIterator {
  const {
    formConfig,
    mobileTablePath,
    stationaryTablePath,
    inspectorTablePath
  } = payload;
  const { counteragentHolder, acceptingEmployee, ...formData } = yield select(
    selectFormCreateData(formConfig.reducerPath)
  );
  const mobileEquipments = yield select(selectListCreateData(mobileTablePath));
  const stationaryEquipments = yield select(
    selectListCreateData(stationaryTablePath)
  );
  const inspectorEquipments = yield select(
    selectListCreateData(inspectorTablePath)
  );

  const { data: uninstallationId } = yield call(
    apiClient.post,
    UNINSTALLATIONS,
    {
      ...formData,
      counteragentHolderId: counteragentHolder,
      acceptingEmployeeId: acceptingEmployee,
      assetIds: [
        ...mobileEquipments,
        ...stationaryEquipments,
        ...inspectorEquipments
      ],
      status: StatusEnum.Draft
    },
    {
      retryAction: actions.createDraftUninstallation.request(payload)
    }
  );

  yield put(actions.createDraftUninstallation.success(uninstallationId));
}

export default function* UninstallationCreateView(): SagaIterator {
  yield takeEvery(
    getType(actions.createDraftUninstallation.request),
    safe(createDraftUninstallation, actions.createDraftUninstallation.failure)
  );
}
