import { Redirect, Route } from 'react-router';
import useActionAllowed from 'src/hooks/useActionAllowed';
import { useSelector } from 'react-redux';
import { selectUserEmployee } from '../../store/selectors';

const ProtectedRoute = ({ action, ...props }: any) => {
  const isAllowed = useActionAllowed(action);
  const userEmployee = useSelector(selectUserEmployee);

  // route is protected if it's action is not in the list
  // or it has no action defined
  //
  // undefined can be for redirecting routes or general entry point
  if (
    action === undefined ||
    userEmployee === undefined ||
    (isAllowed && userEmployee !== null)
  ) {
    return <Route {...props} />;
  }

  return <Redirect to="/" />;
};

export default ProtectedRoute;
