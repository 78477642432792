import { Schema, date, object, number, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { maxLength } from 'src/utils/validation';
import {
  EMPLOYEES_AUTOCOMPLETE,
  COUNTERAGENTS_AUTOCOMPLETE,
  COUNTERAGENTS,
  COUNTERAGENTS_TYPES
} from 'src/config/avlUrls';
import { isToday } from 'date-fns';

const employeesValuesValidation = (
  accepting: AutocompleteOption | null,
  inboundingId: number,
  schema: Schema<{}>
) =>
  schema.test({
    test: () => !!(accepting && accepting.id !== inboundingId),
    message: accepting && inboundingId ? ERROR_MESSAGES.UNIQUE : ''
  });

export const inboundValidationSchema = object().shape({
  dateInbound: date()
    .required(ERROR_MESSAGES.REQUIRED)
    .test({ message: ERROR_MESSAGES.TYPE_ERROR, test: isToday })
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .nullable(),
  inboundingEmployee: object()
    .shape({
      id: number()
    })
    .when(
      ['$inboundingEmployee', '$acceptingEmployee'],
      employeesValuesValidation
    )
    .nullable(),
  acceptingEmployee: number(),
  counteragent: object()
    .shape({
      id: number()
    })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  notes: string().max(...maxLength(255))
});

export const INBOUND_INBOUNDING_EMPLOYEES_NAME =
  'INBOUNDING_EMPLOYEES_AUTOCOMPLETE';
export const INBOUND_INBOUNDING_EMPLOYEES_PATH =
  'asset.inbound.inboundingEmployeesAutocomplete';

export const INBOUNDING_EMPLOYEE_CONFIG = {
  optionsApiUrl: `${EMPLOYEES_AUTOCOMPLETE}?status=Enabled`,
  reducerName: INBOUND_INBOUNDING_EMPLOYEES_NAME,
  reducerPath: INBOUND_INBOUNDING_EMPLOYEES_PATH
};

export const INBOUND_ACCEPTING_EMPLOYEES_NAME =
  'ACCEPTING_EMPLOYEES_AUTOCOMPLETE';
export const INBOUND_ACCEPTING_EMPLOYEES_PATH =
  'asset.inbound.acceptingEmployeesAutocomplete';

export const ACCEPTING_EMPLOYEE_CONFIG = {
  optionsApiUrl: `${EMPLOYEES_AUTOCOMPLETE}?status=Enabled`,
  reducerName: INBOUND_ACCEPTING_EMPLOYEES_NAME,
  reducerPath: INBOUND_ACCEPTING_EMPLOYEES_PATH
};

export const INBOUND_COUNTERAGENT_TYPES_NAME =
  'COUNTERAGENT_TYPES_AUTOCOMPLETE';
export const INBOUND_COUNTERAGENT_TYPES_PATH =
  'asset.inbound.counteragentTypesAutocomplete';

export const COUNTERAGENT_TYPES_CONFIG = {
  optionsApiUrl: COUNTERAGENTS_TYPES,
  reducerName: INBOUND_COUNTERAGENT_TYPES_NAME,
  reducerPath: INBOUND_COUNTERAGENT_TYPES_PATH,
  prohibitDynamicLoad: true
};

export const INBOUND_COUNTERAGENTS_NAME = 'COUNTERAGENTS_AUTOCOMPLETE';
export const INBOUND_COUNTERAGENTS_PATH =
  'asset.inbound.counteragentsAutocomplete';

export const getInboundCounteragentConfig = (counteragentTypeId?: number) => ({
  optionsApiUrl: counteragentTypeId
    ? `${COUNTERAGENTS_AUTOCOMPLETE}?counteragentTypeId=${counteragentTypeId}`
    : '',
  reducerName: INBOUND_COUNTERAGENTS_NAME,
  reducerPath: INBOUND_COUNTERAGENTS_PATH
});

export const INBOUND_EMPLOYEES_NAME = 'EMPLOYEES_AUTOCOMPLETE';
export const INBOUND_EMPLOYEES_PATH = 'asset.inbound.employeesAutocomplete';

export const getEmployeesConfig = (id: number | string) => ({
  optionsApiUrl: id ? `${COUNTERAGENTS}/${id}/employees-autocomplete` : '',
  reducerName: INBOUND_EMPLOYEES_NAME,
  reducerPath: INBOUND_EMPLOYEES_PATH
});

export const INBOUND_VEHICLE_NUMBERS_NAME = 'VEHICLE_NUMBERS_AUTOCOMPLETE';
export const INBOUND_VEHICLE_NUMBERS_PATH =
  'asset.inbound.vehicleNumbersAutocomplete';
