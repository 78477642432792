import { RouterRootState, createMatchSelector } from 'connected-react-router';
import { RootState } from 'typesafe-actions';
import { get, isNaN } from 'lodash';
import { createSelector } from 'reselect';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL } from 'src/config';
import { generateSendEmailAttachmentProps } from 'src/components/SendEmailModalForm/utils/generateSendEmailAttachmentProps';
import { generatePdfOfInbound } from '../../../utils/generatePdfOfInbound';
import { generateJsonOfInbound } from '../../../utils/generateJsonOfInbound';

const matchCreateSpaceParams = createMatchSelector<
  RouterRootState,
  { id: string }
>('/asset/inbound/create/:id');

const matchEditSpaceParams = createMatchSelector<
  RouterRootState,
  { id: string }
>('/asset/inbound/:id');

export const selectInboundId = createSelector(
  matchCreateSpaceParams,
  matchEditSpaceParams,
  (createMatch, editMatch) => {
    if (createMatch) {
      return createMatch.params.id;
    }

    if (editMatch && !isNaN(Number(editMatch.params.id))) {
      return editMatch.params.id;
    }

    return '';
  }
);

export const selectFormInitialValues = (reducerPath: string) => (
  state: RootState
) => get(state, `ui.${reducerPath}`);

export const selectNormalizeData = (reducerPath: string) => (
  state: RootState
) => {
  const data = get(state, `ui.${reducerPath}`, {});

  return {
    dateInbound: data.dateInbound,
    inboundingEmployeeId: data.inboundingEmployee?.id,
    acceptingEmployeeId: data.acceptingEmployee?.id,
    counteragentId: data.counteragent?.id,
    equipmentGroupId: data.equipmentType,
    notes: data.notes,
    employeeId: data.employee?.id,
    vehicleId: data.vehicleNumbers?.id
  };
};

export const selectEmailData = (reducerPath: string, inboundId: string) => (
  state: RootState
) => {
  const { documentId, counteragent, acceptingEmployee, dateInbound } = get(
    state,
    `ui.${reducerPath}`,
    {}
  );

  const data = get(state, `ui.asset.inbound.emailData`);

  const date = utcToLocal(dateInbound as Date, DATE_FORMAT_FULL) as string;

  const subject = `Надходження №${documentId} ${counteragent.name} ${acceptingEmployee.name} ${date}`;
  const bodyText = `Здійснено надходження.\nДокумент надходження №${documentId} від ${date}`;
  const tags = [`inbound:${inboundId}`];

  const pdfFile = generatePdfOfInbound(data);
  const jsonFile = generateJsonOfInbound(data);
  const attachments = generateSendEmailAttachmentProps(
    subject,
    pdfFile,
    jsonFile
  );

  return { subject, bodyText, tags, attachments };
};

export const selectTitle = (reducerPath: string) => (state: RootState) => {
  const { documentId } = get(state, `ui.${reducerPath}`, {});

  return `Редагувати надходження №${documentId}`;
};
