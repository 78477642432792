import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';

export const getListBreadcrumbs = (passportId: string) => [
  {
    text: MAIN_MENU.avl,
    url: '/avl'
  },
  {
    text: NAV_BAR_ITEMS.routes,
    url: '/avl/routes'
  },
  {
    text: NAV_BAR_ITEMS.schemes,
    url: `/avl/routes/details/scheme/${passportId}`
  }
];

export const columns = [
  {
    label: 'Дата зміни',
    value: 'modifiedAt',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Назва Схеми',
    value: 'name',
    sortable: false,
    width: '20%'
  },
  {
    label: 'Кількість зупинок',
    value: 'stopsCount',
    sortable: false,
    width: '20%'
  },
  {
    label: 'Довжина маршруту',
    value: 'routeDistanceKm',
    sortable: false,
    width: '20%'
  },
  {
    label: 'Статус схеми',
    value: 'status',
    sortable: false,
    width: '20%',
    changeValue: changeStatusColumn
  }
];

export const ROUTE_SCHEMAS_HISTORY_LIST_TABLE_PATH =
  'avl.routes.schemes.historyChangesTable';

export const ROUTE_SCHEMAS_HISTORY_LIST_TABLE_NAME =
  'ROUTE_SCHEMAS_HISTORY_LIST_TABLE_NAME';
