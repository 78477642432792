import { SagaIterator } from 'redux-saga';
import { put, call, takeEvery, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import {
  EQUIPMENT_BARCODE_IS_USED,
  INBOUND_EQUIPMENT_VALIDATION
} from 'src/config/assetUrls';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';

export function* validateEquipment({
  payload
}: ReturnType<typeof actions.validateEquipment.request>): SagaIterator {
  yield call(apiClient.post, INBOUND_EQUIPMENT_VALIDATION, payload, {
    retryAction: actions.validateEquipment.request(payload)
  });
  yield put(actions.validateEquipment.success());
}

export function* validateBarcodeIsUsed({
  payload
}: ReturnType<typeof actions.validateBarcodeIsUsed.request>): SagaIterator {
  const res = yield call(apiClient.get, EQUIPMENT_BARCODE_IS_USED(payload), {
    retryAction: actions.validateBarcodeIsUsed.request(payload)
  });
  yield put(actions.validateBarcodeIsUsed.success(res.data));
}

export default function* EquipmentStepSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.validateEquipment.request),
      safe(validateEquipment, actions.validateEquipment.failure)
    ),
    takeEvery(
      getType(actions.validateBarcodeIsUsed.request),
      safe(validateBarcodeIsUsed, actions.validateBarcodeIsUsed.failure)
    )
  ]);
}
