import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { selectPassportId } from 'src/views/avl/Routes/RoutesPassportView/store/selectors';
import { changePassportStatus } from 'src/views/avl/Routes/RoutesPassportLayout/store/actions';
import { ROUTE_PASSPORT } from 'src/config/avlUrls';
import { navigateTo } from 'src/store/actions/app';
import * as actions from './actions';

export function* getPassportSettings(): SagaIterator {
  const id = yield select(selectPassportId);

  const { data } = yield call(apiClient.get, `${ROUTE_PASSPORT}/${id}/edit`, {
    retryAction: actions.getPassportSettings.request
  });

  yield put(changePassportStatus(data.status));
  yield put(actions.getPassportSettings.success(data));
}

export function* getPassportValidationStatus(): SagaIterator {
  const id = yield select(selectPassportId);

  const { data } = yield call(
    apiClient.get,
    `${ROUTE_PASSPORT}/${id}/validation-status`,
    {
      retryAction: actions.getPassportValidationStatus.request
    }
  );

  yield put(actions.getPassportValidationStatus.success(data));
}

export function* updatePassportSettings({
  payload
}: ReturnType<typeof actions.updateSettings.request>): SagaIterator {
  const id = yield select(selectPassportId);

  yield call(apiClient.put, `${ROUTE_PASSPORT}/${id}`, payload, {
    retryAction: actions.updateSettings.request
  });

  yield put(navigateTo({ url: `/avl/routes/details/settings/${id}` }));
  yield put(
    enqueueSnackbar({
      key: 'passport_update',
      message: 'Паспорт маршруту оновлено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.updateSettings.success());
}

export default function* settingsDetailsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getPassportSettings.request),
      safe(getPassportSettings, actions.getPassportSettings.failure)
    ),
    takeEvery(
      getType(actions.updateSettings.request),
      safe(updatePassportSettings, actions.updateSettings.failure)
    ),
    takeEvery(
      getType(actions.getPassportValidationStatus.request),
      safe(
        getPassportValidationStatus,
        actions.getPassportValidationStatus.failure
      )
    )
  ]);
}
