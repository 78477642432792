import { useSelector } from 'react-redux';
import * as selectors from 'src/store/selectors';
import userManager from 'src/utils/userManager';

const LoginGuard = ({ children }: { children: JSX.Element }) => {
  const user = useSelector(selectors.selectUser);
  const isLoadingUser = useSelector(selectors.selectIsLoadingUser);

  if (isLoadingUser) {
    return null;
  }

  if (!user || user.expired) {
    // @ts-ignore
    userManager.storeUser(null);
    userManager.clearStaleState();
    userManager.signinRedirect();
    return null;
  }

  return children;
};

export default LoginGuard;
