import { createAsyncAction } from 'typesafe-actions';
import { EquipmentsRequest, StateChangeAsset } from './types';

export const getEquipments = createAsyncAction(
  '@STATE_CHANGE/GET_EQUIPMENTS_REQUEST',
  '@STATE_CHANGE/GET_EQUIPMENTS_SUCCESS',
  '@STATE_CHANGE/GET_EQUIPMENTS_FAILURE'
)<EquipmentsRequest, undefined, Error>();

export const getStateChangeAsset = createAsyncAction(
  '@STATE_CHANGE/GET_STATE_CHANGE_ASSET_REQUEST',
  '@STATE_CHANGE/GET_STATE_CHANGE_ASSET_SUCCESS',
  '@STATE_CHANGE/GET_STATE_CHANGE_ASSET_FAILURE'
)<EquipmentsRequest, StateChangeAsset, Error>();

export const approveStateChange = createAsyncAction(
  '@STATE_CHANGE/APPROVE_STATE_CHANGE_REQUEST',
  '@STATE_CHANGE/APPROVE_STATE_CHANGE_SUCCESS',
  '@STATE_CHANGE/APPROVE_STATE_CHANGE_FAILURE'
)<number, undefined, Error>();
