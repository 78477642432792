import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { NextBillingDocumentDate } from '../../types';

type NextBillingDocumentDateActions = ActionType<typeof actions>;

const initialState: NextBillingDocumentDate = {
  date: null
};

const billingDocumentNextDateReducers = createReducer<
  NextBillingDocumentDate,
  NextBillingDocumentDateActions
>(initialState).handleAction(
  actions.getNextBillingDocumentDate.success,
  (state, { payload }) => ({
    ...state,
    date: payload
  })
);

export default billingDocumentNextDateReducers;
