import { createAsyncAction } from 'typesafe-actions';

interface RegisterBreakdownsConfig {
  formConfig: ConfigBase;
  dataReducerPath: string;
  tableReducerPath: string;
}

export const createBreakdown = createAsyncAction(
  '@BREAKDOWN/CREATE_BREAKDOWN_REQUEST',
  '@BREAKDOWN/CREATE_BREAKDOWN_SUCCESS',
  '@BREAKDOWN/CREATE_BREAKDOWN_FAILURE'
)<RegisterBreakdownsConfig, number, Error>();
