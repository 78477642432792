import { createAsyncAction } from 'typesafe-actions';
import {
  FiltersValues,
  PdfData
} from 'src/views/avl/dispatching/schedulesStatus/types';

export const getPdfData = createAsyncAction(
  'SCHEDULES_STATUS/GET_PDF_DATA_REQUEST',
  'SCHEDULES_STATUS/GET_PDF_DATA_SUCCESS',
  'SCHEDULES_STATUS/GET_PDF_DATA_FAILURE'
)<FiltersValues, PdfData, Error>();
