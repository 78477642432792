import { all, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { KPIS } from 'src/config/clearingUrls';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';

export function* createKpiSaga({
  payload
}: ReturnType<typeof actions.createKPI.request>): SagaIterator {
  yield call(apiClient.post, KPIS, payload, {
    retryAction: actions.createKPI.request(payload)
  });
  yield put(navigateTo({ url: '/clearing/kpis' }));
  yield put(
    enqueueSnackbar({
      key: 'kpi_create',
      message: 'Показник створено',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.createKPI.success());
}

export default function* KPICreateSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.createKPI.request),
      safe(createKpiSaga, actions.createKPI.failure)
    )
  ]);
}
