import { createReducer, ActionType } from 'typesafe-actions';
import { CoefficientsGroup } from '../../types';
import * as actions from './actions';

export const editCoefficientsAdjustmentsReducer = createReducer<
  CoefficientsGroup | null,
  ActionType<typeof actions>
>(null).handleAction(
  actions.getCoefficientsGroup.success,
  (_state, { payload }) => payload
);
