import { Row } from 'src/components/Tables/types';
import {
  changeStatusColumn,
  formatDate
} from 'src/components/Tables/TableWithPagination/utils';
import {
  TRANSFER_NOTES_AUTOCOMPLETE,
  TRANSFER_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE,
  TRANSFERS
} from 'src/config/assetUrls';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import TextWithEllipsis from 'src/components/TextWithEllipsis';
import { STATUS_CONFIG } from 'src/constants';
import { TransferStatusEnum, transferStatusEnumStatusMap } from '../const';
import TransferSwitchColumn from './TransferSwitchColumn';

import {
  getCounteragentHolderConfig,
  getCounteragentTransferConfig
} from '../TransferStep/const';

export const TRANSFER_TABLE_PATH = 'asset.transfer.table';
export const TRANSFER_TABLE_NAME = 'TRANSFER';

export const TABLE_CONFIG_BASE = {
  reducerName: TRANSFER_TABLE_NAME,
  reducerPath: TRANSFER_TABLE_PATH,
  apiUrl: TRANSFERS
};

export const getColumns = (
  isShowEditAllowed: boolean,
  onChangeStatus: (row: Row) => Promise<void>
) => [
  {
    label: 'Номер документа',
    value: 'documentNumber',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Дата переміщення',
    value: 'dateTransfer',
    sortable: true,
    width: '15%',
    formatValue: (row: Row) => formatDate(row.dateTransfer)
  },
  {
    label: 'Підрозділ від',
    value: 'counteragentHolderName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Підрозділ до',
    value: 'counteragentTransferName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Кількість обладнання',
    value: 'transferEquipment',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Хто перемістив',
    value: 'performingPersonFullName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Статус документа',
    value: 'statusId',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) =>
      changeStatusColumn({
        ...row,
        status: transferStatusEnumStatusMap[row.statusId as TransferStatusEnum]
      }),
    formatValue: (row: Row) =>
      STATUS_CONFIG[
        transferStatusEnumStatusMap[
          row.statusId as TransferStatusEnum
        ].toLowerCase()
      ].label
  },
  {
    label: '',
    value: '',
    sortable: false,
    changeValue: (row: Row) =>
      isShowEditAllowed ? (
        <TransferSwitchColumn data={row} onChange={onChangeStatus} />
      ) : null,
    width: '10%'
  },
  {
    label: 'Примітка',
    value: 'notes',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => <TextWithEllipsis text={row.notes} />
  }
];

export const TRANSFER_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME =
  'TRANSFER_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE';

export const TRANSFER_NOTES_AUTOCOMPLETE_NAME =
  'TRANSFER_NOTES_AUTOCOMPLETE_NAME';

export const getFilters = (counteragentTypeId?: number) => [
  {
    type: FilterType.Date,
    name: 'dateTransfer',
    label: 'Дата',
    datePickerProps: {
      disableFuture: true
    },
    width: 220
  },
  {
    name: 'counteragentHolderId',
    label: 'Підрозділ від',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      ...getCounteragentHolderConfig(counteragentTypeId),
      value: 'id'
    },
    width: 220
  },
  {
    name: 'counteragentTransferId',
    label: 'Підрозділ до',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      ...getCounteragentTransferConfig(counteragentTypeId),
      value: 'id'
    },
    width: 220
  },
  {
    name: 'performingEmployeeId',
    label: 'Хто перемістив',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: TRANSFER_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE_NAME,
      reducerPath: 'asset.transfer.responsibleEmployeeAutocomplete',
      optionsApiUrl: TRANSFER_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE,
      value: 'id'
    },
    width: 220
  },
  {
    name: 'statusId',
    label: 'Статус',
    options: [
      {
        label: 'Зафіксований',
        value: TransferStatusEnum.Fixed
      },
      {
        label: 'Чернетка',
        value: TransferStatusEnum.Draft
      }
    ],
    width: 220
  },
  {
    name: 'notes',
    label: 'Примітка',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: TRANSFER_NOTES_AUTOCOMPLETE_NAME,
      reducerPath: 'asset.transfer.notesAutocomplete',
      optionsApiUrl: TRANSFER_NOTES_AUTOCOMPLETE
    },
    width: 220,
    value: 'name'
  }
];
