import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { ROUTE_TIMETABLE } from 'src/config/avlUrls';
import { selectRouteParams } from 'src/views/avl/Routes/RoutesPassportView/store/selectors';
import { navigateTo } from 'src/store/actions/app';
import * as actions from './actions';

export function* editDeparture({
  payload
}: ReturnType<typeof actions.editDeparture.request>): SagaIterator {
  const { id, subId, extraId } = yield select(selectRouteParams);

  const { data } = yield call(
    apiClient.put,
    `${ROUTE_TIMETABLE}/${subId}/route-departures/${extraId}`,
    payload,
    {
      retryAction: actions.editDeparture.request
    }
  );
  yield put(
    navigateTo({
      url: `/avl/routes/create/timetable/${id}/details/${subId}/details/${data}`
    })
  );
  yield put(
    enqueueSnackbar({
      key: 'departure_update',
      message: 'Випуск оновлено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.editDeparture.success());
}

export default function* departuresEditSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.editDeparture.request),
    safe(editDeparture, actions.editDeparture.failure)
  );
}
