import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';

const matchSpaceParams = createMatchSelector('/avl/vehicles/:id');

export const selectVehicle = (state: RootState) =>
  get(state, `ui.avl.vehicles.edit`);

export const selectIsLoadingVehicle = (state: RootState) => {
  const loading = get(state, `loading.@VEHICLES_EDIT/GET_VEHICLE`);

  return isBoolean(loading) ? loading : true;
};

export const selectVehicleId = createSelector(
  matchSpaceParams,
  (match: any) => match?.params.id || ''
);
