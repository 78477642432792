import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { SchemeStop } from '../../../../types';

type SettingsDetailsActions = ActionType<typeof actions>;

const initialState: SchemeStop[] = [];

const schemeStopsReducer = createReducer<SchemeStop[], SettingsDetailsActions>(
  initialState
).handleAction(actions.getSchemeStops.success, (state, { payload }) => payload);

export default schemeStopsReducer;
