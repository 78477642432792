/* eslint-disable no-param-reassign */
import { ActionType, createReducer } from 'typesafe-actions';
import { findIndex } from 'lodash';
import produce from 'immer';
import { DETAILED_TIME_FORMAT } from 'src/config';
import * as actions from './actions';
import {
  RideModeType,
  TrafficStadiumData,
  TrafficStadiumsState
} from '../types';
import { formatSecondsToTimestamp, normalizeRides } from '../utils';
import { defaultRideModeDetailsValues } from '../components/SpecialMode/const';

export const trafficStadiumInitialState: TrafficStadiumData = {
  id: null,
  counteragentId: null,
  counteragentTreeCode: undefined,
  gpsLoading: false,
  segments: [],
  stops: [],
  traffic: {
    vehicleCategoryName: '-',
    vehicleCategoryId: null,
    rideMode: {
      rideMode: RideModeType.Regular,
      specialModeDetails: {
        endsAtUtc: '',
        notes: '',
        rideDisruptionEventReasonId: undefined,
        startsAtUtc: ''
      }
    },
    rides: {
      allIds: [],
      byId: {}
    }
  }
};

const trafficStadiumsInitialState: TrafficStadiumsState = {
  routes: {
    allIds: [],
    byId: {}
  }
};

export const trafficStadiumsReducer = createReducer<
  TrafficStadiumsState,
  ActionType<typeof actions>
>(trafficStadiumsInitialState)
  .handleAction(actions.setGpsLoadingByRouteId, (state, { payload }) =>
    produce(state, ({ routes: { byId } }) => {
      byId[payload.routeId] = {
        ...byId[payload.routeId],
        gpsLoading: payload.gpsLoading
      };
    })
  )
  .handleAction(
    actions.getTrafficStadiumsData.success,
    (
      _,
      { payload: { trafficStadiums, counteragentId, counteragentTreeCode } }
    ) => ({
      counteragentId,
      counteragentTreeCode,
      routes: {
        allIds: trafficStadiums.map(item => item.id).filter(item => !!item),
        byId: trafficStadiums.reduce((accumulator, item) => {
          if (item.id) {
            accumulator[item.id] = item;
          }

          return accumulator;
        }, {} as { [key: string]: TrafficStadiumData })
      }
    })
  )
  .handleAction(
    actions.updateTrafficStadiumDataByRouteId.success,
    (state, { payload }) =>
      produce(state, ({ routes: { byId } }) => {
        byId[payload.routeId] = payload.trafficStadiumData;
      })
  )
  .handleAction(
    actions.resetTrafficStadiumsData,
    () => trafficStadiumsInitialState
  )
  .handleAction(actions.getGpsMessageSuccessByRouteId, (state, { payload }) =>
    produce(
      state,
      ({
        routes: {
          byId: {
            [payload.routeId]: {
              traffic: { rides }
            }
          }
        }
      }) => {
        const {
          rideId,
          driverId,
          vehicleRegistrationNumber,
          rideStatus,
          createdAt,
          routeDepartureId,
          ...rest
        } = payload;
        const id = rideId?.value as number;
        const ride = rides.byId[id];

        if (ride) {
          const incorrectSchedule =
            driverId || vehicleRegistrationNumber
              ? ride.driver.id !== driverId?.value ||
                ride.vehicle.licensePlate !== vehicleRegistrationNumber
              : false;

          rides.byId[id] = {
            ...ride,
            ...rest,
            status: rideStatus!,
            fromStopId: rest.fromStopId?.value,
            toStopId: rest.toStopId?.value,
            distanceMeters: rest.distanceMeters?.value,
            deviationTypeId: rest.deviationTypeId?.value,
            vehicle: {
              id: payload.vehicleId,
              licensePlate: payload.vehicleRegistrationNumberFromBarcode
            },
            gps: {
              hasSignal: true,
              createdAt: createdAt.seconds,
              receivedAt: formatSecondsToTimestamp(
                createdAt.seconds,
                DETAILED_TIME_FORMAT
              )
            },
            incorrectSchedule
          };
        }
      }
    )
  )
  .handleAction(actions.updateVehicleByRouteId, (state, { payload }) =>
    produce(
      state,
      ({
        routes: {
          byId: {
            [payload.routeId]: {
              traffic: { rides }
            }
          }
        }
      }) => {
        const { rideId } = payload;
        const ride = rides.byId[rideId!];

        if (ride) {
          rides.byId[rideId!] = {
            ...ride,
            ...payload
          };
        }
      }
    )
  )
  .handleAction(
    actions.cancelStopUnavailabilityByRouteId,
    (state, { payload }) =>
      produce(
        state,
        ({
          routes: {
            byId: {
              [payload.routeId]: { stops }
            }
          }
        }) => {
          const stopIndex = findIndex(
            stops,
            stop => payload.busStopId === stop.id
          );
          stops[stopIndex].unavailabilityDetails = null;
        }
      )
  )
  .handleAction(actions.setStopUnavailabilityByRouteId, (state, { payload }) =>
    produce(
      state,
      ({
        routes: {
          byId: {
            [payload.routeId]: { stops }
          }
        }
      }) => {
        const { unavailableFrom, unavailableTo, busStopId, ...rest } = payload;
        const stopIndex = findIndex(stops, stop => busStopId === stop.id);

        stops[stopIndex].unavailabilityDetails = {
          ...rest,
          unavailableFrom: formatSecondsToTimestamp(unavailableFrom.seconds),
          unavailableTo: unavailableTo
            ? formatSecondsToTimestamp(unavailableTo.seconds)
            : ''
        };
      }
    )
  )
  .handleAction(actions.setTerminateDepartureByRouteId, (state, { payload }) =>
    produce(
      state,
      ({
        routes: {
          byId: {
            [payload.routeId]: {
              traffic: { rides }
            }
          }
        }
      }) => {
        const { terminatesAt, ...rest } = payload;

        Object.values(rides.byId).forEach(({ routeDepartureId }, idx) => {
          if (routeDepartureId === payload.id) {
            rides.byId[rides.allIds[idx]].plannedTermination = {
              ...rest,
              terminatesAtUtc: formatSecondsToTimestamp(terminatesAt.seconds)
            };
          }
        });
      }
    )
  )
  .handleAction(
    actions.cancelTerminateDepartureByRouteId,
    (state, { payload }) =>
      produce(
        state,
        ({
          routes: {
            byId: {
              [payload.routeId]: {
                traffic: { rides }
              }
            }
          }
        }) => {
          Object.values(rides.byId).forEach(({ routeDepartureId }, idx) => {
            if (routeDepartureId === payload.routeDepartureId) {
              rides.byId[rides.allIds[idx]].plannedTermination = null;
            }
          });
        }
      )
  )
  .handleAction(actions.setRouteRideModeByRouteId, (state, { payload }) =>
    produce(state, ({ routes: { byId } }) => {
      const { rides, rideMode } = byId[payload.routeId].traffic;

      if (payload.rideMode === RideModeType.Special) {
        Object.values(rides.byId).forEach(ride => {
          ride.rideMode = {
            rideMode: RideModeType.Regular,
            specialModeDetails: defaultRideModeDetailsValues
          };
        });
      }

      byId[payload.routeId].traffic.rideMode = {
        ...rideMode,
        rideMode: payload.rideMode
      };
    })
  )
  .handleAction(
    actions.setRouteRideModeDetailsByRouteId,
    (state, { payload }) =>
      produce(
        state,
        ({
          routes: {
            byId: {
              [payload.routeId]: {
                traffic: { rideMode }
              }
            }
          }
        }) => {
          rideMode.specialModeDetails = {
            ...payload,
            startsAtUtc: formatSecondsToTimestamp(payload.startsAtUtc.seconds),
            endsAtUtc: payload.endsAtUtc?.seconds
              ? formatSecondsToTimestamp(payload.endsAtUtc.seconds)
              : ''
          };
        }
      )
  )
  .handleAction(actions.setRideSpecialModeByRouteId, (state, { payload }) =>
    produce(
      state,
      ({
        routes: {
          byId: {
            [payload.routeId]: {
              traffic: { rides }
            }
          }
        }
      }) => {
        const { rideId, routeId, ...rest } = payload;
        const ride = rides.byId[rideId];

        ride.rideMode = {
          ...ride.rideMode,
          ...rest
        };
      }
    )
  )
  .handleAction(
    actions.setRideSpecialModeDetailsByRouteId,
    (state, { payload }) =>
      produce(
        state,
        ({
          routes: {
            byId: {
              [payload.routeId]: {
                traffic: { rides }
              }
            }
          }
        }) => {
          const {
            rideScheduleId,
            startsAtUtc,
            endsAtUtc,
            ...details
          } = payload;

          const ride = rides.byId[rideScheduleId];

          ride.rideMode.specialModeDetails = {
            ...details,
            startsAtUtc: formatSecondsToTimestamp(startsAtUtc.seconds),
            endsAtUtc: endsAtUtc?.seconds
              ? formatSecondsToTimestamp(endsAtUtc.seconds)
              : ''
          };
        }
      )
  )
  .handleAction(actions.cancelRouteRideModeByRouteId, (state, { payload }) =>
    produce(state, ({ routes: { byId } }) => {
      byId[payload.routeId].traffic.rideMode =
        trafficStadiumInitialState.traffic.rideMode;
    })
  )
  .handleAction(actions.cancelRideSpecialModeByRouteId, (state, { payload }) =>
    produce(
      state,
      ({
        routes: {
          byId: {
            [payload.routeId]: {
              traffic: { rides }
            }
          }
        }
      }) => {
        const ride = rides.byId[payload.rideScheduleId];
        ride.rideMode = trafficStadiumInitialState.traffic.rideMode;
      }
    )
  )
  .handleAction(actions.removeDepartureByRouteId, (state, { payload }) =>
    produce(
      state,
      ({
        routes: {
          byId: {
            [payload.routeId]: { traffic }
          }
        }
      }) => {
        const newRides = Object.values(traffic.rides.byId).filter(
          ride => ride.routeDepartureId !== payload.id
        );
        traffic.rides = normalizeRides(newRides);
      }
    )
  )
  .handleAction(actions.setRideChangeoverByRouteId, (state, { payload }) =>
    produce(
      state,
      ({
        routes: {
          byId: {
            [payload.routeId]: {
              traffic: { rides }
            }
          }
        }
      }) => {
        const { changeAtUtc, rideScheduleId, ...changeover } = payload;
        const ride = rides.byId[rideScheduleId];
        ride.changeover = {
          ...changeover,
          phoneNumber: changeover.phoneNumber?.value,
          id: changeover.changeoverId,
          changeAtUtc: formatSecondsToTimestamp(changeAtUtc.seconds)
        };
      }
    )
  )
  .handleAction(actions.cancelRideChangeoverByRouteId, (state, { payload }) =>
    produce(
      state,
      ({
        routes: {
          byId: {
            [payload.routeId]: {
              traffic: { rides }
            }
          }
        }
      }) => {
        const rideId = rides.allIds.find(
          id => rides.byId[id]!.changeover?.id === payload.changeoverId
        );

        const ride = rides.byId[rideId!];
        ride.changeover = null;
      }
    )
  )
  .handleAction(
    actions.updateRideWithChangeoverByRouteId,
    (state, { payload }) =>
      produce(
        state,
        ({
          routes: {
            byId: {
              [payload.routeId]: {
                traffic: { rides }
              }
            }
          }
        }) => {
          const ride = rides.byId[payload.id];
          const { changeover, deviation } = ride;

          if (changeover) {
            const deviationInSeconds =
              ride.vehicle.id === changeover.vehicleId ? deviation.seconds : '';
            ride.deviation = {
              nanos: 0,
              seconds: deviationInSeconds
            };

            ride.driver = {
              id: changeover.driverId,
              name: changeover.driverName,
              phoneNumber: changeover.phoneNumber
            };

            ride.vehicle = {
              id: changeover.vehicleId,
              licensePlate: changeover.licensePlate
            };

            ride.fromStopId = null;
            ride.toStopId = null;
            ride.changeover = null;
          }
        }
      )
  );
