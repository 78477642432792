import { generateActionCrud } from 'src/components/Guards';
import { orderBy } from 'lodash';
import moment from 'moment';
import { ROUTE_PASSPORT } from 'src/config/avlUrls';
import { MAIN_MENU, NAV_BAR_HEADERS } from 'src/constants';
import { CLEARING_ERROR_MESSAGES } from 'src/constants/validation';
import { COEFFICIENTS_ADJUSTMENTS_URL } from 'src/routes/coefficientsAdjustmentsRoutes';
import { SorterArgs, TableConfig } from 'src/components/Tables/Table/types';
import { DATE_FORMAT_SLASH, DATEPICKER_DATE_FORMAT } from 'src/config';
import { Row } from 'src/components/Tables/types';
import { CoefficientsGroup, RouteCoefficient, TimeCoefficient } from './types';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.clearing,
    url: '/clearing'
  }
];
export const coefficientsAdjustmentsPageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_HEADERS.coefficientsTable,
    url: COEFFICIENTS_ADJUSTMENTS_URL
  }
];

export const crudActions = generateActionCrud({
  module: 'clearing',
  sub: 'coefficientsAdjustments'
});

export const COEFFICIENTS_ADJUSTMENTS_ROUTES_NAME =
  'COEFFICIENTS_ADJUSTMENTS_ROUTES';
export const COEFFICIENTS_ADJUSTMENTS_ROUTES_CONFIG = {
  prohibitInitialLoad: true,
  prohibitDynamicLoad: true,
  optionsApiUrl: `${ROUTE_PASSPORT}?status=approved&routeSchemaValidOn=${moment(
    new Date()
  ).format(DATEPICKER_DATE_FORMAT)}`,
  reducerName: COEFFICIENTS_ADJUSTMENTS_ROUTES_NAME,
  reducerPath: 'clearing.coefficientsAdjustments.edit.routesAutocomplete'
};

export const routeCoefficientInitialValues: RouteCoefficient = {
  complexity: null,
  tender: null,
  specialMode: null,
  isDepotDistanceIncluded: false,
  effectiveDate: null
};

export const timeCoefficientInitialValue: TimeCoefficient = {
  endDate: null,
  startDate: null,
  timeCoefficient: null
};

export const coefficientsGroupInitialValues: CoefficientsGroup = {
  route: null,
  routeCoefficients: [routeCoefficientInitialValues],
  timeCoefficients: []
};

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_HEADERS.coefficientsTable,
    url: COEFFICIENTS_ADJUSTMENTS_URL
  }
];

export enum CoefficientsTabsValues {
  Tracking = 1,
  Timing = 2
}

const dateSorter = ({ key, order, list }: SorterArgs<Row>) =>
  orderBy(list, value => moment(value[key], DATE_FORMAT_SLASH), [
    order.toLowerCase() as 'asc' | 'desc'
  ]);

export const timingCoefficientColumns = [
  {
    label: 'Часовий коефіцієнт',
    value: 'timeCoefficient',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Початок дії',
    value: 'startDate',
    sorterFunc: dateSorter,
    sortable: true,
    width: '20%'
  },
  {
    label: 'Кінець дії',
    value: 'endDate',
    sorterFunc: dateSorter,
    sortable: true,
    width: '20%'
  }
];

export const TIME_COEFFICIENTS_TABLE_NAME = 'TIME_COEFFICIENTS_TABLE_NAME';

// @ts-ignore
export const config: TableConfig = {
  reducerName: TIME_COEFFICIENTS_TABLE_NAME,
  reducerPath: 'clearing.coefficientsAdjustments.timeTable'
};

export const coefficientRouteColumns = [
  'Тендерний коефіцієнт',
  'Коефіцієнт складності',
  'Коефіцієнт спецрежиму',
  'Маршрут з/до депо',
  'Початок дії'
];

export const COEFFICIENTS_ADJUSTMENTS_ERROR_MESSAGES = {
  ...CLEARING_ERROR_MESSAGES,
  uniqueError: ''
};

export const SNACKBAR_ERROR_MESSAGES = {
  uniqueError: 'Вже встановлені коефіцієнти для цього маршруту'
} as Record<string, string>;
