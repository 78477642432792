import { EQUIPMENT, EQUIPMENT_GROUP_NAMES } from 'src/config/assetUrls';
import { TABLE_ACTIONS_CONFIG } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import { crudActions } from '../const';

export const columns = [
  {
    label: 'Назва групи',
    value: 'groupName',
    sortable: true,
    width: '30%'
  },
  {
    label: 'Назва обладнання',
    value: 'name',
    sortable: true,
    width: '30%'
  },
  {
    label: 'Виробник',
    value: 'manufacturer',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Модель',
    value: 'productType',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Періодичність інспекції, дні',
    value: 'inspectionDays',
    sortable: true,
    width: '15%'
  }
];

export const filters = [
  {
    name: 'EquipmentGroup.id',
    label: 'Група',
    optionsURL: EQUIPMENT_GROUP_NAMES
  }
];

export const EQUIPMENT_TABLE_PATH = 'asset.equipment.table';
export const EQUIPMENT_TABLE_NAME = 'EQUIPMENT';

export const tableConfig = {
  action: crudActions.create,
  reducerName: EQUIPMENT_TABLE_NAME,
  reducerPath: EQUIPMENT_TABLE_PATH,
  apiUrl: EQUIPMENT,
  routeUrl: '/asset/dictionaries/equipment/',
  rowActions: {
    [TABLE_ACTIONS_CONFIG.EDIT]: {
      isActive: true,
      action: crudActions.update
    },
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      isActive: (row: Row) => !row.isUsed,
      action: crudActions.delete,
      disabledTooltip: 'Неможливо видалити. Дане обладнання використовується'
    }
  },
  toolbarActions: {
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      action: crudActions.delete
    }
  },
  filters,
  isRowClickEnabled: true
};
