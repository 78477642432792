import { ActionType } from 'typesafe-actions';
import { TableState } from '../components/Tables/Table/types';
import { TableWithPaginationState } from '../components/Tables/TableWithPagination/types';

type Table = TableState | TableWithPaginationState;

// Generic filtering higher-order reducer
export const createNamedWrapperReducer = (
  reducerFunction: Function,
  reducerName: string
) => (state: Table, action: ActionType<any>) => {
  const { config } = action;
  const isInitializationCall = state === undefined;

  if (config && config.reducerName !== reducerName && !isInitializationCall) {
    return state;
  }

  return reducerFunction(state, action);
};
