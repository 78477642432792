import { jsPDF as JSPDF, jsPDFOptions } from 'jspdf';
import autoTable, { RowInput, Styles, Table } from 'jspdf-autotable';
import { get } from 'lodash';
import { pdfFont } from 'src/assets/fonts/pdfFont';

const START_Y = 5;
export const PARAGRAPH = 14;

export interface RowItems<T = Record<string, unknown>> {
  label: string;
  value: keyof T;
  changeValue?: (value: string) => string;
  width?: Styles['cellWidth'];
}

interface Data {
  [key: string]: any;
}

export enum TypeTable {
  InfoTable = 1,
  ListTable = 2
}

interface Generator {
  setHeader(text: string, center: boolean): this;
  setTable(columns: RowItems[], data: object[], type: TypeTable): this;
  saveGenerateFile(text: string): this;
  openFileInNewWindow(): this;
  getBlobPdf(): Blob;
  getBase64Pdf(): string;
  addNewPage(): this;
}

export class PdfGenerator implements Generator {
  readonly doc: JSPDF;
  private fileName: string;
  // @ts-ignore
  tableConfig: Table = {};

  constructor(
    fileName: string = 'index',
    fontSize: number = 13,
    config?: jsPDFOptions
  ) {
    this.doc = new JSPDF(config);
    this.doc.setFontSize(fontSize);
    this.doc.addFileToVFS('Roboto-Regular.ttf', pdfFont);
    this.doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
    this.doc.setFont('Roboto');
    this.fileName = fileName;
    this.doc.setProperties({
      title: fileName
    });
  }

  public setHeader(
    text: string,
    center: boolean,
    options?: {
      xPosition?: number;
      updateFinalY?: boolean;
      marginTop?: number;
    }
  ) {
    const centerPage = this.doc.internal.pageSize.getWidth() / 2;

    const margins = {
      MARGIN_TOP_WITH_LONGTEXT: 20,
      MARGIN_TOP: 10,
      MARGIN_LEFT: 10
    };
    const margin =
      text.length > 50 ? margins.MARGIN_TOP_WITH_LONGTEXT : margins.MARGIN_TOP;
    const finalY =
      (options?.marginTop ?? margin) + (this.tableConfig?.finalY || START_Y);

    if (center) {
      this.doc.text(text, centerPage, finalY, {
        align: 'center',
        maxWidth: (centerPage - margins.MARGIN_LEFT) * 2
      });
    } else {
      this.doc.text(text, options?.xPosition || PARAGRAPH, finalY, {
        maxWidth: (centerPage - margins.MARGIN_LEFT) * 2
      });
    }

    if (options?.updateFinalY) {
      this.tableConfig.finalY = finalY;
    }

    return this;
  }

  public setTable(
    columns: RowItems[],
    data: Data,
    type: TypeTable,
    startY: number = 0
  ) {
    let config = {};

    if (type === TypeTable.InfoTable) {
      const tableBody: RowInput[] = [];

      columns.forEach(({ label, value, changeValue }) => {
        const cell = get(data, value);
        tableBody.push([label, changeValue ? changeValue(cell) : cell || '']);
      });
      config = {
        body: tableBody,
        columnStyles: {
          0: { cellWidth: 60 },
          1: { cellWidth: 'auto' }
        }
      };
    }

    if (type === TypeTable.ListTable) {
      const tableHead: string[] = [];
      const tableRowValues: {
        value: string;
        changeValue?: RowItems['changeValue'];
      }[] = [];

      columns.forEach(item => {
        tableHead.push(item.label);
        tableRowValues.push({
          value: item.value || '',
          changeValue: item.changeValue
        });
      });

      const tableBody = data?.map((row: Data, index: number) =>
        tableRowValues.map(({ value, changeValue }) => {
          if (value === 'index') {
            return index + 1;
          }

          return changeValue ? changeValue(row[value]) : row[value];
        })
      );

      const columnStyles = columns.reduce((accumulator, column, index) => {
        if (column.width) {
          accumulator[index] = { cellWidth: column.width };
        }

        return accumulator;
      }, {} as { [key: string]: Partial<Styles> });

      config = {
        head: [tableHead],
        body: tableBody,
        columnStyles
      };
    }

    config = {
      ...config,
      didParseCell: (HookData: any) => {
        this.tableConfig = HookData.table;
      },
      startY: startY || 25 + (this.tableConfig?.finalY || START_Y),
      theme: 'grid',
      styles: {
        font: 'Roboto',
        fontStyle: 'normal'
      }
    };

    autoTable(this.doc, config);

    return this;
  }

  addNewPage() {
    if (this.tableConfig) {
      this.tableConfig.finalY = START_Y;
    }
    this.doc.addPage();

    return this;
  }

  public saveGenerateFile() {
    this.doc.save(`${this.fileName}.pdf`);
    return this;
  }

  public openFileInNewWindow() {
    window.open(URL.createObjectURL(this.getBlobPdf()));
    return this;
  }

  public getBlobPdf(): Blob {
    return this.doc.output('blob');
  }

  public getBase64Pdf(): string {
    return this.doc.output('datauristring');
  }
}
