export const CREATE_TRANSFER_FORM_NAME = 'CREATE_TRANSFER_FORM';
export const CREATE_TRANSFER_FORM_PATH = 'asset.transfer.create.data';
export const CREATE_TRANSFER_FORM_CONFIG = {
  reducerName: CREATE_TRANSFER_FORM_NAME,
  reducerPath: CREATE_TRANSFER_FORM_PATH
};

export const TRANSFERING_EQUIPMENT_NAME = 'TRANSFERING_EQUIPMENT_NAME';
export const TRANSFERING_EQUIPMENT_PATH = 'asset.transfer.create.table';

export const TRANSFERING_TABLE_CONFIG = {
  reducerName: TRANSFERING_EQUIPMENT_NAME,
  reducerPath: TRANSFERING_EQUIPMENT_PATH,
  apiUrl: ``,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно видалено'
  }
};
