import { SystemStatusDTO } from '@eticket/avl-api-contracts';
import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';
import { COUNTERAGENTS_TYPES_ALL } from 'src/config/avlUrls';

export const columns = [
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '25%'
  },
  {
    label: 'Тип',
    value: 'departmentType',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Батьківський підрозділ',
    value: 'parentDepartmentName',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Контакт',
    value: 'contact',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Статус',
    value: 'status',
    sortable: true,
    width: '15%',
    changeValue: changeStatusColumn
  },
  {
    label: 'Код',
    value: 'code',
    sortable: true,
    width: '10%'
  }
];

export const COUNTERAGENTS_TABLE_PATH = 'avl.counteragents.table';
export const COUNTERAGENTS_TABLE_NAME = 'COUNTERAGENTS';

export const filters = [
  {
    name: 'departmentTypeId',
    label: 'Tuп',
    optionsURL: COUNTERAGENTS_TYPES_ALL
  },
  {
    name: 'status',
    label: 'Статус',
    options: [
      {
        label: 'Не активний',
        value: SystemStatusDTO.Disabled
      },
      {
        label: 'Активний',
        value: SystemStatusDTO.Enabled
      }
    ]
  }
];
