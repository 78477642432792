import { RoutePerformingReport } from '../types';

export const routePerformingReportMock: RoutePerformingReport = {
  rides: [
    {
      date: ' 29.05.2021',
      licensePlate: 'АА3344НН',
      departureNumber: 1,
      totalRides: 5,
      ridesCompleted: 5,
      ridesCompletenessRatio: 100,
      ridesFailed: 0,
      ridesFailureRatio: 0
    },
    {
      date: ' 29.05.2022',
      licensePlate: 'ВВ4455АА',
      departureNumber: 2,
      totalRides: 5,
      ridesCompleted: 5,
      ridesCompletenessRatio: 100,
      ridesFailed: 0,
      ridesFailureRatio: 0
    },
    {
      date: ' 29.05.2022',
      licensePlate: 'ВВ4455АА',
      departureNumber: 2,
      totalRides: 5,
      ridesCompleted: 5,
      ridesCompletenessRatio: 100,
      ridesFailed: 0,
      ridesFailureRatio: 0
    },
    {
      date: ' 29.05.2022',
      licensePlate: 'ВВ4455АА',
      departureNumber: 2,
      totalRides: 5,
      ridesCompleted: 5,
      ridesCompletenessRatio: 100,
      ridesFailed: 0,
      ridesFailureRatio: 0
    },
    {
      date: ' 29.05.2022',
      licensePlate: 'ВВ4455АА',
      departureNumber: 2,
      totalRides: 5,
      ridesCompleted: 5,
      ridesCompletenessRatio: 100,
      ridesFailed: 0,
      ridesFailureRatio: 0
    },
    {
      date: ' 29.05.2022',
      licensePlate: 'ВВ4455АА',
      departureNumber: 2,
      totalRides: 5,
      ridesCompleted: 5,
      ridesCompletenessRatio: 100,
      ridesFailed: 0,
      ridesFailureRatio: 0
    }
  ],
  ridesCount: 30,
  totalRidesCompleted: 21.5,
  totalRidesFailed: 8.5,
  completenessRatio: 71,
  failureRatio: 28
};
