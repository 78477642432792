import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { LatLng } from 'leaflet';
import { SHAPES } from 'src/constants/map';
import { Row } from 'src/components/Tables/types';
import { STATUS_CONFIG } from 'src/constants';
import { MapData } from '../../types';
import { DEPOT } from '../../const';

const matchSpaceParams = createMatchSelector(
  '/avl/geozones/counteragents/:id/details'
);

export const selectIsLoadingCounteragent = (state: RootState) => {
  const loading = get(state, `loading.@COUNTERAGENTS_DETAILS/GET_COUNTERAGENT`);

  return isBoolean(loading) ? loading : true;
};

export const selectCounteragentName = (state: RootState): string => {
  return get(state, `ui.avl.counteragents.details.name`);
};

export const selectDeleteDisable = (state: RootState): boolean => {
  const { status } = get(state, 'ui.avl.counteragents.details');
  return status === 'Enabled';
};

export const selectIsDepotSelected = (state: RootState) => {
  const { counteragentType } = state.ui.avl.counteragents.details;

  return counteragentType === DEPOT;
};

export const selectMapData = (state: RootState): MapData => {
  const counteragentDetails = get(state, `ui.avl.counteragents.details`);
  const { lat, lng, points, address } = counteragentDetails;

  return {
    shape: {
      name: points ? SHAPES.POLYGON : SHAPES.CIRCLE,
      coordinates: { lat, lng } as LatLng,
      points
    },
    address
  };
};

export const selectCounteragentRows = (
  state: RootState
): Array<{ label: string; value: string }> => {
  const {
    name,
    shortName,
    code,
    codeForFareOn,
    counteragentType,
    parentDepartmentName,
    status,
    description,
    address,
    contacts = []
  } = get(state, `ui.avl.counteragents.details`);

  const contactList = contacts.map(
    (
      { phoneNumber, email, description: contactDescription }: Row,
      index: number
    ) => ({
      label: `Контакт ${contacts.length === 1 ? '' : index + 1}`,
      value: [phoneNumber, email, contactDescription].filter(Boolean).join('\n')
    })
  );

  return [
    {
      label: 'Назва',
      value: name
    },
    {
      label: 'Скорочена назва',
      value: shortName
    },
    {
      label: 'Тип',
      value: counteragentType
    },
    {
      label: 'Батьківський підрозділ',
      value: parentDepartmentName
    },
    {
      label: 'Статус',
      value: STATUS_CONFIG[status?.toLowerCase()]?.label
    },
    {
      label: 'Код',
      value: code
    },
    {
      label: 'Код для FareOn',
      value: codeForFareOn
    },
    {
      label: 'Примітка',
      value: description
    },
    ...(counteragentType !== DEPOT ? [{ label: 'Адреса', value: address }] : [])
  ].concat(contactList);
};

export const selectCounteragentId = createSelector(
  matchSpaceParams,
  (match: any) => {
    return match ? match.params.id : '';
  }
);
