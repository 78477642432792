import { FC } from 'react';
import Label from 'src/components/Labels/Label';
import { LabelColors } from 'src/components/Labels/Label/types';
import { KpiParametersState } from '../../const';

const getStateData: Record<KpiParametersState, [string, LabelColors]> = {
  [KpiParametersState.Current]: ['ПОТОЧНИЙ', 'success'],
  [KpiParametersState.Future]: ['МАЙБУТНІЙ', 'secondary'],
  [KpiParametersState.Expired]: ['МИНУЛИЙ', 'draft']
};

interface Props {
  state: KpiParametersState;
}

const KpiStateLabel: FC<Props> = ({ state }) => {
  const [label, color] = getStateData[state];

  return <Label color={color}>{label}</Label>;
};

export default KpiStateLabel;
