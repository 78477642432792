import { createReducer, ActionType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import * as actions from './actions';
import { TransferDetailsPayload } from '../../types';
import { TransferStatusEnum } from '../../const';

const initialState: TransferDetailsPayload = {
  id: 0,
  documentNumber: '',
  dateTransfer: '',
  counteragentHolderAddress: '',
  counteragentHolderName: '',
  counteragentHolderId: 0,
  counteragentTransferAddress: '',
  counteragentTransferName: '',
  counteragentTransferId: 0,
  status: StatusEnum.Fixed,
  statusId: TransferStatusEnum.Fixed,
  performingPerson: '',
  performingPersonFullName: '',
  performingPersonId: 0,
  acceptingPerson: '',
  acceptingPersonFullName: '',
  acceptingPersonId: 0,
  notes: '',
  equipment: []
};

const transferDetailsReducers = createReducer<
  TransferDetailsPayload,
  ActionType<typeof actions>
>(initialState).handleAction(
  actions.getTransfer.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default transferDetailsReducers;
