import { FC } from 'react';
import { makeStyles, Theme, Link } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { Link as RouterLink } from 'react-router-dom';

interface Props {
  to: string;
  stopPropagation?: boolean;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: blue[500]
    }
  },
  icon: {
    color: blue[500],
    marginLeft: spacing(1)
  }
}));

const NewTabLink: FC<Props> = ({ children, to, stopPropagation = false }) => {
  const classes = useStyles();

  return (
    <Link
      color="textPrimary"
      component={RouterLink}
      display="block"
      underline="none"
      noWrap
      to={to}
      variant="h6"
      target="_blank"
      referrerPolicy="no-referrer"
      className={classes.root}
      onClick={(event: React.MouseEvent) => {
        stopPropagation && event.stopPropagation();
      }}
    >
      {children}
      <OpenInNewIcon className={classes.icon} fontSize="small" />
    </Link>
  );
};

export default NewTabLink;
