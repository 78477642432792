import axios from 'axios';
import { Saga, SagaIterator } from 'redux-saga';
import { put, call, cancelled, race, take } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

export const safe = (saga: any, handler: any, cancelSource?: any) =>
  function* safeSaga(action: ActionType<any>) {
    try {
      yield call(saga, action);
    } catch (err) {
      const args = action.config ? [action.config, err] : [err];

      yield put(handler(...args));
    } finally {
      const args = action.config ? [action.config] : [];

      if (yield cancelled()) {
        yield put(handler(...args));
        cancelSource.cancel();
      }
    }
  };

export function* withCancel({
  action,
  cancelActionType,
  failureAction,
  saga
}: {
  action: ActionType<any>;
  cancelActionType: string;
  failureAction: ActionType<any>;
  saga: Saga;
}): SagaIterator {
  const cancelSource = axios.CancelToken.source();

  yield race({
    task: call(safe(saga, failureAction, cancelSource), {
      ...action,
      cancelSource
    }),
    cancel: take(cancelActionType)
  });
}
