import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { EQUIPMENT } from 'src/config/assetUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';

export function* createEquipment({
  payload
}: ReturnType<typeof actions.createEquipment.request>): SagaIterator {
  yield call(apiClient.post, EQUIPMENT, payload, {
    retryAction: actions.createEquipment.request(payload)
  });
  yield put(navigateTo({ url: '/asset/dictionaries/equipment' }));
  yield put(
    enqueueSnackbar({
      key: 'equipment_create',
      message: 'Обладнання створено',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.createEquipment.success());
}

export default function* EquipmentCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createEquipment.request),
    safe(createEquipment, actions.createEquipment.failure)
  );
}
