import { FC } from 'react';
import { StatusEnum } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import SwitchEdit from 'src/components/SwitchEdit';
import { isToggleDateRestricted, TOGGLE_DISABLED_TEXT } from '../const';

interface BreakdownSwitchProps {
  data: Row;
  onChange: (row: Row) => Promise<void>;
}

const BreakdownSwitch: FC<BreakdownSwitchProps> = ({ data, onChange }) => {
  const { status, registrationDate, documentNumber } = data;
  const disabled = isToggleDateRestricted(registrationDate);

  const handleChangeSwitch = async () => {
    if (status !== StatusEnum.Draft) {
      await onChange(data);
    }
  };

  const confirmMessage = `Ви впевнені, що хочете розфіксувати документ ${documentNumber}? Всі несправності обладнання з цього документа не будуть відображатись в Моніторингу та Звітах`;

  return (
    <SwitchEdit
      checked={status === StatusEnum.Draft}
      disabled={disabled}
      tooltip={TOGGLE_DISABLED_TEXT}
      onChange={handleChangeSwitch}
      confirmMessage={confirmMessage}
    />
  );
};

export default BreakdownSwitch;
