import { ActionType, createReducer } from 'typesafe-actions';
import { ReportDataByReportType } from './types';
import * as actions from './actions';

type DownloadReportsState = {
  reportDataByReportType: Partial<ReportDataByReportType>;
};

export const downloadReportsInitialState: DownloadReportsState = {
  reportDataByReportType: {}
};

type ReportsDownloadAction =
  | ActionType<typeof actions.getReportData.success>
  | ActionType<typeof actions.getReportData.failure>;

const downloadReportsReducer = createReducer<
  DownloadReportsState,
  ReportsDownloadAction
>(downloadReportsInitialState)
  .handleAction(actions.getReportData.success, (state, { payload }) => ({
    ...state,
    reportDataByReportType: payload
  }))
  .handleAction(actions.getReportData.failure, state => ({
    ...state,
    reportDataByReportType: {}
  }));

export default downloadReportsReducer;
