import { v4 as uuid } from 'uuid';
import { firstLetterToLowerCase } from 'src/utils/capitalizeFirstLetter';
import { Entry, Sorter } from '../types';
import { ACTIONS_MAP, FILTER_ALL_VALUE } from '../const';

export function getSorter(sorter: Sorter | null) {
  if (!sorter) return undefined;

  const { order, value } = sorter;

  return {
    _script: {
      type: 'string',
      script: {
        lang: 'painless',
        source: `params._source.${value}`
      },
      order
    }
  };
}

export function getFilter(filter: string) {
  if (filter === FILTER_ALL_VALUE) return [];

  return [
    {
      term: {
        eventType: filter.toLowerCase()
      }
    }
  ];
}

export function getSearch(search: string) {
  if (!search) return [];

  return [
    {
      wildcard: {
        'entityFrameworkEvent.entries.name': `*${search}*`
      }
    }
  ];
}

export const getDiff = (entries: Entry[]) =>
  entries.map(({ action, name, changes, columnValues: newValues }) => {
    const oldValues = changes?.reduce(
      (a, { columnName, originalValue }) => ({
        ...a,
        [firstLetterToLowerCase(columnName)]: originalValue
      }),
      newValues
    );

    return {
      id: uuid(),
      newValues,
      name,
      action: ACTIONS_MAP[action],
      oldValues: oldValues || newValues
    };
  });
