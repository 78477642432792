import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { LatLng } from 'leaflet';
import { SHAPES } from 'src/constants/map';

const matchSpaceParams = createMatchSelector(
  '/avl/geozones/dangerous-zones/:id/details'
);

export const selectIsLoading = (state: RootState) => {
  const loading = get(
    state,
    `loading.@DANGEROUS_ZONE_DETAILS/GET_DANGEROUS_ZONE`
  );

  return isBoolean(loading) ? loading : true;
};

export const selectDangerousZoneName = (state: RootState): string => {
  return get(state, `ui.avl.dangerousZones.details.name`);
};

export const selectMapData = (state: RootState) => {
  const dangerousZoneDetails = get(state, `ui.avl.dangerousZones.details`);
  const { lat, lng, points, address } = dangerousZoneDetails;

  return {
    shape: {
      name: points ? SHAPES.POLYGON : SHAPES.CIRCLE,
      coordinates: { lat, lng } as LatLng,
      points
    },
    address
  };
};

export const selectDangerousZoneRows = (state: RootState) => {
  const dangerousZone = get(state, `ui.avl.dangerousZones.details`);
  return [
    {
      label: 'Назва',
      value: dangerousZone.name
    },
    {
      label: 'Категорія',
      value: dangerousZone.category.name
    },
    {
      label: 'Код',
      value: dangerousZone.code
    },
    {
      label: 'Примітка',
      value: dangerousZone.notes
    }
  ];
};

export const selectDangerousZoneId = createSelector(
  matchSpaceParams,
  (match: any) => {
    return match ? match.params.id : '';
  }
);
