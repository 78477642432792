import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { TARIFFS } from 'src/config/clearingUrls';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';

export function* createTariff({
  payload
}: ReturnType<typeof actions.createTariff.request>): SagaIterator {
  yield call(apiClient.post, TARIFFS, payload, {
    retryAction: actions.createTariff.request(payload)
  });
  yield put(navigateTo({ url: '/clearing/tariffs' }));
  yield put(
    enqueueSnackbar({
      key: 'tariff_create',
      message: 'Тариф створено',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.createTariff.success());
}

export default function* TariffCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createTariff.request),
    safe(createTariff, actions.createTariff.failure)
  );
}
