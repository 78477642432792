import { createReducer, ActionType } from 'typesafe-actions';
import { RoutePassportStatus } from 'src/views/avl/Routes/const';
import * as actions from './actions';

type RoutesPassportLayoutActions = ActionType<typeof actions>;

export const initialState: string = RoutePassportStatus.Draft;

const routesPassportLayoutReducers = createReducer<
  string,
  RoutesPassportLayoutActions
>(initialState).handleAction(
  actions.changePassportStatus,
  (state, { payload }) => payload
);

export default routesPassportLayoutReducers;
