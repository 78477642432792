import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { StatusCodes } from 'http-status-codes';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import * as selectors from './selectors';
import { getDataListSaga, getDeleteRowSaga } from '../../store/sagas';

export const getDataList = getDataListSaga(
  actions.getDataRequest,
  actions.getDataSuccess
);

export const deleteRow = getDeleteRowSaga(
  actions.deleteRowRequest,
  actions.deleteRowSuccess,
  actions.getDataRequest
);

export function* deleteSelectedRows({
  config
}: ReturnType<typeof actions.deleteSelectedRowsRequest>): SagaIterator {
  const { reducerPath } = config;
  const selectSelection = yield call(selectors.selectSelection, reducerPath);

  const { selectAll, included, excluded } = yield select(selectSelection);

  const data = {
    strategy: selectAll ? 'AllExcept' : 'OnlySpecified',
    ids: selectAll ? excluded : included
  };

  const { status } = yield call(apiClient.delete, `${config.apiUrl}/bulk`, {
    data,
    retryAction: actions.deleteSelectedRowsRequest(config)
  });

  yield put(
    enqueueSnackbar({
      key: 'delete_selected_rows_success',
      message:
        status === StatusCodes.PARTIAL_CONTENT
          ? 'Деякі записи не були видалені через встановлені обмеження'
          : config.snackbarMessages.deleteRowSuccess || 'Успішно видалено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.deleteSelectedRowsSuccess(config));

  if (!config.enableSetRowsIsLocalProperty) {
    yield put(actions.getDataRequest(config));
  } else {
    yield put(actions.setRowsIsLocalProperty(config, { ...data }));
  }

  yield put(actions.setSelectAll(config, false));
}

export default function* TableViewSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getDataRequest),
      safe(getDataList, actions.getDataFailure)
    ),
    takeEvery(
      getType(actions.deleteRowRequest),
      safe(deleteRow, actions.deleteRowFailure)
    ),
    takeEvery(
      getType(actions.deleteSelectedRowsRequest),
      safe(deleteSelectedRows, actions.deleteSelectedRowsFailure)
    )
  ]);
}
