import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { createSelector } from 'reselect';
import { EDIT_KPI_PARAMETERS_ROUTE_PATH } from 'src/routes/clearingRoutes';
import { RootState } from 'typesafe-actions';

const matchSpaceParams = createMatchSelector<RouterRootState, { id: string }>(
  EDIT_KPI_PARAMETERS_ROUTE_PATH
);

export const selectKPIParametersGroupId = createSelector(
  matchSpaceParams,
  match => match?.params.id
);

export const selectKPIParametersGroup = (state: RootState) =>
  state.ui.clearing.kpiParameters.edit.kpiParametersGroup;
