import { utcToLocal } from 'src/utils/date';
import { CoefficientsGroup, RouteCoefficient, TimeCoefficient } from './types';

export const mapRouteCoefficientToEntity = (
  routeCoefficient: RouteCoefficient
) => ({
  ...routeCoefficient,
  effectiveDate: utcToLocal(routeCoefficient.effectiveDate, 'YYYY-MM-DD')
});

export const mapTimeCoefficientToEntity = ({
  endDate,
  startDate,
  ...rest
}: TimeCoefficient) => ({
  ...rest,
  startDate: utcToLocal(startDate, 'YYYY-MM-DD'),
  endDate: utcToLocal(endDate, 'YYYY-MM-DD')
});

export const mapCoefficientsGroupToEntity = ({
  route,
  routeCoefficients,
  timeCoefficients,
  ...rest
}: CoefficientsGroup) => ({
  ...rest,
  routePassportId: route?.id,
  routeCoefficients: routeCoefficients.map(mapRouteCoefficientToEntity),
  timeCoefficients: timeCoefficients.map(mapTimeCoefficientToEntity)
});
