import { lazy } from 'react';
import { Redirect } from 'react-router';
import { generateActionID } from 'src/components/Guards';
import COEFFICIENTS_ADJUSTMENTS_ROUTES from './coefficientsAdjustmentsRoutes';

export const EDIT_KPI_PARAMETERS_ROUTE_PATH = '/clearing/kpiParameters/:id';
export const INSPECTION_DOCUMENTS_URL = '/clearing/inspection-documents/';
export const INSPECTION_DOCUMENTS_DETAILS_URL = `${INSPECTION_DOCUMENTS_URL}:id/details`;
export const INSPECTION_DOCUMENTS_CREATE_URL = `${INSPECTION_DOCUMENTS_URL}create`;
export const INSPECTION_DOCUMENTS_EDIT_URL = `${INSPECTION_DOCUMENTS_URL}:id`;

const clearingRoutes = [
  {
    exact: true,
    path: '/clearing',
    component: () => <Redirect to="/clearing/kpis" />
  },
  {
    exact: true,
    path: ['/clearing/kpis'],
    component: lazy(() => import('src/views/clearing/kpis/KpisListView')),
    action: generateActionID({ module: 'clearing', sub: 'kpi', action: 'read' })
  },
  {
    exact: true,
    path: ['/clearing/kpis/create'],
    component: lazy(() => import('src/views/clearing/kpis/KpiCreateView')),
    action: generateActionID({
      module: 'clearing',
      sub: 'kpi',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/clearing/kpis/:id'],
    component: lazy(() => import('src/views/clearing/kpis/KpiEditView')),
    action: generateActionID({
      module: 'clearing',
      sub: 'kpi',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/clearing/penalties'],
    component: lazy(() =>
      import('src/views/clearing/penalties/PenaltiesListView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'penalty',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/clearing/penalties/create'],
    component: lazy(() =>
      import('src/views/clearing/penalties/PenaltyCreateView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'penalty',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/clearing/penalties/:id'],
    component: lazy(() =>
      import('src/views/clearing/penalties/PenaltyEditView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'penalty',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/clearing/kpiParameters'],
    component: lazy(() =>
      import('src/views/clearing/kpiParameters/KpiParametersListView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'kpiGroup',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/clearing/kpiParameters/:id/details'],
    component: lazy(() =>
      import('src/views/clearing/kpiParameters/KpiParametersDetailsView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'kpiGroup',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/clearing/kpiParameters/create'],
    component: lazy(() =>
      import('src/views/clearing/kpiParameters/KpiParametersCreateView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'kpiGroup',
      action: 'create'
    })
  },
  {
    exact: true,
    path: [EDIT_KPI_PARAMETERS_ROUTE_PATH],
    component: lazy(() =>
      import('src/views/clearing/kpiParameters/KpiParametersEditView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'kpiGroup',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/clearing/tariffs'],
    component: lazy(() => import('src/views/clearing/tariffs/TariffsListView')),
    action: generateActionID({
      module: 'clearing',
      sub: 'tariff',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/clearing/tariffs/create'],
    component: lazy(() =>
      import('src/views/clearing/tariffs/TariffCreateView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'tariff',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/clearing/tariffs/:id'],
    component: lazy(() => import('src/views/clearing/tariffs/TariffEditView')),
    action: generateActionID({
      module: 'clearing',
      sub: 'tariff',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/clearing/tariffs/:id/details'],
    component: lazy(() =>
      import('src/views/clearing/tariffs/TariffDetailsView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'tariff',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/clearing/penalty-documents/create'],
    component: lazy(() =>
      import('src/views/clearing/penaltyDocuments/PenaltyDocumentCreateView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'penaltyDocument',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/clearing/penalty-documents/:id'],
    component: lazy(() =>
      import('src/views/clearing/penaltyDocuments/PenaltyDocumentEditView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'penaltyDocument',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/clearing/penalty-documents/:id/details'],
    component: lazy(() =>
      import('src/views/clearing/penaltyDocuments/PenaltyDocumentDetailsView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'penaltyDocument',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/clearing/penalty-documents'],
    component: lazy(() =>
      import('src/views/clearing/penaltyDocuments/PenaltyDocumentListView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'penaltyDocument',
      action: 'read'
    })
  },
  {
    exact: true,
    path: [INSPECTION_DOCUMENTS_URL],
    component: lazy(() =>
      import(
        'src/views/clearing/inspectionDocuments/InspectionDocumentsListView'
      )
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'inspectionDocuments',
      action: 'read'
    })
  },
  {
    exact: true,
    path: [INSPECTION_DOCUMENTS_DETAILS_URL],
    component: lazy(() =>
      import(
        'src/views/clearing/inspectionDocuments/InspectionDocumentDetailsView'
      )
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'inspectionDocuments',
      action: 'read'
    })
  },
  {
    exact: true,
    path: [INSPECTION_DOCUMENTS_CREATE_URL],
    component: lazy(() =>
      import(
        'src/views/clearing/inspectionDocuments/InspectionDocumentsCreateView'
      )
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'inspectionDocuments',
      action: 'create'
    })
  },
  {
    exact: true,
    path: [INSPECTION_DOCUMENTS_EDIT_URL],
    component: lazy(() =>
      import(
        'src/views/clearing/inspectionDocuments/InspectionDocumentsEditView'
      )
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'inspectionDocuments',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/clearing/billing-documents/:id/details'],
    component: lazy(() =>
      import('src/views/clearing/billingDocuments/BillingDocumentsDetailsView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'billingDocuments',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/clearing/billing-documents'],
    component: lazy(() =>
      import('src/views/clearing/billingDocuments/BillingDocumentsListView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'billingDocuments',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/clearing/billing-documents/create'],
    component: lazy(() =>
      import('src/views/clearing/billingDocuments/BillingDocumentCreateView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'billingDocuments',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/clearing/billing-documents/:id'],
    component: lazy(() =>
      import('src/views/clearing/billingDocuments/BillingDocumentsEditView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'billingDocuments',
      action: 'update'
    })
  },
  {
    exact: true,
    path: [
      '/clearing/billing-details/(carrier|routes|vehicles)/:id?/:routeId?'
    ],
    component: lazy(() =>
      import('src/views/clearing/billingDocuments/BillingDetailsListView')
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'billingDetails',
      action: 'read'
    })
  },
  ...COEFFICIENTS_ADJUSTMENTS_ROUTES
];

export default clearingRoutes;
