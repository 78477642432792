import {
  changeStatusColumn,
  formatDate
} from 'src/components/Tables/TableWithPagination/utils';
import { COUNTERAGENTS } from 'src/config/avlUrls';
import {
  EQUIPMENT_GROUP_NAMES,
  INSTALLATIONS,
  INSTALLATIONS_NOTES_AUTOCOMPLETE,
  RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE
} from 'src/config/assetUrls';
import { Row } from 'src/components/Tables/types';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import { STATUS_CONFIG } from 'src/constants';
import TextWithEllipsis from 'src/components/TextWithEllipsis';
import InstallationSwitch from '../InstallationSwitch';
import { InstallationStatusEnum } from '../const';

export const INSTALLATION_TABLE_PATH = 'asset.installation.table';
export const INSTALLATION_TABLE_NAME = 'INSTALLATION';

export const TABLE_CONFIG_BASE = {
  reducerPath: INSTALLATION_TABLE_PATH,
  reducerName: INSTALLATION_TABLE_NAME,
  apiUrl: INSTALLATIONS
};

export const getColumns = (
  isAllowed: boolean,
  onChangeStatus: (row: Row) => Promise<void>
) => [
  {
    label: 'Номер документа',
    value: 'documentNumber',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Дата монтажу',
    value: 'dateInstallation',
    sortable: true,
    width: '10%',
    formatValue: (row: Row) => formatDate(row.dateInstallation)
  },
  {
    label: 'Підрозділ від',
    value: 'counteragentHolderName',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Підрозділ',
    value: 'counteragent',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Група обладнання',
    value: 'equipmentGroup',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Кількість обладнання',
    value: 'installedEquipment',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Хто монтував',
    value: 'responsiblePerson',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Статус документа',
    value: 'status',
    sortable: true,
    width: '10%',
    changeValue: changeStatusColumn,
    formatValue: (row: Row) => STATUS_CONFIG[row.status.toLowerCase()].label
  },
  {
    label: '',
    value: '',
    sortable: false,
    changeValue: (row: Row) =>
      isAllowed ? (
        <InstallationSwitch data={row} onChange={onChangeStatus} />
      ) : null,
    width: '5%'
  },
  {
    label: 'Примітка',
    value: 'notes',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => <TextWithEllipsis text={row.notes} />
  }
];

export const RESPONSIBLE_PERSON_AUTOCOMPLETE =
  'RESPONSIBLE_PERSON_AUTOCOMPLETE';
export const RESPONSIBLE_PERSON_AUTOCOMPLETE_PATH =
  'asset.installation.responsibleEmployeeAutocomplete';

export const RESPONSIBLE_PERSON_AUTOCOMPLETE_CONFIG = {
  reducerName: RESPONSIBLE_PERSON_AUTOCOMPLETE,
  reducerPath: RESPONSIBLE_PERSON_AUTOCOMPLETE_PATH,
  optionsApiUrl: RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE
};

export const INSTALLATION_NOTES_AUTOCOMPLETE_NAME =
  'INSTALLATION_NOTES_AUTOCOMPLETE_NAME';
export const INSTALLATION_NOTES_AUTOCOMPLETE_PATH =
  'asset.installation.notesAutocomplete';

export const INSTALLATION_NOTES_AUTOCOMPLETE_CONFIG = {
  reducerName: INSTALLATION_NOTES_AUTOCOMPLETE_NAME,
  reducerPath: INSTALLATION_NOTES_AUTOCOMPLETE_PATH,
  optionsApiUrl: INSTALLATIONS_NOTES_AUTOCOMPLETE
};

export const getFilters = (counteragentTypeId?: number) => [
  {
    type: FilterType.Date,
    name: 'dateInstallation',
    label: 'Дата',
    datePickerProps: {
      disableFuture: true
    }
  },
  {
    name: 'counteragentHolderId',
    label: 'Підрозділ від',
    optionsURL: counteragentTypeId
      ? `${COUNTERAGENTS}?counteragentTypeId=${counteragentTypeId}`
      : ''
  },
  {
    name: 'counteragentId',
    label: 'Підрозділ',
    optionsURL: COUNTERAGENTS
  },
  {
    name: 'equipmentGroupId',
    label: 'Група обладнання',
    optionsURL: EQUIPMENT_GROUP_NAMES
  },
  {
    name: 'responsiblePersonId',
    label: 'Хто монтував',
    type: FilterType.Autocomplete,
    autocompleteConfig: RESPONSIBLE_PERSON_AUTOCOMPLETE_CONFIG
  },
  {
    name: 'statusId',
    label: 'Статус',
    options: [
      {
        label: 'Зафіксований',
        value: InstallationStatusEnum.Fixed
      },
      {
        label: 'Чернетка',
        value: InstallationStatusEnum.Draft
      }
    ]
  },
  {
    name: 'notes',
    label: 'Примітка',
    value: 'name',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      ...INSTALLATION_NOTES_AUTOCOMPLETE_CONFIG,
      value: 'name'
    }
  }
];
