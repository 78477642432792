import { generateJsonFile } from 'src/utils/jsonFile';
import { uninstallationFieldsJson } from '../const';
import {
  MobileEquipment,
  StationaryEquipment,
  UninstallationPayload
} from '../UninstallationDetailsView/store/types';

export function generateJsonOfUninstallation(
  data: UninstallationPayload
): string {
  const { stationaryEquipment, mobileEquipment, inspectorEquipments } = data;

  const equipments = [
    stationaryEquipment?.map((item: StationaryEquipment) => ({
      ...item,
      equipmentGroup: 'стаціонарне обладнання'
    })),
    mobileEquipment?.map((item: MobileEquipment) => ({
      ...item,
      equipmentGroup: 'мобільне обладнання'
    })),
    inspectorEquipments?.map((item: StationaryEquipment) => ({
      ...item,
      equipmentGroup: 'обладнання для контролерів'
    }))
  ].filter(group => !!group?.length);

  return (
    equipments &&
    generateJsonFile(uninstallationFieldsJson, { ...data, equipments })
  );
}
