import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  RideChangeoverRequest,
  TerminateDepartureRequest,
  RideSpecialModeDetails,
  TrafficStadiumData,
  TrafficStadiumsDataRequest,
  SetGpsLoadingByRouteId,
  GetGpsMessageSuccessByRouteId,
  SetRouteRideModeDetailsByRouteId,
  SetStopUnavailabilityByRouteId,
  CancelStopUnavailabilityByRouteId,
  SetTerminateDepartureByRouteId,
  CancelTerminateDepartureByRouteId,
  UpdateTrafficStadiumDataByRouteIdRequest,
  UpdateTrafficStadiumDataByRouteId,
  RideChangeoverByRouteIdByRouteId,
  SpecialModeRouteDetails,
  SetRouteRideModeByRouteId,
  UpdateRideWithChangeoverByRouteId,
  RemoveDepartureByRouteId,
  UpdateVehicleByRouteId,
  SetRideSpecialModeByRouteId,
  SetRideSpecialModeDetailsByRouteId,
  CancelRideSpecialModeByRouteId,
  CancelRouteRideModeByRouteId
} from '../types';

export const setGpsLoadingByRouteId = createAction(
  '@TRAFFIC_STADIUM/GPS_LOADING_BY_ROUTE_ID',
  (payload: SetGpsLoadingByRouteId) => payload
)();

export const getGpsMessageSuccessByRouteId = createAction(
  '@TRAFFIC_STADIUM/GET_GPS_MESSAGE_SUCCESS_BY_ROUTE_ID',
  (payload: GetGpsMessageSuccessByRouteId) => payload
)();

export const getTrafficStadiumsData = createAsyncAction(
  '@TRAFFIC_STADIUM/GET_TRAFFIC_STADIUMS_DATA_REQUEST',
  '@TRAFFIC_STADIUM/GET_TRAFFIC_STADIUMS_DATA_SUCCESS',
  '@TRAFFIC_STADIUM/GET_TRAFFIC_STADIUMS_DATA_FAILURE'
)<
  TrafficStadiumsDataRequest,
  {
    trafficStadiums: TrafficStadiumData[];
    counteragentId: number | string;
    counteragentTreeCode?: string;
  },
  Error
>();

export const resetTrafficStadiumsData = createAction(
  '@TRAFFIC_STADIUM/RESET_TRAFFIC_STADIUMS_DATA'
)();

export const updateTrafficStadiumDataByRouteId = createAsyncAction(
  '@TRAFFIC_STADIUM/UPDATE_TRAFFIC_STADIUM_DATA_BY_ROUTE_ID',
  '@TRAFFIC_STADIUM/UPDATE_TRAFFIC_STADIUM_DATA_BY_ROUTE_ID_SUCCESS',
  '@TRAFFIC_STADIUM/UPDATE_TRAFFIC_STADIUM_DATA_BY_ROUTE_ID_FAILURE'
)<
  UpdateTrafficStadiumDataByRouteIdRequest,
  UpdateTrafficStadiumDataByRouteId,
  Error
>();

export const updateStopUnavailability = createAsyncAction(
  '@TRAFFIC_STADIUM/UPDATE_STOP_UNAVAILABILITY_REQUEST',
  '@TRAFFIC_STADIUM/UPDATE_STOP_UNAVAILABILITY_SUCCESS',
  '@TRAFFIC_STADIUM/UPDATE_STOP_UNAVAILABILITY_FAILURE'
)<SetStopUnavailabilityByRouteId, undefined, Error>();

export const updateVehicleByRouteId = createAction(
  '@TRAFFIC_STADIUM/UPDATE_VEHICLE_BY_ROUTE_ID',
  (payload: UpdateVehicleByRouteId) => payload
)();

export const setStopUnavailabilityByRouteId = createAction(
  '@TRAFFIC_STADIUM/SET_STOP_UNAVAILABILITY_BY_ROUTE_ID',
  (payload: SetStopUnavailabilityByRouteId) => payload
)();

export const cancelStopUnavailabilityByRouteId = createAction(
  '@TRAFFIC_STADIUM/CANCEL_STOP_UNAVAILABILITY_BY_ROUTE_ID',
  (payload: CancelStopUnavailabilityByRouteId) => payload
)();

export const cancelTerminateDepartureByRouteId = createAction(
  '@TRAFFIC_STADIUM/CANCEL_TERMINATE_DEPARTURE_BY_ROUTE_ID',
  (payload: CancelTerminateDepartureByRouteId) => payload
)();

export const updateRouteRideMode = createAsyncAction(
  '@TRAFFIC_STADIUM/UPDATE_ROUTE_RIDE_MODE_REQUEST',
  '@TRAFFIC_STADIUM/UPDATE_ROUTE_RIDE_MODE_SUCCESS',
  '@TRAFFIC_STADIUM/UPDATE_ROUTE_RIDE_MODE_FAILURE'
)<SpecialModeRouteDetails & { routeId: number }, undefined, Error>();

export const updateRideSpecialMode = createAsyncAction(
  '@TRAFFIC_STADIUM/UPDATE_RIDE_SPECIAL_MODE_REQUEST',
  '@TRAFFIC_STADIUM/UPDATE_RIDE_SPECIAL_MODE_SUCCESS',
  '@TRAFFIC_STADIUM/UPDATE_RIDE_SPECIAL_MODE_FAILURE'
)<RideSpecialModeDetails, undefined, Error>();

export const setRouteRideModeByRouteId = createAction(
  '@TRAFFIC_STADIUM/SET_ROUTE_RIDE_MODE_BY_ROUTE_ID',
  (payload: SetRouteRideModeByRouteId) => payload
)();

export const setRouteRideModeDetailsByRouteId = createAction(
  '@TRAFFIC_STADIUM/SET_ROUTE_RIDE_MODE_DETAILS_BY_ROUTE_ID',
  (payload: SetRouteRideModeDetailsByRouteId) => payload
)();

export const cancelRouteRideModeByRouteId = createAction(
  '@TRAFFIC_STADIUM/CANCEL_ROUTE_RIDE_MODE_BY_ROUTE_ID',
  (payload: CancelRouteRideModeByRouteId) => payload
)();

export const setRideSpecialModeByRouteId = createAction(
  '@TRAFFIC_STADIUM/SET_RIDE_SPECIAL_MODE_BY_ROUTE_ID',
  (payload: SetRideSpecialModeByRouteId) => payload
)();

export const setRideSpecialModeDetailsByRouteId = createAction(
  '@TRAFFIC_STADIUM/SET_RIDE_SPECIAL_MODE_DETAILS_BY_ROUTE_ID',
  (payload: SetRideSpecialModeDetailsByRouteId) => payload
)();

export const cancelRideSpecialModeByRouteId = createAction(
  '@TRAFFIC_STADIUM/CANCEL_RIDE_SPECIAL_MODE_BY_ROUTE_ID',
  (payload: CancelRideSpecialModeByRouteId) => payload
)();

export const setRideChangeoverByRouteId = createAction(
  '@TRAFFIC_STADIUM/SET_RIDE_CHANGEOVER_BY_ROUTE_ID',
  (payload: RideChangeoverByRouteIdByRouteId) => payload
)();

export const cancelRideChangeoverByRouteId = createAction(
  '@TRAFFIC_STADIUM/CANCEL_RIDE_CHANGEOVER_BY_ROUTE_ID',
  (payload: RideChangeoverByRouteIdByRouteId) => payload
)();

export const setTerminateDepartureByRouteId = createAction(
  '@TRAFFIC_STADIUM/SET_TERMINATE_DEPARTURE_BY_ROUTE_ID',
  (payload: SetTerminateDepartureByRouteId) => payload
)();

export const updateRideChangeover = createAsyncAction(
  '@TRAFFIC_STADIUM/UPDATE_RIDE_CHANGEOVER_REQUEST',
  '@TRAFFIC_STADIUM/UPDATE_RIDE_CHANGEOVER_SUCCESS',
  '@TRAFFIC_STADIUM/UPDATE_RIDE_CHANGEOVER_FAILURE'
)<RideChangeoverRequest, undefined, Error>();

export const updateTerminateDeparture = createAsyncAction(
  '@TRAFFIC_STADIUM/UPDATE_TERMINATE_DEPARTURE_REQUEST',
  '@TRAFFIC_STADIUM/UPDATE_TERMINATE_DEPARTURE_SUCCESS',
  '@TRAFFIC_STADIUM/UPDATE_TERMINATE_DEPARTURE_FAILURE'
)<TerminateDepartureRequest, undefined, Error>();

export const removeDepartureByRouteId = createAction(
  '@TRAFFIC_STADIUM/REMOVE_DEPARTURE_BY_ROUTE_ID',
  (payload: RemoveDepartureByRouteId) => payload
)();

export const updateRideWithChangeoverByRouteId = createAction(
  '@TRAFFIC_STADIUM/UPDATE_RIDE_WITH_CHANGEOVER_BY_ROUTE_ID',
  (payload: UpdateRideWithChangeoverByRouteId) => payload
)();
