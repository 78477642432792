import { RootState } from 'typesafe-actions';
import { get, isBoolean, uniqBy } from 'lodash';
import { SelectFilterConfig } from 'src/components/Select/types';
import { createSelector } from 'reselect';
import { selectAutocompleteOptions } from 'src/components/Form/FormAutocomplete/store/selectors';
import {
  Filter,
  FilterConfig,
  FilterConfigOption,
  FilterType,
  OptionsData,
  TableConfig
} from '../types';

export const selectList = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.list`);

export const selectSorters = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.sorters`);

export const selectFilters = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.filters`);

export const selectFiltersMap = (reducerPath: string) =>
  createSelector(selectFilters(reducerPath), (filters: Filter[]) =>
    filters.reduce((accumulator, filter) => {
      accumulator[filter.properties] = filter;

      return accumulator;
    }, {} as Record<string, Filter>)
  );

export const selectSelection = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.selection`);

export const selectIsLoading = (name: string) => (state: RootState) => {
  const loading = get(state, `loading.@TABLE_WITH_PAGINATION/GET_DATA/${name}`);

  return isBoolean(loading) ? loading : true;
};

export const selectFilterOptions = (
  reducerPath: string,
  reducerName: string,
  filterValue?: string,
  selectConfig?: SelectFilterConfig
) => (state: RootState) => {
  const responseField = selectConfig?.responseFieldName || 'name';

  let options = get(
    state,
    `ui.${reducerPath}.appliedFilters["${reducerName}"]`
  );

  if (!options) return [];

  if (selectConfig?.IsUnique) {
    options = uniqBy(options, responseField);
  }

  return options
    .map((option: OptionsData) => ({
      label: option[responseField],
      value: filterValue ? option[filterValue] : option.id
    }))
    .filter((option: OptionsData) => option.label);
};

type FilterOptionsConfig = FilterConfig & {
  autocompleteOptions?: AutocompleteOption[];
  selectOptions?: FilterConfigOption[];
};

const selectState = (state: RootState) => state;

export const selectTableOptions = (tableConfig?: TableConfig) =>
  createSelector(selectState, state => {
    return tableConfig?.filters?.map(config => {
      const optionsConfig: FilterOptionsConfig = { ...config };

      if (
        config.type === FilterType.Autocomplete &&
        config.autocompleteConfig?.reducerPath
      ) {
        optionsConfig.autocompleteOptions = selectAutocompleteOptions(
          config.autocompleteConfig?.reducerPath
        )(state);
      } else if (
        config.type === FilterType.Select ||
        config.type !== FilterType.Date
      ) {
        optionsConfig.selectOptions = selectFilterOptions(
          tableConfig.reducerPath,
          config.selectConfig?.reducerName || config.name,
          config.value,
          config.selectConfig
        )(state);
      }

      return optionsConfig;
    });
  });
