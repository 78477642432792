import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from '../actions/snackbar';

export type SnackbarActions = ActionType<typeof actions>;

const snackbarReducers = createReducer<UINotification[], SnackbarActions>([])
  .handleAction(actions.enqueueSnackbar, (state, { payload }) => [
    ...state,
    payload
  ])
  .handleAction(actions.closeSnackbar, (state, { payload }) =>
    state.map((notification: UINotification) =>
      payload.dismissAll || notification.key === payload.key
        ? { ...notification, dismissed: true }
        : { ...notification }
    )
  )
  .handleAction(actions.removeSnackbar, (state, { payload }) =>
    state.filter(
      (notification: UINotification) => notification.key !== payload.key
    )
  );

export default snackbarReducers;
