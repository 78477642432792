import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { InspectionDocument, InspectionDocumentStatus } from '../../types';

type VehicleDetailsActions = ActionType<typeof actions>;

const initialState: InspectionDocument = {
  id: 0,
  documentDate: '',
  documentName: '',
  counteragentId: 0,
  counteragentName: '',
  vehicleId: 0,
  licensePlate: '',
  status: InspectionDocumentStatus.Completed,
  employeeId: 0,
  employeeName: '',
  kpiParamsId: 0,
  kpiParams: [],
  canDelete: true,
  avgKpiGrades: []
};

const inspectionDocumentDetailsReducer = createReducer<
  InspectionDocument,
  VehicleDetailsActions
>(initialState).handleAction(
  actions.getInspectionDocument.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default inspectionDocumentDetailsReducer;
