import { createReducer, ActionType } from 'typesafe-actions';
import { EquipmentGroupEnum, StatusEnum } from 'src/constants';
import * as actions from './actions';
import { InspectionDetails } from '../../InspectionDetailsView/types';

const initialState: InspectionDetails = {
  id: 0,
  equipments: [],
  status: StatusEnum.Fixed,
  documentNumber: '',
  address: '',
  inspectionDate: '',
  inspectorName: '',
  inspectorFullName: '',
  equipmentGroup: EquipmentGroupEnum.Mobile,
  counteragent: '',
  vehicleType: '',
  licensePlate: '',
  vehicleModel: '',
  notes: ''
};

const inspectionEmailDataReducers = createReducer<
  InspectionDetails,
  ActionType<typeof actions>
>(initialState).handleAction(
  actions.getInspectionPdfDetails.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default inspectionEmailDataReducers;
