import { get } from 'lodash';
import { RootState } from 'typesafe-actions';
import { VehicleCategoryDTO } from '@eticket/avl-api-contracts';

export const selectPassportStatus = (state: RootState) =>
  state.ui.avl.routes.status;

export const selectVehicleCategoriesNames = (
  state: RootState
): { [key: string]: string } => {
  const vehicleCategories = get(
    state,
    `ui.avl.vehiclesModels.vehiclesCategoriesEditableSelect.options.active`
  );

  const categoriesNames: { [key: string]: string } = {};
  vehicleCategories.forEach(({ id, name }: VehicleCategoryDTO) => {
    categoriesNames[id] = name!;
  });
  return categoriesNames;
};
