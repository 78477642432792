import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { apiClient } from 'src/utils/api';
import { REPORTS_PAGE } from 'src/config/assetUrls';
import * as actions from './actions';
import { FilterData } from '../../types';
import { MIN_PAGE_SIZE } from '../../const';
import { parseFilter } from '../utils/utils';

import { AnalyseRequestPayload } from '../../AnalyseBreakdownsView/types';

export const fetchReportData = (
  filters: FilterData | AnalyseRequestPayload,
  pageSize = MIN_PAGE_SIZE
) =>
  apiClient.post(REPORTS_PAGE, {
    filters: parseFilter(filters),
    page: 1,
    pageSize,
    sorters: [
      {
        propertyName: 'date',
        order: 'ASC'
      }
    ]
  });

export function* getReportDataSaga({
  payload
}: ReturnType<typeof actions.getReportData.request>): SagaIterator {
  let { data } = yield call(fetchReportData, payload);

  if (data.rowCount > MIN_PAGE_SIZE) {
    const res = yield call(fetchReportData, payload, data.rowCount);
    data = res.data;
  }

  yield put(actions.getReportData.success(data));
}

export default function* DownloadAssetReportsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getReportData.request),
    safe(getReportDataSaga, actions.getReportData.failure)
  );
}
