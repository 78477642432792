import { ActionType, createReducer } from 'typesafe-actions';
import { DangerousZoneDTO, SystemStatusDTO } from '@eticket/avl-api-contracts';
import * as actions from './actions';

type DangerousZonesEditActions = ActionType<typeof actions>;

const initialState: DangerousZoneDTO = {
  id: 0,
  code: '',
  name: '',
  address: '',
  category: {
    id: 0,
    name: '',
    status: SystemStatusDTO.Disabled
  }
};

const dangerousZonesEditReducers = createReducer<
  DangerousZoneDTO,
  DangerousZonesEditActions
>(initialState).handleAction(
  actions.getDangerousZone.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default dangerousZonesEditReducers;
