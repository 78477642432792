import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { createSelector } from 'reselect';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_SLASH } from 'src/config';

const matchSpaceParams = createMatchSelector<RouterRootState, { id: string }>(
  '/asset/dictionaries/states/:id/details'
);

export const selectStateId = createSelector(matchSpaceParams, match =>
  match ? match.params.id : ''
);

export const selectStateName = (state: RootState): string =>
  state.ui.asset.states.details.name;

export const selectStateRows = (state: RootState) => {
  const stateDetails = get(state, `ui.asset.states.details`);

  return [
    { label: 'Стан', value: stateDetails.name },
    {
      label: 'Опис стану',
      value: stateDetails.description
    },
    {
      label: 'Дата',
      value: utcToLocal(stateDetails.date, DATE_FORMAT_SLASH)
    },
    {
      label: 'Працівник',
      value: stateDetails.employeeName
    },
    {
      label: 'Примітка',
      value: stateDetails.notes
    }
  ];
};

export const selectIsStateLoading = (state: RootState) => {
  const loading = get(state, `loading.@STATES_DETAILS/GET_STATE`);
  return isBoolean(loading) ? loading : true;
};

export const selectStateDisabled = (state: RootState) =>
  !state.ui.asset.states.details.canModify;
