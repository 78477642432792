import { combineReducers } from 'redux';
import selectReducers from 'src/components/Select/store/reducers';
import autocompleteReducers from 'src/components/Form/FormAutocomplete/store/reducer';
import { createNamedWrapperReducer } from 'src/utils/reducers';
import {
  AVL_REPORTS_COUNTERAGENTS_NAME,
  AVL_REPORTS_ROUTES_NAME,
  AVL_REPORTS_DRIVERS_NAME
} from '../ReportsDownloadView/components/ReportsFilters/const';
import downloadReportsReducer from '../ReportsDownloadView/store/reducer';

const avlReportsReducer = combineReducers({
  download: downloadReportsReducer,
  counteragents: createNamedWrapperReducer(
    selectReducers,
    AVL_REPORTS_COUNTERAGENTS_NAME
  ),
  routes: createNamedWrapperReducer(
    autocompleteReducers,
    AVL_REPORTS_ROUTES_NAME
  ),
  drivers: createNamedWrapperReducer(selectReducers, AVL_REPORTS_DRIVERS_NAME)
});

export default avlReportsReducer;
