import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { RootState } from 'typesafe-actions';
import { get, isBoolean } from 'lodash';
import moment from 'moment';
import { convertUtcToLocal, isPastDate, isTodayDate } from 'src/utils/date';
import { Tariff } from '../../types';

const matchTariffParams = createMatchSelector('/clearing/tariffs/:id');

export const selectTariff = (state: RootState) => {
  const { tariffs, ...rest } = get(state, 'ui.clearing.tariffs.edit');
  const sortedTariffs = tariffs?.sort((a: Tariff, b: Tariff) =>
    moment(a.effectiveDateUtc).diff(moment(b.effectiveDateUtc))
  );

  return {
    ...rest,
    tariffs: sortedTariffs.map((tariff: Tariff) => ({
      ...tariff,
      effectiveDateUtc: tariff.effectiveDateUtc
        ? convertUtcToLocal(
            String(tariff.effectiveDateUtc),
            'YYYY-MM-DDTHH:mm:ss'
          )
        : '',
      isPast:
        isPastDate(tariff.effectiveDateUtc) ||
        isTodayDate(tariff.effectiveDateUtc)
    }))
  };
};

export const selectIsTariffLoading = (state: RootState) => {
  const loading = get(state, `loading.@TARIFF_EDIT/GET_TARIFF`);

  return isBoolean(loading) ? loading : true;
};

export const selectTariffId = createSelector(
  matchTariffParams,
  (match: any) => {
    return match?.params.id || '';
  }
);
