import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { countBy } from 'lodash';
import Label from 'src/components/Labels/Label';
import { selectBarcodes } from '../store/selectors';

const useStyles = makeStyles({
  label: {
    textTransform: 'none'
  }
});

interface Props {
  barcode: string;
  reducerPath: string;
}

const BarcodeLabel: FC<Props> = ({ barcode, reducerPath }) => {
  const classes = useStyles();
  const barcodes = useSelector(selectBarcodes(reducerPath));

  const isMultipleBarcode = useMemo(() => {
    const barcodeCounts = countBy(barcodes);

    return barcodeCounts[barcode] > 1;
  }, [barcode, barcodes]);

  return isMultipleBarcode ? (
    <Label color="warning" className={classes.label}>
      {barcode}
    </Label>
  ) : (
    <>{barcode}</>
  );
};

export default BarcodeLabel;
