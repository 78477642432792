import { SagaIterator } from 'redux-saga';
import { takeEvery, call, select, put } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { StatusEnum } from 'src/constants';
import { STATE_CHANGES } from 'src/config/assetUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import { setFormValues } from '../../StateChangeStep/store/actions';
import { selectNormalizedList } from '../../StateChangeEquipmentsStep/store/selectors';
import { selectFormCreateData } from '../../StateChangeStep/store/selectors';
import { validateEquipmentRows } from '../../utils';

export function* createDraftStateChange({
  payload
}: ReturnType<typeof actions.createDraftStateChange.request>): SagaIterator {
  const { formConfig, tablePath } = payload;
  const formData = yield select(selectFormCreateData(formConfig.reducerPath));
  const equipment = yield select(selectNormalizedList(tablePath));

  const error = validateEquipmentRows(equipment);

  if (error) {
    yield put(
      enqueueSnackbar({
        key: 'state_change_create',
        message: error,
        options: {
          variant: 'error'
        }
      })
    );

    yield put(actions.createDraftStateChange.failure(new Error(error)));

    return;
  }

  const { data } = yield call(
    apiClient.post,
    STATE_CHANGES,
    {
      ...formData,
      status: StatusEnum.Draft,
      equipment
    },
    {
      retryAction: actions.createDraftStateChange.request(payload)
    }
  );
  yield put(
    setFormValues(formConfig, {
      ...formData,
      id: data
    })
  );
  yield put(actions.createDraftStateChange.success(data));
}

export default function* StateChangeCreateView(): SagaIterator {
  yield takeEvery(
    getType(actions.createDraftStateChange.request),
    safe(createDraftStateChange, actions.createDraftStateChange.failure)
  );
}
