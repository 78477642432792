import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';

const matchSpaceParams = createMatchSelector('/avl/vehicles-models/:id');

export const selectVehiclesModel = (state: RootState) =>
  get(state, `ui.avl.vehiclesModels.edit`);

export const selectIsLoadingVehiclesModel = (state: RootState) => {
  const loading = get(state, `loading.@VEHICLES_MODEL_EDIT/GET_VEHICLES_MODEL`);

  return isBoolean(loading) ? loading : true;
};

export const selectVehiclesModelId = createSelector(
  matchSpaceParams,
  (match: any) => match?.params.id || ''
);
