import { createSelector } from 'reselect';
import { createMatchSelector, RouterRootState } from 'connected-react-router';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/asset/uninstallation/:id'
);

export const selectUninstallationId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);
