import { KpiParametersState } from './const';

export const checkKpiStateActive = (state: string) => {
  return state !== KpiParametersState.Current;
};

export const checkKpiStateDisabled = (state: string) => {
  return state === KpiParametersState.Current;
};

export const checkKpiStateExpiredCurrent = (state: string) => {
  return (
    state === KpiParametersState.Current || state === KpiParametersState.Expired
  );
};
