import { get, isNaN } from 'lodash';
import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { Row } from 'src/components/Tables/types';

const matchCreateSpaceParams = createMatchSelector<
  RouterRootState,
  { id: string }
>('/asset/breakdowns/create/:id');

const matchEditSpaceParams = createMatchSelector<
  RouterRootState,
  { id: string }
>('/asset/breakdowns/:id');

export const selectFormBreakdownsValues = (reducerPath: string) => (
  state: RootState
) => get(state, `ui.${reducerPath}`);

export const selectBreakdownId = createSelector(
  matchCreateSpaceParams,
  matchEditSpaceParams,
  (createMatch, editMatch) => {
    if (createMatch) {
      return createMatch.params.id;
    }

    if (editMatch && !isNaN(Number(editMatch.params.id))) {
      return editMatch.params.id;
    }

    return '';
  }
);

export const selectNormalizeData = (reducerPath: string) => (
  state: RootState
) => {
  const data = get(state, `ui.${reducerPath}`, {});
  const breakdownsTable = get(
    state,
    `ui.asset.breakdowns.create.breakdownsTable.list`,
    {}
  );

  return {
    registrationDate: data.installDate,
    reportedBy: data.installingEmployee,
    counteragentId: data.counteragent?.id,
    notes: data.notes,
    registeredByEmployeeId: data.acceptingEmployee?.id,
    equipmentGroupId: data.equipmentType,
    vehicleId: data.vehicleNumbers?.id || null,
    breakdownItems: breakdownsTable.map((item: Row) => ({
      id: item.installationVehicleId,
      breakdownTypeId: item.breakdownTypeId,
      assetId: item.assetId,
      breakdownTypeName: item.name
    }))
  };
};

export const selectVehicleNumbersTitle = (reducerPath: string) => (
  state: RootState
) => {
  const { vehicleNumbers, equipmentType } = get(state, `ui.${reducerPath}`, {});

  return { vehicleNumbers, equipmentType };
};
export const selectBrakdownItems = (state: RootState) =>
  state.ui.asset.breakdowns.edit.data.breakdownItems;
