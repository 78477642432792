import { object, string, number } from 'yup';
import { AVL_ERROR_MESSAGES, ERROR_MESSAGES } from 'src/constants/validation';
import {
  COUNTERAGENTS,
  COUNTERAGENTS_AUTOCOMPLETE,
  VEHICLES_MODELS
} from 'src/config/avlUrls';
import {
  MAIN_MENU,
  NAV_BAR_ITEMS,
  EmployeeProfessionEnum
} from 'src/constants';
import { generateActionCrud } from 'src/components/Guards';
import { maxLength } from 'src/utils/validation';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.avl,
    url: '/avl'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.vehicles,
    url: '/avl/vehicles'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'avl',
    sub: 'vehicles'
  },
  ['download']
);

export const vehiclesFormValidationSchema = object().shape({
  licensePlate: string()
    .max(...maxLength(10))
    .matches(
      /^[A-Z0-9]{1,10}?$/gm,
      'Значення повинно містити лише цифри та літери латинського алфавіту без пробілів'
    )
    .required(ERROR_MESSAGES.REQUIRED),
  vehicleModelId: number().required(ERROR_MESSAGES.REQUIRED),
  counteragent: object()
    .shape({
      id: number(),
      name: string()
    })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  status: string().required(ERROR_MESSAGES.REQUIRED)
});

export const ENABLED = 'Enabled';
export const DISABLED = 'Disabled';

export const VEHICLES_FORM_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  uniqueError: ERROR_MESSAGES.UNIQUE,
  conflict:
    'Транспортний засіб має встановлене обладнання. Щоб змінити перевізника демонтуйте обладнання'
};

/* CONFIG FOR COUNTERAGENTS AUTOCOMPLETE */
export const VEHICLES_COUNTERAGENTS_AUTOCOMPLETE_NAME =
  'VEHICLES_COUNTERAGENTS_AUTOCOMPLETE';
export const VEHICLES_COUNTERAGENTS_AUTOCOMPLETE_PATH =
  'avl.vehicles.create.counterAgentsAutocomplete';
export const VEHICLES_COUNTERAGENTS_AUTOCOMPLETE_CONFIG = {
  optionsApiUrl: COUNTERAGENTS_AUTOCOMPLETE,
  reducerName: VEHICLES_COUNTERAGENTS_AUTOCOMPLETE_NAME,
  reducerPath: VEHICLES_COUNTERAGENTS_AUTOCOMPLETE_PATH
};

/* CONFIG FOR VEHICLE MODELS SELECT */
export const VEHICLES_MODELS_SELECT_NAME = 'VEHICLES_MODELS_SELECT';
export const VEHICLES_MODELS_SELECT_PATH =
  'avl.vehicles.create.vehicleModelsSelect';
export const VEHICLES_MODELS_SELECT_CONFIG = {
  reducerPath: VEHICLES_MODELS_SELECT_PATH,
  reducerName: VEHICLES_MODELS_SELECT_NAME,
  optionsApiUrl: VEHICLES_MODELS
};

/* CONFIG FOR DRIVERS AUTOCOMPLETE */
export const VEHICLES_DRIVERS_AUTOCOMPLETE_NAME =
  'VEHICLES_DRIVERS_AUTOCOMPLETE';
export const VEHICLES_DRIVERS_AUTOCOMPLETE_PATH =
  'avl.vehicles.create.vehicleDriversAutocomplete';

export const employeesConfig = {
  optionsApiUrl: '',
  reducerName: VEHICLES_DRIVERS_AUTOCOMPLETE_NAME,
  reducerPath: VEHICLES_DRIVERS_AUTOCOMPLETE_PATH
};

export const getEmployeesConfig = (id: number | string) => ({
  ...employeesConfig,
  optionsApiUrl: id
    ? `${COUNTERAGENTS}/${id}/employees-autocomplete?professionId=${EmployeeProfessionEnum.Driver}`
    : ''
});
