import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { BREAKDOWNS_TYPES } from 'src/config/assetUrls';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { safe } from 'src/utils/sagas';
import * as selectors from './selectors';
import * as actions from './actions';

export function* getBreakdownsTypes(): SagaIterator {
  const id = yield select(selectors.selectBreakdownsTypesId);

  const { data } = yield call(apiClient.get, `${BREAKDOWNS_TYPES}/${id}`, {
    retryAction: actions.getBreakdownsTypes.request()
  });

  yield put(actions.getBreakdownsTypes.success(data));
}

export function* updateBreakdownsTypes({
  payload
}: ReturnType<typeof actions.updateBreakdownsTypes.request>): SagaIterator {
  const id = yield select(selectors.selectBreakdownsTypesId);

  yield call(apiClient.put, `${BREAKDOWNS_TYPES}/${id}`, payload);
  yield put(navigateTo({ url: '/asset/dictionaries/breakdownsTypes' }));
  yield put(
    enqueueSnackbar({
      key: 'breakdown_types_edit',
      message: 'Несправність відредаговано',
      options: { variant: 'success' }
    })
  );
  yield put(actions.updateBreakdownsTypes.success());
}

export default function* breakdownsTypesEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getBreakdownsTypes.request),
      safe(getBreakdownsTypes, actions.getBreakdownsTypes.failure)
    ),
    takeEvery(
      getType(actions.updateBreakdownsTypes.request),
      safe(updateBreakdownsTypes, actions.updateBreakdownsTypes.failure)
    )
  ]);
}
