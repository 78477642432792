import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';

type CoefficientAdjustmentsDetailsActions = ActionType<typeof actions>;

const initialState = {
  id: 0,
  name: '',
  timeRouteCoefficients: [],
  currentRouteCoefficients: [],
  futureRouteCoefficients: [],
  pastRouteCoefficients: []
};

const coefficientDetailsReducers = createReducer<
  any,
  CoefficientAdjustmentsDetailsActions
>(initialState).handleAction(
  actions.getCoefficientAdjustments.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default coefficientDetailsReducers;
