import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INSTALLATIONS } from 'src/config/assetUrls';
import * as actions from './actions';
import { selectEquipments } from '../../InstallationCreateView/EquipmentStepView/store/selectors';
import {
  selectNormalizeData,
  selectInstallationId
} from '../../InstallationCreateView/InstallationStepView/store/selectors';

export function* updateInstallation({
  config,
  resolve
}: ReturnType<typeof actions.updateInstallationRequest>): SagaIterator {
  const id = yield select(selectInstallationId);
  const { installationDate } = yield select(
    selectNormalizeData(config.dataReducerPath)
  );
  const equipment = yield select(selectEquipments(config.tableReducerPath));

  yield call(
    apiClient.put,
    `${INSTALLATIONS}/${id}`,
    { installationDate, equipment },
    { retryAction: actions.updateInstallationRequest(config, resolve) }
  );

  yield call(resolve, id);
  yield put(actions.updateInstallationSuccess(config));
}

export default function* InstallationEditSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.updateInstallationRequest),
    safe(updateInstallation, actions.updateInstallationFailure)
  );
}
