import { RootState } from 'typesafe-actions';
import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { get, isBoolean } from 'lodash';
import { SHAPES } from 'src/constants/map';
import { LatLng } from 'leaflet';
import { CounteragentsValues } from '../../types';

const matchSpaceParams = createMatchSelector('/avl/geozones/counteragents/:id');

export const selectCounteragentId = createSelector(
  matchSpaceParams,
  (match: any) => (match && match.params.id !== 'create' ? match.params.id : '')
);

export const selectCounteragent = (state: RootState) => {
  const { lat, lng, ...rest } = get(state, `ui.avl.counteragents.edit`);

  return {
    ...rest,
    coordinates: lat && lng ? `${lat}, ${lng}` : undefined
  } as CounteragentsValues;
};

export const selectIsLoading = (state: RootState) => {
  const loading = get(state, `loading.@COUNTERAGENTS_EDIT/GET_COUNTERAGENT`);

  return isBoolean(loading) ? loading : true;
};

export const selectMapData = (state: RootState) => {
  const { lat, lng, points } = state.ui.avl.counteragents.edit;

  return lat && lng
    ? {
        name: points ? SHAPES.POLYGON : SHAPES.CIRCLE,
        coordinates: { lat, lng } as LatLng,
        points
      }
    : undefined;
};
