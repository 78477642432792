import { number, object, string } from 'yup';
import { ASSET_ERROR_MESSAGES, ERROR_MESSAGES } from 'src/constants/validation';
import { EQUIPMENT_GROUP_NAMES } from 'src/config/assetUrls';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { maxLength } from 'src/utils/validation';
import { generateActionCrud } from 'src/components/Guards';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.equipment,
    url: '/asset/dictionaries/equipment'
  }
];

export const crudActions = generateActionCrud({
  module: 'assets',
  sub: 'equipment'
});

export const equipmentCreateValidationSchema = object().shape({
  name: string()
    .max(...maxLength(100))
    .required(ERROR_MESSAGES.REQUIRED),
  equipmentGroupId: number().required(ERROR_MESSAGES.REQUIRED),
  productType: string()
    .max(...maxLength(100))
    .required(ERROR_MESSAGES.REQUIRED),
  manufacturer: string()
    .max(...maxLength(100))
    .required(ERROR_MESSAGES.REQUIRED),
  inspectionDays: number()
    .integer(ERROR_MESSAGES.TYPE_ERROR)
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .positive(ERROR_MESSAGES.AMOUNT_GREATER_ERROR)
    .max(9999, 'Значення не повинно містити більше 4 цифр')
    .nullable(),
  hasImei: string().required(ERROR_MESSAGES.REQUIRED)
});

export const HAS_IMEI_STRING_VALUE = '1';
export const NO_IMEI_STRING_VALUE = '0';

export const imeiDataOptions = [
  { option: 'Є', value: HAS_IMEI_STRING_VALUE },
  { option: 'Немає', value: NO_IMEI_STRING_VALUE }
];

export const groupNameTypes = [
  {
    typeId: 2,
    name: 'Група 1'
  },
  {
    typeId: 1,
    name: 'Група 2'
  }
];

/* CONFIG FOR EQUIPMENT GROUP SELECT */
export const EQUIPMENT_GROUP_SELECT_NAME = ' EQUIPMENT_GROUP_SELECT';
export const EQUIPMENT_GROUP_SELECT_PATH =
  'asset.equipment.create.equipmentGroupSelect';
export const EQUIPMENT_GROUP_SELECT_CONFIG = {
  reducerPath: EQUIPMENT_GROUP_SELECT_PATH,
  reducerName: EQUIPMENT_GROUP_SELECT_NAME,
  optionsApiUrl: EQUIPMENT_GROUP_NAMES
};

export const EQUIPMENT_ERRORS = {
  ...ASSET_ERROR_MESSAGES,
  uniqueError: ERROR_MESSAGES.UNIQUE
};
