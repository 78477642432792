import moment from 'moment';
import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { RouteSchedules, SchedulesActionsConfig, DisableRules } from '../types';

type SchedulesActions = ActionType<typeof actions>;

interface State {
  list: RouteSchedules[];
  lastUpdated?: number;
  config?: SchedulesActionsConfig;
  disableRules: DisableRules;
}

const initialState: State = {
  list: [],
  lastUpdated: undefined,
  config: undefined,
  disableRules: {
    actualFrom: '',
    actualTo: '',
    calendarExceptions: [],
    calendarSetting: []
  }
};

const schedulesReducers = createReducer<State, SchedulesActions>(initialState)
  .handleAction(actions.getSchedules.success, (state, { payload }) => ({
    ...state,
    lastUpdated: moment().unix(),
    list: payload
  }))
  .handleAction(actions.getSchedules.request, (state, { payload }) => ({
    ...state,
    config: payload
  }))
  .handleAction(
    actions.getSchedulesAvailableDates.success,
    (state, { payload }) => ({
      ...state,
      disableRules: payload
    })
  );

export default schedulesReducers;
