import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { COEFFICIENTS_ADJUSTMENTS_URL } from 'src/routes/coefficientsAdjustmentsRoutes';
import { apiClient } from 'src/utils/api';
import {
  COEFFICIENTS_ADJUSTMENTS,
  EDIT_COEFFICIENTS_ADJUSTMENTS
} from 'src/config/clearingUrls';
import * as actions from './actions';
import * as selectors from './selectors';
import {
  CoefficientsGroup,
  RouteCoefficient,
  TimeCoefficient
} from '../../types';
import { mapCoefficientsGroupToEntity } from '../../helpers';

export const updateCoefficientsGroup = (
  id: string,
  coefficientsGroup: CoefficientsGroup
) => {
  const entity = mapCoefficientsGroupToEntity(coefficientsGroup);
  return apiClient.put(`${COEFFICIENTS_ADJUSTMENTS}/${id}`, entity, {
    retryAction: actions.updateCoefficientsGroup.request(coefficientsGroup)
  });
};

export const snackbarOptions: UINotification = {
  key: 'coefficients_group_edit',
  message: 'Коефіцієнти відредаговано',
  options: {
    variant: 'success'
  }
};

export function* updateCoefficientsGroupSaga({
  payload
}: ReturnType<typeof actions.updateCoefficientsGroup.request>): SagaIterator {
  const id = yield select(selectors.selectCoefficientsGroupId);

  yield call(updateCoefficientsGroup, id, payload);
  yield put(navigateTo({ url: COEFFICIENTS_ADJUSTMENTS_URL }));
  yield put(enqueueSnackbar(snackbarOptions));
  yield put(actions.updateCoefficientsGroup.success());
}

export type GetCoefficientsGroupResponse = {
  id: number;
  routePassportName: string;
  timeCoefficients: TimeCoefficient[];
  routeCoefficients: RouteCoefficient[];
};

export const toCoefficientsGroup = ({
  id,
  routePassportName,
  timeCoefficients,
  routeCoefficients
}: GetCoefficientsGroupResponse): CoefficientsGroup => ({
  route: {
    id,
    name: routePassportName
  },
  routeCoefficients,
  timeCoefficients
});

export const fetchCoefficientsGroupConfig = {
  retryAction: actions.getCoefficientsGroup.request()
};

export const fetchCoefficientsGroup = (
  id: number
): Promise<CoefficientsGroup> =>
  apiClient
    .get<GetCoefficientsGroupResponse>(
      EDIT_COEFFICIENTS_ADJUSTMENTS(id),
      fetchCoefficientsGroupConfig
    )
    .then(res => toCoefficientsGroup(res.data));

export function* getCoefficientsGroup(): SagaIterator {
  const id = yield select(selectors.selectCoefficientsGroupId);
  const data = yield call(fetchCoefficientsGroup, id);

  yield put(actions.getCoefficientsGroup.success(data));
}

export default function* CoefficientsAdjustmentsEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.updateCoefficientsGroup.request),
      safe(updateCoefficientsGroupSaga, actions.updateCoefficientsGroup.failure)
    ),
    takeEvery(
      getType(actions.getCoefficientsGroup.request),
      safe(getCoefficientsGroup, actions.getCoefficientsGroup.failure)
    )
  ]);
}
