export const columns = [
  {
    label: 'Номер',
    value: 'id',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '30%'
  },
  {
    label: 'Рекомендована сума (грн)',
    value: 'amount',
    sortable: true,
    width: '20%'
  }
];

export const PENALTIES_TABLE_PATH = 'clearing.penalties.table';
export const PENALTIES_TABLE_NAME = 'PENALTIES';
