import { object, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { Row } from 'src/components/Tables/types';
import BarcodeLabel from './BarcodeLabel';
import { STATES } from '../../../../config/assetUrls';
import StateTypeColumn from '../../states/StatesListView/StateTypeColumn';

// Counteragents
export const STATECHANGE_STATE_SELECT_NAME = 'STATE_CHANGE_STATE_SELECT';
export const STATECHANGE_STATE_SELECT_PATH = 'asset.stateChange.statesSelect';

export const STATECHANGE_STATE_SELECT_CONFIG = {
  reducerPath: STATECHANGE_STATE_SELECT_PATH,
  reducerName: STATECHANGE_STATE_SELECT_NAME,
  optionsApiUrl: STATES
};

export const EQUIPMENT_TABLE_COLUMNS = (reducerPath: string) => [
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '11%',
    changeValue: (row: Row) => (
      <BarcodeLabel barcode={row.barcode} reducerPath={reducerPath} />
    )
  },
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '11%'
  },
  {
    label: 'IMEI',
    value: 'imei',
    sortable: true,
    width: '11%'
  },
  {
    label: 'Запчастини',
    value: 'spareParts',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Стан',
    value: 'previousStateId',
    sortable: true,
    width: '11%',
    changeValue: (row: Row) => (
      <StateTypeColumn id={row.previousStateId} name={row.previousState} />
    )
  },
  {
    label: 'Новий стан',
    value: 'newStateId',
    sortable: true,
    width: '11%',
    changeValue: (row: Row) => (
      <StateTypeColumn id={row.newStateId} name={row.newState} />
    )
  }
];

export const equipmentFormValidationScheme = object().shape({
  barcode: string()
    .matches(/^[a-zA-Z0-9]+$/, ERROR_MESSAGES.TYPE_ERROR)
    .matches(/^\d+$/, ERROR_MESSAGES.TYPE_ERROR)
    .max(13, 'Значення повинно містити не більше 13 цифр')
    .required(ERROR_MESSAGES.REQUIRED),
  state: string().required(ERROR_MESSAGES.REQUIRED)
});
