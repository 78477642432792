import { createAsyncAction } from 'typesafe-actions';

interface UpdateInspectionRequestPayload {
  id: number;
  formPath: string;
  equipmentPath: string;
}

export const updateInspection = createAsyncAction(
  '@INSPECTIONS/UPDATE_INSPECTION_REQUEST',
  '@INSPECTIONS/UPDATE_INSPECTION_SUCCESS',
  '@INSPECTIONS/UPDATE_INSPECTION_FAILURE'
)<UpdateInspectionRequestPayload, undefined, Error>();

export const getInspection = createAsyncAction(
  '@INSPECTIONS/GET_INSPECTION_REQUEST',
  '@INSPECTIONS/GET_INSPECTION_SUCCESS',
  '@INSPECTIONS/GET_INSPECTION_FAILURE'
)<undefined, undefined, Error>();
