import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { apiClient } from 'src/utils/api';
import { USERS_LOGS } from 'src/config/administrationUrls';
import * as actions from './actions';
import { UsersLogs } from '../types';
import { getFilter, getSearch, getSorter } from '../utils';

export const fetchLogs = ({
  search,
  filter,
  sort,
  page,
  pageSize
}: {
  search: [];
  filter: [];
  sort: {};
  page: number;
  pageSize: number;
}) => {
  const should = [...search, ...filter];

  return apiClient.post(USERS_LOGS, {
    from: page * pageSize,
    size: pageSize,
    query: {
      bool: {
        should,
        minimum_should_match: should.length
      }
    },
    sort
  });
};

export function* getUsersLogs({
  payload
}: ReturnType<typeof actions.getUsersLogs.request>): SagaIterator {
  const { page, pageSize, sorter, filters } = payload;

  const sort = yield call<typeof getSorter>(getSorter, sorter);
  const filter = yield call<typeof getFilter>(getFilter, filters.schema);
  const search = yield call<typeof getSearch>(getSearch, filters.search);

  const { data }: { data: UsersLogs } = yield call(fetchLogs, {
    search,
    filter,
    sort,
    pageSize,
    page
  });

  yield put(
    actions.getUsersLogs.success({
      ...payload,
      list: data.hits.hits,
      rowCount: data.hits.total.value
    })
  );
}

export default function* UsersLogsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getUsersLogs.request),
    safe(getUsersLogs, actions.getUsersLogs.failure)
  );
}
