import { generateActionCrud } from 'src/components/Guards';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { getDateDifference } from 'src/utils/date';

export interface UpdateBreakdownPayload {
  id: number;
  status: BreakdownStatusEnum;
}

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.breakdowns,
    url: '/asset/breakdowns'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'breakdowns'
  },
  ['download', 'resendDocument', 'breakdownAllowEditing']
);

export enum BreakdownStatusEnum {
  Draft = 0,
  Fixed = 1
}

export const BREAKDOWN_STEPS = ['Деталі', 'Обладнання'];

export const installationDocumentPdf = [
  {
    label: 'Підрозділ монтажу',
    value: 'counteragentName'
  },
  {
    label: 'Адреса підрозділу монтажу',
    value: 'counteragentAddress'
  },
  {
    label: 'Виконавець',
    value: 'installingEmployeeName'
  },
  {
    label: 'Прийняв роботу',
    value: 'acceptingEmployeeName'
  },
  {
    label: 'Група обладнання',
    value: 'equipmentGroupName'
  },
  {
    label: 'Тип ТЗ',
    value: 'vehicleCategoryName'
  },
  {
    label: 'Номерний знак ТЗ',
    value: 'licensePlate'
  },
  {
    label: 'Модель',
    value: 'vehicleModelName'
  },
  {
    label: 'Працівник підрозділу монтажу - відповідальна особа',
    value: 'employeeName'
  },
  {
    label: 'Примітка',
    value: 'notes'
  }
];

export const installationColumnsPdf = [
  {
    label: '№',
    value: 'index'
  },
  {
    label: 'Штрихкод',
    value: 'barcode'
  },
  {
    label: 'Назва',
    value: 'name'
  },
  {
    label: 'Модель',
    value: 'model'
  },
  {
    label: 'Виробник',
    value: 'manufacturer'
  }
];

export const MAX_DAYS_AFTER_BREAKDOWN_CREATE = 40;
export const TOGGLE_DISABLED_TEXT = `Документ зафіксовано понад ${MAX_DAYS_AFTER_BREAKDOWN_CREATE} днів тому. Редагування заборонене.`;
export const isToggleDateRestricted = (date: string) =>
  getDateDifference(date) >= MAX_DAYS_AFTER_BREAKDOWN_CREATE;

export const breakdownsFieldsJson = [
  'documentNumber',
  'registrationDate',
  'registrationEmployeeName',
  'reportingEmployeeName',
  'counteragentName',
  'counteragentAddress',
  'notes',
  'vehicleModelName',
  'licensePlate',
  'vehicleCategoryName',
  {
    label: 'breakdownItems',
    fields: ['equipmentDetails', 'breakdownTypeName']
  }
];
