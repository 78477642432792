import { SagaIterator } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { BILLING_CALCULATOR } from 'src/config/clearingUrls';
import * as actions from './actions';

export function* downloadCalculator(): SagaIterator {
  const { data } = yield call(apiClient.get, BILLING_CALCULATOR, {
    headers: {
      accept: 'application/octet-stream'
    },
    responseType: 'blob'
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(data);
  link.download = 'Приклад нарахування за послуги перевізників.xlsx';
  link.click();
  link.remove();
}

export default function* BillingDetailsListSaga(): SagaIterator {
  yield takeEvery(getType(actions.downloadCalculator), downloadCalculator);
}
