import { VEHICLES_CATEGORIES, VEHICLES_MODELS } from 'src/config/avlUrls';
import { TABLE_ACTIONS_CONFIG } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import { crudActions } from '../const';

export const columns = [
  {
    label: 'Назва моделі',
    value: 'name',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Тип ТЗ',
    value: 'vehicleCategory.name',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Тарифна група',
    value: 'tariffGroupName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Пасажиромісткість',
    value: 'capacity',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Код',
    value: 'code',
    sortable: true,
    width: '10%'
  }
];

export const VEHICLES_MODELS_TABLE_PATH = 'avl.vehiclesModels.table';
export const VEHICLES_MODELS_TABLE_NAME = 'VEHICLES_MODELS';

export const filters = [
  {
    label: 'Тип',
    name: 'vehicleCategory.id',
    optionsURL: VEHICLES_CATEGORIES
  }
];

export const config = {
  action: crudActions.create,
  reducerName: VEHICLES_MODELS_TABLE_NAME,
  reducerPath: VEHICLES_MODELS_TABLE_PATH,
  apiUrl: VEHICLES_MODELS,
  routeUrl: '/avl/vehicles-models/',
  rowActions: {
    [TABLE_ACTIONS_CONFIG.EDIT]: {
      isActive: true,
      action: crudActions.update
    },
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      isActive: (row: Row) => !row.isUsed,
      action: crudActions.delete,
      disabledTooltip: 'Неможливо видалити оскільки статус активний'
    },
    [TABLE_ACTIONS_CONFIG.DOWNLOAD]: {
      isActive: true,
      action: crudActions.download
    }
  },
  toolbarActions: {
    [TABLE_ACTIONS_CONFIG.DOWNLOAD]: {
      action: crudActions.download
    },
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      action: crudActions.delete
    }
  },
  isRowClickEnabled: true,
  filters
};
