import { MAIN_MENU, NAV_BAR_HEADERS, NAV_BAR_ITEMS } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import RoutesTenderStatusColumn from '../RoutesTenderStatusColumn';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.avl,
    url: '/avl/routes'
  },
  {
    text: NAV_BAR_HEADERS.routes,
    url: '/avl/routes'
  },
  {
    text: NAV_BAR_ITEMS.routes,
    url: '/avl/routes'
  }
];

export const columns = [
  {
    label: 'Дата зміни',
    value: 'modifiedAt',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Номер',
    value: 'number',
    sortable: false,
    width: '10%'
  },
  {
    label: 'Назва маршруту',
    value: 'name',
    sortable: false,
    width: '20%'
  },
  {
    label: 'Початок дії паспорта',
    value: 'effectiveDate',
    sortable: false,
    width: '15%'
  },
  {
    label: 'Перевізник',
    value: 'counteragent',
    sortable: false,
    width: '20%'
  },
  {
    label: 'Тариф, грн/км',
    value: 'tariffAmount',
    sortable: false,
    width: '10%'
  },
  {
    label: 'Тендер',
    value: 'tenderStatus',
    sortable: false,
    width: '10%',
    changeValue: (row: Row) => (
      <RoutesTenderStatusColumn
        tenderStatus={row.tenderStatus}
        tenderStatusId={row.tenderStatusId}
      />
    )
  }
];

export const ROUTE_HISTORY_LIST_TABLE_PATH =
  'avl.routes.settings.historyChangesTable';
export const ROUTE_HISTORY_LIST_TABLE_NAME = 'ROUTE_HISTORY_LIST_TABLE_NAME';
