import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { INSPECTION_DOCUMENTS } from 'src/config/clearingUrls';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { INSPECTION_DOCUMENTS_URL } from 'src/routes/clearingRoutes';
import * as actions from './actions';

export function* createInspectionDocument({
  payload
}: ReturnType<typeof actions.createInspectionDocument.request>): SagaIterator {
  yield call(apiClient.post, INSPECTION_DOCUMENTS, payload, {
    retryAction: actions.createInspectionDocument.request(payload)
  });
  yield put(navigateTo({ url: INSPECTION_DOCUMENTS_URL }));
  yield put(
    enqueueSnackbar({
      key: 'inspection_document_created',
      message: 'Документ інспекції створено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.createInspectionDocument.success());
}

export default function* InspectionDocumentsCreateView(): SagaIterator {
  yield takeEvery(
    getType(actions.createInspectionDocument.request),
    safe(createInspectionDocument, actions.createInspectionDocument.failure)
  );
}
