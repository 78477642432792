import { date, object, number, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { maxLength } from 'src/utils/validation';
import {
  COUNTERAGENTS_AUTOCOMPLETE,
  EMPLOYEES_AUTOCOMPLETE
} from 'src/config/avlUrls';
import { isToday } from 'date-fns';

export const TRANSFERING_COUNTERAGENTS_HOLDER_NAME =
  'TRANSFERING_COUNTERAGENTS_HOLDER_AUTOCOMPLETE';
export const TRANSFERING_COUNTERAGENTS_HOLDER_PATH =
  'asset.transfer.counteragentsHolderAutocomplete';

export const getCounteragentHolderConfig = (counteragentTypeId?: number) => ({
  optionsApiUrl: counteragentTypeId
    ? `${COUNTERAGENTS_AUTOCOMPLETE}?counteragentTypeId=${counteragentTypeId}`
    : '',
  reducerName: TRANSFERING_COUNTERAGENTS_HOLDER_NAME,
  reducerPath: TRANSFERING_COUNTERAGENTS_HOLDER_PATH
});

export const TRANSFERING_COUNTERAGENTS_TRANSFERING_NAME =
  'TRANSFERING_COUNTERAGENTS_TRANSFERING_AUTOCOMPLETE';
export const TRANSFERING_COUNTERAGENTS_TRANSFERING_PATH =
  'asset.transfer.counteragentsTransferAutocomplete';

export const getCounteragentTransferConfig = (counteragentTypeId?: number) => ({
  optionsApiUrl: counteragentTypeId
    ? `${COUNTERAGENTS_AUTOCOMPLETE}?counteragentTypeId=${counteragentTypeId}`
    : '',
  reducerName: TRANSFERING_COUNTERAGENTS_TRANSFERING_NAME,
  reducerPath: TRANSFERING_COUNTERAGENTS_TRANSFERING_PATH
});

export const ACCEPTING_EMPLOYEES_NAME = 'ACCEPTING_EMPLOYEES_AUTOCOMPLETE';
export const ACCEPTING_EMPLOYEES_PATH =
  'asset.transfer.acceptingEmployeesAutocomplete';

export const ACCEPTING_EMPLOYEE_CONFIG = {
  optionsApiUrl: EMPLOYEES_AUTOCOMPLETE,
  reducerName: ACCEPTING_EMPLOYEES_NAME,
  reducerPath: ACCEPTING_EMPLOYEES_PATH
};

const dateTransfer = date()
  .required(ERROR_MESSAGES.REQUIRED)
  .test({ message: ERROR_MESSAGES.TYPE_ERROR, test: isToday })
  .typeError(ERROR_MESSAGES.TYPE_ERROR)
  .nullable();
const notes = string().max(...maxLength(255));

export const transferValidationSchema = object().shape({
  dateTransfer,
  performingEmployee: number()
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  acceptingEmployee: object()
    .required(ERROR_MESSAGES.REQUIRED)
    .shape({ id: number() }),
  counteragentHolder: object()
    .shape({ id: number() })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  notes
});

export const transferDraftValidationSchema = object().shape({
  dateTransfer,
  transferingEmployee: object()
    .shape({
      id: number()
    })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  notes
});
