import { createAsyncAction, createAction } from 'typesafe-actions';
import { SendEmailValues, PredefinedEmails } from '../types';

export const sendEmail = createAsyncAction(
  '@SEND_EMAIL/SEND_EMAIL_REQUEST',
  '@SEND_EMAIL/SEND_EMAIL_SUCCESS',
  '@SEND_EMAIL/SEND_EMAIL_FAILURE'
)<SendEmailValues, undefined, Error>();

export const toggleSendEmailDialog = createAction(
  '@SEND_EMAIL/TOGGLE_SEND_EMAIL',
  (sendEmailDialog: { isOpen: boolean }) => sendEmailDialog
)();

export const getPredefinedEmails = createAsyncAction(
  '@SEND_EMAIL/GET_PREDEFINED_EMAILS_REQUEST',
  '@SEND_EMAIL/GET_PREDEFINED_EMAILS_SUCCESS',
  '@SEND_EMAIL/GET_PREDEFINED_EMAILS_FAILURE'
)<undefined, PredefinedEmails, Error>();
