import { createAsyncAction } from 'typesafe-actions';
import { PenaltyDTO } from '@eticket/clearing-api-contracts';
import { Values } from '../../types';

export const getPenalty = createAsyncAction(
  '@PENALTIES/GET_PENALTY_REQUEST',
  '@PENALTIES/GET_PENALTY_SUCCESS',
  '@PENALTIES/GET_PENALTY_FAILURE'
)<undefined, PenaltyDTO, Error>();

export const updatePenalty = createAsyncAction(
  '@PENALTIES/UPDATE_PENALTY_REQUEST',
  '@PENALTIES/UPDATE_PENALTY_SUCCESS',
  '@PENALTIES/UPDATE_PENALTY_FAILURE'
)<Values, undefined, Error>();
