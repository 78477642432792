export function getFirstPathPart(location: any, defaultPath: any = '') {
  const pathFirstElement = location.pathname.match(/^\/[^/]+/);
  if (pathFirstElement && pathFirstElement.length)
    return pathFirstElement[0].substring(1);
  return defaultPath;
}

export const createBackPath = (pathname: string) => {
  let prevPath = pathname;
  if (prevPath[prevPath.length - 1] === '/') {
    prevPath = prevPath.slice(0, prevPath.length - 1);
  }
  if (!isNaN(Number(prevPath.slice(prevPath.lastIndexOf('/') + 1)))) {
    prevPath = prevPath.slice(0, prevPath.lastIndexOf('/'));
  }
  return prevPath.slice(0, prevPath.lastIndexOf('/'));
};
