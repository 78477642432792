import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { ROUTE_TIMETABLE } from 'src/config/avlUrls';
import { selectRouteParams } from 'src/views/avl/Routes/RoutesPassportView/store/selectors';
import * as actions from './actions';

export function* getDepartureSaga(): SagaIterator {
  const { id: passportId, extraId: departureId } = yield select(
    selectRouteParams
  );

  const { data } = yield call(
    apiClient.get,
    `${ROUTE_TIMETABLE}/${passportId}/route-departures/${departureId}`,
    {
      retryAction: actions.getDeparture.request()
    }
  );
  yield put(actions.getDeparture.success(data));
}

export default function* DepartureDetailsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getDeparture.request),
    safe(getDepartureSaga, actions.getDeparture.failure)
  );
}
