import { Redirect } from 'react-router';
import { lazy } from 'react';
import { generateActionID } from '../components/Guards';

// ROUTE SETTINGS
export const ADMINISTRATION_PATH = 'administration';
export const ROUTE_SETTINGS_PATH = `${ADMINISTRATION_PATH}/route-settings`;

// OTHER SETTINGS
export const OTHER_SETTINGS_PATH = `${ADMINISTRATION_PATH}/other`;

// USERS SETTINGS
export const USERS_PATH = `${ADMINISTRATION_PATH}/users`;

// PAYMENTS SETTINGS
export const PAYMENTS_SETTINGS_PATH = `/${ADMINISTRATION_PATH}/payments`;

// LOGS
export const LOGS_PATH = `/${ADMINISTRATION_PATH}/logs`;

// API KEYS SETTINGS
export const API_KEYS_SETTINGS_PATH = `/${ADMINISTRATION_PATH}/api-keys`;

export const administrationRoutes = [
  {
    exact: true,
    path: ADMINISTRATION_PATH,
    component: () => <Redirect to={ROUTE_SETTINGS_PATH} />
  },
  {
    exact: true,
    path: [`/${ROUTE_SETTINGS_PATH}`],
    component: lazy(() => import('src/views/administration/routeSettings')),
    action: generateActionID({
      module: 'administration',
      sub: 'routeSettings',
      action: 'read'
    })
  },
  {
    exact: true,
    path: [`/${OTHER_SETTINGS_PATH}`],
    component: lazy(() => import('src/views/administration/otherSettings')),
    action: generateActionID({
      module: 'administration',
      sub: 'assetSettings',
      action: 'read'
    })
  },
  {
    exact: true,
    path: [`/${USERS_PATH}`],
    component: lazy(() => import('src/views/administration/usersSettings')),
    action: generateActionID({
      module: 'userManagement',
      sub: 'usersSettings',
      action: 'read'
    })
  },
  {
    exact: true,
    path: [`/${USERS_PATH}/:id`],
    component: lazy(() =>
      import('src/views/administration/usersSettings/components/UserEditView')
    ),
    action: generateActionID({
      module: 'userManagement',
      sub: 'usersSettings',
      action: 'update'
    })
  },
  {
    exact: true,
    path: [`/${USERS_PATH}/:id/details`],
    component: lazy(() =>
      import(
        'src/views/administration/usersSettings/components/UserSettingsDetailsView'
      )
    ),
    action: generateActionID({
      module: 'userManagement',
      sub: 'usersSettings',
      action: 'read'
    })
  },
  {
    exact: true,
    path: [PAYMENTS_SETTINGS_PATH],
    component: lazy(() => import('src/views/administration/paymentsSettings')),
    action: generateActionID({
      module: 'administration',
      sub: 'clearingSettings',
      action: 'read'
    })
  },
  {
    exact: false,
    path: [LOGS_PATH],
    component: lazy(() =>
      import('src/views/administration/usersLogs/UsersLogsListView')
    ),
    action: generateActionID({
      module: 'userManagement',
      sub: 'usersLogs',
      action: 'read'
    })
  },
  {
    exact: false,
    path: [API_KEYS_SETTINGS_PATH],
    component: lazy(() =>
      import('src/views/administration/apiKeysSettings/ApiKeysListView')
    ),
    action: generateActionID({
      module: 'administration',
      sub: 'apiKeysSettings',
      action: 'read'
    })
  }
];
