import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { navigateTo } from 'src/store/actions/app';
import { INBOUNDS } from 'src/config/assetUrls';
import * as actions from './actions';
import { selectNormalizedList } from '../EquipmentStepView/store/selectors';
import { selectNormalizeData } from '../InboundStepView/store/selectors';

export function* registerInbound({
  config,
  resolve
}: ReturnType<typeof actions.registerInboundRequest>): SagaIterator {
  const inboundData = yield select(selectNormalizeData(config.dataReducerPath));
  const equipment = yield select(selectNormalizedList(config.tableReducerPath));

  const { data: inboundId } = yield call(
    apiClient.post,
    INBOUNDS,
    { ...inboundData, equipment },
    { retryAction: actions.registerInboundRequest(config, resolve) }
  );

  yield call(resolve, inboundId);
  yield put(navigateTo({ url: `/asset/inbound/create/${inboundId}` }));
  yield put(actions.registerInboundSuccess(config, inboundId));
}

export default function* InboundCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.registerInboundRequest),
    safe(registerInbound, actions.registerInboundFailure)
  );
}
