import { API_VERSION } from './index';

const SUB_DOMAIN = `/asset-management/${API_VERSION}`;

// equipment
export const EQUIPMENT = `${SUB_DOMAIN}/equipments`;
export const EQUIPMENT_GROUP_NAMES = `${EQUIPMENT}/groups`;
export const EQUIPMENT_AUTOCOMPLETE = `${EQUIPMENT}/autocomplete`;
export const EQUIPMENT_MOBILE_LIST = `${EQUIPMENT}/mobile/list`;
export const EQUIPMENT_STATIONARY_LIST = `${EQUIPMENT}/stationary/list`;
export const EQUIPMENT_INSPECTOR_LIST = `${EQUIPMENT}/inspectors/list`;
export const EQUIPMENT_ACTIONS = `${EQUIPMENT}/actions`;
export const EQUIPMENT_BARCODE_IS_USED = (barcode: string) =>
  `${EQUIPMENT}/barcode/${barcode}/is-used`;

// breakdownsTypes
export const BREAKDOWNS_TYPES = `${SUB_DOMAIN}/breakdown-types`;
export const BREAKDOWNS_TYPES_AUTOCOMPLETE = `${BREAKDOWNS_TYPES}/autocomplete`;

// breakdowns
export const BREAKDOWNS = `${SUB_DOMAIN}/breakdowns`;
export const REGISTERED_EMPLOYEE = `${BREAKDOWNS}/registered-by-employees`;
export const REGISTERED_EMPLOYEE_AUTOCOMPLETE = `${REGISTERED_EMPLOYEE}/autocomplete`;
export const BREAKDOWNS_NOTES_AUTOCOMPLETE = `${BREAKDOWNS}/notes/autocomplete`;
export const BREAKDOWNS_CHANGE_STATUS = (id: number) =>
  `${BREAKDOWNS}/${id}/change-status`;

// installation
export const INSTALLATIONS = `${SUB_DOMAIN}/installations`;
export const INSTALLATIONS_EQUIPMENT_VALIDATION = `${INSTALLATIONS}/equipment-uniqueness`;
export const RESPONSIBLE_EMPLOYEE = `${INSTALLATIONS}/responsible-employees`;
export const RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE = `${RESPONSIBLE_EMPLOYEE}/autocomplete`;
export const IMEI_AUTOCOMPLETE = `${INSTALLATIONS}/imei/autocomplete`;
export const ADDRESS_AUTOCOMPLETE = `${INSTALLATIONS}/address/autocomplete`;
export const INSTALLATIONS_NOTES_AUTOCOMPLETE = `${INSTALLATIONS}/notes/autocomplete`;
export const SPARE_PARTS_AUTOCOMPLETE = `${INSTALLATIONS}/spare-parts/autocomplete`;
export const BREAKDOWNS_AUTOCOMPLETE = `${INSTALLATIONS}/breakdowns/autocomplete`;
export const BARCODE_AUTOCOMPLETE = `${INSTALLATIONS}/barcode/autocomplete`;

// inbound
export const INBOUNDS = `${SUB_DOMAIN}/inbounds`;
export const INBOUND_NOTES_AUTOCOMPLETE = `${INBOUNDS}/notes/autocomplete`;
export const INBOUND_EQUIPMENT_VALIDATION = `${INBOUNDS}/equipment-uniqueness`;

// uninstallation
export const UNINSTALLATIONS = `${SUB_DOMAIN}/uninstallations`;
export const UNINSTALLATIONS_RESPONSIBLE_EMPLOYEE = `${UNINSTALLATIONS}/responsible-employees`;
export const UNINSTALLATION_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE = `${UNINSTALLATIONS_RESPONSIBLE_EMPLOYEE}/autocomplete`;
export const UNINSTALLATION_NOTES_AUTOCOMPLETE = `${UNINSTALLATIONS}/notes/autocomplete`;

// transfers
export const TRANSFERS = `${SUB_DOMAIN}/transfers`;
export const TRANSFER_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE = `${TRANSFERS}/performing-person/autocomplete`;
export const TRANSFER_NOTES_AUTOCOMPLETE = `${TRANSFERS}/notes/autocomplete`;

// stateChanges
export const STATE_CHANGES = `${SUB_DOMAIN}/state-changes`;
export const STATE_CHANGES_RESPONSIBLE_EMPLOYEE = `${STATE_CHANGES}/employee`;
export const STATE_CHANGES_RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE = `${STATE_CHANGES_RESPONSIBLE_EMPLOYEE}/autocomplete`;
export const STATE_CHANGES_ASSETS = `${STATE_CHANGES}/assets`;
export const STATE_CHANGES_BARCODE_AUTOCOMPLETE = `${STATE_CHANGES}/barcode/autocomplete`;
export const STATE_CHANGES_IMEI_AUTOCOMPLETE = `${STATE_CHANGES}/imei/autocomplete`;
export const STATE_CHANGES_SPARE_PARTS_AUTOCOMPLETE = `${STATE_CHANGES}/spare-parts/autocomplete`;
export const STATE_CHANGES_ADDRESS_AUTOCOMPLETE = `${STATE_CHANGES}/address/autocomplete`;
export const STATE_CHANGES_NOTES_AUTOCOMPLETE = `${STATE_CHANGES}/notes/autocomplete`;

// inspections
export const INSPECTIONS = `${SUB_DOMAIN}/inspections`;
export const INSPECTIONS_EQUIPMENT = `${INSPECTIONS}/equipment`;
export const INSPECTION_NOTES_AUTOCOMPLETE = `${INSPECTIONS}/notes/autocomplete`;

// send email
export const SEND_EMAIL = `${SUB_DOMAIN}/emails/send`;

// tracking
export const TRACKING_MOBILE = `${SUB_DOMAIN}/tracking/mobile`;
export const TRACKING_STATIONARY = `${SUB_DOMAIN}/tracking/stationary`;
export const TRACKING_INSPECTOR = `${SUB_DOMAIN}/tracking/inspectors`;
export const TRACKING_WAREHOUSE = `${SUB_DOMAIN}/tracking/in-warehouses`;

// states
export const STATES = `${EQUIPMENT}/states`;

// reports
export const REPORTS = `${SUB_DOMAIN}/reports/equipment-history`;
export const REPORTS_PAGE = `${REPORTS}/page`;
export const REPORTS_DOCUMENT_NUMBER_AUTOCOMPLETE = `${REPORTS}/document-number/autocomplete`;
export const REPORTS_SPARE_PARTS_AUTOCOMPLETE = `${REPORTS}/spare-parts/autocomplete`;
export const REPORTS_IMEI_AUTOCOMPLETE = `${REPORTS}/imei/autocomplete`;
export const REPORTS_BARCODES_AUTOCOMPLETE = `${REPORTS}/barcodes/autocomplete`;

// warehouse
export const WAREHOUSE_EQUIPMENT_LIST = `${SUB_DOMAIN}/in-warehouses-equipment`;
export const WAREHOUSE_BARCODE_AUTOCOMPLETE = `${WAREHOUSE_EQUIPMENT_LIST}/barcode/autocomplete`;
export const WAREHOUSE_IMEI_AUTOCOMPLETE = `${WAREHOUSE_EQUIPMENT_LIST}/imei/autocomplete`;
export const WAREHOUSE_ADDRESS_AUTOCOMPLETE = `${WAREHOUSE_EQUIPMENT_LIST}/address/autocomplete`;
export const WAREHOUSE_SPARE_PARTS_AUTOCOMPLETE = `${WAREHOUSE_EQUIPMENT_LIST}/spare-parts/autocomplete`;
