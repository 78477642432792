import { createAsyncAction } from 'typesafe-actions';
import { Equipment } from '../../types';
import { EquipmentEditState } from '../types';

export const getEquipment = createAsyncAction(
  '@EQUIPMENT_EDIT/GET_EQUIPMENT_REQUEST',
  '@EQUIPMENT_EDIT/GET_EQUIPMENT_SUCCESS',
  '@EQUIPMENT_EDIT/GET_EQUIPMENT_FAILURE'
)<undefined, EquipmentEditState, Error>();

export const updateEquipment = createAsyncAction(
  '@EQUIPMENT_EDIT/UPDATE_EQUIPMENT_REQUEST',
  '@EQUIPMENT_EDIT/UPDATE_EQUIPMENT_SUCCESS',
  '@EQUIPMENT_EDIT/UPDATE_EQUIPMENT_FAILURE'
)<Equipment, undefined, Error>();
