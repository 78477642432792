import { generateActionID } from 'src/components/Guards';
import {
  AdministrationSubTypes,
  AssetsSubTypes,
  AvlSubTypes,
  ClearingSubTypes
} from 'src/components/Guards/types';

export const generateAvlAction = (sub: AvlSubTypes): string =>
  generateActionID({
    module: 'avl',
    sub,
    action: 'read'
  });

export const generateAssetsAction = (sub: AssetsSubTypes): string =>
  generateActionID({
    module: 'assets',
    sub,
    action: 'read'
  });

export const generateClearingAction = (sub: ClearingSubTypes): string =>
  generateActionID({
    module: 'clearing',
    sub,
    action: 'read'
  });

export const generateAdministrationAction = (
  sub: AdministrationSubTypes
): string =>
  generateActionID({
    module: 'administration',
    sub,
    action: 'read'
  });
