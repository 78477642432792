import clsx from 'clsx';
import { colors, fade, makeStyles } from '@material-ui/core';
import { LabelProps } from './types';

const useStyles = makeStyles(({ typography, palette, spacing }) => ({
  root: {
    fontFamily: typography.fontFamily,
    alignItems: 'center',
    borderRadius: 2,
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    flexShrink: 0,
    fontSize: typography.pxToRem(12),
    fontWeight: typography.fontWeightMedium,
    height: 20,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: spacing(0.5, 1),
    textTransform: 'uppercase'
  },
  primary: {
    color: palette.primary.main,
    backgroundColor: fade(palette.primary.main, 0.08)
  },
  secondary: {
    color: palette.secondary.main,
    backgroundColor: fade(palette.secondary.main, 0.08)
  },
  error: {
    color: palette.error.main,
    backgroundColor: fade(palette.error.main, 0.08)
  },
  success: {
    color: palette.success.main,
    backgroundColor: fade(palette.success.main, 0.08)
  },
  warning: {
    color: palette.warning.main,
    backgroundColor: fade(palette.warning.main, 0.08)
  },
  draft: {
    color: palette.text.primary,
    backgroundColor: colors.blueGrey[50]
  }
}));

const Label = ({ color, children, className }: LabelProps) => {
  const classes = useStyles();

  return (
    <span
      className={clsx(classes.root, className, {
        [classes[color]]: color
      })}
    >
      {children}
    </span>
  );
};

export default Label;
