import { all, call, put, takeEvery, throttle } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import ls from 'localstorage-slim';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { PELIAS_REVERSE } from 'src/config/pelias';
import { safe } from 'src/utils/sagas';
import {
  MAP_CONFIGURATION,
  ROUTE_SCHEME_GEOZONES,
  EMPLOYEE_USERS
} from 'src/config/avlUrls';
import { MAP_CACHE } from 'src/constants/map';
import * as actions from '../actions/app';

export const setMapConfigurationStorage = <T extends object>(data: T) =>
  ls.set(MAP_CACHE.key, data, { ttl: MAP_CACHE.ttl });

export function* changeLocation({
  payload: { url }
}: ReturnType<typeof actions.navigateTo>): SagaIterator {
  const urlParams = { pathname: url };

  yield put(actions.setIsFormDirty(false));
  yield put(push(urlParams));
}

export function* getAddressByCoordinates({
  payload
}: ReturnType<typeof actions.getAddressByCoordinates.request>): SagaIterator {
  const { data } = yield call(apiClient.get, `${PELIAS_REVERSE}`, {
    params: { 'point.lat': payload.lat, 'point.lon': payload.lng },
    retryAction: actions.getAddressByCoordinates.request(payload)
  });

  yield put(actions.getAddressByCoordinates.success(data));
}

export function* getAddressByCoordinatesSilent({
  payload
}: ReturnType<
  typeof actions.getAddressByCoordinatesSilent.request
>): SagaIterator {
  const { data } = yield call(apiClient.get, `${PELIAS_REVERSE}`, {
    params: { 'point.lat': payload.lat, 'point.lon': payload.lng },
    retryAction: actions.getAddressByCoordinatesSilent.request(payload)
  });

  yield put(actions.getAddressByCoordinatesSilent.success(data));
}

export function* getMapConfiguration(): SagaIterator {
  let data: any = ls.get(MAP_CACHE.key);
  if (!data) {
    const response = yield call(apiClient.get, MAP_CONFIGURATION, {
      retryAction: actions.getMapConfiguration.request()
    });
    data = response.data;

    setMapConfigurationStorage(data);
  }
  yield put(actions.getMapConfiguration.success(data));
}

export function* updateMapConfiguration({
  payload
}: ReturnType<typeof actions.updateMapConfiguration.request>): SagaIterator {
  const { bottomRightLat, bottomRightLng, topLeftLat, topLeftLng } = payload;
  const data = {
    workArea: {
      bottomRightPoint: { lat: bottomRightLat, lng: bottomRightLng },
      topLeftPoint: { lat: topLeftLat, lng: topLeftLng }
    }
  };

  yield call(apiClient.put, MAP_CONFIGURATION, payload, {
    retryAction: actions.updateMapConfiguration.request(payload)
  });

  setMapConfigurationStorage(data);

  yield put(actions.updateMapConfiguration.success(data));
}

export function* getGeozonesSaga(): SagaIterator {
  const { data } = yield call(apiClient.get, ROUTE_SCHEME_GEOZONES, {
    retryAction: actions.getGeozones.request()
  });

  yield put(actions.getGeozones.success(data));
}

export function* getUserEmployeeSaga(): SagaIterator {
  const { data } = yield call(apiClient.get, `${EMPLOYEE_USERS}/current`, {
    retryAction: actions.getUserEmployee.request()
  });

  yield put(actions.getUserEmployee.success(data || null));
}

export default function* AppSagas(): SagaIterator {
  yield all([
    takeEvery(getType(actions.navigateTo), changeLocation),
    takeEvery(
      getType(actions.getMapConfiguration.request),
      safe(getMapConfiguration, actions.getMapConfiguration.failure)
    ),
    takeEvery(
      getType(actions.getGeozones.request),
      safe(getGeozonesSaga, actions.getGeozones.failure)
    ),
    takeEvery(
      getType(actions.getUserEmployee.request),
      safe(getUserEmployeeSaga, actions.getUserEmployee.failure)
    ),
    takeEvery(
      getType(actions.updateMapConfiguration.request),
      safe(updateMapConfiguration, actions.updateMapConfiguration.failure)
    ),
    throttle(
      1000,
      getType(actions.getAddressByCoordinates.request),
      safe(getAddressByCoordinates, actions.getAddressByCoordinates.failure)
    ),
    throttle(
      1000,
      getType(actions.getAddressByCoordinatesSilent.request),
      safe(
        getAddressByCoordinatesSilent,
        actions.getAddressByCoordinatesSilent.failure
      )
    )
  ]);
}
