import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { HelpCircle } from 'react-feather';
import {
  Avatar,
  Box,
  ButtonBase,
  colors,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@material-ui/core';
import userManager from 'src/utils/userManager';
import {
  selectUser,
  selectUserEmployee,
  selectUserInitials
} from 'src/store/selectors';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
    fontSize: 13,
    border: 'solid 0.8px',
    borderColor: theme.palette.secondary.main
  },
  popover: {
    width: 210
  },
  role: {
    // @ts-ignore
    color: theme.palette.text.grey
  },
  rolesIcon: {
    verticalAlign: 'top',
    marginLeft: theme.spacing(0.5)
  },
  mobileAccountInfo: {
    backgroundColor: colors.grey[50]
  },
  rolesList: {
    listStyle: 'none',
    color: colors.grey[500]
  },
  rolesListItem: {
    color: colors.grey[50]
  }
}));

function Account() {
  const classes = useStyles();
  const ref = useRef(null);
  const user = useSelector(selectUser);
  const employee = useSelector(selectUserEmployee);
  const initials = useSelector(selectUserInitials);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    // INFO:
    // manual redirect to OP
    // don't use 'userManager.signoutRedirect()', it causes page to refresh
    document.location.href = `${userManager?.settings?.metadata?.end_session_endpoint}?id_token_hint=${user?.id_token}&post_logout_redirect_uri=${userManager?.settings?.post_logout_redirect_uri}`;
    // @ts-ignore
    userManager.storeUser(null);
  };

  const userName = employee ? employee.shortName : user?.profile.name;

  const userRole: string | string[] = user?.profile.role || '';

  // if user has multiple roles - the first one should be shown here
  // and an additional tooltip with all roles wil be made separately
  const userRoleName: string = Array.isArray(userRole) ? userRole[0] : userRole;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <Avatar alt="User" color="" className={classes.avatar}>
          {initials}
        </Avatar>
        <Hidden smDown>
          <Box textAlign="left">
            <Typography variant="h6" color="inherit">
              {userName}
            </Typography>
            <Typography variant="body2" className={classes.role}>
              {userRoleName}
              {Array.isArray(userRole) && (
                <Tooltip
                  title={
                    <ul className={classes.rolesList}>
                      {userRole.map((role: string) => (
                        <li key={role}>
                          <Typography className={classes.rolesListItem}>
                            {role}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  }
                >
                  <HelpCircle size={18} className={classes.rolesIcon} />
                </Tooltip>
              )}
            </Typography>
          </Box>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <Hidden mdUp>
          <MenuItem className={classes.mobileAccountInfo}>
            <Box textAlign="left">
              <Typography variant="h6" color="inherit">
                {userName}
              </Typography>
              <Typography variant="body2" className={classes.role}>
                {Array.isArray(userRole) ? (
                  <ul className={classes.rolesList}>
                    {userRole.map((role: string) => (
                      <li key={role}>{role}</li>
                    ))}
                  </ul>
                ) : (
                  <span>{userRoleName}</span>
                )}
              </Typography>
            </Box>
          </MenuItem>
        </Hidden>
        <MenuItem onClick={handleLogout}>Вихід</MenuItem>
      </Menu>
    </>
  );
}

export default Account;
