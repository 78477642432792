import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { safe } from 'src/utils/sagas';
import { EMPLOYEES } from 'src/config/avlUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getEmployee(): SagaIterator {
  const id = yield select(selectors.selectEmployeeId);

  const { data } = yield call(apiClient.get, `${EMPLOYEES}/${id}/edit`, {
    retryAction: actions.getEmployee.request()
  });

  yield put(actions.getEmployee.success(data));
}

export function* updateEmployee({
  payload
}: ReturnType<typeof actions.updateEmployee.request>): SagaIterator {
  const id = yield select(selectors.selectEmployeeId);

  yield call(apiClient.put, `${EMPLOYEES}/${id}`, payload, {
    retryAction: actions.updateEmployee.request(payload)
  });
  yield put(navigateTo({ url: '/avl/employees/' }));
  yield put(
    enqueueSnackbar({
      key: 'employee_edit',
      message: 'Працівника відредаговано',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.updateEmployee.success());
}

export default function* employeeEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getEmployee.request),
      safe(getEmployee, actions.getEmployee.failure)
    ),
    takeEvery(
      getType(actions.updateEmployee.request),
      safe(updateEmployee, actions.updateEmployee.failure)
    )
  ]);
}
