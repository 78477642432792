import { ActionType, createReducer } from 'typesafe-actions';
import { MapState } from 'src/types/map';
import * as actions from '../actions/app';

export type MapActions = ActionType<typeof actions>;

export const initialState: MapState = {
  workArea: {
    topLeftPoint: {
      lng: 0,
      lat: 0
    },
    bottomRightPoint: {
      lng: 0,
      lat: 0
    }
  },
  geozones: {
    busStops: [],
    depots: [],
    dangerousZones: []
  }
};

const mapReducers = createReducer<MapState, MapActions>(initialState)
  .handleAction(
    actions.getMapConfiguration.success,
    (state, { payload: { workArea } }) => ({
      ...state,
      workArea
    })
  )
  .handleAction(
    actions.updateMapConfiguration.success,
    (state, { payload: { workArea } }) => ({
      ...state,
      workArea
    })
  )
  .handleAction(actions.getGeozones.success, (state, { payload }) => ({
    ...state,
    geozones: payload
  }));

export default mapReducers;
