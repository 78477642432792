import { TABLE_ACTIONS_CONFIG } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import { BREAKDOWNS_TYPES } from 'src/config/assetUrls';
import EquipmentStateLabel from 'src/components/Labels/EquipmentStateLabel';
import { crudActions } from '../const';

export const columns = [
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '40%'
  },
  {
    label: 'Стан обладнання',
    value: 'state',
    sortable: true,
    width: '40%',
    changeValue: (row: Row) => <EquipmentStateLabel state={row.state} />
  }
];

export const BREAKDOWNS_TYPES_TABLE_PATH = 'asset.breakdownsTypes.table';
export const BREAKDOWNS_TYPES_TABLE_NAME = 'BREAKDOWNS_TYPES';

export const tableConfig = {
  action: crudActions.create,
  reducerName: BREAKDOWNS_TYPES_TABLE_NAME,
  reducerPath: BREAKDOWNS_TYPES_TABLE_PATH,
  apiUrl: BREAKDOWNS_TYPES,
  routeUrl: '/asset/dictionaries/breakdownsTypes/',
  rowActions: {
    [TABLE_ACTIONS_CONFIG.EDIT]: {
      isActive: true,
      action: crudActions.update
    },
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      isActive: (row: Row) => !row.isUsed,
      action: crudActions.delete
    }
  },
  toolbarActions: {
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      action: crudActions.delete
    }
  },
  isRowClickEnabled: true
};
