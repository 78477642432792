import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { API_MANAGEMENT, REVOKE_API_KEY } from 'src/config/administrationUrls';
import { selectTableOptions } from 'src/components/Tables/TableWithPagination/store/sagas';
import { getDataRequest } from 'src/components/Tables/TableWithPagination/store/actions';
import * as actions from './actions';
import { tableConfig } from '../const';

export function* getTableData() {
  const { requestPayload } = yield call(selectTableOptions, tableConfig);
  yield put(getDataRequest(tableConfig, requestPayload));
}

export function* createApiKeySaga({
  payload
}: ReturnType<typeof actions.createApiKey.request>): SagaIterator {
  const { data } = yield call(apiClient.post, API_MANAGEMENT, payload, {
    retryAction: actions.createApiKey.request(payload)
  });

  yield put(actions.createApiKey.success({ ...payload, ...data }));
  yield call(getTableData);
}

export function* revokeApiKey({
  payload
}: ReturnType<typeof actions.revokeApiKey.request>): SagaIterator {
  yield call(apiClient.put, REVOKE_API_KEY(payload), payload, {
    retryAction: actions.revokeApiKey.request(payload)
  });

  yield put(actions.revokeApiKey.success());
  yield call(getTableData);
}

export default function* ApiKeysSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.createApiKey.request),
      safe(createApiKeySaga, actions.createApiKey.failure)
    ),
    takeEvery(
      getType(actions.revokeApiKey.request),
      safe(revokeApiKey, actions.revokeApiKey.failure)
    )
  ]);
}
