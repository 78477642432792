import { all, call, put, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { VEHICLES } from 'src/config/avlUrls';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';

function* getVehicleTrack({
  payload
}: ReturnType<typeof actions.getVehicleTrack.request>) {
  const { vehicle, dateTo, dateFrom } = payload;
  const { id } = vehicle!;
  const params = { to: dateTo, from: dateFrom };

  const [{ data: track }, { data: routes }] = yield all([
    call(apiClient.get, `${VEHICLES}/${id}/track-points`, { params }),
    call(apiClient.get, `${VEHICLES}/${id}/route-schemas`, { params })
  ]);

  yield put(actions.getVehicleTrack.success({ track: track.points, routes }));
}

export function* TrafficMapSagas() {
  yield takeEvery(
    getType(actions.getVehicleTrack.request),
    safe(getVehicleTrack, actions.getVehicleTrack.failure)
  );
}
