import { Error } from 'src/utils/validation';
import { DEPARTURES_ERRORS } from './const';

export const getErrorText = ({ code, source, message }: Error) => {
  if (code === 'notEqualValidator') {
    if (source.includes('arrivalTime')) {
      return DEPARTURES_ERRORS.depotArrivalTimeEqualWorkPointTime;
    }

    return DEPARTURES_ERRORS.depotDepartureTimeEqualWorkPointTime;
  }

  if (DEPARTURES_ERRORS[code]) {
    return DEPARTURES_ERRORS[code];
  }

  return message;
};
