import { createAsyncAction } from 'typesafe-actions';
import { UserManagementValues } from '../types';

export const getUserDetails = createAsyncAction(
  '@USER_MANAGEMENT/GET_EMPLOYEE_REQUEST',
  '@USER_MANAGEMENT/GET_EMPLOYEE_SUCCESS',
  '@USER_MANAGEMENT/GET_EMPLOYEE_FAILURE'
)<undefined, UserManagementValues, Error>();

export const updateUserSettings = createAsyncAction(
  '@USER_MANAGEMENT/REGISTER_USER_MANAGEMENT_REQUEST',
  '@USER_MANAGEMENT/REGISTER_USER_MANAGEMENT_SUCCESS',
  '@USER_MANAGEMENT/REGISTER_USER_MANAGEMENT_FAILURE'
)<UserManagementValues, undefined, Error>();
