import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { createSelector } from 'reselect';
import { INSPECTIONS_EDIT_URL } from 'src/routes/assetRoutes';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  INSPECTIONS_EDIT_URL
);

export const selectInspectionId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);
