import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { KPIS, KPI_PARAMETERS_TOTAL_WEIGHT } from 'src/config/clearingUrls';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { getType } from 'typesafe-actions';
import * as actions from './actions';

export function* getKpis(): SagaIterator {
  const { data } = yield call(apiClient.get, KPIS, {
    retryAction: actions.getKPIs.request()
  });

  yield put(actions.getKPIs.success(data));
}

export function* getKPIParametersTotalWeight(): SagaIterator {
  const { data } = yield call(apiClient.get, KPI_PARAMETERS_TOTAL_WEIGHT, {
    retryAction: actions.getKPIParametersTotalWeight.request()
  });

  yield put(actions.getKPIParametersTotalWeight.success(data));
}

export default function* KPIParametersFormSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getKPIs.request),
      safe(getKpis, actions.getKPIs.failure)
    ),
    takeEvery(
      getType(actions.getKPIParametersTotalWeight.request),
      safe(
        getKPIParametersTotalWeight,
        actions.getKPIParametersTotalWeight.failure
      )
    )
  ]);
}
