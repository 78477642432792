import { FC, MouseEvent } from 'react';
import {
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as appActions from 'src/store/actions/app';
import { Props } from './types';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

const TextWithIcon: FC<Props> = ({
  text = '',
  destination = '#',
  title = '',
  disabled,
  icon: Icon
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const navigate = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    destination &&
      dispatch(
        appActions.navigateTo({
          url: destination
        })
      );
  };

  return (
    <span className={classes.root}>
      <Typography>{text}</Typography>
      <Tooltip title={title}>
        <IconButton disabled={disabled} onClick={navigate}>
          {Icon}
        </IconButton>
      </Tooltip>
    </span>
  );
};

export default TextWithIcon;
