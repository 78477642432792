export const MAIN_MENU = {
  avl: 'Маршрути',
  asset: 'Обладнання',
  clearing: 'Нарахування',
  administration: 'Адміністрування'
};

export const NAV_BAR_HEADERS = {
  routes: 'Налаштування маршрутів',
  coefficientsTable: 'Таблиця коефіцієнтів',
  scheduling: 'Диспетчеризація',
  handbook: 'Довідники',
  devices: 'Пристрої',
  dictionaries: 'Словники',
  settlement: 'Налаштування нарахувань',
  coefficients: 'Налаштування коефіцієнтів',
  equipment: 'Обладнання',
  vehicleModels: 'Моделі транспортних засобів',
  uninstallation: 'Демонтаж',
  transfer: 'Переміщення',
  documents: 'Документи',
  inspections: 'Інспекції',
  reports: 'Звіти',
  analysis: 'Аналіз'
};

export const NAV_BAR_SUB_HEADERS = {
  geozones: 'Геозони',
  staff: 'Працівники',
  vehiclesModels: 'Моделі ТЗ',
  dictionaries: 'Довідники',
  vehicles: 'Транспортні засоби'
};

export const NAV_BAR_ITEMS = {
  other: 'Інше',
  routes: 'Паспорти маршрутів',
  schedulePlanning: 'Планування графіків',
  schedulesStatus: 'Статус графіків',
  trafficStadium: 'Моніторинг руху',
  trafficMap: 'Карта руху',
  geozones: 'Геозони',
  employees: 'Працівники',
  stops: 'Зупинки',
  counteragents: 'Підрозділи',
  dangerousZones: 'Небезпечні зони',
  devices: 'Зчитувачі',
  kpis: 'Показники (Ключові показники ефективності)',
  penalties: 'Штрафи',
  tariffs: 'Тарифні групи',
  kpiParameters: 'Параметри КПЕ',
  coefficientsAdjustments: 'Налаштування коефіцієнтів',
  vehicleModels: 'Моделі транспортних засобів',
  installation: 'Монтаж',
  uninstallation: 'Демонтаж',
  transfer: 'Переміщення',
  inbound: 'Надходження',
  inspections: 'Інспекції',
  inspectionDocuments: 'Документ інспекції',
  reports: 'Звіти',
  tracking: 'Моніторинг',
  equipment: 'Обладнання',
  breakdownsTypes: 'Несправності',
  breakdowns: 'Несправності',
  dictionaries: 'Довідники',
  vehicles: 'Транспортні засоби',
  schedules: 'Розклади',
  penaltyDocuments: 'Штрафи',
  routeSettings: 'Налаштування руху',
  billingDocuments: 'Нарахування',
  billingDetails: 'Деталі нарахувань',
  billingDetailsRoutes: 'Маршрути',
  usersSettings: 'Налаштування користувачів',
  paymentsSettings: 'Налаштування нарахувань',
  apiKeysSettings: 'Налаштування API-ключів',
  schemes: 'Схеми',
  historyOfChanges: 'Історія змін',
  logs: 'Логи користувачів',
  states: 'Стани',
  stateChanges: 'Зміна стану',
  replacement: 'Заміна'
};
