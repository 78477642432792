import { ActionType, createReducer } from 'typesafe-actions';
import {
  BreakdownPageItemDTO,
  BreakdownStateEnum
} from '@eticket/asset-management-api-contracts';
import * as actions from './actions';

type BreakdownsTypesEditActions = ActionType<typeof actions>;

const initialState: BreakdownPageItemDTO = {
  id: 0,
  name: '',
  state: BreakdownStateEnum.Repair
};

const breakdownsTypesEditReducers = createReducer<
  BreakdownPageItemDTO,
  BreakdownsTypesEditActions
>(initialState).handleAction(
  actions.getBreakdownsTypes.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default breakdownsTypesEditReducers;
