import { SagaIterator } from 'redux-saga';
import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { STATE_CHANGES_ASSETS, STATE_CHANGES } from 'src/config/assetUrls';
import {
  addRowsLocally,
  deleteRowLocally
} from 'src/components/Tables/TableView/store/actions';
import { Row } from 'src/components/Tables/types';
import { selectList } from 'src/components/Tables/TableWithPagination/store/selectors';
import * as actions from './actions';
import { NewEquipment } from '../../types';

export function* getStateChangeAsset({
  payload
}: ReturnType<typeof actions.getStateChangeAsset.request>): SagaIterator {
  const { barcode, counteragentId } = payload;

  const { data } = yield call(
    apiClient.get,
    `${STATE_CHANGES_ASSETS}/${counteragentId}/${barcode}`,
    { retryAction: actions.getStateChangeAsset.request }
  );

  yield put(actions.getStateChangeAsset.success(data));
}

export function* getEquipments({
  payload
}: ReturnType<typeof actions.getEquipments.request>): SagaIterator {
  const {
    barcode,
    editBarcode,
    tableConfig,
    counteragentId,
    newStateId,
    newState,
    editSpareParts
  } = payload;
  const { data: equipment } = yield call(
    apiClient.get,
    `${STATE_CHANGES_ASSETS}/${counteragentId}/${barcode}`
  );

  const equipmentItem: NewEquipment = {
    ...equipment,
    assetId: equipment.id,
    previousState: equipment.currentStateName,
    previousStateId: equipment.currentStateId,
    newStateId,
    newState,
    spareParts: editSpareParts
  };

  if (editBarcode?.length) {
    const equipments = yield select(selectList(tableConfig.reducerPath));
    const row = equipments.find((item: Row) => item.barcode === editBarcode);

    yield put(deleteRowLocally(tableConfig, { row }));
  }

  yield put(
    addRowsLocally(tableConfig, {
      rows: [equipmentItem]
    })
  );

  yield put(actions.getEquipments.success());
}

export function* approveStateChange({
  payload: id
}: ReturnType<typeof actions.approveStateChange.request>): SagaIterator {
  yield call(apiClient.put, `${STATE_CHANGES}/${id}/approve`);
  yield put(actions.approveStateChange.success());
}

export default function* StateChangeEquipmentsStepSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getEquipments.request),
      safe(getEquipments, actions.getEquipments.failure)
    ),
    takeEvery(
      getType(actions.getStateChangeAsset.request),
      safe(getStateChangeAsset, actions.getStateChangeAsset.failure)
    ),
    takeEvery(
      getType(actions.approveStateChange.request),
      safe(approveStateChange, actions.approveStateChange.failure)
    )
  ]);
}
