import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { KpiParametersDetailsState } from '../types';
import { KpiParametersState } from '../../const';

type KpiParametersDetailsActions = ActionType<typeof actions>;

const initialState: KpiParametersDetailsState = {
  id: 0,
  effectiveDate: '',
  endsOn: '',
  groupName: '',
  state: KpiParametersState.Expired,
  canDelete: false,
  kpis: [
    {
      kpiId: 0,
      kpiName: '',
      weight: 0,
      targetValue: 0,
      toleranceFrom: 0,
      toleranceTo: 0,
      maxValue: 0
    }
  ]
};

const kpiParametersDetailsReducers = createReducer<
  KpiParametersDetailsState,
  KpiParametersDetailsActions
>(initialState).handleAction(
  actions.getKpiParameters.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default kpiParametersDetailsReducers;
