import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { VEHICLES_MODELS } from 'src/config/avlUrls';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getVehicleModel(): SagaIterator {
  const id = yield select(selectors.selectVehicleModelId);

  const { data } = yield call(apiClient.get, `${VEHICLES_MODELS}/${id}`, {
    retryAction: actions.getVehicleModels.request
  });

  yield put(actions.getVehicleModels.success(data));
}

export default function* vehicleModelsDetailsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getVehicleModels.request),
      safe(getVehicleModel, actions.getVehicleModels.failure)
    )
  ]);
}
