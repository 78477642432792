import { Filter } from 'src/components/Tables/TableWithPagination/types';
import { FilterData } from '../../types';

import { AnalyseRequestPayload } from '../../AnalyseBreakdownsView/types';

type ConfigMap = Record<string, string>;

const comparisons: ConfigMap = {
  dateFrom: 'greaterThanOrEqual',
  dateTo: 'lessThanOrEqual'
};

export const DATE_KEY = 'date';

const keys: ConfigMap = {
  dateFrom: DATE_KEY,
  dateTo: DATE_KEY
};

export const parseFilter = (
  config: FilterData | AnalyseRequestPayload
): Filter[] => {
  const filter: Filter[] = [];

  Object.keys(config).forEach(key => {
    const typedKey = key as keyof typeof config;

    if (
      ((typedKey as keyof FilterData) !== 'equipmentStateId' &&
        !config[typedKey]) ||
      config[typedKey] === null
    ) {
      return;
    }

    filter.push({
      properties: keys[typedKey] || key,
      comparison: comparisons[typedKey],
      value: config[typedKey]
    });
  });

  return filter;
};
