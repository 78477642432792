import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { RouteSchemeState } from '../../types';

type RouteSchemeActions = ActionType<typeof actions>;

const initialState: RouteSchemeState = {
  schemeCount: 0,
  routeName: ''
};

const routeSchemeCreateReducers = createReducer<
  RouteSchemeState,
  RouteSchemeActions
>(initialState).handleAction(
  actions.getRouteSchemeData.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default routeSchemeCreateReducers;
