import { createReducer, ActionType } from 'typesafe-actions';
import moment from 'moment';
import { DATEPICKER_DATE_FORMAT } from 'src/config';
import * as actions from './actions';
import { StateChangeForm } from '../../types';

export const initialState: StateChangeForm = {
  id: 0,
  documentNumber: '',
  stateChangeDate: moment().format(DATEPICKER_DATE_FORMAT),
  employee: 0,
  counteragent: {
    id: 0,
    name: ''
  },
  counteragentAddress: '',
  notes: ''
};

const stateChangeStepReducer = createReducer<
  StateChangeForm,
  ActionType<typeof actions>
>(initialState).handleAction(actions.setFormValues, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default stateChangeStepReducer;
