import { useState, useEffect } from 'react';
import { Dialog, List, ListItem } from '@material-ui/core';
import { APP_VERSION, ENV } from 'src/config';
import { apiClient } from 'src/utils/api';

const VersioningDialog = ({ onClose }: { onClose: () => void }) => {
  const [data, setData] = useState<{
    [index: string]: { version: string; gitInfo: string };
  }>({});

  useEffect(() => {
    const getVersioning = async () => {
      const { data: clearing } = await apiClient.get(
        '/clearing/application-version'
      );
      const { data: avl } = await apiClient.get('/avl/application-version');
      const { data: identity } = await apiClient.get(
        '/identity/application-version'
      );
      const { data: asset } = await apiClient.get(
        '/asset-management/application-version'
      );
      const { data: notifications } = await apiClient.get(
        '/notifications/application-version'
      );

      setData({
        clearing,
        avl,
        identity,
        asset,
        notifications
      });
    };

    getVersioning();
  }, []);

  return (
    <Dialog open={true} onClose={onClose}>
      <List>
        <ListItem>ENVIRONMENT: {ENV}</ListItem>
        <ListItem>{`CENTRAL AVL UI: ${
          APP_VERSION ? `v.${APP_VERSION}` : '-'
        }`}</ListItem>
        {Object.keys(data).map(key => (
          <ListItem key={key}>{`${key.toUpperCase()}: v.${data[key].version}, ${
            data[key].gitInfo
          }`}</ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default VersioningDialog;
