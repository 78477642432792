import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { apiClient } from 'src/utils/api';
import { BREAKDOWNS, BREAKDOWNS_CHANGE_STATUS } from 'src/config/assetUrls';
import * as actions from './actions';
import { BreakdownDetailsState } from '../../BreakdownDetailsView/types';

export function* getBreakdownDetails({
  payload: id
}: ReturnType<typeof actions.getBreakdownRowData.request>): SagaIterator {
  const { data } = yield call(apiClient.get, `${BREAKDOWNS}/${id}`, {
    retryAction: actions.getBreakdownRowData.request(id)
  });

  yield put(actions.getBreakdownRowData.success(data));
}

export function* getSelectedBreakdownsData({
  payload
}: ReturnType<typeof actions.getSelectedBreakdownsData.request>): SagaIterator {
  const requests = payload.map(({ id }) =>
    call(apiClient.get, `${BREAKDOWNS}/${id}`, {
      retryAction: actions.getSelectedBreakdownsData.request(payload)
    })
  );

  const response = yield all(requests);

  const breakdowns = response.map(
    ({ data }: { data: BreakdownDetailsState[] }) => data
  );

  yield put(actions.getSelectedBreakdownsData.success(breakdowns));
}

export function* updateBreakdownStatus({
  payload
}: ReturnType<typeof actions.updateBreakdownStatus.request>): SagaIterator {
  yield call(
    apiClient.put,
    BREAKDOWNS_CHANGE_STATUS(payload.id),
    { status: payload.status },
    { retryAction: actions.updateBreakdownStatus.request(payload) }
  );
  yield put(actions.updateBreakdownStatus.success());
}

export default function* BreakdownListSaga() {
  yield all([
    takeEvery(
      getType(actions.getBreakdownRowData.request),
      safe(getBreakdownDetails, actions.getBreakdownRowData.failure)
    ),
    takeEvery(
      getType(actions.updateBreakdownStatus.request),
      safe(updateBreakdownStatus, actions.updateBreakdownStatus.failure)
    ),
    takeEvery(
      getType(actions.getSelectedBreakdownsData.request),
      safe(getSelectedBreakdownsData, actions.getSelectedBreakdownsData.failure)
    )
  ]);
}
