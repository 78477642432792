import { put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INSPECTION_DOCUMENTS } from 'src/config/clearingUrls';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getInspectionDocument(): SagaIterator {
  const id = yield select(selectors.selectInspectionDocumentId);

  const { data } = yield call(apiClient.get, `${INSPECTION_DOCUMENTS}/${id}`, {
    retryAction: actions.getInspectionDocument.request
  });

  yield put(actions.getInspectionDocument.success(data));
}

export default function* InspectionDocumentsDetailsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getInspectionDocument.request),
    safe(getInspectionDocument, actions.getInspectionDocument.failure)
  );
}
