import { createAction, createAsyncAction } from 'typesafe-actions';
import { LatLng } from 'leaflet';
import { GeozonesLayers, MapBoundPoint } from 'src/types/map';
import { Employee } from 'src/views/avl/employees/types';

interface UrlParam {
  url: string;
}

type UpdateMapConfigurationPayload = {
  topLeftLng: number;
  topLeftLat: number;
  bottomRightLng: number;
  bottomRightLat: number;
};

export const navigateTo = createAction(
  '@APP/NAVIGATE_TO',
  (payload: UrlParam) => payload
)();

export const getAddressByCoordinates = createAsyncAction(
  '@APP/GET_ADDRESS_REQUEST',
  '@APP/GET_ADDRESS_SUCCESS',
  '@APP/GET_ADDRESS_FAILURE'
)<LatLng, any, Error>();

export const getAddressByCoordinatesSilent = createAsyncAction(
  '@APP/GET_ADDRESS_SILENTREQUEST',
  '@APP/GET_ADDRESS_SILENTSUCCESS',
  '@APP/GET_ADDRESS_SILENTFAILURE'
)<LatLng, any, Error>();

export const getMapConfiguration = createAsyncAction(
  '@APP/GET_MAP_REQUEST',
  '@APP/GET_MAP_SUCCESS',
  '@APP/GET_MAP_FAILURE'
)<undefined, { workArea: MapBoundPoint }, Error>();

export const updateMapConfiguration = createAsyncAction(
  '@MAP_CONFIGURATION/UPDATE_MAP_CONFIGURATION_REQUEST',
  '@MAP_CONFIGURATION/UPDATE_MAP_CONFIGURATION_SUCCESS',
  '@MAP_CONFIGURATION/UPDATE_MAP_CONFIGURATION_FAILURE'
)<UpdateMapConfigurationPayload, { workArea: MapBoundPoint }, Error>();

export const getGeozones = createAsyncAction(
  '@ROUTE_SCHEMES/GET_GEOZONES_REQUEST',
  '@ROUTE_SCHEMES/GET_GEOZONES_SUCCESS',
  '@ROUTE_SCHEMES/GET_GEOZONES_FAILURE'
)<undefined, GeozonesLayers, Error>();

export const getUserEmployee = createAsyncAction(
  '@APP/GET_USER_EMPLOYEE_REQUEST',
  '@APP/GET_USER_EMPLOYEE_SUCCESS',
  '@APP/GET_USER_EMPLOYEE_FAILURE'
)<undefined, Employee, Error>();

export const setIsFormDirty = createAction('@APP/SET_IS_FORM_DIRTY')<boolean>();
