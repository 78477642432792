import { createContext, useState, useEffect } from 'react';
import { merge } from 'lodash';
import { THEMES } from 'src/constants';
import { storeSettings } from 'src/utils/settings';

type ThemeContextType = {
  settings: {
    direction: string;
    responsiveFontSizes: boolean;
    theme: string;
  };
  saveSettings: (updatedSettings: {}) => void;
};

export const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.ONE_DARK
};

const defaultContext: ThemeContextType = {
  settings: defaultSettings,
  saveSettings: () => {}
};

const SettingsContext = createContext<ThemeContextType>(defaultContext);

export function SettingsProvider({
  settings,
  children
}: {
  settings: any;
  children: any;
}) {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings as ThemeContextType['settings'],
        saveSettings: handleSaveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
