import { SagaIterator } from 'redux-saga';
import { select, call, put, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { selectRouteParams } from 'src/views/avl/Routes/RoutesPassportView/store/selectors';
import { ROUTE_SCHEMES_DETAILS } from 'src/config/avlUrls';
import { changePassportStatus } from 'src/views/avl/Routes/RoutesPassportLayout/store/actions';
import { selectPassportStatus } from 'src/views/avl/Routes/RoutesPassportLayout/store/selectors';
import { RoutePassportStatus } from 'src/views/avl/Routes/const';
import * as actions from './actions';

export function* updateRouteScheme({
  payload
}: ReturnType<typeof actions.updateRouteScheme.request>): SagaIterator {
  const { id, subId, mode } = yield select(selectRouteParams);

  yield call(apiClient.put, ROUTE_SCHEMES_DETAILS(id, subId), payload, {
    retryAction: actions.updateRouteScheme.request(payload)
  });

  yield put(navigateTo({ url: `/avl/routes/${mode}/scheme/${id}` }));
  yield put(
    enqueueSnackbar({
      key: 'route_scheme_update',
      message: 'Cхему оновлено',
      options: {
        variant: 'success'
      }
    })
  );

  const status = yield select(selectPassportStatus);
  if (status === RoutePassportStatus.Approved) {
    yield put(changePassportStatus(RoutePassportStatus.Draft));
  }

  yield put(actions.updateRouteScheme.success());
}

export default function* RouteSchemeUpdateSaga() {
  yield takeEvery(
    getType(actions.updateRouteScheme.request),
    safe(updateRouteScheme, actions.updateRouteScheme.failure)
  );
}
