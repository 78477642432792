import { flattenDeep } from 'lodash';
import { inboundFieldsJson } from '../const';
import {
  EquipmentDetail,
  InboundDetailsState
} from '../InboundDetailsView/types';
import { generateJsonFile } from '../../../../utils/jsonFile';

export function generateJsonOfInbound(data: InboundDetailsState): string {
  const inboundFields: any[] = [];

  const equipments = flattenDeep(
    data.equipments.map((item: EquipmentDetail) => {
      const { supplements, ...obj } = item;
      if (supplements?.length) {
        return [
          obj,
          ...supplements.map(suppl => {
            return {
              name: suppl
            };
          })
        ];
      }
      return obj;
    })
  );

  inboundFieldsJson.forEach(item => {
    if (typeof item === 'string' || typeof item === 'object') {
      inboundFields.push(item);
    }
  });

  return generateJsonFile(inboundFields, { ...data, equipments });
}
