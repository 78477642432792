import { object, number, string } from 'yup';
import { Row } from 'src/components/Tables/types';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { INSPECTIONS_EQUIPMENT } from 'src/config/assetUrls';
import { EquipmentTypeEnum } from 'src/constants';
import StateTypeColumn from '../../states/StatesListView/StateTypeColumn';

export const TABLE_COLUMNS = [
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Модель',
    value: 'productType',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Виробник',
    value: 'manufacturer',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Стан',
    value: 'equipmentStateId',
    sortable: true,
    width: '20%',
    changeValue: ({ equipmentStateName, equipmentStateId }: Row) => (
      <StateTypeColumn id={equipmentStateId} name={equipmentStateName} />
    )
  }
];

export const INSPECTIONS_EQUIPMENTS_SELECT_NAME =
  'INSPECTIONS_EQUIPMENTS_SELECT_NAME';
export const INSPECTIONS_EQUIPMENTS_SELECT_PATH =
  'asset.inspections.equipmentsSelect';
export const INSPECTIONS_EQUIPMENTS_SELECT_CONFIG = (
  counteragentId: number,
  equipmentGroupId: number,
  vehicleId?: number
) => {
  const isMobileEquipment = equipmentGroupId === EquipmentTypeEnum.Mobile;
  const counteragent = isMobileEquipment
    ? ''
    : `counteragentId=${counteragentId}`;

  return {
    reducerName: INSPECTIONS_EQUIPMENTS_SELECT_NAME,
    reducerPath: INSPECTIONS_EQUIPMENTS_SELECT_PATH,
    optionsApiUrl: `${INSPECTIONS_EQUIPMENT}?${counteragent}&equipmentGroupId=${equipmentGroupId}${
      vehicleId ? `&vehicleId=${vehicleId}` : ''
    }`
  };
};

export const equipmentsValidationSchema = object().shape({
  assetId: number()
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  state: string().required(ERROR_MESSAGES.REQUIRED)
});
