import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { enqueueSnackbar } from '../store/actions/snackbar';

export const useSnackbar = () => {
  const dispatch = useDispatch();

  return useCallback(
    (message: string, options?: Partial<UINotification>) => {
      dispatch(
        enqueueSnackbar({
          key: 'error',
          options: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            variant: 'error'
          },
          message,
          ...options
        })
      );
    },
    [dispatch]
  );
};
