import { createMatchSelector } from 'connected-react-router';
import { RootState } from 'typesafe-actions';
import { get, isBoolean } from 'lodash';
import { createSelector } from 'reselect';
import { LatLng } from 'leaflet';
import { SHAPES } from 'src/constants/map';
import { Values } from '../../types';

const matchSpaceParams = createMatchSelector(
  '/avl/geozones/dangerous-zones/:id'
);

export const selectDangerousZoneId = createSelector(
  matchSpaceParams,
  (match: any) => {
    return match ? match.params.id : '';
  }
);

export const selectDangerousZone = (state: RootState): Values => {
  const { category, lat, lng, ...rest } = get(
    state,
    `ui.avl.dangerousZones.edit`
  );

  return {
    categoryId: category?.id,
    categoryName: category?.name,
    coordinates: `${lat}, ${lng}`,
    ...rest
  } as Values;
};

export const selectIsLoadingDangerousZone = (state: RootState) => {
  const loading = get(
    state,
    `loading.@DANGEROUS_ZONES_EDIT/GET_DANGEROUS_ZONE`
  );
  return isBoolean(loading) ? loading : true;
};

export const selectMapData = (state: RootState) => {
  const dangerousZone = get(state, `ui.avl.dangerousZones.edit`);
  const { lat, lng, points } = dangerousZone;

  return {
    name: points ? SHAPES.POLYGON : SHAPES.CIRCLE,
    coordinates: { lat, lng } as LatLng,
    points
  };
};
