import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { InspectionDetails } from '../types';

type InspectionDetailsActions = ActionType<typeof actions>;

const initialState = null;

const inspectionDetailsReducers = createReducer<
  InspectionDetails | null,
  InspectionDetailsActions
>(initialState).handleAction(
  actions.getInspectionDetails.success,
  (_state, { payload }) => payload
);

export default inspectionDetailsReducers;
