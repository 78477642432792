import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { InspectionDocumentState } from './types';

const initialState = {
  id: 0,
  documentDate: null,
  documentName: '',
  counteragentId: null,
  vehicle: null,
  employee: null,
  kpiParams: []
};

const inspectionDocumentEditReducers = createReducer<
  InspectionDocumentState,
  ActionType<typeof actions>
>(initialState).handleAction(
  actions.getInspectionDocument.success,
  (state, { payload }) => ({
    ...state,
    id: payload.id,
    documentName: payload.documentName,
    documentDate: payload.documentDate.split('T')[0],
    counteragentId: payload.counteragentId,
    vehicle: {
      id: payload.vehicleId,
      name: payload.licensePlate
    },
    employee: {
      id: payload.employeeId,
      name: payload.employeeName
    },
    kpiParams: payload.kpiParams
  })
);

export default inspectionDocumentEditReducers;
