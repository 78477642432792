import { object, number, date, array, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { MAIN_MENU } from 'src/constants';
import { generateActionCrud } from 'src/components/Guards';
import { KPIS } from 'src/config/clearingUrls';
import { maxLength, valueFromTo } from 'src/utils/validation';
import { getTomorrowsUtcDate } from 'src/utils/date';
import { KPIParametersGroup } from './types';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.clearing,
    url: '/clearing'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: 'Групи параметрів КПЕ',
    url: '/clearing/kpiParameters'
  }
];

export const crudActions = generateActionCrud({
  module: 'clearing',
  sub: 'kpiGroup'
});

export const STATUS = {
  DISABLED: 0,
  ENABLED: 1
};

export enum KpiParametersState {
  Future = 'Future',
  Current = 'Current',
  Expired = 'Expired'
}

export const KpiStateEnumStatusMap = {
  [KpiParametersState.Current]: 'Поточний',
  [KpiParametersState.Future]: 'Майбутній',
  [KpiParametersState.Expired]: 'Минулий'
};

const RANGE_ERROR = valueFromTo(0, 100);
const TARGET_VALUE_ERROR =
  'Значення повинно бути меншим ніж максимальне значення';
const DEVIATION_FROM_ERROR = 'Значення має бути менше ніж бажаний показник';
const DEVIATION_TO_ERROR =
  'Значення має бути між бажаним показником і максимальним значенням';

export const KPIS_SELECT_NAME = 'KPIS_SELECT';
export const KPIS_SELECT_PATH = 'clearing.kpis.select';

export const KPIS_SELECT_CONFIG = {
  optionsApiUrl: KPIS,
  reducerName: KPIS_SELECT_NAME,
  reducerPath: KPIS_SELECT_PATH
};

const maxValueRangeMessage = valueFromTo(5, 100);

export const kpisParameterFormValidationSchema = object().shape({
  kpis: array().of(
    object().shape({
      kpiId: number()
        .nullable()
        .required(ERROR_MESSAGES.REQUIRED)
        .test({
          message: ERROR_MESSAGES.UNIQUE,
          name: 'kpiId',
          test(value) {
            const idxs = (this.options.context as { kpiIds: (null | number)[] })
              .kpiIds;

            const duplicateItemIndex = idxs.lastIndexOf(value);

            const isDuplicateItem = idxs.indexOf(value) !== duplicateItemIndex;

            if (isDuplicateItem && duplicateItemIndex > -1 && value) {
              throw this.createError({
                path: `kpis[${duplicateItemIndex}].kpiId`,
                message: ERROR_MESSAGES.UNIQUE
              });
            }

            return true;
          }
        }),
      weight: number()
        .nullable()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(0, RANGE_ERROR)
        .max(100, RANGE_ERROR)
        .integer(ERROR_MESSAGES.TYPE_ERROR)
        .typeError(ERROR_MESSAGES.TYPE_ERROR),
      maxValue: number()
        .nullable()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(5, maxValueRangeMessage)
        .max(100, maxValueRangeMessage)
        .integer(ERROR_MESSAGES.TYPE_ERROR)
        .typeError(ERROR_MESSAGES.TYPE_ERROR),
      targetValue: number()
        .nullable()
        .required(ERROR_MESSAGES.REQUIRED)
        .typeError(ERROR_MESSAGES.TYPE_ERROR)
        .integer(ERROR_MESSAGES.TYPE_ERROR)
        .test({
          name: 'targetValue',
          message: TARGET_VALUE_ERROR,
          test(value) {
            const { maxValue } = this.parent;
            return !(value && maxValue && value > maxValue);
          }
        }),
      toleranceFrom: number()
        .nullable()
        .required(ERROR_MESSAGES.REQUIRED)
        .positive(ERROR_MESSAGES.AMOUNT_GREATER_ERROR)
        .typeError(ERROR_MESSAGES.TYPE_ERROR)
        .integer(ERROR_MESSAGES.TYPE_ERROR)
        .test({
          name: 'toleranceFrom',
          message: DEVIATION_FROM_ERROR,
          test(value) {
            const { targetValue } = this.parent;
            return !(value && targetValue && value >= targetValue);
          }
        }),
      toleranceTo: number()
        .nullable()
        .required(ERROR_MESSAGES.REQUIRED)
        .positive(ERROR_MESSAGES.AMOUNT_GREATER_ERROR)
        .typeError(ERROR_MESSAGES.TYPE_ERROR)
        .integer(ERROR_MESSAGES.TYPE_ERROR)
        .test({
          name: 'deviationTo',
          message: DEVIATION_TO_ERROR,
          test(value) {
            const { targetValue, maxValue } = this.parent;
            return !(
              value &&
              targetValue &&
              maxValue &&
              (value < targetValue || value > maxValue)
            );
          }
        })
    })
  ),
  effectiveDate: date()
    .min(getTomorrowsUtcDate(), ERROR_MESSAGES.MIN_DATE_TOMORROW)
    .typeError(ERROR_MESSAGES.REQUIRED),
  groupName: string()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(...maxLength(100))
});

export const kpiParametersGroupInitialValues: KPIParametersGroup = {
  groupName: '',
  effectiveDate: null,
  kpis: [
    {
      kpiId: null,
      weight: null,
      maxValue: null,
      targetValue: null,
      toleranceFrom: null,
      toleranceTo: null
    }
  ]
};
