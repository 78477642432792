import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Button,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import { APP_VERSION, ENV } from 'src/config';
import Account from './Account';
import Menu from './Menu';
import VersioningDialog from './VersioningDialog';

const useStyles = makeStyles(
  // @ts-ignore
  ({ zIndex, shadows, palette, name, breakpoints, spacing }) => ({
    root: {
      zIndex: zIndex.drawer + 100,
      ...(name === THEMES.LIGHT
        ? {
            boxShadow: shadows[10],
            backgroundColor: palette.primary.main
          }
        : {})
    },
    toolbar: {
      minHeight: 64,
      [breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between'
      }
    },
    link: {
      textDecoration: 'none'
    },
    logo: {
      fontWeight: 'bold',
      width: 130
    },
    accountIcon: {
      [breakpoints.up('md')]: {
        marginLeft: spacing(3)
      }
    },
    settingsIcon: {
      [breakpoints.up('md')]: {
        marginLeft: spacing(1)
      }
    }
  })
);

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}: {
  className?: any;
  onMobileNavOpen: any;
}) {
  const classes = useStyles();

  const [isVersioningDialogShown, setIsVersioningDialogShown] = useState(false);

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Logo
            className={classes.logo}
            component={RouterLink}
            to="/"
            variant="h4"
            color="inherit"
            underline="none"
          />
          <Box ml={2}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setIsVersioningDialogShown(true)}
            >
              {ENV} {APP_VERSION && `v.${APP_VERSION}`}
            </Button>
          </Box>
        </Hidden>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box flex={1}>
            <Menu />
          </Box>
          <Box display="flex" alignItems="center">
            <Box className={classes.accountIcon}>
              <Account />
            </Box>
          </Box>
        </Box>
      </Toolbar>
      {isVersioningDialogShown && (
        <VersioningDialog onClose={() => setIsVersioningDialogShown(false)} />
      )}
    </AppBar>
  );
}

TopBar.defaultProps = {
  className: ''
};

export default TopBar;
