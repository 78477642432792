import { put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { selectRouteParams } from 'src/views/avl/Routes/RoutesPassportView/store/selectors';
import { ROUTE_SCHEMES_SUMMARY } from 'src/config/avlUrls';
import * as actions from './actions';

export function* getRouteSchemeSummary(): SagaIterator {
  const { id, subId } = yield select(selectRouteParams);

  const { data } = yield call(apiClient.get, ROUTE_SCHEMES_SUMMARY(id, subId), {
    retryAction: actions.getRouteSchemeSummary.request()
  });
  yield put(actions.getRouteSchemeSummary.success(data));
}

export default function* routeSchemeSummarySaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getRouteSchemeSummary.request),
    safe(getRouteSchemeSummary, actions.getRouteSchemeSummary.failure)
  );
}
