import { createReducer, ActionType } from 'typesafe-actions';
import moment from 'moment';
import { DATEPICKER_DATE_FORMAT } from 'src/config';
import * as actions from './actions';
import { InitialState } from './types';

export const initialState: InitialState = {
  documentId: '',
  dateInbound: moment().format(DATEPICKER_DATE_FORMAT),
  inboundingEmployee: null,
  acceptingEmployee: null,
  counteragent: null,
  address: '',
  notes: ''
};

const inboundStepReducer = createReducer<
  InitialState,
  ActionType<typeof actions>
>(initialState)
  .handleAction(actions.getCounteragentSuccess, (state, { payload }) => ({
    ...state,
    address: payload.address
  }))
  .handleAction(actions.getVehicleModelSuccess, (state, { payload }) => ({
    ...state,
    vehicleModelName: payload.vehicleModelName
  }))
  .handleAction(actions.setFormValues, (state, { values }) => ({
    ...state,
    ...values
  }))
  .handleAction(actions.getInboundDataSuccess, (_, { payload }) => {
    const parseToAutocompleteValue = (name?: string, id = 0) =>
      name ? { name, id } : null;

    return {
      documentId: `${payload.documentNumber}`,
      dateInbound: payload.dateInbound,
      inboundingEmployee: parseToAutocompleteValue(
        payload.inboundingEmployeeFullName,
        1
      ),
      acceptingEmployee: parseToAutocompleteValue(
        payload.acceptingEmployeeFullName,
        2
      ),
      equipmentType: payload.equipmentGroupId,
      counteragent: parseToAutocompleteValue(payload.counteragentName),
      address: payload.counteragentAddress,
      employee: parseToAutocompleteValue(payload.employeeName),
      vehicleNumbers: parseToAutocompleteValue(payload.licensePlate),
      vehicleModelName: payload.vehicleModelName,
      notes: payload.notes
    };
  });

export default inboundStepReducer;
