import { put, takeEvery, call, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INSTALLATIONS } from 'src/config/assetUrls';
import * as actions from './actions';
import { InstallationDetailsState } from '../../InstallationDetailsView/types';

export function* getInstallationRowData({
  payload
}: ReturnType<typeof actions.getInstallationRowData.request>): SagaIterator {
  const { id } = payload;

  const { data } = yield call(apiClient.get, `${INSTALLATIONS}/${id}`, {
    retryAction: actions.getInstallationRowData.request
  });

  yield put(actions.getInstallationRowData.success(data));
}

export function* getSelectedInstallationsData({
  payload
}: ReturnType<
  typeof actions.getSelectedInstallationsData.request
>): SagaIterator {
  const requests = payload.map(({ id }) =>
    call(apiClient.get, `${INSTALLATIONS}/${id}`, {
      retryAction: actions.getSelectedInstallationsData.request(payload)
    })
  );

  const response = yield all(requests);

  const installationsData = response.map(
    ({ data }: { data: InstallationDetailsState[] }) => data
  );

  yield put(actions.getSelectedInstallationsData.success(installationsData));
}

export function* updateInstallationState({
  payload
}: ReturnType<typeof actions.updateInstallationStatus.request>): SagaIterator {
  yield call(
    apiClient.put,
    `${INSTALLATIONS}/${payload.id}/change-status`,
    { status: payload.status },
    { retryAction: actions.updateInstallationStatus.request(payload) }
  );
  yield put(actions.updateInstallationStatus.success());
}

export default function* InstallationListViewSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getInstallationRowData.request),
      safe(getInstallationRowData, actions.getInstallationRowData.failure)
    ),
    takeEvery(
      getType(actions.getSelectedInstallationsData.request),
      safe(
        getSelectedInstallationsData,
        actions.getSelectedInstallationsData.failure
      )
    ),
    takeEvery(
      getType(actions.updateInstallationStatus.request),
      safe(updateInstallationState, actions.updateInstallationStatus.failure)
    )
  ]);
}
