import { createAsyncAction } from 'typesafe-actions';
import {
  EditPenaltyDocumentPayload,
  GetPenaltyDocumentPayload
} from '../../types';

export const editPenaltyDocument = createAsyncAction(
  '@PENALTY_DOCUMENTS/EDIT_PENALTY_DOCUMENT_REQUEST',
  '@PENALTY_DOCUMENTS/EDIT_PENALTY_DOCUMENT_SUCCESS',
  '@PENALTY_DOCUMENTS/EDIT_PENALTY_DOCUMENT_FAILURE'
)<EditPenaltyDocumentPayload, undefined, Error>();

export const getPenaltyDocument = createAsyncAction(
  '@PENALTY_DOCUMENTS/GET_PENALTY_DOCUMENT_REQUEST',
  '@PENALTY_DOCUMENTS/GET_PENALTY_DOCUMENT_SUCCESS',
  '@PENALTY_DOCUMENTS/GET_PENALTY_DOCUMENT_FAILURE'
)<undefined, GetPenaltyDocumentPayload, Error>();
