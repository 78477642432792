import { StateChange, StateChangeForm, StateChangePayload } from '../../types';

export const normalizeFormResponse = (
  payload: StateChange
): StateChangeForm => ({
  id: payload.id,
  documentNumber: payload.documentNumber,
  stateChangeDate: payload.stateChangeDate.split('T')[0],
  employee: payload.personId,

  counteragent: {
    id: payload.counteragentId,
    name: payload.counteragentName
  },
  counteragentAddress: payload.counteragentAddress,
  notes: payload.notes
});

export const normalizeFormRequest = (
  form: StateChangeForm
): StateChangePayload => {
  return {
    employeeId: form.employee,
    stateChangeDate: form.stateChangeDate,
    counteragentId: form.counteragent?.id || 0,
    notes: form.notes,
    equipment: []
  };
};
