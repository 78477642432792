import { WebStorageStateStore } from 'oidc-client';
import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
  authority: `${process.env.REACT_APP_API_BASE_URL}/identity`,
  client_id: 'central-avl-ui',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/signin-callback`,
  response_type: 'code',
  scope: 'openid profile offline_access IdentityServerApi',
  post_logout_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}/login`,
  automaticSilentRenew: true,
  acr_values: 'idp:oidc-avl',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  silent_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  loadUserInfo: true,
  monitorSession: true,
  checkSessionInterval: 5000,
  silentRequestTimeout: 10000
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
