import { RootState } from 'typesafe-actions';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { Row } from 'src/components/Tables/types';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL } from 'src/config';
import { generateSendEmailAttachmentProps } from 'src/components/SendEmailModalForm/utils/generateSendEmailAttachmentProps';
import { generatePdfOfUninstallation } from '../../utils/generatePdfOfUninstallation';
import { generateJsonOfUninstallation } from '../../utils/generateJsonOfUninstallation';
import { UninstallationPayload } from '../../UninstallationDetailsView/store/types';

export const selectListResult = (reducerPath: string) => (
  state: RootState
): Row[] => get(state, `ui.${reducerPath}.list`, []);

export const selectListCreateData = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list => {
    const res: number[] = [];

    list.forEach(el => res.push(el.id));

    return res;
  });

export const selectBarcodes = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list =>
    list.map(el => el.barcode)
  );

export const selectEmailData = (state: RootState) => {
  const data: UninstallationPayload = get(
    state,
    `ui.asset.uninstallation.details`,
    {}
  );

  const {
    id,
    documentNumber,
    uninstallingEmployeeName,
    uninstallationDate
  } = data;

  const date = utcToLocal(
    uninstallationDate as Date,
    DATE_FORMAT_FULL
  ) as string;

  const subject = `Демонтаж №${documentNumber} ${uninstallingEmployeeName} ${date}`;
  const bodyText = `Здійснено демонтаж обладнання.\nДокумент демонтажу №${documentNumber} від ${date}`;
  const tags = [`uninstallation:${id}`];

  let attachments;
  if (data.id) {
    const pdfData = {
      ...data,
      responsiblePerson: data.uninstallingEmployeeFullName
    };

    const pdfFile = generatePdfOfUninstallation(pdfData);
    const jsonFile = generateJsonOfUninstallation(pdfData);

    attachments = generateSendEmailAttachmentProps(subject, pdfFile, jsonFile);
  }

  return { subject, bodyText, tags, attachments };
};
