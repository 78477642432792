import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { COUNTERAGENTS, VEHICLES } from 'src/config/avlUrls';
import * as actions from '../installation/InstallationCreateView/InstallationStepView/store/actions';

export function* getCounteragent({
  config,
  payload: id
}: ReturnType<typeof actions.getCounteragentRequest>): SagaIterator {
  const { data } = yield call(apiClient.get, `${COUNTERAGENTS}/${id}`, {
    retryAction: actions.getCounteragentRequest(config, id)
  });

  yield put(actions.getCounteragentSuccess(config, data));
}

export function* getVehicleModel({
  config,
  payload: id
}: ReturnType<typeof actions.getVehicleModelRequest>): SagaIterator {
  const { data } = yield call(apiClient.get, `${VEHICLES}/${id}`, {
    retryAction: actions.getVehicleModelRequest(config, id)
  });

  yield put(actions.getVehicleModelSuccess(config, data));
}
