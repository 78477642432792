export const EDIT_INSPECTION_FORM_NAME = 'EDIT_INSPECTION_FORM';
export const EDIT_INSPECTION_FORM_PATH = 'asset.inspections.edit.data';
export const EDIT_INSPECTION_FORM_CONFIG = {
  reducerName: EDIT_INSPECTION_FORM_NAME,
  reducerPath: EDIT_INSPECTION_FORM_PATH
};

export const EDIT_INSPECTIONS_EQUIPMENT_NAME =
  'EDIT_INSPECTIONS_EQUIPMENT_NAME';
export const EDIT_INSPECTIONS_EQUIPMENT_PATH =
  'asset.inspections.edit.equipmentTable';

export const EDIT_INSPECTIONS_TABLE_CONFIG = {
  reducerName: EDIT_INSPECTIONS_EQUIPMENT_NAME,
  reducerPath: EDIT_INSPECTIONS_EQUIPMENT_PATH,
  apiUrl: '',
  snackbarMessages: {
    deleteRowSuccess: 'Успішно видалено'
  }
};
