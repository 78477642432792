import { createAsyncAction } from 'typesafe-actions';
import { TimetableCreatePayload, GetTimetablePayload } from '../../types';

export const editTimetable = createAsyncAction(
  '@ROUTES_TIMETABLE/EDIT_TIMETABLE_REQUEST',
  '@ROUTES_TIMETABLE/EDIT_TIMETABLE_SUCCESS',
  '@ROUTES_TIMETABLE/EDIT_TIMETABLE_FAILURE'
)<TimetableCreatePayload, undefined, Error>();

export const getTimetable = createAsyncAction(
  '@ROUTES_TIMETABLE/GET_TIMETABLE_REQUEST',
  '@ROUTES_TIMETABLE/GET_TIMETABLE_SUCCESS',
  '@ROUTES_TIMETABLE/GET_TIMETABLE_FAILURE'
)<undefined, GetTimetablePayload, Error>();
