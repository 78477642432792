import { RootState } from 'typesafe-actions';
import { get } from 'lodash';
import { RouteSettingsValues } from '../types';
import { formatRouteSettings } from '../utils';
import { initialState } from './reducer';

export const selectRouteSettingsValues = (
  state: RootState
): RouteSettingsValues => {
  const data = get(state, 'ui.administration.routeSettings', initialState);

  return formatRouteSettings(data);
};
