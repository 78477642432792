import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { EquipmentDetailsState } from '../types';

type EquipmentDetailsActions = ActionType<typeof actions>;

const initialState = {
  name: '',
  groupName: '',
  inspectionDays: 0,
  hasImei: false,
  manufacturer: '',
  productType: '',
  isUsed: false
};

const equipmentDetailsReducers = createReducer<
  EquipmentDetailsState,
  EquipmentDetailsActions
>(initialState).handleAction(
  actions.getEquipment.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default equipmentDetailsReducers;
