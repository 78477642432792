import { createReducer, ActionType } from 'typesafe-actions';
import { TariffGroupDetailDTO } from '@eticket/clearing-api-contracts/build';
import * as actions from './actions';

type TariffsDetailsActions = ActionType<typeof actions>;

const initialState = {
  id: 0,
  code: '',
  name: '',
  notes: '',
  passengerCapacity: [],
  activeTariffs: [],
  futureTariffs: [],
  pastTariffs: []
};

const tariffDetailsReducers = createReducer<
  TariffGroupDetailDTO,
  TariffsDetailsActions
>(initialState).handleAction(
  actions.getTariff.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default tariffDetailsReducers;
