import { lazy } from 'react';
import { Redirect } from 'react-router';
import { generateActionID } from 'src/components/Guards';
import { avlReportsRoutes } from 'src/views/avl/reports/const';

const avlRoutes = [
  {
    exact: true,
    path: '/avl',
    component: () => <Redirect to="/avl/routes" />
  },
  {
    exact: true,
    path: ['/avl/routes'],
    component: lazy(() => import('src/views/avl/Routes/RoutesListView')),
    action: generateActionID({ module: 'avl', sub: 'routes', action: 'read' })
  },
  {
    exact: false,
    path: ['/avl/routes/details/(settings|timetable|scheme)'],
    component: lazy(() => import('src/views/avl/Routes/RoutesPassportView')),
    action: generateActionID({ module: 'avl', sub: 'routes', action: 'read' })
  },
  {
    exact: false,
    path: [
      '/avl/routes-view/(create|edit|details)/timetable/:id/details/:subId'
    ],
    component: lazy(() =>
      import(
        'src/views/avl/Routes/RoutesPassportLayout/Steps/Timetable/TimetableHistoryChangesListView'
      )
    ),
    action: generateActionID({ module: 'avl', sub: 'routes', action: 'read' })
  },
  {
    exact: false,
    path: ['/avl/routes/create/(settings|timetable|scheme)'],
    component: lazy(() => import('src/views/avl/Routes/RoutesPassportView')),
    action: generateActionID({ module: 'avl', sub: 'routes', action: 'create' })
  },
  {
    exact: false,
    path: ['/avl/routes/edit/(settings|timetable|scheme)'],
    component: lazy(() => import('src/views/avl/Routes/RoutesPassportView')),
    action: generateActionID({ module: 'avl', sub: 'routes', action: 'update' })
  },
  {
    exact: true,
    path: ['/avl/geozones/stops'],
    component: lazy(() => import('src/views/avl/stops/StopsListView')),
    action: generateActionID({ module: 'avl', sub: 'stops', action: 'read' })
  },
  {
    exact: true,
    path: ['/avl/geozones/stops/create'],
    component: lazy(() => import('src/views/avl/stops/StopsCreateView')),
    action: generateActionID({ module: 'avl', sub: 'stops', action: 'create' })
  },
  {
    exact: true,
    path: ['/avl/geozones/stops/:id'],
    component: lazy(() => import('src/views/avl/stops/StopsEditView')),
    action: generateActionID({ module: 'avl', sub: 'stops', action: 'update' })
  },
  {
    exact: true,
    path: ['/avl/geozones/stops/:id/details'],
    component: lazy(() => import('src/views/avl/stops/StopsDetailsView')),
    action: generateActionID({ module: 'avl', sub: 'stops', action: 'read' })
  },
  {
    exact: true,
    path: ['/avl/geozones/dangerous-zones'],
    component: lazy(() =>
      import('src/views/avl/dangerousZones/DangerousZonesListView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'dangerousZones',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/avl/geozones/dangerous-zones/create'],
    component: lazy(() =>
      import('src/views/avl/dangerousZones/DangerousZonesCreateView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'dangerousZones',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/avl/geozones/dangerous-zones/:id'],
    component: lazy(() =>
      import('src/views/avl/dangerousZones/DangerousZonesEditView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'dangerousZones',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/avl/geozones/dangerous-zones/:id/details'],
    component: lazy(() =>
      import('src/views/avl/dangerousZones/DangerousZonesDetailsView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'dangerousZones',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/avl/employees'],
    component: lazy(() => import('src/views/avl/employees/EmployeeListView')),
    action: generateActionID({
      module: 'avl',
      sub: 'employees',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/avl/employees/create'],
    component: lazy(() => import('src/views/avl/employees/EmployeeCreateView')),
    action: generateActionID({
      module: 'avl',
      sub: 'employees',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/avl/employees/:id'],
    component: lazy(() => import('src/views/avl/employees/EmployeeEditView')),
    action: generateActionID({
      module: 'avl',
      sub: 'employees',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/avl/employees/:id/details'],
    component: lazy(() =>
      import('src/views/avl/employees/EmployeeDetailsView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'employees',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/avl/geozones/counteragents'],
    component: lazy(() =>
      import('src/views/avl/counteragents/CounteragentsListView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'counteragents',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/avl/geozones/counteragents/create'],
    component: lazy(() =>
      import('src/views/avl/counteragents/CounteragentsCreateView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'counteragents',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/avl/geozones/counteragents/:id'],
    component: lazy(() =>
      import('src/views/avl/counteragents/CounteragentsEditView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'counteragents',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/avl/geozones/counteragents/:id/details'],
    component: lazy(() =>
      import('src/views/avl/counteragents/CounteragentsDetailsView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'counteragents',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/avl/vehicles-models'],
    component: lazy(() =>
      import('src/views/avl/vehiclesModels/VehiclesModelsListView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'vehicleModels',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/avl/vehicles-models/create'],
    component: lazy(() =>
      import('src/views/avl/vehiclesModels/VehiclesModelCreateView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'vehicleModels',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/avl/vehicles-models/:id'],
    component: lazy(() =>
      import('src/views/avl/vehiclesModels/VehiclesModelEditView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'vehicleModels',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/avl/vehicles-models/:id/details'],
    component: lazy(() =>
      import('src/views/avl/vehiclesModels/VehiclesModelDetailsView')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'vehicleModels',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/avl/vehicles'],
    component: lazy(() => import('src/views/avl/vehicles/VehiclesListView')),
    action: generateActionID({ module: 'avl', sub: 'vehicles', action: 'read' })
  },
  {
    exact: true,
    path: ['/avl/vehicles/create'],
    component: lazy(() => import('src/views/avl/vehicles/VehiclesCreateView')),
    action: generateActionID({
      module: 'avl',
      sub: 'vehicles',
      action: 'create'
    })
  },
  {
    exact: true,
    path: ['/avl/vehicles/:id'],
    component: lazy(() => import('src/views/avl/vehicles/VehiclesEditView')),
    action: generateActionID({
      module: 'avl',
      sub: 'vehicles',
      action: 'update'
    })
  },
  {
    exact: true,
    path: ['/avl/vehicles/:id/details'],
    component: lazy(() => import('src/views/avl/vehicles/VehicleDetailsView')),
    action: generateActionID({ module: 'avl', sub: 'vehicles', action: 'read' })
  },
  {
    exact: true,
    path: ['/avl/traffic-stadium'],
    component: lazy(() => import('src/views/avl/dispatching/trafficStadium/')),
    action: generateActionID({ module: 'avl', sub: 'rides', action: 'read' })
  },
  {
    exact: true,
    path: ['/avl/traffic-map'],
    component: lazy(() => import('src/views/avl/dispatching/trafficMap/')),
    action: generateActionID({ module: 'avl', sub: 'rides', action: 'read' })
  },
  {
    exact: true,
    path: ['/avl/schedule-planning'],
    component: lazy(() =>
      import('src/views/avl/dispatching/schedulePlanning/')
    ),
    action: generateActionID({
      module: 'avl',
      sub: 'schedules',
      action: 'read'
    })
  },
  {
    exact: true,
    path: ['/avl/schedules-status'],
    component: lazy(() => import('src/views/avl/dispatching/schedulesStatus/')),
    action: generateActionID({
      module: 'avl',
      sub: 'schedules',
      action: 'viewScheduleStatuses'
    })
  },
  {
    exact: false,
    path: ['/avl/routes-view/(create|edit|details)/scheme/:id/details/:subId'],
    component: lazy(() =>
      import(
        'src/views/avl/Routes/RoutesPassportLayout/Steps/RouteScheme/RouteSchemesHistoryList'
      )
    ),
    action: generateActionID({ module: 'avl', sub: 'routes', action: 'read' })
  },
  ...avlReportsRoutes,
  {
    exact: false,
    path: ['/avl/routes-history/:id'],
    component: lazy(() =>
      import('src/views/avl/Routes/RoutesListView/RoutePassportHistoryList')
    ),
    action: generateActionID({ module: 'avl', sub: 'routes', action: 'read' })
  }
];

export default avlRoutes;
