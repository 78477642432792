import { createTheme, ThemeProvider } from '@material-ui/core';
import { themeConfigs } from 'src/theme';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/uk';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { ERROR_MESSAGES } from '../../constants/validation';

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 400,
    md: 768,
    lg: 992,
    xl: 1200
  }
};

const breakpoints = createBreakpoints(customBreakpointValues);

type OverridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

type CustomType = {
  MuiPickersBasePicker: {
    pickerView: {
      margin?: string;
    };
  };
};
declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey extends OverridesNameToClassKey {}
  export interface ComponentNameToClassKey extends CustomType {}
}

const datePickerTheme = createTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: themeConfigs[0].palette.secondary.main
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        margin: '5px auto'
      }
    },
    MuiDialogActions: {
      root: {
        [breakpoints.down('xs')]: {
          '& > :not(:first-child):not(:last-child)': {
            marginLeft: 0
          },
          display: 'flex',
          flexWrap: 'wrap'
        }
      }
    },
    MuiButton: {
      root: {
        [breakpoints.down('xs')]: {
          flexGrow: 4
        }
      }
    }
  },
  palette: {
    primary: {
      main: themeConfigs[0].palette.primary.main
    }
  }
});

const DatePicker = (props: KeyboardDatePickerProps) => {
  return (
    <ThemeProvider theme={datePickerTheme}>
      <MuiPickersUtilsProvider locale="uk" utils={MomentUtils}>
        <KeyboardDatePicker
          {...props}
          invalidDateMessage={ERROR_MESSAGES.TYPE_ERROR}
          clearLabel="Очистити"
          okLabel="Ок"
          cancelLabel="Відмінити"
          todayLabel="Сьогодні"
          minDate={`${props.minDate || '2010-01-01'}`}
          minDateMessage="Значення повинно бути більше 01.01.2010"
          maxDateMessage="Значення повинно бути менше 01.01.2100"
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePicker;
