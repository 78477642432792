import { put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { KPI_PARAMETERS_GROUPS } from 'src/config/clearingUrls';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getKpiParameters(): SagaIterator {
  const id = yield select(selectors.selectKpiParametersId);

  const { data } = yield call(apiClient.get, `${KPI_PARAMETERS_GROUPS}/${id}`, {
    retryAction: actions.getKpiParameters.request()
  });
  yield put(actions.getKpiParameters.success(data));
}

export default function* KpiParametersDetailsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getKpiParameters.request),
    safe(getKpiParameters, actions.getKpiParameters.failure)
  );
}
