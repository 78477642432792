import { createSelector } from 'reselect';
import { LatLng } from 'leaflet';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { STATUS_CONFIG } from 'src/constants';
import { SHAPES } from 'src/constants/map';
import { BusStopFacility, Params } from '../../types';

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/avl/geozones/stops/:id/details'
);

export const selectMapData = (state: RootState) => {
  const stop = get(state, `ui.avl.stops.details`);
  const { lat, lng, points, address } = stop;

  return {
    shape: {
      name: points ? SHAPES.POLYGON : SHAPES.CIRCLE,
      coordinates: { lat, lng } as LatLng,
      points
    },
    address
  };
};

export const selectStop = (state: RootState) =>
  get(state, `ui.avl.stops.details`);

export const selectStopRows = createSelector(selectStop, stop => [
  { label: 'Назва', value: stop.name },
  { label: 'Скорочена назва', value: stop.shortName },
  {
    label: 'Облаштування',
    value: stop.facilities
      .map((facility: BusStopFacility) => facility.name)
      .join(', ')
  },
  {
    label: 'Статус',
    value: STATUS_CONFIG[stop.status?.toLowerCase()]?.label
  },
  { label: 'Код', value: stop.code },
  { label: 'Радіус', value: stop.radiusInMeters },
  { label: 'Примітка', value: stop.description }
]);

export const selectIsLoadingStop = (state: RootState) => {
  const loading = get(state, `loading.@STOPS_DETAILS/GET_STOP`);

  return isBoolean(loading) ? loading : true;
};

export const selectStopId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectShortName = (state: RootState): string =>
  get(state, `ui.avl.stops.details.name`);

export const isDeleteDisabled = (state: RootState) =>
  !state.ui.avl.stops.details.canDelete;
