import { RowConfig, DetailsRow } from './types';

export const toDetailsTableRow = <T>(data: T) => ({
  label,
  format,
  field
}: RowConfig<T>): DetailsRow => ({
  label,
  value: format ? format(data) : data[field]
});
