import { ActionType, createReducer } from 'typesafe-actions';
import { RoutePassportStatus } from 'src/views/avl/Routes/const';
import * as actions from './actions';
import {
  CreatePassportPayload,
  GetPassportValidationStatusPayload
} from '../../types';

type SettingsDetailsActions = ActionType<typeof actions>;

type InitialState = {
  values: CreatePassportPayload;
  isFormDirty: boolean;
  validationStatus: GetPassportValidationStatusPayload;
};

export const initialState: InitialState = {
  values: {
    generalSettings: {
      number: '',
      name: '',
      shortName: '',
      contractStartDate: null,
      contractEndDate: null,
      tariffGroupId: '',
      fare: '',
      effectiveDate: null,
      technicalSpeed: '',
      isTenderCompleted: false,
      counteragentId: '',
      notes: ''
    },
    vehicleRequirements: {
      vehicleCategoryId: '',
      vehicleCategory: '',
      vehicleClass: ''
    },
    status: RoutePassportStatus.Draft
  },
  isFormDirty: false,
  validationStatus: {
    wasPassportApproved: false,
    hasApprovedRouteSchema: false,
    hasApprovedRouteTimetable: false,
    hasActiveRouteTimetable: false,
    wasPassportApprovedWithTender: false,
    manualUpdate: false
  }
};

const settingsDetailsReducer = createReducer<
  InitialState,
  SettingsDetailsActions
>(initialState)
  .handleAction(actions.getPassportSettings.success, (state, { payload }) => ({
    ...state,
    values: payload
  }))
  .handleAction(actions.setSettingsFormDirty, (state, { payload }) => ({
    ...state,
    isFormDirty: payload
  }))
  .handleAction(
    actions.getPassportValidationStatus.success,
    (state, { payload }) => ({
      ...state,
      validationStatus: { ...payload, manualUpdate: false }
    })
  )
  .handleAction(actions.resetPassportValidationStatus, state => ({
    ...state,
    validationStatus: { ...initialState.validationStatus, manualUpdate: true }
  }));

export default settingsDetailsReducer;
