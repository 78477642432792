import { RootState } from 'typesafe-actions';
import { get } from 'lodash';
import { Row } from 'src/components/Tables/types';

export const normalizeEquipment = ({ barcode, imei, equipment }: Row) => ({
  barcode,
  imei,
  equipmentId: equipment.id
});

export const selectListResult = (reducerPath: string) => (
  state: RootState
): Row[] => get(state, `ui.${reducerPath}.list`, []);

export const selectBreakdownRowsForEdit = (reducerPath: string) => (
  state: RootState
) => get(state, `ui.${reducerPath}.breakdownsTable.list`, []);

export const selectBreakdownsEditData = (reducerPath: string) => (
  state: RootState
) => get(state, `ui.${reducerPath}`, []);

export const selectEquipmentTypeId = (reducerPath: string) => (
  state: RootState
) => get(state, `ui.${reducerPath}.equipmentType`, []);

export const selectCounteragentId = (reducerPath: string) => (
  state: RootState
) => get(state, `ui.${reducerPath}.counteragent.id`);

export const selectCounteragentIdForEdit = (reducerPath: string) => (
  state: RootState
) => get(state, `ui.${reducerPath}.counteragentId`);

export const selectVehicleId = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.vehicleNumbers.id`);

export const selectVehicleIdForEdit = (reducerPath: string) => (
  state: RootState
) => get(state, `ui.${reducerPath}.vehicleId`);
