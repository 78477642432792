import { ActionType, createReducer } from 'typesafe-actions';
import {
  CounteragentEditDTO,
  SystemStatusDTO
} from '@eticket/avl-api-contracts';
import * as actions from './actions';

type CounteragentsEditActions = ActionType<typeof actions>;

const initialState: CounteragentEditDTO = {
  id: 0,
  name: '',
  shortName: '',
  address: '',
  contacts: [],
  departmentTypeId: 0,
  departmentTypeName: '',
  description: '',
  status: SystemStatusDTO.Enabled,
  code: '',
  counteragentType: {
    id: 0,
    name: '',
    status: SystemStatusDTO.Enabled,
    isPredefined: false
  },
  defaultContactIndex: 0,
  lat: 0,
  lng: 0,
  parentCounteragentId: 0,
  points: ''
};

const counteragentsEditReducers = createReducer<
  CounteragentEditDTO,
  CounteragentsEditActions
>(initialState).handleAction(
  actions.getCounteragent.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default counteragentsEditReducers;
