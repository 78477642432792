import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { STATES } from 'src/config/assetUrls';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { safe } from 'src/utils/sagas';
import * as selectors from './selectors';
import * as actions from './actions';

export function* getState(): SagaIterator {
  const id = yield select(selectors.selectStatesId);

  const { data } = yield call(apiClient.get, `${STATES}/${id}`, {
    retryAction: actions.getState.request()
  });

  yield put(actions.getState.success(data));
}

export function* updateState({
  payload
}: ReturnType<typeof actions.updateState.request>): SagaIterator {
  const id = yield select(selectors.selectStatesId);

  yield call(apiClient.put, `${STATES}/${id}`, payload);
  yield put(navigateTo({ url: '/asset/dictionaries/states' }));
  yield put(
    enqueueSnackbar({
      key: 'states_edit',
      message: 'Стан відредаговано',
      options: { variant: 'success' }
    })
  );
  yield put(actions.updateState.success());
}

export default function* StateEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getState.request),
      safe(getState, actions.getState.failure)
    ),
    takeEvery(
      getType(actions.updateState.request),
      safe(updateState, actions.updateState.failure)
    )
  ]);
}
