import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';

const matchSpaceParams = createMatchSelector('/clearing/kpis/:id');

export const selectKPI = (state: RootState) => {
  const kpi = get(state, `ui.clearing.kpis.edit`);

  return { name: kpi.name, typeId: kpi.type.id };
};

export const selectIsLoadingKPI = (state: RootState) => {
  const loading = get(state, `loading.@KPIS/GET_KPI`);

  return isBoolean(loading) ? loading : true;
};

export const selectKPIId = createSelector(matchSpaceParams, (match: any) => {
  return match ? match.params.id : '';
});
