import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { DialogInitialState, SpecialModeDialogState } from '../../../../types';

interface InitialState {
  changeover: DialogInitialState;
  termination: DialogInitialState;
  specialMode: SpecialModeDialogState;
}

export const dialogInitialState = {
  routeId: 0,
  isOpen: false,
  initialValues: null
};

export const initialState: InitialState = {
  changeover: dialogInitialState,
  termination: dialogInitialState,
  specialMode: dialogInitialState
};

const trafficStadiumDialogsReducer = createReducer<
  InitialState,
  ActionType<typeof actions>
>(initialState)
  .handleAction(actions.toggleChangeoverDialog, (state, { payload }) => ({
    ...state,
    changeover: payload
  }))
  .handleAction(actions.toggleTerminationDialog, (state, { payload }) => ({
    ...state,
    termination: payload
  }))
  .handleAction(actions.toggleSpecialModeDialog, (state, { payload }) => ({
    ...state,
    specialMode: payload
  }));

export default trafficStadiumDialogsReducer;
