import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INBOUNDS } from 'src/config/assetUrls';
import * as actions from './actions';
import * as selectors from './selectors';
import { updateInboundStatus as updateInboundStatusAction } from '../../InboundListView/store/actions';

export function* getInbound(): SagaIterator {
  const id = yield select(selectors.selectInboundId);

  const { data } = yield call(apiClient.get, `${INBOUNDS}/${id}`, {
    retryAction: actions.getInbound.request()
  });

  yield put(actions.getInbound.success(data));
}

export function* updateInboundStatus({
  payload
}: ReturnType<typeof actions.updateInboundState.request>): SagaIterator {
  yield put(updateInboundStatusAction.request(payload));
  yield put(actions.getInbound.request());
  yield put(actions.updateInboundState.success());
}

export default function* InboundDetailsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getInbound.request),
      safe(getInbound, actions.getInbound.failure)
    ),
    takeEvery(
      getType(actions.updateInboundState.request),
      safe(updateInboundStatus, actions.updateInboundState.failure)
    )
  ]);
}
