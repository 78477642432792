import { generateActionCrud } from 'src/components/Guards';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { EquipmentStateStatusEnum } from '../tracking/const';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.inbound,
    url: '/asset/inbound'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'inbound'
  },
  ['download', 'resendDocument', 'inboundAllowEditing']
);

export enum InboundStatusEnum {
  Draft = 0,
  Fixed = 1
}

export const INBOUND_STEPS = ['Прийняття', 'Обладнання', 'Перевірка статусу'];

export const inboundDocumentPdf = [
  {
    label: 'Підрозділ',
    value: 'counteragentName'
  },
  {
    label: 'Адреса',
    value: 'counteragentAddress'
  },
  {
    label: 'Передав',
    value: 'inboundingEmployeeFullName'
  },
  {
    label: 'Прийняв',
    value: 'acceptingEmployeeFullName'
  },
  {
    label: 'Примітка',
    value: 'notes'
  }
];

export const inboundColumnsPdf = [
  {
    label: '№',
    value: 'index',
    width: 7
  },
  {
    label: 'Штрихкод',
    value: 'barcode',
    width: 25
  },
  {
    label: 'Назва',
    value: 'name',
    width: 25
  },
  {
    label: 'Модель',
    value: 'model',
    width: 25
  },
  {
    label: 'Виробник',
    value: 'manufacturer',
    width: 25
  },
  {
    label: 'ІМЕІ',
    value: 'imei',
    width: 25
  },
  {
    label: 'Запчастини',
    value: 'spareParts',
    width: 25
  },
  {
    label: 'Стан',
    value: 'state',
    changeValue: (value: string) =>
      EquipmentStateStatusEnum[value as keyof typeof EquipmentStateStatusEnum],
    width: 25
  }
];

export const inboundFieldsJson = [
  'documentNumber',
  'dateInbound',
  'acceptingEmployeeName',
  'acceptingEmployeeFullName',
  'counteragentName',
  'counteragentAddress',
  'inboundingEmployeeName',
  'inboundingEmployeeFullName',
  'notes',
  {
    label: 'equipments',
    fields: [
      'name',
      'model',
      'manufacturer',
      'barcode',
      'imei',
      'spareParts',
      'state'
    ]
  }
];
