import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { apiClient } from 'src/utils/api';
import { INSPECTIONS } from 'src/config/assetUrls';
import * as actions from './actions';
import * as selectors from './selectors';
import { InspectionDetails } from '../types';

export const fetchInspectionDetails = (id: string) =>
  apiClient
    .get<InspectionDetails>(`${INSPECTIONS}/${id}`, {
      retryAction: actions.getInspectionDetails.request()
    })
    .then(res => res.data);

export function* getInspectionDetails() {
  const id: string = yield select(selectors.selectInspectionId);
  const data: InspectionDetails = yield call(fetchInspectionDetails, id);

  yield put(actions.getInspectionDetails.success(data));
}

export default function* InspectionDetailsSaga() {
  yield takeEvery(
    getType(actions.getInspectionDetails.request),
    safe(getInspectionDetails, actions.getInspectionDetails.failure)
  );
}
