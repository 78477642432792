import { call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { COEFFICIENTS_ADJUSTMENTS_URL } from 'src/routes/coefficientsAdjustmentsRoutes';
import { apiClient } from 'src/utils/api';
import { COEFFICIENTS_ADJUSTMENTS } from 'src/config/clearingUrls';
import * as actions from './actions';
import { CoefficientsGroup } from '../../types';
import { mapCoefficientsGroupToEntity } from '../../helpers';

export const snackbarOptions: UINotification = {
  key: 'coefficients_group_create',
  message: 'Створено нові коефіцієнти',
  options: {
    variant: 'success'
  }
};

export const createCoefficientsGroup = (payload: CoefficientsGroup) => {
  const coefficientsGroup = mapCoefficientsGroupToEntity(payload);
  return apiClient.post(COEFFICIENTS_ADJUSTMENTS, coefficientsGroup, {
    retryAction: actions.createCoefficientsGroup.request(payload)
  });
};

export function* createCoefficientsGroupSaga({
  payload
}: ReturnType<typeof actions.createCoefficientsGroup.request>): SagaIterator {
  yield call(createCoefficientsGroup, payload);
  yield put(navigateTo({ url: COEFFICIENTS_ADJUSTMENTS_URL }));
  yield put(enqueueSnackbar(snackbarOptions));
  yield put(actions.createCoefficientsGroup.success());
}

export default function* CoefficientsAdjustmentsCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createCoefficientsGroup.request),
    safe(createCoefficientsGroupSaga, actions.createCoefficientsGroup.failure)
  );
}
