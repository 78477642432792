import { FC } from 'react';
import { Switch, Tooltip } from '@material-ui/core';
import { useSnackbar } from 'src/hooks/useSnackbar';
import { useDispatch } from 'react-redux';
import { toggleConfirmDialog } from '../ConfrimDialog/store/actions';

interface Props {
  checked: boolean;
  disabled?: boolean;
  tooltip?: string;
  confirmMessage: string;
  onChange: () => Promise<void>;
}

const SwitchEdit: FC<Props> = ({
  checked,
  disabled = false,
  tooltip = '',
  confirmMessage,
  onChange
}) => {
  const openSnackbar = useSnackbar();
  const dispatch = useDispatch();

  const changeSwitch = async () => {
    try {
      await onChange();
    } catch (errors) {
      if (!Array.isArray(errors)) {
        return;
      }

      const [error] = errors;
      const regex = /type:'([^']*)' number:'([^']*)' date:'([^']*)'/;
      const match = error.message?.match(regex);

      if (match) {
        const documentType = match[1];
        const documentNumber = match[2];
        const documentDate = match[3];

        openSnackbar(
          `Неможливо розфіксувати даний документ. Існує інший зафіксований документ для цього обладнання: ${documentType} ${documentNumber} від ${documentDate}`
        );
      }
    }
  };

  const handleOpenConfirm = () => {
    if (checked) {
      return;
    }

    dispatch(
      toggleConfirmDialog({
        open: true,
        onConfirm: changeSwitch,
        content: confirmMessage
      })
    );
  };

  const switchComponent = (
    <Switch
      disabled={disabled}
      checked={checked}
      onChange={handleOpenConfirm}
    />
  );

  return disabled ? (
    <Tooltip placement="bottom" title={tooltip}>
      <span>{switchComponent}</span>
    </Tooltip>
  ) : (
    switchComponent
  );
};

export default SwitchEdit;
