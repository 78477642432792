import { DISRUPTIONS, DISRUPTIONS_CUSTOM_CATEGORIES } from 'src/config/avlUrls';
import { AVL_ERROR_MESSAGES } from 'src/constants/validation';

export const DISRUPTION_REASONS_NAME = 'DISRUPTION_REASONS';
export const DISRUPTION_REASONS_PATH = 'avl.routes.disruptionReasons';

export const DISRUPTIONS_CONFIG = {
  reducerPath: DISRUPTION_REASONS_PATH,
  reducerName: DISRUPTION_REASONS_NAME,
  activeOptionsApiUrl: DISRUPTIONS,
  customOptionsApiUrl: DISRUPTIONS_CUSTOM_CATEGORIES
};

export const DISRUPTIONS_DIALOG_ERRORS = {
  ...AVL_ERROR_MESSAGES
};
