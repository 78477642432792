import { object, string, number, date } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { maxLength } from 'src/utils/validation';
import moment from 'moment';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { generateActionCrud } from 'src/components/Guards';
import { getDateDifference } from 'src/utils/date';
import { BillingType, BillingDocumentStatusEnum } from './types';

export const crudActions = generateActionCrud(
  {
    module: 'clearing',
    sub: 'billingDocuments'
  },
  ['download', 'billingDocumentsAllowEditing']
);

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.clearing,
    url: '/clearing'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.billingDocuments,
    url: '/clearing/billing-documents'
  }
];

export const billingDocumentSchema = object().shape({
  documentDate: date()
    .nullable()
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .min(
      moment()
        .add(-1, 'year')
        .format('yyyy-MM-DD'),
      ERROR_MESSAGES.TYPE_ERROR
    )
    .max(
      moment()
        .add(1, 'year')
        .format('yyyy-MM-DD'),
      ERROR_MESSAGES.TYPE_ERROR
    ),
  documentName: string()
    .max(...maxLength(100))
    .required(ERROR_MESSAGES.REQUIRED),
  dateFrom: date()
    .nullable()
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.TYPE_ERROR),
  dateTo: date()
    .nullable()
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .min(
      moment()
        .add(-1, 'year')
        .format('yyyy-MM-DD'),
      ERROR_MESSAGES.TYPE_ERROR
    )
    .max(
      moment()
        .add(1, 'year')
        .format('yyyy-MM-DD'),
      ERROR_MESSAGES.TYPE_ERROR
    )
    .test({
      name: 'dateTo',
      message: ERROR_MESSAGES.TYPE_ERROR,
      test(value) {
        if (
          (value && moment(value).isBefore(this.parent.dateFrom, 'day')) ||
          (value && !moment(value).isBefore(this.parent.documentDate, 'day')) ||
          (this.parent.documentDateFrom &&
            value &&
            moment(value).isSame(this.parent.dateFrom, 'day'))
        ) {
          return false;
        }
        return true;
      }
    }),
  counteragent: object()
    .shape({
      id: number(),
      name: string()
    })
    .default(null)
    .nullable()
    .required(ERROR_MESSAGES.REQUIRED)
});

export const BILLING_DOCUMENT_SUBTITLE = 'За період';

export const reverseStatus = (status: BillingDocumentStatusEnum) => {
  if (status === BillingDocumentStatusEnum.Fixed) {
    return BillingDocumentStatusEnum.Unfixed;
  }

  return BillingDocumentStatusEnum.Fixed;
};

export const MAX_DAYS_AFTER_BILLING_DOCUMENT_CREATE = 40;
export const TOGGLE_DISABLED_TEXT = `Документ зафіксовано понад ${MAX_DAYS_AFTER_BILLING_DOCUMENT_CREATE} днів тому. Редагування заборонене.`;
export const isToggleDateRestricted = (time: string) =>
  getDateDifference(time) > MAX_DAYS_AFTER_BILLING_DOCUMENT_CREATE;

export const billingDocumentStatuses: Record<BillingType, string> = {
  Automatic: 'Автоматичне',
  Manual: 'Здійснено вручну'
};

export const CALCULATOR_ICON_TOOLTIP_TITLE = 'Здійснюється обрахунок';
export const GPS_ICON_TOOLTIP_TITLE =
  'Містить неповні дані GPS-треку за вибраний період';

export const ERROR_MESSAGE_UNIQUE_DATA_RANGE =
  'Вже існує незафіксований документ нарахувань, що потрапляє у вибраний період. Зафіксуйте або видаліть його, після цього зможете згенерувати наступний документ';

export const BILLING_DOCUMENTS_LOADING_KEY =
  '@TABLE_WITH_PAGINATION/GET_DATA/BILLING_DETAILS';
