import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { DANGEROUS_ZONES } from 'src/config/avlUrls';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as selectors from './selectors';
import * as actions from './actions';

export function* getDangerousZone(): SagaIterator {
  const id = yield select(selectors.selectDangerousZoneId);

  const { data } = yield call(apiClient.get, `${DANGEROUS_ZONES}/${id}`, {
    retryAction: actions.getDangerousZone.request()
  });

  yield put(actions.getDangerousZone.success(data));
}

export function* updateDangerousZone({
  payload
}: ReturnType<typeof actions.updateDangerousZone.request>): SagaIterator {
  const id = yield select(selectors.selectDangerousZoneId);

  yield call(apiClient.put, `${DANGEROUS_ZONES}/${id}`, payload);
  yield put(navigateTo({ url: '/avl/geozones/dangerous-zones' }));
  yield put(
    enqueueSnackbar({
      key: 'dangerous_zone_edit',
      message: 'Небезпечну зону відредаговано',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.updateDangerousZone.success());
}

export default function* dangerousZonesEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getDangerousZone.request),
      safe(getDangerousZone, actions.getDangerousZone.failure)
    ),
    takeEvery(
      getType(actions.updateDangerousZone.request),
      safe(updateDangerousZone, actions.updateDangerousZone.failure)
    )
  ]);
}
