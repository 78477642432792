import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import confirmDialogReducers from 'src/components/ConfrimDialog/store/reducers';
import sendEmailsReducers from 'src/components/SendEmailModalForm/store/reducers';
import loadingReducers from './reducers/loadingReducers';
import errorReducers from './reducers/errorReducers';
import snackbarReducers from './reducers/snackbarReducers';
import avlReducers from './reducers/avlReducers';
import assetReducers from './reducers/assetReducers';
import clearingReducers from './reducers/clearingReducers';
import mapReducers from './reducers/mapReducers';
import userReducers from './reducers/userReducers';
import formReducers from './reducers/formReducers';
import { administrationReducers } from './reducers/administrationReducers';

const rootReducer = {
  oidc: oidcReducer,
  loading: loadingReducers,
  error: errorReducers,
  snackbar: snackbarReducers,
  confirmDialog: confirmDialogReducers,
  sendEmails: sendEmailsReducers,
  map: mapReducers,
  user: userReducers,
  form: formReducers,
  ui: combineReducers({
    avl: avlReducers,
    asset: assetReducers,
    clearing: clearingReducers,
    administration: administrationReducers
  })
};

export default rootReducer;
