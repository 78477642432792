import { FC, useState, ReactFragment } from 'react';
import { Box, makeStyles, Theme, Tooltip } from '@material-ui/core';

interface Props {
  name: string | ReactFragment;
}

const useStyles = makeStyles(({ typography }: Theme) => ({
  menuOption: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  optionTooltip: {
    maxWidth: 500,
    fontSize: typography.fontSize
  }
}));

const EditableSelectItem: FC<Props> = ({ name }) => {
  const classes = useStyles();
  const [isOverflowing, toggleIsOverflowing] = useState(false);

  return (
    <Tooltip
      classes={{ tooltip: classes.optionTooltip }}
      title={isOverflowing ? name : ''}
    >
      <Box
        onMouseEnter={event => {
          const { scrollWidth, clientWidth } = event.currentTarget;
          toggleIsOverflowing(scrollWidth > clientWidth);
        }}
        className={classes.menuOption}
      >
        {name}
      </Box>
    </Tooltip>
  );
};

export default EditableSelectItem;
