import { lazy } from 'react';
import { generateActionID } from 'src/components/Guards';

export const COEFFICIENTS_ADJUSTMENTS_URL =
  '/clearing/coefficients-adjustments';
export const COEFFICIENTS_ADJUSTMENTS_CREATE_URL = `${COEFFICIENTS_ADJUSTMENTS_URL}/create`;
export const COEFFICIENTS_ADJUSTMENTS_DETAILS_URL = `${COEFFICIENTS_ADJUSTMENTS_URL}/:id/details`;
export const COEFFICIENTS_ADJUSTMENTS_EDIT_URL = `${COEFFICIENTS_ADJUSTMENTS_URL}/:id`;

const COEFFICIENTS_ADJUSTMENTS_ROUTES = [
  {
    exact: true,
    path: [COEFFICIENTS_ADJUSTMENTS_CREATE_URL],
    component: lazy(() =>
      import(
        'src/views/clearing/coefficientsAdjustments/CoefficientsAdjustmentsCreateView'
      )
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'coefficientsAdjustments',
      action: 'create'
    })
  },
  {
    exact: true,
    path: [COEFFICIENTS_ADJUSTMENTS_EDIT_URL],
    component: lazy(() =>
      import(
        'src/views/clearing/coefficientsAdjustments/CoefficientsAdjustmentsEditView'
      )
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'coefficientsAdjustments',
      action: 'update'
    })
  },
  {
    exact: true,
    path: [COEFFICIENTS_ADJUSTMENTS_URL],
    component: lazy(() =>
      import(
        'src/views/clearing/coefficientsAdjustments/CoefficientsAdjustmentsListView'
      )
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'coefficientsAdjustments',
      action: 'read'
    })
  },
  {
    exact: true,
    path: [COEFFICIENTS_ADJUSTMENTS_DETAILS_URL],
    component: lazy(() =>
      import(
        'src/views/clearing/coefficientsAdjustments/CoefficientsAdjustmentsDetailsView'
      )
    ),
    action: generateActionID({
      module: 'clearing',
      sub: 'coefficientsAdjustments',
      action: 'read'
    })
  }
];

export default COEFFICIENTS_ADJUSTMENTS_ROUTES;
