import { createAsyncAction } from 'typesafe-actions';
import { UninstallationPayload } from './types';

export const getUninstallation = createAsyncAction(
  '@UNINSTALLATION/GET_UNINSTALLATION_REQUEST',
  '@UNINSTALLATION/GET_UNINSTALLATION_SUCCESS',
  '@UNINSTALLATION/GET_UNINSTALLATION_FAILURE'
)<{ id: string }, UninstallationPayload, Error>();

export const getSelectedUninstallationsData = createAsyncAction(
  '@UNINSTALLATION/GET_SELECTED_UNINSTALLATIONS_REQUEST',
  '@UNINSTALLATION/GET_SELECTED_UNINSTALLATIONS_SUCCESS',
  '@UNINSTALLATION/GET_SELECTED_UNINSTALLATIONS_FAILURE'
)<{ id: string }[], UninstallationPayload[], Error>();
