import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { apiClient } from 'src/utils/api';
import * as actions from './actions';

export function* getAutocompleteOptions({
  config,
  payload = {}
}: ReturnType<typeof actions.getAutocompleteOptionsRequest>): SagaIterator {
  const { limit = 10, filter = '' } = payload;
  let response;

  yield put(actions.setLoading(config, true));

  if (!config.prohibitDynamicLoad) {
    response = yield call(
      apiClient.post,
      config.optionsApiUrl,
      { limit, filter },
      {
        retryAction: actions.getAutocompleteOptionsRequest(config, {
          limit,
          filter
        }),
        params: payload?.queryParams
      }
    );
  } else {
    response = yield call(apiClient.get, config.optionsApiUrl, {
      retryAction: actions.getAutocompleteOptionsRequest(config)
    });
  }

  yield put(actions.getAutocompleteOptionsSuccess(config, response.data));
  yield put(actions.setLoading(config, false));
}

export default function* AutocompleteSagas(): SagaIterator {
  yield takeEvery(
    getType(actions.getAutocompleteOptionsRequest),
    safe(getAutocompleteOptions, actions.getAutocompleteOptionsFailure)
  );
}
