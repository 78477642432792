import { createCustomAction } from 'typesafe-actions';
import {
  CounteragentResponse,
  VehicleModelResponse,
  InitialState,
  InboundStepConfig,
  InboundDataPayload
} from './types';

export const getCounteragentRequest = createCustomAction(
  '@INSTALLATION_STEP/GET_COUNTERAGENT_REQUEST',
  (config: InboundStepConfig, payload: number) => ({
    config,
    payload
  })
);

export const getCounteragentSuccess = createCustomAction(
  '@INSTALLATION_STEP/GET_COUNTERAGENT_SUCCESS',
  (config: InboundStepConfig, payload: CounteragentResponse) => ({
    config,
    payload
  })
);

export const getCounteragentFailure = createCustomAction(
  '@INSTALLATION_STEP/GET_COUNTERAGENT_FAILURE',
  (config: InboundStepConfig, payload: Error) => ({
    config,
    payload
  })
);

export const getVehicleModelRequest = createCustomAction(
  '@INSTALLATION_STEP/GET_VEHICLE_MODEL_REQUEST',
  (config: InboundStepConfig, payload: number) => ({
    config,
    payload
  })
);

export const getVehicleModelSuccess = createCustomAction(
  '@INSTALLATION_STEP/GET_VEHICLE_MODEL_SUCCESS',
  (config: InboundStepConfig, payload: VehicleModelResponse) => ({
    config,
    payload
  })
);

export const getVehicleModelFailure = createCustomAction(
  '@INSTALLATION_STEP/GET_VEHICLE_MODEL_FAILURE',
  (config: InboundStepConfig, payload: Error) => ({
    config,
    payload
  })
);

export const getInboundDataRequest = createCustomAction(
  '@INBOUND_PAGE/GET_DATA_REQUEST',
  (config: InboundStepConfig) => ({ config })
);

export const getInboundDataSuccess = createCustomAction(
  '@INBOUND_PAGE/GET_DATA_SUCCESS',
  (config: InboundStepConfig, payload: InboundDataPayload) => ({
    config,
    payload
  })
);

export const getInboundDataFailure = createCustomAction(
  '@INBOUND_PAGE/GET_DATA_FAILURE',
  (config: InboundStepConfig, payload: Error) => ({ config, payload })
);

export const setFormValues = createCustomAction(
  '@INSTALLATION_STEP/SET_FORM_VALUES',
  (config: InboundStepConfig, values: InitialState) => ({
    config,
    values
  })
);
