export interface LogsTableRow {
  id: string;
  modifiedAtUtc: string;
  email: string;
  action: string;
  schema: string;
  name: string;
  diff: Diff[];
}

export interface Diff {
  id: string;
  name: string;
  action: string;
  oldValues: ColumnValues;
  newValues: ColumnValues;
}

export interface Total {
  value: number;
}

export interface ColumnValues {
  createdAtUtc: string;
  createdBy: string;
  deletedAtUtc?: string;
  modifiedAtUtc: string;
  modifiedBy: string;
  [k: string]: unknown;
}

export interface Change {
  columnName: string;
  originalValue: unknown;
  newValue: unknown;
}

export enum Action {
  Insert = 'Insert',
  Update = 'Update',
  Delete = 'Delete'
}

export enum Schema {
  AvlDbContext = 'AvlDbContext',
  AssetManagementDBContext = 'AssetManagementDBContext',
  ClearingDBContext = 'ClearingDBContext'
}

export interface Entry {
  schema: Schema;
  name: string;
  action: Action;
  columnValues: ColumnValues;
  changes: Change[];
}

export interface EntityFrameworkEvent {
  entries: Entry[];
}

export interface Source {
  entityFrameworkEvent: EntityFrameworkEvent;
  Email: string;
  startDate: string;
  eventType: Schema;
}

export interface Hit {
  _source: Source;
}

export interface Hits {
  total: Total;
  hits: Hit[];
}

export interface UsersLogs {
  hits: Hits;
}

export interface Column {
  value: keyof LogsTableRow;
  sorterKey: string;
  width: string;
  label: string;
}

export interface Sorter {
  value: string;
  order: 'asc' | 'desc';
}

export interface IFilters {
  schema: string;
  search: string;
}

export interface TableOptions {
  page: number;
  pageSize: number;
  rowCount: number;
  sorter: Sorter | null;
  filters: IFilters;
}

export interface TableState extends TableOptions {
  list: Hit[];
}
