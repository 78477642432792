import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { date, object } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import {
  BILLING_DOCUMENTS_DETAILS_CARRIER,
  BILLING_DOCUMENTS_DETAILS_ROUTE,
  BILLING_DOCUMENTS_DETAILS_VEHICLE,
  BILLING_DOCUMENTS_AUTOCOMPLETE
} from 'src/config/clearingUrls';
import { TableConfig } from 'src/components/Tables/TableWithPagination/types';
import moment from 'moment';
import { Row } from 'src/components/Tables/types';
import { convertUtcToLocal } from 'src/utils/date';
import { DATE_FORMAT_SLASH } from 'src/config';
import BillingDetailsLink from './components/BillingDetailsLink';
import {
  BillingDetailsColumn,
  BillingDetailsColumnGroup,
  BillingDetailsLevels
} from '../types';
import { getLevelLink } from './utils';

export const BILLING_DETAILS_REDUCER_NAME = 'BILLING_DETAILS';
export const BILLING_DETAILS_REDUCER_PATH =
  'clearing.billingDocuments.detailsTable';

const BILLING_DETAILS_CARRIER_TABLE_CONFIG: TableConfig = {
  reducerPath: BILLING_DETAILS_REDUCER_PATH,
  reducerName: BILLING_DETAILS_REDUCER_NAME,
  apiUrl: BILLING_DOCUMENTS_DETAILS_CARRIER,
  routeUrl: '',
  toolbarActions: {},
  isRowClickEnabled: false
};

const BILLING_DETAILS_ROUTE_TABLE_CONFIG: TableConfig = {
  ...BILLING_DETAILS_CARRIER_TABLE_CONFIG,
  apiUrl: BILLING_DOCUMENTS_DETAILS_ROUTE
};

const BILLING_DETAILS_VEHICLE_TABLE_CONFIG: TableConfig = {
  ...BILLING_DETAILS_CARRIER_TABLE_CONFIG,
  apiUrl: BILLING_DOCUMENTS_DETAILS_VEHICLE
};

export const tableConfigs: Record<BillingDetailsLevels, TableConfig> = {
  [BillingDetailsLevels.Carrier]: BILLING_DETAILS_CARRIER_TABLE_CONFIG,
  [BillingDetailsLevels.Routes]: BILLING_DETAILS_ROUTE_TABLE_CONFIG,
  [BillingDetailsLevels.Vehicles]: BILLING_DETAILS_VEHICLE_TABLE_CONFIG
};

export const validationSchema = object().shape({
  documentDateFrom: date()
    .nullable()
    .typeError(ERROR_MESSAGES.TYPE_ERROR),
  documentDateTo: date()
    .nullable()
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .test({
      name: 'documentDateTo',
      message: ERROR_MESSAGES.TYPE_ERROR,
      test(value) {
        const { documentDateFrom } = this.parent;

        if (
          !documentDateFrom ||
          moment(value).isSame(documentDateFrom, 'day')
        ) {
          return true;
        }

        return !moment(value).isBefore(documentDateFrom);
      }
    })
});

export const BILLING_DOCUMENT_NAME = 'BILLING_DOCUMENT_NAME';
export const BILLING_DOCUMENT_PATH =
  'clearing.billingDocuments.billingDocumentAutocomplete';

export const BILLING_DOCUMENT_CONFIG = {
  optionsApiUrl: BILLING_DOCUMENTS_AUTOCOMPLETE,
  reducerName: BILLING_DOCUMENT_NAME,
  reducerPath: BILLING_DOCUMENT_PATH
};

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.clearing,
    url: '/clearing'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.billingDetails,
    url: getLevelLink(BillingDetailsLevels.Carrier)
  }
];

export const routesPageBreadcrumbs = [
  ...pageBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.billingDetailsRoutes,
    url: getLevelLink(BillingDetailsLevels.Routes)
  }
];

export const vehiclesPageBreadcrumbs = [
  ...routesPageBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.vehicles,
    url: getLevelLink(BillingDetailsLevels.Vehicles)
  }
];

export const carrierColumns: (
  | BillingDetailsColumn
  | BillingDetailsColumnGroup
)[] = [
  {
    value: 'documentDate',
    label: 'Дата документа',
    sortable: true,
    width: '176px',
    changeValue: (row: Row) =>
      convertUtcToLocal(row.documentDate, DATE_FORMAT_SLASH)
  },
  {
    value: 'documentName',
    label: 'Назва документа',
    sortable: true,
    width: '168px'
  },
  {
    value: 'counteragentName',
    label: 'Перевізник',
    sortable: true,
    width: '120px',
    changeValue: row => {
      return (
        <BillingDetailsLink label={row.counteragentName} id={row.documentId} />
      );
    }
  },
  {
    value: 'plannedDistanceByTrack',
    label: 'План, км',
    sortable: true,
    width: '75px'
  },
  {
    value: 'tariffAmountPerKm',
    label: 'Тариф, грн/км',
    sortable: true,
    width: '115px'
  },
  {
    label: 'Фактичний пробіг, км',
    value: [
      {
        value: 'distanceByRoute',
        label: 'По маршруту',
        sortable: true,
        width: '150px',
        isFirstCell: true
      },
      {
        value: 'distanceInSpecialMode',
        label: 'В спецрежимі',
        sortable: true,
        width: '164px'
      },
      {
        value: 'distanceFromDepot',
        label: 'З депо',
        sortable: true,
        width: '105px'
      },
      {
        value: 'distanceToDepot',
        label: 'До депо',
        sortable: true,
        width: '118px'
      },
      {
        value: 'totalDistance',
        label: 'Сумарний',
        sortable: true,
        width: '72px',
        isLastCell: true
      }
    ]
  },
  {
    label: 'Коефіцієнти',
    value: [
      {
        value: 'tenderCoefficient',
        label: 'Тендерний',
        sortable: true,
        isFirstCell: true
      },
      {
        value: 'complexityCoefficient',
        label: 'Складність',
        sortable: true
      },
      {
        value: 'specialModeCoefficient',
        label: 'Спецрежим',
        sortable: true
      },
      {
        value: 'timeCoefficient',
        label: 'Часовий',
        sortable: true,
        isLastCell: true
      }
    ]
  },
  {
    value: 'billingBaseAmount',
    label: 'База нарахування, грн',
    sortable: true,
    width: '166px',
    changeValue: row => <b>{row.billingBaseAmount}</b>
  },
  {
    label: 'Нарахування по КПЕ',
    value: [
      {
        value: 'graphicityRatio',
        label: 'Графічність, %',
        sortable: true,
        isFirstCell: true
      },
      {
        value: 'graphicityAmount',
        label: 'Нарахування графічності, грн',
        sortable: true,
        width: '166px'
      },
      {
        value: 'completenessRatio',
        label: 'Виконання рейсу, %',
        sortable: true
      },
      {
        value: 'completenessAmount',
        label: 'Виконання рейсу, грн',
        sortable: true
      },
      {
        value: 'totalObjectiveKpiAmount',
        label: "Сума по об'єктивних КПЕ, грн",
        sortable: true,
        width: '198px'
      },
      {
        value: 'totalSubjectiveKpiAmount',
        label: "Сума по суб'єктивних КПЕ, грн",
        sortable: true,
        width: '205px',
        isLastCell: true
      }
    ]
  },
  {
    value: 'penaltyAmount',
    label: 'Штрафи, грн',
    sortable: true
  },
  {
    value: 'totalAmount',
    label: 'Загальна сума, грн',
    sortable: true,
    changeValue: row => <b>{row.totalAmount}</b>
  }
];

const routeColumn: BillingDetailsColumn = {
  value: 'routePassportName',
  label: 'Маршрути',
  sortable: true,
  width: '120px',
  changeValue: row => {
    return (
      <BillingDetailsLink
        label={row.routePassportName}
        id={row.documentId}
        routeId={row.routePassportId}
      />
    );
  }
};

const vehicleColumn: BillingDetailsColumn = {
  value: 'vehicleLicensePlate',
  label: 'Транспортні засоби',
  sortable: true,
  width: '120px',
  changeValue: row => <span>{row.licensePlate}</span>
};

const routeColumns = [
  ...carrierColumns.slice(0, 2),
  routeColumn,
  ...carrierColumns.slice(3)
];

const vehicleColumns = [
  ...carrierColumns.slice(0, 2),
  {
    value: 'routePassportName',
    label: 'Маршрут',
    sortable: true,
    width: '120px'
  },
  {
    value: 'routeRideScheduleDate',
    label: 'Дата',
    sortable: true,
    width: '120px'
  },
  vehicleColumn,
  {
    value: 'iterationsCount',
    label: 'Кількість кіл',
    sortable: true,
    width: '120px'
  },
  ...carrierColumns.slice(3)
];

export const billingDetailsColumns: Record<
  BillingDetailsLevels,
  (BillingDetailsColumn | BillingDetailsColumnGroup)[]
> = {
  [BillingDetailsLevels.Carrier]: carrierColumns,
  [BillingDetailsLevels.Routes]: routeColumns,
  [BillingDetailsLevels.Vehicles]: vehicleColumns
};

interface BillingDetailsPageTitle {
  title: string;
  breadcrumbs: typeof listBreadcrumbs;
}

export const billingDetailsPageTitles: Record<
  BillingDetailsLevels,
  BillingDetailsPageTitle
> = {
  [BillingDetailsLevels.Carrier]: {
    title: 'Деталі нарахувань за документами',
    breadcrumbs: pageBreadcrumbs
  },
  [BillingDetailsLevels.Routes]: {
    title: 'Деталі нарахувань за документами',
    breadcrumbs: routesPageBreadcrumbs
  },
  [BillingDetailsLevels.Vehicles]: {
    title: 'Деталі нарахувань за документами',
    breadcrumbs: vehiclesPageBreadcrumbs
  }
};
