import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { FC } from 'react';
import { Row } from 'src/components/Tables/types';
import Label from 'src/components/Labels/Label';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  typography: {
    margin: spacing(2)
  }
}));

interface Props {
  row: Row;
}

const AllCounteragentsColumn: FC<Props> = ({ row }) => {
  const classes = useStyles();
  const color = 'success';

  return row.hasAccessToAllCounteragents ? (
    <Box className={classes.root}>
      <Label color={color}>ВСІ</Label>
    </Box>
  ) : (
    <Box className={classes.root}>
      <Typography className={classes.typography}>-</Typography>
    </Box>
  );
};

export default AllCounteragentsColumn;
