import { array, ArraySchema, boolean, date, number, object, string } from 'yup';
import { AVL_ERROR_MESSAGES, ERROR_MESSAGES } from 'src/constants/validation';
import { maxLength, valueFromTo } from 'src/utils/validation';
import { COUNTERAGENTS, ROUTE_PASSPORT } from 'src/config/avlUrls';
import { getTomorrowsUtcDate } from 'src/utils/date';
import moment from 'moment';
import { ReactNode } from 'react';
import { DEPARTURES_ERRORS } from 'src/views/avl/Routes/RoutesPassportLayout/Steps/Timetable/Departures/DeparturesForm/const';
import { CalendarException, CalendarSetting } from './types';
import { crudActions, RoutePassportStatus } from '../../../const';
import { RouteSchemeStatus } from '../RouteScheme/types';

export const distanceRangeErrorMessage = valueFromTo(0, 1000);

const distanceValidationSchema = string()
  .required(ERROR_MESSAGES.REQUIRED)
  .test({
    test: value => /^[\d]{1,3}([.][\d]{1,2})?$/.test(String(value)),
    message:
      'Значення не повинно містити більше 5 цифр, з урахуванням 2 знаків після крапки, наприклад - 100.50'
  })
  .nullable();

const dateSchema = date().typeError(ERROR_MESSAGES.TYPE_ERROR);

export const timetableFormValidationSchema = object().shape({
  routeSchemaId: number().required(ERROR_MESSAGES.REQUIRED),
  name: string()
    .max(...maxLength(100))
    .when('status', {
      is: RoutePassportStatus.Approved,
      then: string().required(ERROR_MESSAGES.REQUIRED)
    }),
  counteragentId: number().nullable(),
  status: string(),
  startsAt: dateSchema
    .nullable()
    .when(['$initialStartsAt'], {
      is: value => !!value,
      then: dateSchema.test({
        test(value) {
          // @ts-ignore
          let startsAt = this.options.context.initialStartsAt;
          const tomorrow = getTomorrowsUtcDate();

          if (startsAt < tomorrow) {
            startsAt = tomorrow;
          }

          const prevStartsAt = moment(startsAt).set({ hour: 0 });

          return !moment(value).isBefore(prevStartsAt);
        },
        message: ERROR_MESSAGES.MIN_DATE_TOMORROW
      }),
      otherwise: dateSchema.min(
        getTomorrowsUtcDate(),
        ERROR_MESSAGES.MIN_DATE_TOMORROW
      )
    })
    .when('status', {
      is: RoutePassportStatus.Approved,
      then: date()
        .typeError(ERROR_MESSAGES.TYPE_ERROR)
        .required(ERROR_MESSAGES.REQUIRED)
    }),
  endsOn: date()
    .nullable()
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .test({
      name: 'endsOn',
      message: ERROR_MESSAGES.TYPE_ERROR,
      test(value) {
        const { startsAt } = this.parent;
        if (value) {
          return (
            !moment(value).isBefore(startsAt) &&
            !moment(value).isSame(startsAt, 'day')
          );
        }
        return true;
      }
    }),
  calendarSettings: array()
    .of(
      object({
        months: array().of(string()),
        daysOfWeek: array().of(string())
      })
    )
    .when(
      ['status', 'calendarExceptions'],
      (
        status: RoutePassportStatus,
        calendarExceptions: CalendarException[],
        schema: ArraySchema<CalendarSetting[]>
      ) => {
        if (
          status === RoutePassportStatus.Approved &&
          !calendarExceptions.some(({ isActive }) => isActive)
        ) {
          return schema.min(1, 'minLength');
        }
        return schema;
      }
    ),
  calendarExceptions: array().of(
    object({
      date: string(),
      isActive: boolean()
    })
  ),
  distanceFromDepotInKm: distanceValidationSchema,
  distanceToDepotInKm: distanceValidationSchema
});

export const APPROVED_SCHEMES_SELECT_NAME = 'APPROVED_SCHEMES_SELECT';
export const APPROVED_SCHEMES_SELECT_PATH =
  'avl.routes.timetable.approvedSchemesSelect';
export const APPROVED_SCHEMES_SELECT_CONFIG = {
  reducerPath: APPROVED_SCHEMES_SELECT_PATH,
  reducerName: APPROVED_SCHEMES_SELECT_NAME,
  optionsApiUrl: ROUTE_PASSPORT
};

export const DEPOTS_SELECT_NAME = 'DEPOTS_SELECT';
export const DEPOTS_SELECT_PATH = 'avl.routes.timetable.depotsSelect';
export const getDepotsConfig = (passportId?: string) => ({
  reducerPath: DEPOTS_SELECT_PATH,
  reducerName: DEPOTS_SELECT_NAME,
  optionsApiUrl: `${COUNTERAGENTS}?counterAgentTypeId=4&routePassportId=${passportId}`
});

export const daysOptions = [
  { value: 'Monday', label: 'Понеділок' },
  { value: 'Tuesday', label: 'Вівторок' },
  { value: 'Wednesday', label: 'Середа' },
  { value: 'Thursday', label: 'Четвер' },
  { value: 'Friday', label: "П'ятниця" },
  { value: 'Saturday', label: 'Субота' },
  { value: 'Sunday', label: 'Неділя' }
];

export const monthsOptions = [
  { value: 'January', label: 'Січень' },
  { value: 'February', label: 'Лютий' },
  { value: 'March', label: 'Березень' },
  { value: 'April', label: 'Квітень' },
  { value: 'May', label: 'Травень' },
  { value: 'June', label: 'Червень' },
  { value: 'July', label: 'Липень' },
  { value: 'August', label: 'Серпень' },
  { value: 'September', label: 'Вересень' },
  { value: 'October', label: 'Жовтень' },
  { value: 'November', label: 'Листопад' },
  { value: 'December', label: 'Грудень' }
];

export const daysList: { [key: string]: string } = {
  Monday: 'ПН',
  Tuesday: 'ВТ',
  Wednesday: 'СР',
  Thursday: 'ЧТ',
  Friday: 'ПТ',
  Saturday: 'СБ',
  Sunday: 'НД'
};

export const monthsList: { [key: string]: string } = {
  January: 'Січень',
  February: 'Лютий',
  March: 'Березень',
  April: 'Квітень',
  May: 'Травень',
  June: 'Червень',
  July: 'Липень',
  August: 'Серпень',
  September: 'Вересень',
  October: 'Жовтень',
  November: 'Листопад',
  December: 'Грудень'
};

export const calendarInitialValues: CalendarSetting = {
  months: [],
  daysOfWeek: []
};
export const renderItems = (data: { [key: string]: string }) => (
  value: unknown
): ReactNode =>
  (value as string[]).length
    ? (value as string[]).map(item => data[item] || '').join(', ')
    : 'Не вибрано';

export const formatCalendarItem = (calendar: CalendarSetting) => {
  const monthsItems = calendar.months
    .map(month => monthsList[month].slice(0, 3))
    .join(', ');
  const daysItems = calendar.daysOfWeek.map(day => daysList[day]).join(', ');
  return `${monthsItems}: ${daysItems}`;
};

export const calendarValidationSchema = object({
  months: array()
    .of(string())
    .min(1, ERROR_MESSAGES.REQUIRED),
  daysOfWeek: array()
    .of(string())
    .min(1, ERROR_MESSAGES.REQUIRED)
});

export const DEPARTURES_TABLE_NAME = 'DEPARTURES_TABLE';
export const DEPARTURES_TABLE_PATH = 'avl.routes.departures.table';

export const DEPARTURE_TABLE_CONFIG = {
  reducerName: DEPARTURES_TABLE_NAME,
  reducerPath: DEPARTURES_TABLE_PATH,
  snackbarMessages: {
    deleteRowSuccess: 'Випуск успішно видалено'
  }
};

export const departureColumns = [
  {
    label: 'Час роботи випуску',
    value: 'workTime',
    sortable: false,
    width: '20%'
  }
];

export const getDetailsActions = (
  canDelete: boolean,
  onDownload: () => void,
  status?: string
) => ({
  edit: {
    action: crudActions.update
  },
  download: {
    isDisabled: status === RouteSchemeStatus.Draft,
    action: crudActions.download,
    onClick: onDownload
  },
  delete: {
    isDisabled: !canDelete,
    action: crudActions.delete
  }
});

export const TIMETABLE_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  forbidden: 'Схему змінено. Перезатвердіть її та створіть новий роклад'
};

export const snackbarMessages = {
  ...DEPARTURES_ERRORS,
  notEmptyValidator: 'Потрібно створити випуск та налаштувати їздки',
  greaterThanValidator: 'Потрібно створити випуск та налаштувати їздки',
  conflict: 'Накладаються розклади руху'
};
