import { Check, Minus } from 'react-feather';
import { Box, colors } from '@material-ui/core';
import TextWithIcon from 'src/components/TextWithIcon';

interface CheckMinusProps {
  checked: boolean;
  role?: string;
  title?: string;
}

const CheckMinusIcon = ({ checked, role, title }: CheckMinusProps) => {
  return (
    <Box display="flex">
      <TextWithIcon
        destination="#"
        disabled={true}
        title={title}
        icon={
          checked ? (
            <Check
              data-testid="check-icon"
              size={18}
              role={role}
              color={colors.green[700]}
            />
          ) : (
            <Minus
              data-testid="minus-icon"
              size={18}
              role={role}
              color={colors.grey[700]}
            />
          )
        }
      />
    </Box>
  );
};
export default CheckMinusIcon;
