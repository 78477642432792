import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { safe } from 'src/utils/sagas';
import { VEHICLES } from 'src/config/avlUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getVehicle(): SagaIterator {
  const id = yield select(selectors.selectVehicleId);

  const { data } = yield call(apiClient.get, `${VEHICLES}/${id}/edit`, {
    retryAction: actions.getVehicle.request()
  });

  yield put(actions.getVehicle.success(data));
}

export function* updateVehicle({
  payload
}: ReturnType<typeof actions.updateVehicle.request>): SagaIterator {
  const id = yield select(selectors.selectVehicleId);

  yield call(apiClient.put, `${VEHICLES}/${id}`, payload, {
    retryAction: actions.updateVehicle.request(payload)
  });
  yield put(navigateTo({ url: '/avl/vehicles/' }));
  yield put(
    enqueueSnackbar({
      key: 'vehicles_edit',
      message: 'Транспортний Засіб відредаговано',
      options: { variant: 'success' }
    } as UINotification)
  );
  yield put(actions.updateVehicle.success());
}

export default function* vehicleEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getVehicle.request),
      safe(getVehicle, actions.getVehicle.failure)
    ),
    takeEvery(
      getType(actions.updateVehicle.request),
      safe(updateVehicle, actions.updateVehicle.failure)
    )
  ]);
}
