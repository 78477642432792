import { WorkOfCarrierReport } from '../types';

export const workOfCarrierReportMock: WorkOfCarrierReport = {
  counteragents: [
    {
      counteragentId: 1,
      counteragentName: 'name',
      routes: [
        {
          routeName: 'Назва маршруту',
          releaseRatio: 10,
          presenceRatio: 10,
          completedIterationsRatio: 10,
          graphicityRatio: 10
        },
        {
          routeName: 'Назва маршруту',
          releaseRatio: 10,
          presenceRatio: 10,
          completedIterationsRatio: 10,
          graphicityRatio: 10
        },
        {
          routeName: 'Назва маршруту',
          releaseRatio: 10,
          presenceRatio: 10,
          completedIterationsRatio: 10,
          graphicityRatio: 10
        }
      ],
      totalReleaseRatio: 10,
      totalPresenceRatio: 10,
      totalCompletedIterationsRatio: 10,
      totalGraphicityRatio: 10
    }
  ]
};
