import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { createSelector } from 'reselect';
import { COEFFICIENTS_ADJUSTMENTS_EDIT_URL } from 'src/routes/coefficientsAdjustmentsRoutes';
import { RootState } from 'typesafe-actions';
import { isPastDate, isTodayDate } from 'src/utils/date';
import { coefficientsGroupInitialValues } from '../../const';
import { CoefficientsGroup } from '../../types';

const matchSpaceParams = createMatchSelector<RouterRootState, { id: string }>(
  COEFFICIENTS_ADJUSTMENTS_EDIT_URL
);

export const selectCoefficientsGroupId = createSelector(
  matchSpaceParams,
  match => match?.params.id ?? ''
);

export const selectCoefficientsGroup = (
  state: RootState
): CoefficientsGroup => {
  const data = state.ui.clearing.coefficientsAdjustments.edit.details;

  return data
    ? {
        ...data,
        timeCoefficients: data.timeCoefficients.map(coefficient => ({
          ...coefficient,
          isStartDatePast:
            isPastDate(coefficient.startDate) ||
            isTodayDate(coefficient.startDate),
          isEndDatePast:
            isPastDate(coefficient.endDate) || isTodayDate(coefficient.endDate)
        }))
      }
    : coefficientsGroupInitialValues;
};
