import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';

type StopEditActions = ActionType<typeof actions>;

const stopEditReducers = createReducer<any, StopEditActions>({}).handleAction(
  actions.getStop.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default stopEditReducers;
