import { MAIN_MENU } from 'src/constants';
import { ADMINISTRATION_PATH } from 'src/routes/administrationRoutes';
import { object, string, array } from 'yup';
import Cron from 'cron-converter';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { range } from 'lodash';
import { generateActionID } from 'src/components/Guards';
import { PaymentsSettingsValues } from './types';

export const breadcrumbs = [
  {
    text: MAIN_MENU.administration,
    url: `/${ADMINISTRATION_PATH}`
  }
];

export const formId = 'payments-settings-form';

const cron = new Cron();

export const validationSchema = object<PaymentsSettingsValues>({
  billingFrequencySchedulerCron: string()
    .required(ERROR_MESSAGES.REQUIRED)
    .test({
      message:
        'Немає наступних дат. Перевірте правильність вибору дат для вибраних місяців.',
      test: value => {
        const schedule = cron.fromString(value).schedule();
        try {
          return Boolean(schedule.next());
        } catch {
          return false;
        }
      }
    }),
  billingEmails: array().of(string())
});

// default time for billing scheduler should be 09:00
const hoursOptions = [9];
const minutesOptions = [0];

export const daysOptions = range(1, 32);

export const monthOptions = range(1, 13);

export const weekdayOptions = range(1, 7);
// 0 represents Sunday weekday, it needs to be last
weekdayOptions.push(0);

const defaultBillingFrequencyScheduleValue: Cron.CronArray = [
  minutesOptions,
  hoursOptions,
  [daysOptions[0]],
  monthOptions,
  weekdayOptions
];

export const defaultBillingFrequencyScheduleCron = cron
  .fromArray(defaultBillingFrequencyScheduleValue)
  .toString();

export const updateAction = generateActionID({
  module: 'administration',
  sub: 'clearingSettings',
  action: 'update'
});
