import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { PENALTIES_DOCUMENT } from 'src/config/clearingUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { createPenaltyDocument } from './actions';

export function* penaltyDocumentCreate({
  payload
}: ReturnType<typeof createPenaltyDocument.request>): SagaIterator {
  yield call(apiClient.post, PENALTIES_DOCUMENT, payload, {
    retryAction: createPenaltyDocument.request(payload)
  });
  yield put(navigateTo({ url: '/clearing/penalty-documents' }));
  yield put(
    enqueueSnackbar({
      key: 'penalty_document_create',
      message: 'Штраф створено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(createPenaltyDocument.success());
}

export default function* PenaltyDocumentCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(createPenaltyDocument.request),
    safe(penaltyDocumentCreate, createPenaltyDocument.failure)
  );
}
