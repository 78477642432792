import { createAsyncAction } from 'typesafe-actions';
import { InboundStatusEnum } from '../../const';
import { InboundDetailsState } from '../../InboundDetailsView/types';

export interface Payload {
  id: number;
  status: InboundStatusEnum;
}

export const getInboundRowData = createAsyncAction(
  '@INBOUNDS_SEND_EMAIL/GET_INBOUND_SEND_EMAIL_REQUEST',
  '@INBOUNDS_SEND_EMAIL/GET_INBOUND_SEND_EMAIL_SUCCESS',
  '@INBOUNDS_SEND_EMAIL/GET_INBOUND_SEND_EMAIL_FAILURE'
)<{ id: string }, InboundDetailsState, Error>();

export const getSelectedInboundsData = createAsyncAction(
  '@INBOUNDS_SEND_EMAIL/GET_SELECTED_INBOUNDS_REQUEST',
  '@INBOUNDS_SEND_EMAIL/GET_SELECTED_INBOUNDS_SUCCESS',
  '@INBOUNDS_SEND_EMAIL/GET_SELECTED_INBOUNDS_FAILURE'
)<{ id: string }[], InboundDetailsState[], Error>();

export const updateInboundStatus = createAsyncAction(
  '@INBOUND/UPDATE_INBOUND_STATUS_REQUEST',
  '@INBOUND/UPDATE_INBOUND_STATUS_SUCCESS',
  '@INBOUND/UPDATE_INBOUND_STATUS_FAILURE'
)<Payload, undefined, Error>();
