import moment from 'moment';
import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { TRANSFERS } from 'src/config/assetUrls';
import { addRowsLocally } from 'src/components/Tables/TableView/store/actions';
import { DATEPICKER_DATE_FORMAT } from 'src/config';
import * as actions from './actions';
import * as selectors from './selectors';
import {
  EDIT_TRANSFER_FORM_CONFIG,
  EDIT_TRANSFERING_TABLE_CONFIG
} from '../const';
import { normalizeEquipmentRequest, normalizeFormResponse } from './utils';
import { setFormValues } from '../../TransferStep/store/actions';
import { selectNormalizedList } from '../../TransferEquipmentsStep/store/selectors';
import { selectFormCreateData } from '../../TransferStep/store/selectors';
import { getTransfer } from '../../TransferDetailsView/store/actions';

export function* updateTransfer({
  payload
}: ReturnType<typeof actions.updateTransfer.request>): SagaIterator {
  const { equipmentPath, formPath, id } = payload;

  const [formData, equipment] = yield all([
    select(selectFormCreateData(formPath)),
    select(selectNormalizedList(equipmentPath))
  ]);

  yield call(
    apiClient.put,
    `${TRANSFERS}/${id}`,
    {
      ...formData,
      equipment,
      status: StatusEnum.Draft
    },
    {
      retryAction: actions.updateTransfer.request(payload)
    }
  );

  yield put(actions.updateTransfer.success());
}

export function* getTransferData(): SagaIterator {
  const id = yield select(selectors.selectTransferId);

  const { data } = yield call(apiClient.get, `${TRANSFERS}/${id}`, {
    retryAction: actions.getTransferData.request()
  });
  const { equipment, ...formValues } = data;

  yield put(getTransfer.success(data));

  yield put(
    addRowsLocally(EDIT_TRANSFERING_TABLE_CONFIG(id), {
      rows: normalizeEquipmentRequest(equipment)
    })
  );

  yield put(
    setFormValues(
      EDIT_TRANSFER_FORM_CONFIG,
      normalizeFormResponse({
        ...formValues,
        dateTransfer: moment().format(DATEPICKER_DATE_FORMAT)
      })
    )
  );
  yield put(actions.getTransferData.success());
}

export default function* TransferEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.updateTransfer.request),
      safe(updateTransfer, actions.updateTransfer.failure)
    ),
    takeEvery(
      getType(actions.getTransferData.request),
      safe(getTransferData, actions.getTransferData.failure)
    )
  ]);
}
