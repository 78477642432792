import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';

const matchSpaceParams = createMatchSelector(
  '/avl/vehicles-models/:id/details'
);

export const selectVehicleModelId = createSelector(
  matchSpaceParams,
  (match: any) => match?.params.id || ''
);

export const selectIsLoadingVehicleModel = (state: RootState) => {
  const loading = get(
    state,
    `loading.@VEHICLE_MODELS_DETAILS/GET_VEHICLE_MODELS`
  );
  return isBoolean(loading) ? loading : true;
};

export const selectShortName = (state: RootState): string =>
  get(state, `ui.avl.vehiclesModels.details.name`);

export const selectVehicleModelRows = (state: RootState) => {
  const {
    name,
    vehicleCategory,
    tariffGroupName,
    numberOfSeats,
    numberOfStandPlaces,
    code,
    equipment,
    numberOfDisabilitySeats,
    notes
  } = get(state, `ui.avl.vehiclesModels.details`);

  return [
    { label: 'Назва моделі', value: name },
    { label: 'Тип ТЗ', value: vehicleCategory },
    { label: 'Тарифна група', value: tariffGroupName },
    { label: 'К-ть місць для сидіння', value: numberOfSeats },
    {
      label: 'К-ть стоячих місць',
      value: numberOfStandPlaces
    },
    {
      label: 'К-ть місць для людей з інвалідністю',
      value: numberOfDisabilitySeats
    },
    { label: 'Додаткове обладнання', value: equipment },
    { label: 'Код', value: code },
    { label: 'Примітка', value: notes }
  ];
};

export const selectDeleteDisable = (state: RootState) =>
  get(state, 'ui.avl.vehiclesModels.details.isUsed');
