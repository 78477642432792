import {
  changeStatusColumn,
  formatDate
} from 'src/components/Tables/TableWithPagination/utils';
import {
  INBOUND_NOTES_AUTOCOMPLETE,
  INBOUNDS,
  RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE
} from 'src/config/assetUrls';
import { Row } from 'src/components/Tables/types';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import { STATUS_CONFIG } from 'src/constants';
import TextWithEllipsis from 'src/components/TextWithEllipsis';
import InboundSwitch from '../InboundSwitch';
import { InboundStatusEnum } from '../const';

export const INBOUND_TABLE_PATH = 'asset.inbound.table';
export const INBOUND_TABLE_NAME = 'INBOUND';

export const TABLE_CONFIG_BASE = {
  reducerPath: INBOUND_TABLE_PATH,
  reducerName: INBOUND_TABLE_NAME,
  apiUrl: INBOUNDS
};

export const getColumns = (
  isAllowed: boolean,
  onChangeStatus: (row: Row) => Promise<void>
) => [
  {
    label: 'Номер документа',
    value: 'documentNumber',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Дата надходження',
    value: 'dateInbound',
    sortable: true,
    width: '10%',
    formatValue: (row: Row) => formatDate(row.dateInbound)
  },
  {
    label: 'Підрозділ',
    value: 'counteragent',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Кількість обладнання',
    value: 'inboundEquipment',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Хто прийняв',
    value: 'acceptingPerson',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Статус документа',
    value: 'status',
    sortable: true,
    width: '10%',
    changeValue: changeStatusColumn,
    formatValue: (row: Row) => STATUS_CONFIG[row.status.toLowerCase()].label
  },
  {
    label: '',
    value: '',
    sortable: false,
    changeValue: (row: Row) =>
      isAllowed ? <InboundSwitch data={row} onChange={onChangeStatus} /> : null,
    width: '10%'
  },
  {
    label: 'Примітка',
    value: 'notes',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => <TextWithEllipsis text={row.notes} />
  }
];

export const INBOUND_NOTES_AUTOCOMPLETE_NAME = 'INBOUND_NOTES_AUTOCOMPLETE';

export const INBOUND_RESPONSIBLE_PERSON_AUTOCOMPLETE =
  'INBOUND_RESPONSIBLE_PERSON_AUTOCOMPLETE';

export const filters = [
  {
    type: FilterType.Date,
    name: 'dateInbound',
    label: 'Дата',
    datePickerProps: {
      disableFuture: true
    },
    width: 220
  },
  {
    name: 'counteragentId',
    label: 'Підрозділ',
    optionsURL: '',
    width: 220
  },
  {
    name: 'acceptingPersonId',
    label: 'Хто прийняв',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: INBOUND_RESPONSIBLE_PERSON_AUTOCOMPLETE,
      reducerPath: 'asset.inbound.responsibleEmployeeAutocomplete',
      optionsApiUrl: RESPONSIBLE_EMPLOYEE_AUTOCOMPLETE,
      value: 'id'
    },
    width: 220
  },
  {
    name: 'statusId',
    label: 'Статус',
    options: [
      {
        label: 'Зафіксований',
        value: InboundStatusEnum.Fixed
      },
      {
        label: 'Чернетка',
        value: InboundStatusEnum.Draft
      }
    ],
    width: 220
  },
  {
    name: 'notes',
    label: 'Примітка',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerName: INBOUND_NOTES_AUTOCOMPLETE_NAME,
      reducerPath: 'asset.inbound.notesAutocomplete',
      optionsApiUrl: INBOUND_NOTES_AUTOCOMPLETE
    },
    width: 220,
    value: 'name'
  }
];

export const deleteWarningAppendix =
  'Все прийняте обладнання буде видалене з моніторингу';

export const warehouseCounteragentTypeName = 'Склад';
