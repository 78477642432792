import { MAIN_MENU } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import { ADMINISTRATION_PATH } from 'src/routes/administrationRoutes';
import { generateActionCrud } from 'src/components/Guards';
import { COUNTERAGENTS } from 'src/config/avlUrls';
import { USERS_AUTOCOMPLETE } from 'src/config/administrationUrls';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import UsersStatusColumn from './components/usersStatusColumn/UserStatusColumn';
import UserRolesColumn from './components/userRolesColumn';
import CounteragentNameColumn from './components/CounteragentNameColumn';
import AllCounteragentsColumn from './components/AllCounteragentsColumn';
import EmployeeNameColumn from './components/EmployeeNameColumn';

export const columns = [
  {
    label: "Iм'я користувача",
    value: 'userName',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Ролі користувача',
    value: 'usersRoles',
    sortable: false,
    width: '25%',
    changeValue: (row: Row) => <UserRolesColumn row={row} />
  },
  {
    label: "Ім'я працівника",
    value: 'employeeName',
    sortable: true,
    width: '25%',
    changeValue: (row: Row) => <EmployeeNameColumn row={row} />
  },
  {
    label: 'Підрозділ',
    value: 'counteragentName',
    sortable: true,
    width: '20%',
    changeValue: (row: Row) => <CounteragentNameColumn row={row} />
  },
  {
    label: 'Доступ',
    value: 'hasAccessToAllCounteragents',
    sortable: true,
    width: '20%',
    changeValue: (row: Row) => <AllCounteragentsColumn row={row} />
  },
  {
    label: 'Статус',
    value: 'status',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => <UsersStatusColumn status={row.status} />
  }
];

export const crudActions = generateActionCrud({
  module: 'userManagement',
  sub: 'usersSettings'
});

export const USERS_LIST_TABLE_PATH = 'administration.usersSettings.table';
export const USERS_LIST_TABLE_NAME = 'USERS_LIST_TABLE_NAME';

export const USERS_AUTOCOMPLETE_NAME = 'USERS_AUTOCOMPLETE_AUTOCOMPLETE';
export const USERS_AUTOCOMPLETE_PATH =
  'administration.usersSettings.usersAutocomplete';

export const filters = [
  {
    name: 'counteragentId',
    label: 'Підрозділ',
    optionsURL: COUNTERAGENTS
  },
  {
    name: 'id',
    label: 'Користувач',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      optionsApiUrl: USERS_AUTOCOMPLETE,
      reducerName: USERS_AUTOCOMPLETE_NAME,
      reducerPath: USERS_AUTOCOMPLETE_PATH
    }
  }
];

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.administration,
    url: `/${ADMINISTRATION_PATH}`
  }
];
