import { createAsyncAction } from 'typesafe-actions';
import { DownloadReportsFormValues } from '../components/types';
import { ReportType } from '../types';
import { ReportDataByReportType } from './types';

export type GetReportDataRequestPayload = {
  reportType: ReportType;
  filters: DownloadReportsFormValues['filters'];
};

export const getReportData = createAsyncAction(
  '@AVL_REPORTS/GET_REPORT_DATA_REQUEST',
  '@AVL_REPORTS/GET_REPORT_DATA_SUCCESS',
  '@AVL_REPORTS/GET_REPORT_DATA_FAILURE'
)<GetReportDataRequestPayload, Partial<ReportDataByReportType>, Error>();
