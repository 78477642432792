import { object, string, number } from 'yup';
import { AVL_ERROR_MESSAGES, ERROR_MESSAGES } from 'src/constants/validation';
import {
  VEHICLES_CATEGORIES,
  VEHICLES_CUSTOM_CATEGORIES,
  ADDITIONAL_EQUIPMENT,
  ADDITIONAL_CUSTOM_EQUIPMENT
} from 'src/config/avlUrls';
import { TARIFFS } from 'src/config/clearingUrls';
import { MAIN_MENU, NAV_BAR_HEADERS } from 'src/constants';
import { generateActionCrud } from 'src/components/Guards';
import { maxLength } from 'src/utils/validation';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.avl,
    url: '/avl'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_HEADERS.vehicleModels,
    url: '/avl/vehicles-models'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'avl',
    sub: 'vehicleModels'
  },
  ['download']
);

const SEATS_MAX_ERROR = 'Значення не повинно містити більше 3 цифр';

export const vehiclesModelFormValidationSchema = object().shape({
  name: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(...maxLength(100)),
  vehicleCategoryId: number().required(ERROR_MESSAGES.REQUIRED),
  tariffGroupId: number().required(ERROR_MESSAGES.REQUIRED),
  numberOfSeats: number()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(1, ERROR_MESSAGES.AMOUNT_GREATER_ERROR)
    .max(999, SEATS_MAX_ERROR),
  numberOfStandPlaces: number()
    .required(ERROR_MESSAGES.REQUIRED)
    .min(1, ERROR_MESSAGES.AMOUNT_GREATER_ERROR)
    .max(999, SEATS_MAX_ERROR),
  numberOfDisabilitySeats: number()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(999, SEATS_MAX_ERROR),
  code: string()
    .trim()
    .max(...maxLength(20))
    .when('$editMode', {
      is: true,
      then: string().required(ERROR_MESSAGES.REQUIRED)
    }),
  notes: string().max(...maxLength(255))
});

export const VEHICLES_MODEL_FORM_ERRORS = {
  ...AVL_ERROR_MESSAGES
};

/* CONFIG FOR VEHICLES CATEGORIES EDITABLE SELECT */
export const VEHICLES_CATEGORIES_NAME = 'VEHICLES_CATEGORIES';
export const VEHICLES_CATEGORIES_PATH =
  'avl.vehiclesModels.vehiclesCategoriesEditableSelect';
export const VEHICLES_CATEGORIES_CONFIG = {
  reducerPath: VEHICLES_CATEGORIES_PATH,
  reducerName: VEHICLES_CATEGORIES_NAME,
  activeOptionsApiUrl: VEHICLES_CATEGORIES,
  customOptionsApiUrl: VEHICLES_CUSTOM_CATEGORIES
};

export const VEHICLES_CATEGORIES_DIALOG_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  UniqueError: ERROR_MESSAGES.UNIQUE
};

/* CONFIG FOR TARIFF GROUPS SELECT */
export const TARIFF_GROUPS_SELECT_NAME = 'TARIFF_GROUPS_SELECT';
export const TARIFF_GROUPS_SELECT_PATH =
  'avl.vehiclesModels.tariffGroupsSelect';
export const TARIFF_GROUPS_SELECT_CONFIG = {
  reducerPath: TARIFF_GROUPS_SELECT_PATH,
  reducerName: TARIFF_GROUPS_SELECT_NAME,
  optionsApiUrl: TARIFFS
};

/* CONFIG FOR ADDITIONAL EQUIPMENT EDITABLE SELECT */
export const ADDITIONAL_EQUIPMENT_NAME = 'ADDITIONAL_EQUIPMENT';
export const ADDITIONAL_EQUIPMENT_PATH =
  'avl.vehiclesModels.equipmentEditableSelect';
export const ADDITIONAL_EQUIPMENT_CONFIG = {
  reducerPath: ADDITIONAL_EQUIPMENT_PATH,
  reducerName: ADDITIONAL_EQUIPMENT_NAME,
  activeOptionsApiUrl: ADDITIONAL_EQUIPMENT,
  customOptionsApiUrl: ADDITIONAL_CUSTOM_EQUIPMENT
};

export const ADDITIONAL_EQUIPMENT_DIALOG_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  UniqueError: AVL_ERROR_MESSAGES.uniqueError
};
