import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { StatesDetailsState } from '../../types';

type StatesEditActions = ActionType<typeof actions>;

const initialState: StatesDetailsState = {
  id: 0,
  name: '',
  description: '',
  date: ''
};

const statesEditReducer = createReducer<StatesDetailsState, StatesEditActions>(
  initialState
).handleAction(actions.getState.success, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default statesEditReducer;
