import { COUNTERAGENTS } from 'src/config/avlUrls';
import { Row } from 'src/components/Tables/types';
import { PENALTIES_DOCUMENT } from 'src/config/clearingUrls';
import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';
import { TABLE_ACTIONS_CONFIG } from 'src/constants';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';
import {
  crudActionsList,
  PenaltyDocumentStatus,
  penaltyDocumentStatusMap
} from '../const';

export const PENALTY_DOCUMENTS_TABLE_PATH = 'clearing.penaltyDocuments.table';
export const PENALTY_DOCUMENTS_TABLE_NAME = 'PENALTY_DOCUMENTS';

export const TABLE_CONFIG_BASE = {
  reducerPath: PENALTY_DOCUMENTS_TABLE_PATH,
  reducerName: PENALTY_DOCUMENTS_TABLE_NAME,
  apiUrl: PENALTIES_DOCUMENT,
  routeUrl: '/clearing/penalty-documents/',
  toolbarActions: {
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      action: crudActionsList.delete
    }
  },
  rowActions: {
    [TABLE_ACTIONS_CONFIG.EDIT]: {
      isActive: (row: Row) =>
        row?.status === penaltyDocumentStatusMap[PenaltyDocumentStatus.Imposed],
      action: crudActionsList.update
    },
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      isActive: ({ canDeleted }: Row) => canDeleted,
      action: crudActionsList.delete
    }
  }
};

export const columns = [
  {
    label: 'Дата документа',
    value: 'documentDate',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Назва документа',
    value: 'documentName',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Перевізник',
    value: 'counteragentName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Штраф',
    value: 'penaltyName',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Сума, грн',
    value: 'amount',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Cтатус',
    value: 'status',
    sortable: true,
    width: '15%',
    changeValue: changeStatusColumn
  }
];

export const filters = [
  {
    type: FilterType.Date,
    name: 'documentDate',
    label: 'Дата'
  },
  {
    name: 'counteragentId',
    label: 'Перевізник',
    optionsURL: COUNTERAGENTS
  }
];
