import { createReducer, ActionType } from 'typesafe-actions';
import { SystemStatusDTO } from '@eticket/avl-api-contracts';
import * as actions from './actions';
import { BusStop } from '../../types';

type StopDetailsActions = ActionType<typeof actions>;

const initialState: BusStop = {
  id: 0,
  code: '',
  name: '',
  shortName: '',
  facilities: [],
  address: '',
  lat: 0,
  lng: 0,
  points: '',
  description: '',
  status: SystemStatusDTO.Disabled,
  routesCount: 0,
  canDelete: false
};

const stopDetailsReducers = createReducer<BusStop, StopDetailsActions>(
  initialState
).handleAction(actions.getStop.success, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default stopDetailsReducers;
