import { array, number, object, string } from 'yup';
import { NAV_BAR_ITEMS } from 'src/constants';
import { USERS_PATH } from 'src/routes/administrationRoutes';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { maxLength } from 'src/utils/validation';
import { COUNTERAGENTS_AUTOCOMPLETE, EMPLOYEES } from 'src/config/avlUrls';
import { ROLES } from 'src/config/administrationUrls';
import { SystemStatusDTO } from '@eticket/avl-api-contracts';
import { listBreadcrumbs } from '../../const';

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.usersSettings,
    url: `/${USERS_PATH}`
  }
];

export const EMPLOYEES_NAME = 'EMPLOYEES_AUTOCOMPLETE';
export const EMPLOYEES_PATH =
  'administration.usersSettings.employeesAutocomplete';

export const UNASSIGNED_EMPLOYEES_CONFIG = {
  prohibitDynamicLoad: true,
  optionsApiUrl: `${EMPLOYEES}/unassigned`,
  reducerName: EMPLOYEES_NAME,
  reducerPath: EMPLOYEES_PATH
};

export const ROLES_SELECT_NAME = 'ROLES_SELECT_NAME';
export const ROLES_SELECT_PATH = 'administration.usersSettings.rolesSelect';

export const ROLES_CONFIG = {
  optionsApiUrl: ROLES,
  reducerName: ROLES_SELECT_NAME,
  reducerPath: ROLES_SELECT_PATH,
  prohibitInitialLoad: true
};

export const COUNTERAGENTS_NAME = 'COUNTERAGENTS_AUTOCOMPLETE';
export const COUNTERAGENTS_PATH =
  'administration.usersSettings.counteragentsAutocomplete';

export const COUNTERAGENT_CONFIG = {
  optionsApiUrl: COUNTERAGENTS_AUTOCOMPLETE,
  reducerName: COUNTERAGENTS_NAME,
  reducerPath: COUNTERAGENTS_PATH,
  prohibitInitialLoad: true
};

export const userEditValidationSchema = object().shape({
  userName: string()
    .nullable()
    .max(...maxLength(100))
    .required(ERROR_MESSAGES.REQUIRED),
  status: string().required(ERROR_MESSAGES.REQUIRED),
  roleIds: array().of(string()),
  employee: object()
    .shape({
      id: number()
    })
    .nullable()
    .typeError(ERROR_MESSAGES.REQUIRED)
});

export const PERMISSION_MESSAGE =
  'Надати доступ до налаштувань, що стосуються усіх підрозділів';

export const employeeStatus: Record<SystemStatusDTO, string> = {
  [SystemStatusDTO.Enabled]: 'Активний',
  [SystemStatusDTO.Disabled]: 'Неактивний'
};

export const EMPTY_EMPLOYEE = 'Не налаштований';
