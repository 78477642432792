import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { PenaltyDocumentValues } from '../../types';

type PenaltyDocumentEditActions = ActionType<typeof actions>;

const initialState: PenaltyDocumentValues = {
  routePassport: null,
  counteragent: null,
  penalty: null,
  vehicle: null,
  documentName: '',
  amount: 0,
  documentDate: null,
  canDelete: false
};

const penaltyDocumentEditReducers = createReducer<
  PenaltyDocumentValues,
  PenaltyDocumentEditActions
>(initialState).handleAction(
  actions.getPenaltyDocument.success,
  (state, { payload }) => {
    const { vehicle, ...rest } = payload;

    return {
      ...state,
      ...rest,
      vehicle: vehicle
        ? {
            id: vehicle.id,
            name: vehicle.licensePlate
          }
        : null
    };
  }
);

export default penaltyDocumentEditReducers;
