import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { TableState } from '../types';
import { FILTER_ALL_VALUE } from '../const';

export const initialState: TableState = {
  list: [],
  page: 0,
  pageSize: 5,
  rowCount: 0,
  sorter: null,
  filters: {
    schema: FILTER_ALL_VALUE,
    search: ''
  }
};

const usersLogsReducer = createReducer<TableState, ActionType<typeof actions>>(
  initialState
).handleAction(actions.getUsersLogs.success, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default usersLogsReducer;
