import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { SEND_EMAIL } from 'src/config/assetUrls';
import { OTHER_SETTINGS } from 'src/config/administrationUrls';
import * as actions from './actions';

export function* sendEmail({
  payload
}: ReturnType<typeof actions.sendEmail.request>): SagaIterator {
  const formData = new FormData();
  const { attachments, ...payloadData } = payload;

  attachments?.forEach(item => {
    formData.append('attachments', item.file, item.fileName);
  });

  formData.append('message', JSON.stringify(payloadData));

  yield call(apiClient.post, SEND_EMAIL, formData, {
    retryAction: actions.sendEmail.request(payloadData),
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  yield put(
    enqueueSnackbar({
      key: 'send_email',
      message: 'Емейл надіслано',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.sendEmail.success());
}

export function* getPredefinedEmails(): SagaIterator {
  const { data } = yield call(apiClient.get, OTHER_SETTINGS, {
    retryAction: actions.getPredefinedEmails.request()
  });

  yield put(actions.getPredefinedEmails.success(data));
}

export default function* SendEmailSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.sendEmail.request),
      safe(sendEmail, actions.sendEmail.failure)
    ),
    takeEvery(
      getType(actions.getPredefinedEmails.request),
      safe(getPredefinedEmails, actions.getPredefinedEmails.failure)
    )
  ]);
}
