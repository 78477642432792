import { put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { EQUIPMENT } from 'src/config/assetUrls';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getEquipment(): SagaIterator {
  const id = yield select(selectors.selectEquipmentId);

  const { data } = yield call(apiClient.get, `${EQUIPMENT}/${id}`, {
    retryAction: actions.getEquipment.request
  });
  yield put(actions.getEquipment.success(data));
}

export default function* EquipmentDetailsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getEquipment.request),
    safe(getEquipment, actions.getEquipment.failure)
  );
}
