import { generateActionCrud } from 'src/components/Guards';
import { equipmentStateMap, MAIN_MENU, NAV_BAR_HEADERS } from 'src/constants';
import { getDateDifference } from 'src/utils/date';
import { BreakdownStateEnum } from '@eticket/asset-management-api-contracts';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_HEADERS.transfer,
    url: '/asset/transfer'
  }
];

export const MAX_DAYS_AFTER_TRANSFER = 40;
export const TOGGLE_DISABLED_TEXT = `Документ зафіксовано понад ${MAX_DAYS_AFTER_TRANSFER} днів тому. Редагування заборонене.`;
export const isToggleDateRestricted = (date: string) =>
  getDateDifference(date) > MAX_DAYS_AFTER_TRANSFER;

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'transfer'
  },
  ['download', 'resendDocument', 'transferAllowEditing']
);
export enum TransferStatusEnum {
  Draft = 0,
  Fixed = 1
}

export const transferStatusEnumStatusMap = {
  [TransferStatusEnum.Draft]: 'Draft',
  [TransferStatusEnum.Fixed]: 'Fixed'
};

export const TRANSFER_STEPS = ['Переміщення', 'Обладнання'];

export const transferDocumentPdf = [
  {
    label: 'Підрозділ від',
    value: 'counteragentHolderName'
  },
  {
    label: 'Адреса підрозділу від',
    value: 'counteragentHolderAddress'
  },
  {
    label: 'Передав',
    value: 'performingPersonFullName'
  },
  {
    label: 'Підрозділ до',
    value: 'counteragentTransferName'
  },
  {
    label: 'Адреса підрозділу до',
    value: 'counteragentTransferAddress'
  },
  {
    label: 'Прийняв',
    value: 'acceptingPersonFullName'
  },
  {
    label: 'Примітка',
    value: 'notes'
  }
];

export const transferColumnsPdf = [
  {
    label: '№',
    value: 'index'
  },
  {
    label: 'Штрихкод',
    value: 'barcode'
  },
  {
    label: 'Назва',
    value: 'name'
  },
  {
    label: 'Модель',
    value: 'model'
  },
  {
    label: 'Виробник',
    value: 'manufacturer'
  },
  {
    label: 'IMEI',
    value: 'imei'
  },
  {
    label: 'Запчастини',
    value: 'spareParts'
  },
  {
    label: 'Стан',
    value: 'state',
    changeValue: (value: string) =>
      equipmentStateMap[value as keyof typeof BreakdownStateEnum]
  }
];

export const transferFieldsJson = [
  'documentNumber',
  'dateTransfer',
  'performingPerson',
  'performingPersonFullName',
  'counteragentFrom',
  'counteragentFromAdress',
  'acceptingPerson',
  'acceptingPersonFullName',
  'counteragentTo',
  'counteragentToAdress',
  'notes',
  'equipment',
  {
    label: 'equipment',
    fields: [
      'name',
      'model',
      'manufacturer',
      'barcode',
      'imei',
      'notes',
      'state'
    ]
  }
];

export const TRANSFER_EMAIL_DATA_PATH = 'asset.transfer.emailData';
