import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { safe } from 'src/utils/sagas';
import { STOPS } from 'src/config/avlUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getStop(): SagaIterator {
  const id = yield select(selectors.selectStopId);

  const { data } = yield call(apiClient.get, `${STOPS}/${id}`, {
    retryAction: actions.getStop.request()
  });

  yield put(actions.getStop.success(data));
}

export function* updateStop({
  payload
}: ReturnType<typeof actions.updateStop.request>): SagaIterator {
  const id = yield select(selectors.selectStopId);

  yield call(apiClient.put, `${STOPS}/${id}`, payload, {
    retryAction: actions.updateStop.request(payload)
  });
  yield put(navigateTo({ url: '/avl/geozones/stops' }));
  yield put(
    enqueueSnackbar({
      key: 'stop_edit',
      message: 'Зупинку відредаговано',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.updateStop.success());
}

export default function* stopEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getStop.request),
      safe(getStop, actions.getStop.failure)
    ),
    takeEvery(
      getType(actions.updateStop.request),
      safe(updateStop, actions.updateStop.failure)
    )
  ]);
}
