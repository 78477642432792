import Label from 'src/components/Labels/Label';
import { Row } from 'src/components/Tables/types';
import { billingDocumentStatuses } from '../const';
import { BillingType } from '../types';

const BillingDocumentType = ({ row }: Row) => {
  const label = billingDocumentStatuses[row.type as BillingType];

  const color = row.type === BillingType.Automatic ? 'success' : 'draft';

  return (
    <span data-testid="billing-document-type">
      <Label color={color}>{label}</Label>
    </span>
  );
};

export default BillingDocumentType;
