import { lazy } from 'react';
import { Grid as GridIcon } from 'react-feather';
import { generateActionID } from 'src/components/Guards';
import { Breadcrumbs } from 'src/components/Header/types';
import {
  AVL_REPORTS_1,
  AVL_REPORTS_2,
  AVL_REPORTS_3,
  AVL_REPORTS_4,
  AVL_REPORTS_5,
  AVL_REPORTS_7,
  AVL_REPORTS_8,
  AVL_REPORTS_9,
  AVL_REPORTS_10
} from 'src/config/avlUrls';
import { NAV_BAR_ITEMS } from 'src/constants';
import { ReportType } from './ReportsDownloadView/types';

const avlReportsUrl = '/avl/reports';

const action = generateActionID({
  module: 'avl',
  sub: 'reports',
  action: 'generateAndDownload'
});

export const avlReportsNavItems = [
  {
    id: 'reports',
    subheader: NAV_BAR_ITEMS.reports.toUpperCase(),
    items: [
      {
        id: '2.2',
        title: NAV_BAR_ITEMS.reports,
        icon: GridIcon,
        href: avlReportsUrl,
        exact: false,
        action
      }
    ]
  }
];

export const avlReportsRoutes = [
  {
    exact: true,
    path: avlReportsUrl,
    component: lazy(() => import('src/views/avl/reports/ReportsDownloadView')),
    action
  }
];

export const avlReportsBreadcrumbs: Breadcrumbs = [
  {
    text: 'Маршрути',
    url: '/avl'
  }
];

export const avlReportsLinks: Record<ReportType, string> = {
  [ReportType.busWorkOnRouteReport]: AVL_REPORTS_1,
  [ReportType.hourlyDeparture]: AVL_REPORTS_2,
  [ReportType.workOfDriver]: AVL_REPORTS_3,
  [ReportType.workOfCarrier]: AVL_REPORTS_4,
  [ReportType.graphicityPerformance]: AVL_REPORTS_5,
  [ReportType.morningAndEveningRide]: AVL_REPORTS_7,
  [ReportType.routePerforming]: AVL_REPORTS_8,
  [ReportType.presenceOnRouteNetwork]: AVL_REPORTS_9,
  [ReportType.unmappedGPSData]: AVL_REPORTS_10
};
