import moment from 'moment';
import { ACTIVE_TARIFFS } from 'src/config/clearingUrls';
import { boolean, date, number, object, Schema, string } from 'yup';
import {
  amountRegExp,
  AVL_ERROR_MESSAGES,
  ERROR_MESSAGES
} from 'src/constants/validation';
import { maxLength } from 'src/utils/validation';
import { getTomorrowsUtcDate } from 'src/utils/date';
import { RoutePassportStatus } from 'src/views/avl/Routes/const';
import { SettingsValues } from './types';

const vehicleClassRegExp: { [key: string]: RegExp } = {
  М2: /^[АВав]$/,
  М3: /^(([1-3])|([АВав](, ??[1-3])??))$/
};

const routeNumberRegExp = /^[1-9]{1}[0-9]{0,3}[А-ЩЬЮЯЇІЄҐ]?$/;

/* CONFIG FOR ACTIVE TARIFFS SELECT */
export const ACTIVE_TARIFFS_SELECT_NAME = 'ACTIVE_TARIFFS_SELECT';
export const ACTIVE_TARIFFS_SELECT_PATH =
  'avl.routes.settings.create.activeTariffsSelect';
export const ACTIVE_TARIFFS_SELECT_CONFIG = {
  reducerPath: ACTIVE_TARIFFS_SELECT_PATH,
  reducerName: ACTIVE_TARIFFS_SELECT_NAME,
  optionsApiUrl: `${ACTIVE_TARIFFS}/${getTomorrowsUtcDate()}`
};

export const settingsFormValidationSchema = object().shape({
  generalSettings: object().shape({
    number: string()
      .max(...maxLength(5))
      .matches(
        routeNumberRegExp,
        'Значення повинно містити лише цифри та літери українського алфавіту у форматі: 23А.'
      )
      .when('$status', {
        is: RoutePassportStatus.Approved,
        then: string().required(ERROR_MESSAGES.REQUIRED)
      }),
    name: string()
      .max(...maxLength(250))
      .when('$status', {
        is: RoutePassportStatus.Approved,
        then: string().required(ERROR_MESSAGES.REQUIRED)
      }),
    shortName: string().max(...maxLength(100)),
    tariffGroupId: number()
      .nullable()
      .when('$status', {
        is: RoutePassportStatus.Approved,
        then: number().required(ERROR_MESSAGES.REQUIRED)
      }),
    counteragentId: number()
      .nullable()
      .when(['isTenderCompleted'], {
        is: isTenderCompleted => isTenderCompleted,
        then: number().required(ERROR_MESSAGES.REQUIRED)
      }),
    fare: string()
      .nullable()
      .typeError(ERROR_MESSAGES.NUMBER)
      .test({
        name: 'fare',
        message: ERROR_MESSAGES.AMOUNT_TYPE_ERROR,
        test(value) {
          if (value && Number(value) === 0) {
            throw this.createError({
              path: this.path,
              message: ERROR_MESSAGES.AMOUNT_GREATER_ERROR
            });
          } else if (value && isNaN(value)) {
            throw this.createError({
              path: this.path,
              message: ERROR_MESSAGES.TYPE_ERROR
            });
          }
          return !value || amountRegExp.test(String(value).trim());
        }
      })
      .when('$status', {
        is: RoutePassportStatus.Approved,
        then: string().required(ERROR_MESSAGES.REQUIRED)
      }),
    technicalSpeed: string()
      .nullable()
      .test({
        message: ERROR_MESSAGES.TYPE_ERROR,
        name: 'technicalSpeed',
        test(val) {
          // @ts-ignore
          const { speedLimitInKmH } = this.options.context;

          if (val) {
            if (Number(val) > 99) {
              throw this.createError({
                path: this.path,
                message: 'Введіть значення не більше 2 символів'
              });
            }
            if (isNaN(val) || Number(val) < 0) {
              throw this.createError({
                path: this.path,
                message: ERROR_MESSAGES.TYPE_ERROR
              });
            }
            if (Number(val) === 0) {
              throw this.createError({
                path: this.path,
                message: ERROR_MESSAGES.AMOUNT_GREATER_ERROR
              });
            }
            if (val > speedLimitInKmH) {
              throw this.createError({
                path: this.path,
                message: ERROR_MESSAGES.MAX_SPEED_LIMIT
              });
            }
            return /^[1-9][0-9]?$/.test(val);
          }
          // @ts-ignore
          if (this.options.context?.status === RoutePassportStatus.Approved) {
            throw this.createError({
              path: this.path,
              message: ERROR_MESSAGES.REQUIRED
            });
          }
          return true;
        }
      }),
    isTenderCompleted: boolean(),
    notes: string()
      .nullable()
      .max(...maxLength(255)),
    effectiveDate: date()
      .nullable()
      .typeError(ERROR_MESSAGES.TYPE_ERROR)
      .min(getTomorrowsUtcDate(), ERROR_MESSAGES.MIN_DATE_TOMORROW)
      .when('$status', {
        is: RoutePassportStatus.Approved,
        then: date()
          .required(ERROR_MESSAGES.REQUIRED)
          .typeError(ERROR_MESSAGES.TYPE_ERROR)
      }),
    contractStartDate: date()
      .nullable()
      .typeError(ERROR_MESSAGES.TYPE_ERROR),
    contractEndDate: date()
      .test({
        name: 'contractEndDate',
        message: ERROR_MESSAGES.TYPE_ERROR,
        test(value) {
          if (
            value &&
            moment(value).isBefore(this.parent.contractStartDate, 'day')
          ) {
            throw this.createError({
              path: this.path,
              message:
                'Час кінця дії договору не може бути раніше ніж час початку'
            });
          } else if (
            this.parent.contractStartDate &&
            value &&
            moment(value).isSame(this.parent.contractStartDate, 'day')
          ) {
            return false;
          }
          return true;
        }
      })
      .nullable()
      .typeError(ERROR_MESSAGES.TYPE_ERROR)
  }),
  vehicleRequirements: object().shape({
    vehicleCategoryId: number()
      .nullable()
      .when('$status', {
        is: RoutePassportStatus.Approved,
        then: number().required(ERROR_MESSAGES.REQUIRED)
      }),
    vehicleCategory: string().when(
      ['$status', '$vehicleCategoriesNames', 'vehicleCategoryId'],
      {
        is: (
          status: RoutePassportStatus,
          vehicleCategoriesNames: { [key: string]: string },
          vehicleCategoryId: number
        ) =>
          status === RoutePassportStatus.Approved &&
          vehicleCategoriesNames[vehicleCategoryId] &&
          categoriesWithClass.includes(
            vehicleCategoriesNames[vehicleCategoryId]
          ),
        then: string().required(ERROR_MESSAGES.REQUIRED)
      }
    ),
    vehicleClass: string()
      .test({
        name: 'vehicleClass',
        message: ERROR_MESSAGES.TYPE_ERROR,
        test(value) {
          const category = this.parent.vehicleCategory;
          if (!category || !value) {
            return true;
          }
          return vehicleClassRegExp[category].test(value.trim());
        }
      })
      .when(
        ['$status', 'vehicleCategory'],
        (
          status: RoutePassportStatus,
          vehicleCategory: string,
          schema: Schema<SettingsValues>
        ) =>
          status === RoutePassportStatus.Approved && vehicleCategory
            ? string()
                .required(ERROR_MESSAGES.REQUIRED)
                .test({
                  name: 'vehicleClass',
                  message: ERROR_MESSAGES.TYPE_ERROR,
                  test(value) {
                    const category = this.parent.vehicleCategory;
                    if (!category || !value) {
                      return true;
                    }
                    return vehicleClassRegExp[category].test(value.trim());
                  }
                })
            : schema
      )
  })
});

export const categoriesWithClass = ['Автобус', 'Тролейбус'];

export const tenderTooltip =
  'Активуйте позначку після проведення тендеру. Це дозволить обрати перевізника для маршруту на наступних кроках';

export const vehicleRequirementsInstructions =
  '1. \n' +
  'Впишіть одну із категорії транспортного засобу в полі "Категорія ТЗ". \n' +
  'M2 КТЗ, призначені для перевезення пасажирів і мають більше ніж 8 місць, не враховуючи місце водія, і максимальну масу не більше ніж 5 тонн. \n' +
  'M3 КТЗ, призначені для перевезення пасажирів і мають більше ніж 8 місць, не враховуючи місце водія, і максимальну масу, що перевищує 5 тонн.\n' +
  '\n' +
  '2.\n' +
  'Після введення значення Категорії ТЗ, впишіть клас транспортного засобу в полі "Клас ТЗ".\n' +
  ' ТЗ категорій М2 та М3 належать до: \n' +
  'а) одного або кількох класів — клас І, клас ІІ, клас ІІІ (згідно з ДСТУ UN/ECE R 36-03); \n' +
  'б) одного з класів — клас А або клас В (згідно з ДСТУ UN/ECE R 52-01).  \n' +
  'Клас І — автобуси, призначені для перевезення пасажирів, що сидять і стоять, із можливістю для пасажирів безперешкодно переміщуватися по салону. \n' +
  'Клас ІІ — автобуси, призначені для перевезення головним чином пасажирів, що сидять, а також пасажирів, що стоять у проході поміж рядами та (або) на площадці, яка не перевищує за розміром площі, необхідної для розміщення двох здвоєних сидінь. \n' +
  'Клас ІІІ — автобуси, призначені для перевезення винятково пасажирів, що сидять. \n' +
  'Клас А — автобуси (пасажировмісткістю до 22 пасажирів), призначені для перевезення пасажирів, що сидять, та мають місця для пасажирів, які стоять. \n' +
  'Клас В — автобуси (пасажировмісткістю до 22 пасажирів), призначені для перевезення винятково пасажирів, що сидять.\n';

export const PASSPORT_ERROR_MESSAGES = {
  ...AVL_ERROR_MESSAGES,
  forbidden: 'Схему змінено. Перезатвердіть її та створіть новий роклад'
};
