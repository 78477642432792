import { generateActionCrud } from 'src/components/Guards';

export const avlRoutesCrud = generateActionCrud({
  module: 'avl',
  sub: 'routes'
});

export const assetTrackingCrud = generateActionCrud({
  module: 'assets',
  sub: 'tracking'
});

export const clearingTariffsCrud = generateActionCrud({
  module: 'clearing',
  sub: 'tariff'
});

export const administrationCrud = generateActionCrud({
  module: 'administration',
  sub: 'routeSettings'
});
