import { ReportType } from '../../types';
import { ReportDataByReportType } from '../types';
import { busWorkOnRouteReportMock } from '../../components/ReportsTables/BusWorkOnRoute/tests/mocks';
import { hourlyDepartureReportMock } from '../../components/ReportsTables/HourlyDeparture/tests/mocks';
import { presenceOnRouteNetworkMock } from '../../components/ReportsTables/PresenceOnRouteNetwork/tests/mocks';
import { workOfDriverReportMock } from '../../components/ReportsTables/WorkOfDriver/tests/mocks';
import { routePerformingReportMock } from '../../components/ReportsTables/RoutePerforming/tests/mocks';
import { morningEveningRidesReportMock } from '../../components/ReportsTables/MorningEveningRides/tests/mocks';
import { workOfCarrierReportMock } from '../../components/ReportsTables/WorkOfCarrier/tests/mocks';
import { mockedGraphicityPerformanceReport } from '../../components/ReportsTables/GraphicityPerformance/tests/mocks';
import { UnmappedGPSDataReportMock } from '../../components/ReportsTables/UnmappedGPSData/tests/mocks';

export const reportDataMockByReportType: ReportDataByReportType = {
  [ReportType.busWorkOnRouteReport]: busWorkOnRouteReportMock,
  [ReportType.hourlyDeparture]: hourlyDepartureReportMock,
  [ReportType.workOfDriver]: workOfDriverReportMock,
  [ReportType.workOfCarrier]: workOfCarrierReportMock,
  [ReportType.graphicityPerformance]: mockedGraphicityPerformanceReport,
  [ReportType.morningAndEveningRide]: morningEveningRidesReportMock,
  [ReportType.routePerforming]: routePerformingReportMock,
  [ReportType.presenceOnRouteNetwork]: presenceOnRouteNetworkMock,
  [ReportType.unmappedGPSData]: UnmappedGPSDataReportMock
};
