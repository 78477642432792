import { date, object, number, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { EquipmentTypeEnum } from 'src/constants';
import { maxLength } from 'src/utils/validation';
import {
  EMPLOYEES_AUTOCOMPLETE,
  COUNTERAGENTS_AUTOCOMPLETE,
  COUNTERAGENTS,
  PROFESSIONS
} from 'src/config/avlUrls';
import { ENABLED } from 'src/views/avl/employees/const';

export const INSPECTOR_EMPLOYEES_NAME = 'INSPECTOR_EMPLOYEES';
export const INSPECTOR_EMPLOYEES_PATH =
  'asset.inspections.inspectorEmployeesAutocomplete';

export const INSPECTOR_EMPLOYEES_CONFIG = (professionId?: number) => ({
  reducerName: INSPECTOR_EMPLOYEES_NAME,
  reducerPath: INSPECTOR_EMPLOYEES_PATH,
  optionsApiUrl: `${EMPLOYEES_AUTOCOMPLETE}?status=${ENABLED}&${
    professionId ? `professionId=${professionId}` : ''
  }`
});

export const INSPECTION_COUNTERAGENTS_NAME =
  'INSPECTION_COUNTERAGENTS_AUTOCOMPLETE';
export const INSPECTION_COUNTERAGENTS_PATH =
  'asset.inspections.counteragentsAutocomplete';

export const INSPECTION_COUNTERAGENT_CONFIG = {
  optionsApiUrl: COUNTERAGENTS_AUTOCOMPLETE,
  reducerName: INSPECTION_COUNTERAGENTS_NAME,
  reducerPath: INSPECTION_COUNTERAGENTS_PATH
};

export const COUNTERAGENT_VEHICLES_NAME =
  'INSPECTION_COUNTERAGENT_VEHICLES_AUTOCOMPLETE';
export const COUNTERAGENT_VEHICLES_PATH =
  'asset.inspections.counteragentVehiclesAutocomplete';

export const COUNTERAGENT_VEHICLES_CONFIG = (id = 0) => ({
  optionsApiUrl: id
    ? `${COUNTERAGENTS}/${id}/vehicles-autocomplete?status=Enabled`
    : '',
  reducerName: COUNTERAGENT_VEHICLES_NAME,
  reducerPath: COUNTERAGENT_VEHICLES_PATH
});

export const PROFESSIONS_NAME = 'PROFESSIONS';
export const PROFESSIONS_PATH = 'asset.inspections.professions';

export const PROFESSIONS_CONFIG = {
  reducerPath: PROFESSIONS_PATH,
  reducerName: PROFESSIONS_NAME,
  optionsApiUrl: PROFESSIONS
};

export const professionForInspectionCreation = 'Інженер-інспектор';

export const inspectionsValidationSchema = object().shape({
  inspectionDate: date()
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .nullable(),
  inspectorEmployee: object()
    .shape({
      id: number()
    })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  equipmentGroupId: number().required(ERROR_MESSAGES.REQUIRED),
  counteragent: object()
    .shape({
      id: number()
    })
    .required(ERROR_MESSAGES.REQUIRED)
    .typeError(ERROR_MESSAGES.REQUIRED),
  vehicle: object()
    .shape({
      id: number()
    })
    .when('$equipmentGroupId', {
      is: equipmentType => equipmentType === EquipmentTypeEnum.Mobile,
      then: object()
        .required(ERROR_MESSAGES.REQUIRED)
        .typeError(ERROR_MESSAGES.REQUIRED),
      otherwise: object().nullable()
    })
    .typeError(ERROR_MESSAGES.REQUIRED),
  notes: string().max(...maxLength(255))
});
