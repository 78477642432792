import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';

export const listBreadcrumbs = (id: number | string) => [
  {
    text: MAIN_MENU.avl,
    url: '/avl'
  },
  {
    text: NAV_BAR_ITEMS.routes,
    url: '/avl/routes'
  },
  {
    text: NAV_BAR_ITEMS.schedules,
    url: `/avl/routes/details/timetable/${id}`
  }
];

export const columns = [
  {
    label: 'Дата зміни',
    value: 'modifiedAt',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '30%'
  },
  {
    label: 'К-ть випусків',
    value: 'count',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Період дії',
    value: 'actualFrom',
    sortable: true,
    width: '20%'
  }
];

export const TIME_TABLE_HISTORY_CHANGES_TABLE_PATH =
  'avl.routes.timetable.historyChangesTable';
export const TIME_TABLE_HISTORY_CHANGES_TABLE_NAME =
  'TIME_TABLE_HISTORY_CHANGES';
