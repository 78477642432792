import { SystemStatusDTO } from '@eticket/avl-api-contracts';
import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';
import {
  FilterConfig,
  FilterType
} from 'src/components/Tables/TableWithPagination/types';
import { STOPS_BY_CODE_AUTOCOMPLETE } from 'src/config/avlUrls';
import { STOPS_AUTOCOMPLETE_CONFIG } from '../../Routes/RoutesPassportLayout/Steps/RouteScheme/RouteSchemeForm/const';

export const columns = [
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Адреса',
    value: 'address',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Облаштування',
    value: 'facilities',
    sortable: true,
    width: '15%'
  },
  {
    label: 'К-ть маршрутів',
    value: 'routesCount',
    sortable: true,
    width: '10%'
  },
  {
    label: 'Статус',
    value: 'status',
    sortable: true,
    width: '15%',
    changeValue: changeStatusColumn
  },
  {
    label: 'Код',
    value: 'code',
    sortable: true,
    width: '10%'
  }
];

export const STOPS_TABLE_PATH = 'avl.stops.table';
export const STOPS_TABLE_NAME = 'STOPS';

export const STOPS_BY_CODE_AUTOCOMPLETE_NAME = 'STOPS BY CODE AUTOCOMPLETE';

export const filters: FilterConfig[] = [
  {
    name: 'id',
    label: 'Назва зупинки',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      ...STOPS_AUTOCOMPLETE_CONFIG,
      prohibitInitialLoad: false
    }
  },
  {
    name: 'code',
    label: 'Код',
    value: 'code',
    type: FilterType.Autocomplete,
    filterByField: 'code',
    autocompleteConfig: {
      reducerName: STOPS_BY_CODE_AUTOCOMPLETE_NAME,
      reducerPath: 'avl.stops.autocomplete',
      optionsApiUrl: STOPS_BY_CODE_AUTOCOMPLETE
    }
  },
  {
    name: 'status',
    label: 'Статус',
    options: [
      {
        label: 'Не активний',
        value: SystemStatusDTO.Disabled
      },
      {
        label: 'Активний',
        value: SystemStatusDTO.Enabled
      }
    ]
  },
  {
    name: 'facilities',
    label: 'Облаштування',
    options: [
      {
        label: 'Перегін',
        value: 'Перегін'
      },
      {
        label: 'Автостанція',
        value: 'Автостанція'
      },
      {
        label: 'Павільйон',
        value: 'Павільйон'
      },
      {
        label: 'Навіс',
        value: 'Навіс'
      },
      {
        label: 'Лава',
        value: 'Лава'
      },
      {
        label: 'Пандус',
        value: 'Пандус'
      }
    ],
    comparison: 'Contains'
  }
];
