import { put, takeEvery, call, all, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { getDataSuccess } from 'src/components/Tables/TableView/store/actions';
import { VEHICLES } from 'src/config/avlUrls';
import { BREAKDOWNS } from 'src/config/assetUrls';
import {
  getCounteragent,
  getVehicleModel
} from 'src/views/asset/sharedStore/sagas';
import * as actions from './actions';
import { selectBreakdownId } from './selectors';
import {
  normalizeBreakdownData,
  normalizeBreakdownFormData
} from '../helpers/normalizeBreakdown';
import { getBreakdown } from '../../../BreakdownDetailsView/store/actions';

export function* getVehicleType({
  config,
  payload: id
}: ReturnType<typeof actions.getVehicleTypeRequest>): SagaIterator {
  const { data } = yield call(apiClient.get, `${VEHICLES}/${id}`, {
    retryAction: actions.getVehicleTypeRequest(config, id)
  });

  yield put(actions.getVehicleTypeSuccess(config, data));
}

export function* getBreakdownData({
  payload
}: ReturnType<typeof actions.getBreakdownData.request>): SagaIterator {
  const { config, tableConfig } = payload;
  const id = yield select(selectBreakdownId);

  const { data } = yield call(apiClient.get, `${BREAKDOWNS}/${id}`, {
    retryAction: actions.getBreakdownData.request(payload)
  });
  yield put(getBreakdown.success(data));
  yield put(
    getDataSuccess(tableConfig, {
      list: normalizeBreakdownData(data?.breakdownItems)
    })
  );

  yield put(
    actions.setFormValues(config, data && normalizeBreakdownFormData(data))
  );

  yield put(actions.getBreakdownData.success());
}

export default function* BreakdownStepSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getCounteragentRequest),
      safe(getCounteragent, actions.getCounteragentFailure)
    ),
    takeEvery(
      getType(actions.getVehicleModelRequest),
      safe(getVehicleModel, actions.getVehicleModelFailure)
    ),
    takeEvery(
      getType(actions.getVehicleTypeRequest),
      safe(getVehicleType, actions.getVehicleTypeFailure)
    ),
    takeEvery(
      getType(actions.getBreakdownData.request),
      safe(getBreakdownData, actions.getBreakdownData.failure)
    )
  ]);
}
