import { RootState } from 'typesafe-actions';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { createSelector } from 'reselect';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/administration/users/:id'
);

export const selectUserManagementId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectEmployeeData = (state: RootState) =>
  state.ui.administration.usersSettings.edit;

export const selectEmployees = (state: RootState) => {
  const employees =
    state.ui.administration.usersSettings.employeesAutocomplete.options;

  return employees.map((employee: { shortName: string }) => ({
    ...employee,
    name: employee.shortName
  }));
};
