import { MAIN_MENU } from 'src/constants';
import {
  Column,
  TableConfig
} from 'src/components/Tables/TableWithPagination/types';
import {
  ROUTE_PASSPORT_DEPARTURES,
  SCHEDULES_STATUS
} from 'src/config/avlUrls';
import { SelectConfig } from 'src/components/Select/types';
import { isDateValid } from 'src/utils/date';
import { FiltersValues, SchedulesStatusRow } from './types';

export const breadcrumbs = [
  {
    text: MAIN_MENU.avl,
    url: '/avl'
  }
];

export const SCHEDULES_STATUS_TABLE_NAME = 'SCHEDULES_STATUS_TABLE_NAME';

export const TABLE_CONFIG: TableConfig = {
  toolbarActions: {},
  reducerName: SCHEDULES_STATUS_TABLE_NAME,
  reducerPath: 'avl.schedulesStatus.table',
  apiUrl: SCHEDULES_STATUS,
  routeUrl: '/avl/schedules-status'
};

export const columns: Column<keyof SchedulesStatusRow>[] = [
  {
    label: 'Перевізник',
    value: 'counteragentName',
    width: '22%',
    sortable: true
  },
  {
    label: 'Маршрут',
    value: 'routeName',
    width: '22%',
    sortable: true
  },
  {
    label: 'Випуск',
    value: 'departureName',
    width: '10%',
    sortable: true
  },
  {
    label: 'Заплановано ТЗ',
    value: 'scheduledVehiclesCount',
    width: '15%',
    sortable: true
  },
  {
    label: 'Необхідно ТЗ',
    value: 'requiredVehiclesCount',
    width: '15%',
    sortable: true
  },
  {
    label: 'Заплановано у %',
    value: 'ratio',
    width: '16%',
    sortable: true
  }
];

export const SCHEDULES_STATUS_LOADING_KEY =
  '@TABLE_WITH_PAGINATION/GET_DATA/SCHEDULES_STATUS_TABLE_NAME';

export const filtersInitialValues: FiltersValues = {
  counteragentId: '',
  route: null,
  date: null,
  departureId: 'All'
};

export const SCHEDULES_DEPARTURES_NAME = 'SCHEDULES_DEPARTURES_NAME';
export const SCHEDULES_DEPARTURES_CONFIG = (
  passportId?: number,
  date?: string | null
): SelectConfig => ({
  optionsApiUrl:
    passportId && date && isDateValid(date)
      ? ROUTE_PASSPORT_DEPARTURES(passportId, date)
      : '',
  reducerName: SCHEDULES_DEPARTURES_NAME,
  reducerPath: 'avl.schedulesStatus.departuresSelect'
});
