import { createReducer, ActionType } from 'typesafe-actions';
import {
  DangerousZoneDetailDTO,
  SystemStatusDTO
} from '@eticket/avl-api-contracts';
import * as actions from './actions';

type DangerousZonesDetailsActions = ActionType<typeof actions>;

export const initialState: DangerousZoneDetailDTO = {
  id: 0,
  code: '',
  name: '',
  address: '',
  lat: 0,
  lng: 0,
  points: '',
  notes: '',
  category: {
    id: 0,
    name: '',
    status: SystemStatusDTO.Disabled
  }
};

const dangerousZoneDetailsReducer = createReducer<
  DangerousZoneDetailDTO,
  DangerousZonesDetailsActions
>(initialState).handleAction(
  actions.getDangerousZone.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default dangerousZoneDetailsReducer;
