import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { PaymentsSettingsValues } from '../types';
import { defaultBillingFrequencyScheduleCron } from '../const';

export const initialState: PaymentsSettingsValues = {
  billingFrequencySchedulerCron: defaultBillingFrequencyScheduleCron,
  billingEmails: []
};

const paymentsSettingsReducer = createReducer<
  PaymentsSettingsValues,
  ActionType<typeof actions>
>(initialState).handleAction(
  actions.getPaymentsSettings.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default paymentsSettingsReducer;
