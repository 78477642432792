import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { UNINSTALLATIONS } from 'src/config/assetUrls';
import { addRowsLocally } from 'src/components/Tables/TableView/store/actions';
import { StatusEnum } from 'src/constants';
import * as actions from './actions';
import * as selectors from './selectors';
import {
  EDIT_UNINSTALLATION_FORM_CONFIG,
  EDIT_UNINSTALLING_MOBILE_TABLE_CONFIG,
  EDIT_UNINSTALLING_STATIONARY_TABLE_CONFIG,
  EDIT_UNINSTALLING_INSPECTOR_TABLE_CONFIG
} from '../const';
import { normalizeFormResponse } from './utils';
import { setFormValues } from '../../UninstallationStep/store/actions';
import { selectListCreateData } from '../../UninstallationEquipmentsStep/store/selectors';
import { selectFormCreateData } from '../../UninstallationStep/store/selectors';
import { getUninstallation } from '../../UninstallationDetailsView/store/actions';

export function* updateUninstallation({
  payload
}: ReturnType<typeof actions.updateUninstallation.request>): SagaIterator {
  const { formPath, inspectorPath, mobilePath, stationaryPath, id } = payload;
  const [
    formData,
    mobileEquipments,
    stationaryEquipments,
    inspectorEquipments
  ] = yield all([
    select(selectFormCreateData(formPath)),
    select(selectListCreateData(mobilePath)),
    select(selectListCreateData(stationaryPath)),
    select(selectListCreateData(inspectorPath))
  ]);

  yield call(
    apiClient.put,
    `${UNINSTALLATIONS}/${id}`,
    {
      ...formData,
      assetIds: [
        ...mobileEquipments,
        ...stationaryEquipments,
        ...inspectorEquipments
      ],
      status: StatusEnum.Draft
    },
    {
      retryAction: actions.updateUninstallation.request(payload)
    }
  );

  yield put(actions.updateUninstallation.success());
}

export function* getUninstallationData(): SagaIterator {
  const id = yield select(selectors.selectUninstallationId);

  const { data } = yield call(apiClient.get, `${UNINSTALLATIONS}/${id}`, {
    retryAction: actions.getUninstallationData.request()
  });
  const {
    mobileEquipment,
    stationaryEquipment,
    inspectorEquipments,
    ...formValues
  } = data;

  yield put(
    addRowsLocally(EDIT_UNINSTALLING_MOBILE_TABLE_CONFIG(id), {
      rows: mobileEquipment
    })
  );
  yield put(
    addRowsLocally(EDIT_UNINSTALLING_STATIONARY_TABLE_CONFIG(id), {
      rows: stationaryEquipment
    })
  );
  yield put(
    addRowsLocally(EDIT_UNINSTALLING_INSPECTOR_TABLE_CONFIG(id), {
      rows: inspectorEquipments
    })
  );

  yield put(
    setFormValues(
      EDIT_UNINSTALLATION_FORM_CONFIG,
      normalizeFormResponse(formValues)
    )
  );

  yield put(getUninstallation.success(data));
  yield put(actions.getUninstallationData.success());
}

export default function* UninstallationEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.updateUninstallation.request),
      safe(updateUninstallation, actions.updateUninstallation.failure)
    ),
    takeEvery(
      getType(actions.getUninstallationData.request),
      safe(getUninstallationData, actions.getUninstallationData.failure)
    )
  ]);
}
