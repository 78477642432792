import { number, object, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { maxLength } from 'src/utils/validation';
import { isTimeBefore } from 'src/utils/date';
import {
  RideModeType,
  SpecialModeDetails,
  StopDialogValues
} from '../../types';

export const SPECIAL_MODE_ERRORS: Record<string, string> = {
  uniqueError: 'Спеціальний режим вже встановлено для цього маршруту'
};

export const SPECIAL_MODE_STOPS_ERRORS: Record<string, string> = {
  uniqueError: 'Недоступність даної зупинки вже встановлено'
};

export const DEFAULT_ERROR_MESSAGE =
  'Не вдалося зберегти, перевірте правильність даних';

export const stopDialogInitialValues = {
  stop: null,
  rideDisruptionEventReasonId: -1,
  unavailableFrom: '',
  unavailableTo: '',
  notes: ''
} as StopDialogValues;

export const defaultRideModeDetailsValues: SpecialModeDetails = {
  rideDisruptionEventReasonId: undefined,
  notes: '',
  startsAtUtc: '',
  endsAtUtc: ''
};

export const stopDialogValidationSchema = object().shape({
  routeId: number().required(ERROR_MESSAGES.REQUIRED),
  stop: object()
    .required(ERROR_MESSAGES.REQUIRED)
    .nullable(),
  rideDisruptionEventReasonId: number().required(ERROR_MESSAGES.REQUIRED),
  unavailableFrom: string().when('stop', {
    is: stop => !stop?.unavailabilityDetails,
    then: string()
      .required(ERROR_MESSAGES.REQUIRED)
      .test({
        message: ERROR_MESSAGES.MIN_TIME_IN_FUTURE,
        test: value => isTimeBefore(new Date(), value)
      })
  }),
  unavailableTo: string(),
  notes: string().max(...maxLength(255))
});

export const routeRideModeValidationSchema = object().shape({
  routeId: number().required(ERROR_MESSAGES.REQUIRED),
  rideDisruptionEventReasonId: number().required(ERROR_MESSAGES.REQUIRED),
  endsAtUtc: string(),
  startsAtUtc: string().when('$rideMode', {
    is: RideModeType.Special,
    then: string(),
    otherwise: string()
      .required(ERROR_MESSAGES.REQUIRED)
      .test({
        message: ERROR_MESSAGES.MIN_TIME_IN_FUTURE,
        name: 'startsAtUtc',
        test: value => isTimeBefore(new Date(), value)
      })
  }),
  notes: string().max(...maxLength(255))
});
