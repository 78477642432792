import { createAsyncAction } from 'typesafe-actions';

interface UpdateStateChangeRequestPayload {
  id: number;
  formPath: string;
  equipmentsPath: string;
}

export const updateStateChange = createAsyncAction(
  '@STATE_CHANGE/UPDATE_STATE_CHANGE_REQUEST',
  '@STATE_CHANGE/UPDATE_STATE_CHANGE_SUCCESS',
  '@STATE_CHANGE/UPDATE_STATE_CHANGE_FAILURE'
)<UpdateStateChangeRequestPayload, undefined, Error>();

export const getStateChangeData = createAsyncAction(
  '@STATE_CHANGE/GET_STATE_CHANGE_DATA_REQUEST',
  '@STATE_CHANGE/GET_STATE_CHANGE_DATA_SUCCESS',
  '@STATE_CHANGE/GET_STATE_CHANGE_DATA_FAILURE'
)<undefined, undefined, Error>();
