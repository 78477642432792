import { createSelector } from 'reselect';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { RootState } from 'typesafe-actions';
import { INSPECTION_DOCUMENTS_EDIT_URL } from 'src/routes/clearingRoutes';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  INSPECTION_DOCUMENTS_EDIT_URL
);

export const selectInspectionDocumentId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectInspectionDocument = (state: RootState) =>
  state.ui.clearing.inspectionDocuments.edit;
