import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { apiClient } from 'src/utils/api';
import { INSPECTIONS } from 'src/config/assetUrls';
import { InspectionDetails } from '../../InspectionDetailsView/types';
import * as actions from './actions';
import { fetchInspectionDetails } from '../../InspectionDetailsView/store/sagas';

export function* getInspectionDetails({
  payload: id
}: ReturnType<typeof actions.getInspectionPdfDetails.request>): SagaIterator {
  const data: InspectionDetails = yield call(fetchInspectionDetails, id);

  yield put(actions.getInspectionPdfDetails.success(data));
}

export function* getSelectedInspectionsData({
  payload
}: ReturnType<
  typeof actions.getSelectedInspectionsData.request
>): SagaIterator {
  const requests = payload.map(({ id }) =>
    call(apiClient.get, `${INSPECTIONS}/${id}`, {
      retryAction: actions.getSelectedInspectionsData.request(payload)
    })
  );

  const response = yield all(requests);

  const inspections = response.map(
    ({ data }: { data: InspectionDetails[] }) => data
  );

  yield put(actions.getSelectedInspectionsData.success(inspections));
}

export function* updateInspectionStatus({
  payload
}: ReturnType<typeof actions.updateInspectionStatus.request>): SagaIterator {
  yield call(
    apiClient.put,
    `${INSPECTIONS}/${payload.id}/change-status`,
    { status: payload.status },
    { retryAction: actions.updateInspectionStatus.request(payload) }
  );
  yield put(actions.updateInspectionStatus.success());
}

export default function* InspectionListSaga() {
  yield all([
    takeEvery(
      getType(actions.getInspectionPdfDetails.request),
      safe(getInspectionDetails, actions.getInspectionPdfDetails.failure)
    ),
    takeEvery(
      getType(actions.updateInspectionStatus.request),
      safe(updateInspectionStatus, actions.updateInspectionStatus.failure)
    ),
    takeEvery(
      getType(actions.getSelectedInspectionsData.request),
      safe(
        getSelectedInspectionsData,
        actions.getSelectedInspectionsData.failure
      )
    )
  ]);
}
