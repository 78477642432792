import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';
import { selectEditableOption } from './selectors';

export function* getOptions({
  config
}: ReturnType<typeof actions.getOptionsRequest>): SagaIterator {
  const { activeOptionsApiUrl, customOptionsApiUrl } = config;
  const { data: active } = yield call(apiClient.get, activeOptionsApiUrl, {
    retryAction: actions.getOptionsRequest(config)
  });
  const { data: custom } = yield call(apiClient.get, customOptionsApiUrl, {
    retryAction: actions.getOptionsRequest(config)
  });

  yield put(
    actions.getOptionsSuccess(config, {
      active,
      custom
    })
  );
}

export function* updateOptions({
  payload
}: ReturnType<typeof actions.updateCustomOptions.request>): SagaIterator {
  const { config, requestData } = payload;
  const { reducerPath, activeOptionsApiUrl } = config;
  const editableOption = yield select(selectEditableOption(reducerPath));

  if (editableOption) {
    const { id } = editableOption;

    // quickfix until proper implementation on FE and BE, need to remove query params
    const url = activeOptionsApiUrl.split('?')[0];

    yield call(apiClient.put, `${url}/${id}`, requestData, {
      retryAction: actions.updateCustomOptions.request(payload)
    });
    yield put(actions.setEditableOption(config, null));
    yield put(actions.updateCustomOptions.success({ config, id: null }));
  } else {
    const { data } = yield call(
      apiClient.post,
      activeOptionsApiUrl,
      requestData,
      {
        retryAction: actions.updateCustomOptions.request(payload)
      }
    );
    yield put(actions.updateCustomOptions.success({ config, id: data }));
  }

  yield put(actions.getOptionsRequest(config));
}

export function* changeOptionStatus({
  config,
  payload
}: ReturnType<typeof actions.changeOptionStatusRequest>): SagaIterator {
  const { id, status } = payload;
  const { activeOptionsApiUrl } = config;

  // quickfix until proper implementation on FE and BE, need to remove query params
  const url = activeOptionsApiUrl.split('?')[0];

  yield call(
    apiClient.put,
    `${url}/${id}/change-status`,
    {
      status
    },
    {
      retryAction: actions.changeOptionStatusRequest(config, payload)
    }
  );
  yield put(actions.changeOptionStatusSuccess(config));
  yield put(actions.getOptionsRequest(config));
}

export default function* EditableSelectSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getOptionsRequest),
      safe(getOptions, actions.getOptionsFailure)
    ),
    takeEvery(
      getType(actions.updateCustomOptions.request),
      safe(updateOptions, actions.updateCustomOptions.failure)
    ),
    takeEvery(
      getType(actions.changeOptionStatusRequest),
      safe(changeOptionStatus, actions.changeOptionStatusFailure)
    )
  ]);
}
