import moment from 'moment';
import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { STATE_CHANGES } from 'src/config/assetUrls';
import { addRowsLocally } from 'src/components/Tables/TableView/store/actions';
import { DATEPICKER_DATE_FORMAT } from 'src/config';
import * as actions from './actions';
import * as selectors from './selectors';
import {
  EDIT_STATE_CHANGE_FORM_CONFIG,
  EDIT_STATE_CHANGING_TABLE_CONFIG
} from '../const';
import { normalizeFormResponse } from './utils';
import { setFormValues } from '../../StateChangeStep/store/actions';
import { selectNormalizedList } from '../../StateChangeEquipmentsStep/store/selectors';
import { selectFormCreateData } from '../../StateChangeStep/store/selectors';
import { getStateChange } from '../../StateChangeDetailsView/store/actions';

export function* updateStateChange({
  payload
}: ReturnType<typeof actions.updateStateChange.request>): SagaIterator {
  const { id, formPath, equipmentsPath } = payload;

  const [formData, equipment] = yield all([
    select(selectFormCreateData(formPath)),
    select(selectNormalizedList(equipmentsPath))
  ]);

  yield call(
    apiClient.put,
    `${STATE_CHANGES}/${id}`,
    {
      ...formData,
      equipment,
      status: StatusEnum.Draft
    },
    {
      retryAction: actions.updateStateChange.request(payload)
    }
  );

  yield put(actions.updateStateChange.success());
}

export function* getStateChangeData(): SagaIterator {
  const id = yield select(selectors.selectStateChangeId);

  const { data } = yield call(apiClient.get, `${STATE_CHANGES}/${id}`, {
    retryAction: actions.getStateChangeData.request()
  });
  const { equipment, ...formValues } = data;

  yield put(getStateChange.success(data));

  yield put(
    addRowsLocally(EDIT_STATE_CHANGING_TABLE_CONFIG(id), {
      rows: equipment
    })
  );

  yield put(
    setFormValues(
      EDIT_STATE_CHANGE_FORM_CONFIG,
      normalizeFormResponse({
        ...formValues,
        stateChangeDate: moment().format(DATEPICKER_DATE_FORMAT)
      })
    )
  );
  yield put(actions.getStateChangeData.success());
}

export default function* StateChangeEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.updateStateChange.request),
      safe(updateStateChange, actions.updateStateChange.failure)
    ),
    takeEvery(
      getType(actions.getStateChangeData.request),
      safe(getStateChangeData, actions.getStateChangeData.failure)
    )
  ]);
}
