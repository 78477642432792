import { createAsyncAction } from 'typesafe-actions';
import { RouteSchemeState, RouteSchemeValues } from '../../types';

export const createRouteScheme = createAsyncAction(
  '@ROUTE_SCHEME/CREATE_ROUTE_SCHEME_REQUEST',
  '@ROUTE_SCHEME/CREATE_ROUTE_SCHEME_SUCCESS',
  '@ROUTE_SCHEME/CREATE_ROUTE_SCHEME_FAILURE'
)<RouteSchemeValues, undefined, Error>();

export const getRouteSchemeData = createAsyncAction(
  '@ROUTE_SCHEME/GET_ROUTE_SCHEME_DATA_REQUEST',
  '@ROUTE_SCHEME/GET_ROUTE_SCHEME_DATA_SUCCESS',
  '@ROUTE_SCHEME/GET_ROUTE_SCHEME_DATA_FAILURE'
)<undefined, RouteSchemeState, Error>();
