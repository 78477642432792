import { object, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { EQUIPMENT_AUTOCOMPLETE } from 'src/config/assetUrls';
import { Row } from 'src/components/Tables/types';

export const EQUIPMENT_NAME = 'EQUIPMENT_AUTOCOMPLETE';
export const EQUIPMENT_PATH = 'asset.installation.equipmentAutocomplete';

export const EQUIPMENT_CONFIG = (equipmentId: number) => ({
  optionsApiUrl: `${EQUIPMENT_AUTOCOMPLETE}/${equipmentId}`,
  reducerName: EQUIPMENT_NAME,
  reducerPath: EQUIPMENT_PATH
});

export const initialFormState = {
  isOpen: false,
  isEditMode: false,
  values: {}
};

export const columns = [
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '25%'
  },
  {
    label: 'Назва',
    value: '',
    sortable: true,
    width: '25%',
    changeValue: (row: Row) => row.name
  },
  {
    label: 'IMEI',
    value: 'imei',
    sortable: true,
    width: '25%'
  },
  {
    label: 'Запчастини',
    value: 'spareParts',
    sortable: true,
    width: '25%'
  }
];

export const equipmentFormValidationScheme = object().shape({
  barcode: string()
    .matches(/^\d+$/, ERROR_MESSAGES.TYPE_ERROR)
    .max(13, 'Значення повинно містити не більше 13 цифр')
    .required(ERROR_MESSAGES.REQUIRED)
});
