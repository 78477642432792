import { SagaIterator } from 'redux-saga';
import moment from 'moment';
import { all, call, put, select, takeEvery, delay } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import {
  RIDE_CHANGEOVER,
  RIDE_MODE,
  RIDE_SPECIAL_MODE,
  RIDE_TERMINATION,
  ROUTES,
  ROUTES_STOP_UNAVAILABILITY
} from 'src/config/avlUrls';
import { safe } from 'src/utils/sagas';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import { selectRideSpecialMode, selectRouteRideMode } from './selectors';
import { normalizeRides } from '../utils';

export function* openSnackbar(title: string, method: 'post' | 'delete') {
  yield put(
    enqueueSnackbar({
      key: 'stadium_form',
      message: `${title} ${method === 'post' ? 'встановлено' : 'скасовано'}`,
      options: {
        variant: 'success'
      }
    })
  );
}

function* fetchStadiumData(routeId: number, vehicleId?: number) {
  const { data } = yield call(apiClient.get, `${ROUTES}/${routeId}`, {
    params: {
      vehicleId
    }
  });
  data.traffic.rides = normalizeRides(data.traffic.rides);
  return data;
}

export function* updateTrafficStadiumDataByRouteId({
  payload: { routeId }
}: ReturnType<
  typeof actions.updateTrafficStadiumDataByRouteId.request
>): SagaIterator {
  const trafficStadiumData = yield call(fetchStadiumData, routeId);

  yield put(
    actions.updateTrafficStadiumDataByRouteId.success({
      routeId,
      trafficStadiumData
    })
  );
}

function* getTrafficStadiumData(
  routeId: number,
  vehicleId?: number
): SagaIterator {
  const response = yield call(fetchStadiumData, routeId, vehicleId);

  return { ...response, id: routeId ?? null, gpsLoading: true };
}

export function* getTrafficStadiumsData({
  payload
}: ReturnType<typeof actions.getTrafficStadiumsData.request>): SagaIterator {
  const { routeIds, vehicleId, counteragentId, counteragentTreeCode } = payload;
  let trafficStadiums = [];

  if (routeIds?.length) {
    // @ts-ignore
    // fetch traffic stadiums data in parallel
    trafficStadiums = yield all(
      routeIds.map(id => getTrafficStadiumData(id, vehicleId))
    );
  } else {
    // fake loading to show user that we are subscribing for gps topic
    yield delay(500);
  }

  yield put(
    actions.getTrafficStadiumsData.success({
      trafficStadiums,
      counteragentId,
      counteragentTreeCode
    })
  );
}

export function* updateStopUnavailability({
  payload
}: ReturnType<typeof actions.updateStopUnavailability.request>) {
  const { busStopId, routeId, ...body } = payload;
  const method = Object.keys(body).length ? 'post' : 'delete';

  yield call(
    apiClient[method],
    `${ROUTES_STOP_UNAVAILABILITY(`${routeId}`, busStopId)}`,
    body,
    {
      retryAction: actions.updateStopUnavailability.request(payload)
    }
  );
  yield call(openSnackbar, 'Недоступність зупинки', method);

  yield put(actions.updateStopUnavailability.success());
}

export function* updateRouteRideMode({
  payload
}: ReturnType<typeof actions.updateRouteRideMode.request>): SagaIterator {
  const { specialModeDetails } = yield select(
    selectRouteRideMode(payload.routeId)
  );
  const specialModeSet = specialModeDetails?.rideDisruptionEventReasonId;

  const method = specialModeDetails.startsAtUtc ? 'delete' : 'post';
  const requestPayload = method === 'post' ? payload : undefined;

  const { ok } = yield call(
    apiClient[method],
    RIDE_MODE(payload.routeId),
    requestPayload,
    {
      retryAction: actions.updateRouteRideMode.request(payload)
    }
  );
  yield call(openSnackbar, 'Спецрежим руху', method);

  if (ok) {
    if (specialModeSet) {
      yield put(
        actions.cancelRouteRideModeByRouteId({
          routeId: payload.routeId
        })
      );
    } else {
      yield put(
        actions.setRouteRideModeDetailsByRouteId({
          rideDisruptionEventReasonId: payload.rideDisruptionEventReasonId,
          routeId: payload.routeId,
          notes: payload.notes,
          startsAtUtc: {
            seconds: moment.utc(payload.startsAtUtc).unix()
          },
          endsAtUtc: {
            seconds: moment.utc(payload.endsAtUtc).unix()
          }
        })
      );
    }
  }

  yield put(actions.updateRouteRideMode.success());
}

export function* updateRideChangeover({
  payload
}: ReturnType<typeof actions.updateRideChangeover.request>): SagaIterator {
  const { rideId, id, routeId, ...body } = payload;
  const method = id ? 'delete' : 'post';
  const changeoverId = id ? `?rideChangeoverId=${id}` : '';

  yield call(
    apiClient[method],
    `${RIDE_CHANGEOVER(routeId, rideId)}${changeoverId}`,
    body,
    {
      retryAction: actions.updateRideChangeover.request(payload),
      params: { rideChangeoverId: id }
    }
  );
  yield call(openSnackbar, 'Заміну ТЗ/Водія', method);

  yield put(actions.updateRideChangeover.success());
}

export function* updateTerminateDeparture({
  payload
}: ReturnType<typeof actions.updateTerminateDeparture.request>): SagaIterator {
  const { id, routeId, ...body } = payload;
  const method = Object.keys(body).length ? 'post' : 'delete';

  yield call(apiClient[method], RIDE_TERMINATION(routeId, id), body, {
    retryAction: actions.updateTerminateDeparture.request(payload)
  });
  yield call(openSnackbar, 'Припинення випуску', method);

  yield put(actions.updateTerminateDeparture.success());
  yield put(
    actions.setTerminateDepartureByRouteId({
      ...payload,
      terminatesAt: {
        seconds: moment.utc(payload.terminatesAtUtc).unix()
      }
    })
  );
}

export function* updateRideSpecialMode({
  payload
}: ReturnType<typeof actions.updateRideSpecialMode.request>): SagaIterator {
  const { rideId, routeId, ...details } = payload;
  const rideMode = yield select(selectRideSpecialMode(routeId, rideId));

  const specialModeSet =
    rideMode.specialModeDetails?.rideDisruptionEventReasonId;
  const method = !specialModeSet ? 'post' : 'delete';
  const requestPayload = method === 'post' ? details : undefined;

  const { ok } = yield call(
    apiClient[method],
    RIDE_SPECIAL_MODE(routeId, rideId),
    requestPayload,
    {
      retryAction: actions.updateRideSpecialMode.request(payload)
    }
  );
  yield call(openSnackbar, 'Спецрежим ТЗ', method);

  yield put(actions.updateRideSpecialMode.success());

  if (ok && specialModeSet) {
    yield put(
      actions.cancelRideSpecialModeByRouteId({
        rideScheduleId: rideId,
        routeId
      })
    );
  }
}

export default function* TrafficStadiumSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getTrafficStadiumsData.request),
      safe(getTrafficStadiumsData, actions.getTrafficStadiumsData.failure)
    ),
    takeEvery(
      getType(actions.updateTrafficStadiumDataByRouteId.request),
      safe(
        updateTrafficStadiumDataByRouteId,
        actions.updateTrafficStadiumDataByRouteId.failure
      )
    ),
    takeEvery(
      getType(actions.updateRouteRideMode.request),
      safe(updateRouteRideMode, actions.updateRouteRideMode.failure)
    ),
    takeEvery(
      getType(actions.updateStopUnavailability.request),
      safe(updateStopUnavailability, actions.updateStopUnavailability.failure)
    ),
    takeEvery(
      getType(actions.updateRideChangeover.request),
      safe(updateRideChangeover, actions.updateRideChangeover.failure)
    ),
    takeEvery(
      getType(actions.updateTerminateDeparture.request),
      safe(updateTerminateDeparture, actions.updateTerminateDeparture.failure)
    ),
    takeEvery(
      getType(actions.updateRideSpecialMode.request),
      safe(updateRideSpecialMode, actions.updateRideSpecialMode.failure)
    )
  ]);
}
