export const CREATE_INSPECTION_FORM_NAME = 'CREATE_INSPECTION_FORM';
export const CREATE_INSPECTION_FORM_PATH = 'asset.inspections.create.data';
export const CREATE_INSPECTION_FORM_CONFIG = {
  reducerName: CREATE_INSPECTION_FORM_NAME,
  reducerPath: CREATE_INSPECTION_FORM_PATH
};

export const INSPECTIONS_EQUIPMENT_NAME = 'INSPECTIONS_EQUIPMENT_NAME';
export const INSPECTIONS_EQUIPMENT_PATH =
  'asset.inspections.create.equipmentTable';

export const INSPECTIONS_TABLE_CONFIG = {
  reducerName: INSPECTIONS_EQUIPMENT_NAME,
  reducerPath: INSPECTIONS_EQUIPMENT_PATH,
  apiUrl: '',
  snackbarMessages: {
    deleteRowSuccess: 'Успішно видалено'
  }
};
