import { createCustomAction } from 'typesafe-actions';
import { SelectedPayload } from 'src/components/Tables/TableWithPagination/types';
import {
  SortRowsAction,
  GetDataSuccessPayload
} from 'src/components/Tables/Table/types';
import { TableConfig } from '../types';
import { Row } from '../../types';

export const getDataRequest = createCustomAction(
  '@TABLE_VIEW/GET_DATA_REQUEST',
  (config: TableConfig) => ({ config })
);

export const getDataSuccess = createCustomAction(
  '@TABLE_VIEW/GET_DATA_SUCCESS',
  (config: TableConfig, payload: GetDataSuccessPayload) => ({
    payload,
    config
  })
);

export const getDataFailure = createCustomAction(
  '@TABLE_VIEW/GET_DATA_FAILURE',
  (config: TableConfig, payload: Error) => ({ payload, config })
);

export const addRowLocally = createCustomAction(
  '@TABLE_VIEW/ADD_ROW_LOCALLY',
  (config: TableConfig, payload: { row: Row }) => ({ payload, config })
);

export const addRowsLocally = createCustomAction(
  '@TABLE_VIEW/ADD_ROWS_LOCALLY',
  (config: TableConfig, payload: { rows: Row[] }) => ({ payload, config })
);

export const updateRowLocally = createCustomAction(
  '@TABLE_VIEW/UPDATE_ROW_LOCALLY',
  (config: TableConfig, payload: { oldRow: Row; newRow: Row }) => ({
    payload,
    config
  })
);

export const deleteRowLocally = createCustomAction(
  '@TABLE_VIEW/DELETE_ROW_LOCALLY',
  (config: TableConfig, payload: { row: Row }) => ({
    payload,
    config
  })
);

export const setRowsIsLocalProperty = createCustomAction(
  '@TABLE_VIEW/SET_ROWS_IS_LOCAL_PROPERTY',
  (config: TableConfig, payload: { strategy: string; ids: number[] }) => ({
    config,
    payload
  })
);

export const deleteRowRequest = createCustomAction(
  '@TABLE_VIEW/DELETE_ROW_REQUEST',
  (config: TableConfig, payload: { id: number; name: string }) => ({
    payload,
    config
  })
);

export const deleteRowSuccess = createCustomAction(
  '@TABLE_VIEW/DELETE_ROW_SUCCESS',
  (config: TableConfig) => ({ config })
);

export const deleteRowFailure = createCustomAction(
  '@TABLE_VIEW/DELETE_ROW_FAILURE',
  (config: TableConfig, payload: Error) => ({ payload, config })
);

export const deleteSelectedRowsRequest = createCustomAction(
  '@TABLE_VIEW/DELETE_SELECTED_ROWS_REQUEST',
  (config: TableConfig) => ({ config })
);

export const deleteSelectedRowsSuccess = createCustomAction(
  '@TABLE_VIEW/DELETE_SELECTED_ROWS_SUCCESS',
  (config: TableConfig) => ({ config })
);

export const deleteSelectedRowsFailure = createCustomAction(
  '@TABLE_VIEW/DELETE_SELECTED_ROWS_FAILURE',
  (config: TableConfig, payload: Error) => ({ payload, config })
);

export const sortRows = createCustomAction(
  '@TABLE_VIEW/SORT_ROWS',
  (config, payload: SortRowsAction) => ({ config, payload })
);

export const setSelectAll = createCustomAction(
  '@TABLE_VIEW/SET_SELECT_ALL',
  (config: TableConfig, payload: boolean) => ({ payload, config })
);

export const setSelected = createCustomAction(
  '@TABLE_VIEW/SET_SELECTED',
  (config: TableConfig, payload: SelectedPayload) => ({
    payload,
    config
  })
);

export const clearTableState = createCustomAction(
  '@TABLE_VIEW/CLEAR_TABLE_STATE',
  (config: TableConfig) => ({ config })
);
