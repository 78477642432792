import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { RouteSchemeSummary } from '../../types';

type RouteSchemeSummaryActions = ActionType<typeof actions>;

const initialState: RouteSchemeSummary = {
  directDirectionDistance: null,
  reverseDirectionDistance: null,
  busStopCountDirect: null,
  busStopCountReverse: null,
  facilities: [],
  dangerousZones: []
};

const routeSchemeSummaryReducers = createReducer<
  RouteSchemeSummary,
  RouteSchemeSummaryActions
>(initialState).handleAction(
  actions.getRouteSchemeSummary.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default routeSchemeSummaryReducers;
