import { FormikErrors } from 'formik';

export interface Error {
  source: string;
  message: string;
  code: string;
}

export const mapErrors = (
  errors: Error[],
  errorFieldMap: Record<string, string> = {}
) =>
  errors.map(({ source, ...error }) => ({
    ...error,
    source: errorFieldMap[source] || source
  }));

export const getFormErrors = (
  errors: Error[],
  errorMessagesMap: Record<string, string | undefined>
): FormikErrors<any> =>
  errors.reduce(
    (acc, error) => ({
      ...acc,
      [error.source]: errorMessagesMap[error.code]
    }),
    {}
  );

export const maxLength = (max: number): [number, string] => [
  max,
  `Введіть значення не більше ${max} символів`
];

export const valueFromTo = (from: number, to: number) =>
  `Введіть значення від ${from} до ${to}`;
