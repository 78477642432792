import { createAsyncAction } from 'typesafe-actions';
import { StateChangeDetailsPayload } from '../../types';

export const getStateChange = createAsyncAction(
  '@STATE_CHANGE/GET_STATE_CHANGE_REQUEST',
  '@STATE_CHANGE/GET_STATE_CHANGE_SUCCESS',
  '@STATE_CHANGE/GET_STATE_CHANGE_FAILURE'
)<{ id: string }, StateChangeDetailsPayload, Error>();

export const getSelectedStateChangesData = createAsyncAction(
  '@STATE_CHANGE/GET_SELECTED_STATE_CHANGES_REQUEST',
  '@STATE_CHANGE/GET_SELECTED_STATE_CHANGES_SUCCESS',
  '@STATE_CHANGE/GET_SELECTED_STATE_CHANGES_FAILURE'
)<{ id: string }[], StateChangeDetailsPayload[], Error>();
