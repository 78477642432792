import { all, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { PENALTIES } from 'src/config/clearingUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { createPenalty } from './actions';

export function* penaltyCreate({
  payload
}: ReturnType<typeof createPenalty.request>): SagaIterator {
  yield call(apiClient.post, PENALTIES, payload, {
    retryAction: createPenalty.request(payload)
  });
  yield put(navigateTo({ url: '/clearing/penalties' }));
  yield put(
    enqueueSnackbar({
      key: 'penalty_create',
      message: 'Штраф створено',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(createPenalty.success());
}

export default function* PenaltyCreateSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(createPenalty.request),
      safe(penaltyCreate, createPenalty.failure)
    )
  ]);
}
