import { FC } from 'react';
import Label from 'src/components/Labels/Label';
import { inspectionDocumentStatuses } from '../../const';
import { InspectionDocumentStatus } from '../../types';

interface Props {
  status: InspectionDocumentStatus;
}

const InspectionDocumentLabel: FC<Props> = ({ status }) => {
  const label = inspectionDocumentStatuses[status];

  const color =
    status === InspectionDocumentStatus.Completed ? 'draft' : 'success';

  return <Label color={color}>{label}</Label>;
};

export default InspectionDocumentLabel;
