import { createAsyncAction } from 'typesafe-actions';
import { CoefficientsGroup } from '../../types';

export const updateCoefficientsGroup = createAsyncAction(
  '@COEFFICIENTS/UPDATE_COEFFICIENTS_GROUP_REQUEST',
  '@COEFFICIENTS/UPDATE_COEFFICIENTS_GROUP_SUCCESS',
  '@COEFFICIENTS/UPDATE_COEFFICIENTS_GROUP_FAILURE'
)<CoefficientsGroup, undefined, Error>();

export const getCoefficientsGroup = createAsyncAction(
  '@COEFFICIENTS/GET_COEFFICIENTS_GROUP_REQUEST',
  '@COEFFICIENTS/GET_COEFFICIENTS_GROUP_SUCCESS',
  '@COEFFICIENTS/GET_COEFFICIENTS_GROUP_FAILURE'
)<undefined, CoefficientsGroup | null, Error>();
