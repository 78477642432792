import { RouterRootState, createMatchSelector } from 'connected-react-router';
import { RootState } from 'typesafe-actions';
import { get, isNaN } from 'lodash';
import { createSelector } from 'reselect';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL } from 'src/config';
import { EquipmentTypeEnum } from 'src/constants';
import { generateSendEmailAttachmentProps } from 'src/components/SendEmailModalForm/utils/generateSendEmailAttachmentProps';
import { generatePdfOfInstallation } from '../../../utils/generatePdfOfInstallation';
import { generateJsonOfInstallation } from '../../../utils/generateJsonOfInstallation';

export const selectFormValues = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}`, {});

const matchCreateSpaceParams = createMatchSelector<
  RouterRootState,
  { id: string }
>('/asset/installation/create/:id');

const matchEditSpaceParams = createMatchSelector<
  RouterRootState,
  { id: string }
>('/asset/installation/:id');

export const selectInstallationId = createSelector(
  matchCreateSpaceParams,
  matchEditSpaceParams,
  (createMatch, editMatch) => {
    if (createMatch) {
      return createMatch.params.id;
    }

    if (editMatch && !isNaN(Number(editMatch.params.id))) {
      return editMatch.params.id;
    }

    return '';
  }
);

export const selectFormInitialValues = (reducerPath: string) => (
  state: RootState
) => get(state, `ui.${reducerPath}`);

export const selectNormalizeData = (reducerPath: string) => (
  state: RootState
) => {
  const data = get(state, `ui.${reducerPath}`, {});

  return {
    installationDate: data.installDate,
    installingEmployeeId: data.installingEmployee?.id,
    acceptingEmployeeId: data.acceptingEmployee?.id,
    counteragentHolderId: data.counteragentHolder?.id,
    counteragentId: data.counteragent?.id,
    equipmentGroupId: data.equipmentType,
    notes: data.notes,
    employeeId: data.employee?.id,
    vehicleId: data.vehicleNumbers?.id
  };
};

export const selectEmailData = (
  reducerPath: string,
  installationId: string
) => (state: RootState) => {
  const {
    documentId,
    vehicleNumbers,
    installingEmployee,
    installDate,
    equipmentType
  } = get(state, `ui.${reducerPath}`, {});
  const data = get(state, `ui.asset.installation.emailData`);

  const date = utcToLocal(installDate as Date, DATE_FORMAT_FULL) as string;

  let title = '';
  if (equipmentType === EquipmentTypeEnum.Mobile) {
    title = ` ${vehicleNumbers?.name}`;
  }

  const subject = `Монтаж №${documentId}${title} ${installingEmployee?.name} ${date}`;
  const bodyText = `Здійснено монтаж обладнання.\nДокумент монтажу №${documentId} від ${date}`;
  const tags = [`installation:${installationId}`];

  const pdfFile = generatePdfOfInstallation(data);
  const jsonFile = generateJsonOfInstallation(data);
  const attachments = generateSendEmailAttachmentProps(
    subject,
    pdfFile,
    jsonFile
  );

  return { subject, bodyText, tags, attachments };
};

export const selectTitle = (reducerPath: string) => (state: RootState) => {
  const { documentId } = get(state, `ui.${reducerPath}`, {});

  return `Редагувати монтаж №${documentId}`;
};
