import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { EditableSelectState } from '../types';

type EditableSelectActions = ActionType<typeof actions>;

export const initialState: EditableSelectState = {
  editableOption: null,
  isOptionEditDialogShown: false,
  options: {
    active: [],
    custom: []
  }
};

const editableSelectReducers = createReducer<
  EditableSelectState,
  EditableSelectActions
>(initialState)
  .handleAction(actions.setOptionEditDialogShown, (state, { showDialog }) => ({
    ...state,
    isOptionEditDialogShown: showDialog
  }))
  .handleAction(actions.setEditableOption, (state, { editableOption }) => ({
    ...state,
    editableOption
  }))
  .handleAction(actions.getOptionsSuccess, (state, { payload }) => ({
    ...state,
    options: payload
  }));

export default editableSelectReducers;
