import { generateActionCrud } from 'src/components/Guards';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { BREAKDOWNS } from 'src/config/assetUrls';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.breakdowns,
    url: '/asset/breakdowns'
  }
];

export const BREAKDOWNS_STEPS = ['Деталі', 'Обладнання'];

export const CREATE_BREAKDOWNS_FORM_NAME = 'CREATE_BREAKDOWNS_FORM';
export const CREATE_BREAKDOWNS_FORM_PATH = 'asset.breakdowns.create.data';

export const CREATE_BREAKDOWN_FORM_CONFIG = {
  reducerName: CREATE_BREAKDOWNS_FORM_NAME,
  reducerPath: CREATE_BREAKDOWNS_FORM_PATH
};

export const BREAKDOWNS_EQUIPMENT_NAME = 'BREAKDOWNS_EQUIPMENT_TABLE_VIEW';
export const BREAKDOWNS_EQUIPMENT_PATH =
  'asset.breakdowns.create.breakdownsTable';

export const BREAKDOWNS_EQUIPMENT_TABLE_NAME = 'EQUIPMENT';

export enum EquipmentTypeEnum {
  Mobile = 1,
  Stationary = 2,
  Inspector = 3
}

export const getTableViewConfig = (id: number) => ({
  reducerName: BREAKDOWNS_EQUIPMENT_NAME,
  reducerPath: BREAKDOWNS_EQUIPMENT_PATH,
  apiUrl: `${BREAKDOWNS}/${id}/equipment`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено реєстрацію'
  },
  enableSetRowsIsLocalProperty: true
});

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'breakdowns'
  },
  ['download', 'resendDocument', 'breakdownsAllowEditing']
);
