import { createAsyncAction } from 'typesafe-actions';
import {
  CounteragentEditDTO,
  UpdateCounteragentCommand
} from '@eticket/avl-api-contracts';

export const getCounteragent = createAsyncAction(
  '@COUNTERAGENTS_EDIT/GET_COUNTERAGENT_REQUEST',
  '@COUNTERAGENTS_EDIT/GET_COUNTERAGENT_SUCCESS',
  '@COUNTERAGENTS_EDIT/GET_COUNTERAGENT_FAILURE'
)<undefined, CounteragentEditDTO, Error>();

export const updateCounteragent = createAsyncAction(
  '@COUNTERAGENTS_EDIT/UPDATE_COUNTERAGENT_REQUEST',
  '@COUNTERAGENTS_EDIT/UPDATE_COUNTERAGENT_SUCCESS',
  '@COUNTERAGENTS_EDIT/UPDATE_COUNTERAGENT_FAILURE'
)<UpdateCounteragentCommand, undefined, Error>();
