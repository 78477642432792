import { createAsyncAction } from 'typesafe-actions';

export const confirmInstallation = createAsyncAction(
  '@INSTALLATION_STEP/CONFIRM_INSTALLATION_REQUEST',
  '@INSTALLATION_STEP/CONFIRM_INSTALLATION_SUCCESS',
  '@INSTALLATION_STEP/CONFIRM_INSTALLATION_FAILURE'
)<number, undefined, Error>();

export const cancelInstallation = createAsyncAction(
  '@INSTALLATION_STEP/CANCEL_INSTALLATION_REQUEST',
  '@INSTALLATION_STEP/CANCEL_INSTALLATION_SUCCESS',
  '@INSTALLATION_STEP/CANCEL_INSTALLATION_FAILURE'
)<undefined, undefined, Error>();
