import { put, takeEvery, call, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { UNINSTALLATIONS } from 'src/config/assetUrls';
import * as actions from './actions';
import { UninstallationPayload } from './types';

export function* getUninstallation({
  payload
}: ReturnType<typeof actions.getUninstallation.request>): SagaIterator {
  const { id } = payload;

  const { data } = yield call(apiClient.get, `${UNINSTALLATIONS}/${id}`, {
    retryAction: actions.getUninstallation.request
  });

  yield put(actions.getUninstallation.success(data));
}

export function* getSelectedUninstallationsData({
  payload
}: ReturnType<
  typeof actions.getSelectedUninstallationsData.request
>): SagaIterator {
  const requests = payload.map(({ id }) =>
    call(apiClient.get, `${UNINSTALLATIONS}/${id}`, {
      retryAction: actions.getSelectedUninstallationsData.request(payload)
    })
  );

  const response = yield all(requests);

  const uninstallationsData = response.map(
    ({ data }: { data: UninstallationPayload[] }) => data
  );

  yield put(
    actions.getSelectedUninstallationsData.success(uninstallationsData)
  );
}

export default function* UninstallationDetailsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getUninstallation.request),
    safe(getUninstallation, actions.getUninstallation.failure)
  );
  yield takeEvery(
    getType(actions.getSelectedUninstallationsData.request),
    safe(
      getSelectedUninstallationsData,
      actions.getSelectedUninstallationsData.failure
    )
  );
}
