import { put, takeEvery, call, all, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INBOUNDS } from 'src/config/assetUrls';
import {
  getCounteragent,
  getVehicleModel
} from 'src/views/asset/sharedStore/sagas';
import * as actions from './actions';
import { selectInboundId } from './selectors';

export function* getInboundData({
  config
}: ReturnType<typeof actions.getInboundDataRequest>): SagaIterator {
  const id = yield select(selectInboundId);
  const { data } = yield call(apiClient.get, `${INBOUNDS}/${id}`, {
    retryAction: actions.getInboundDataRequest(config)
  });

  yield put(actions.getInboundDataSuccess(config, data));
}

export default function* InboundStepSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getCounteragentRequest),
      safe(getCounteragent, actions.getCounteragentFailure)
    ),
    takeEvery(
      getType(actions.getVehicleModelRequest),
      safe(getVehicleModel, actions.getVehicleModelFailure)
    ),
    takeEvery(
      getType(actions.getInboundDataRequest),
      safe(getInboundData, actions.getInboundDataFailure)
    )
  ]);
}
