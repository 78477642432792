import { createReducer, ActionType } from 'typesafe-actions';
import { UpdatePenaltyCommand } from '@eticket/clearing-api-contracts';
import * as actions from './actions';

type PenaltyEditActions = ActionType<typeof actions>;

export const initialState: UpdatePenaltyCommand = {
  name: '',
  amount: 0
};

const penaltyEditReducers = createReducer<
  UpdatePenaltyCommand,
  PenaltyEditActions
>(initialState).handleAction(
  actions.getPenalty.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default penaltyEditReducers;
