import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { EMPLOYEES } from 'src/config/avlUrls';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getEmployee(): SagaIterator {
  const id = yield select(selectors.selectEmployeeId);

  const { data } = yield call(apiClient.get, `${EMPLOYEES}/${id}`, {
    retryAction: actions.getEmployee.request()
  });
  yield put(actions.getEmployee.success(data));
}

export default function* employeeDetailsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getEmployee.request),
      safe(getEmployee, actions.getEmployee.failure)
    )
  ]);
}
