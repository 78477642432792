import { createAsyncAction } from 'typesafe-actions';
import { FilterData, ReportsData } from '../../types';

import { AnalyseRequestPayload } from '../../AnalyseBreakdownsView/types';

export const getReportData = createAsyncAction(
  '@ASSET_REPORTS/GET_REPORT_DATA_REQUEST',
  '@ASSET_REPORTS/GET_REPORT_DATA_SUCCESS',
  '@ASSET_REPORTS/GET_REPORT_DATA_FAILURE'
)<FilterData | AnalyseRequestPayload, ReportsData, Error>();
