import { FC } from 'react';
import { Box, makeStyles, Theme, Typography, colors } from '@material-ui/core';
import { Lens as CircleIcon } from '@material-ui/icons';
import { colorStateMap } from 'src/constants';

const useStyles = makeStyles<Theme, { id: number }>({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  circleIcon: ({ id }) => ({
    marginRight: 10,
    fontSize: 8,
    color: colorStateMap[id] || colors.purple[500]
  })
});

interface Props {
  id: number;
  name: string;
}

const StateTypeColumn: FC<Props> = ({ id, name }) => {
  const classes = useStyles({ id });

  return (
    <Box className={classes.root}>
      <CircleIcon className={classes.circleIcon} />
      <Typography variant="h6">{name}</Typography>
    </Box>
  );
};

export default StateTypeColumn;
