import { ActionType, createReducer } from 'typesafe-actions';
import { RouteSchemeDetails, RouteSchemeStatus } from '../types';
import * as actions from './actions';

type SettingsDetailsActions = ActionType<typeof actions>;

const initialState: RouteSchemeDetails = {
  name: '',
  routeName: '',
  segments: [],
  status: RouteSchemeStatus.Draft,
  stops: [],
  turningStopId: null,
  isDeletable: false
};

const routeSchemeDetailsReducer = createReducer<
  RouteSchemeDetails,
  SettingsDetailsActions
>(initialState).handleAction(
  actions.getSchemeDetails.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default routeSchemeDetailsReducer;
