import { ActionType, createReducer } from 'typesafe-actions';
import { ConfirmDialogState } from 'src/components/ConfrimDialog/types';
import * as actions from './actions';

export type DialogActions = ActionType<typeof actions>;

const initialState: ConfirmDialogState = {
  open: false
};

const reducers = createReducer<ConfirmDialogState, DialogActions>(
  initialState
).handleAction(actions.toggleConfirmDialog, ({ content }, { payload }) => ({
  content,
  ...payload
}));

export default reducers;
