import {
  RouteSchemeRow,
  RouteSchemeStatus
} from '../RoutesPassportLayout/Steps/RouteScheme/types';

export const checkTimetablesEnabled = (
  hasApprovedSchema: boolean,
  schemesList: RouteSchemeRow[]
) =>
  hasApprovedSchema ||
  schemesList.some(
    (element: RouteSchemeRow) => element.status === RouteSchemeStatus.Approved
  );
