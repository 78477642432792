import { createAsyncAction } from 'typesafe-actions';
import { StatesDetailsState } from '../../types';

export const getState = createAsyncAction(
  '@STATES_EDIT/GET_STATE_REQUEST',
  '@STATES_EDIT/GET_STATE_SUCCESS',
  '@STATES_EDIT/GET_STATE_FAILURE'
)<undefined, StatesDetailsState, Error>();

export const updateState = createAsyncAction(
  '@STATES_EDIT/UPDATE_STATE_REQUEST',
  '@STATES_EDIT/UPDATE_STATE_SUCCESS',
  '@STATES_EDIT/UPDATE_STATE_FAILURE'
)<Omit<StatesDetailsState, 'id'>, undefined, Error>();
