import { array, number, object, string } from 'yup';
import { PM } from 'leaflet';
import { maxLength } from 'src/utils/validation';
import { STOPS_AUTOCOMPLETE } from 'src/config/avlUrls';
import { AVL_ERROR_MESSAGES, ERROR_MESSAGES } from 'src/constants/validation';
import { RouteSchemeStatus } from '../types';

const stopSchema = object()
  .shape({
    id: number(),
    name: string(),
    code: string()
  })
  .nullable();

export const routeSchemeValidationSchema = object().shape({
  name: string()
    .max(...maxLength(100))
    .when('status', {
      is: RouteSchemeStatus.Approved,
      then: string().required(ERROR_MESSAGES.REQUIRED)
    }),
  status: string(),
  stops: array()
    .of(stopSchema)
    .when('status', {
      is: RouteSchemeStatus.Approved,
      then: array().of(
        stopSchema.test({
          message: ERROR_MESSAGES.REQUIRED,
          test: value => !!value?.id
        })
      )
    }),
  turningStopId: number()
    .nullable()
    .when('status', {
      is: RouteSchemeStatus.Approved,
      then: number()
        .required('Оберіть зупинку розвороту')
        .test({
          message:
            'Зупинка розвороту не може бути початковою або кінцевою зупинкою',
          test(value) {
            const { stops } = this.parent;

            return (
              value !== stops[0]?.id && value !== stops[stops.length - 1]?.id
            );
          }
        })
    })
});

// @ts-ignore
export const MAP_SETTINGS: PM.DrawControlOptions = {
  position: 'topright',
  rotateMode: false,
  drawMarker: false,
  drawCircle: false,
  drawCircleMarker: false,
  drawPolygon: false,
  cutPolygon: false,
  drawRectangle: false,
  dragMode: false
};

export const STOPS_AUTOCOMPLETE_NAME = 'STOPS_AUTOCOMPLETE';

export const STOPS_AUTOCOMPLETE_CONFIG = {
  reducerName: STOPS_AUTOCOMPLETE_NAME,
  reducerPath: 'avl.routes.schemes.stopsAutocomplete',
  optionsApiUrl: STOPS_AUTOCOMPLETE,
  prohibitInitialLoad: true
};

export const SEGMENTS_ERROR_MESSAGES = {
  invalidRouteSchemaPathsCount:
    'Для деяких зупинок було знайдено більше двох шляхів',
  notFound: "Перевірте порядковість з'єднання зупинок на маршруті",
  pathOfRouteSchemaShouldBeClosed:
    "Не всі зупинки з'єднані шляхами на маршруті",
  equalValidator: "Не всі зупинки з'єднані шляхами на маршруті"
} as Record<string, string>;

export const SCHEME_FORM_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  uniqueError: ERROR_MESSAGES.UNIQUE
};
