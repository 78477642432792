import { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { getFirstPathPart } from 'src/utils/path';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import Protected from 'src/components/Guards/Protected';
import NavItem from './NavItem';
import config from './config';

function renderNavItems({ items, ...rest }: { items: any; pathname: string }) {
  return (
    <List disablePadding>
      {items.reduce(
        // @ts-ignore
        (acc: any, item: any) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}: {
  acc: any;
  pathname: any;
  item: any;
  depth: any;
}) {
  const key = item.title + depth;
  const isMatched = Boolean(
    matchPath(pathname, {
      path: item.href,
      exact: false
    })
  );

  if (item.items) {
    acc.push(
      <Protected action={item.action} key={key}>
        {/* @ts-ignore */}
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          open={isMatched}
          title={item.title}
        >
          {renderNavItems({
            // @ts-ignore
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      </Protected>
    );
  } else {
    acc.push(
      <Protected action={item.action} key={key}>
        {/* @ts-ignore */}
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          title={item.title}
          active={isMatched}
        />
      </Protected>
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({
  openMobile,
  onMobileClose
}: {
  openMobile: any;
  onMobileClose: any;
}) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const menuPath = getFirstPathPart(location);
  const [currentConfig] = config.filter(x => x.menuItem === menuPath);

  if (!currentConfig) return null;

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Divider />
        <Box p={2}>
          {currentConfig.items.map((menuItem: any) => (
            <Protected action={menuItem.action} key={menuItem.id}>
              <List
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {menuItem.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: menuItem.items,
                  pathname: location.pathname
                })}
              </List>
            </Protected>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

export default NavBar;
