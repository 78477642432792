import { createAsyncAction } from 'typesafe-actions';
import { BillingDocumentDetailsState } from '../types';
import { BillingDocumentStatusEnum } from '../../types';

export const getBillingDocument = createAsyncAction(
  '@BILLING_DOCUMENTS_DETAILS/GET_BILLING_DOCUMENTS_REQUEST',
  '@BILLING_DOCUMENTS_DETAILS/GET_BILLING_DOCUMENTS_SUCCESS',
  '@BILLING_DOCUMENTS_DETAILS/GET_BILLING_DOCUMENTS_FAILURE'
)<undefined, BillingDocumentDetailsState, Error>();

export const updateBillingDocumentStatus = createAsyncAction(
  '@BILLING_DOCUMENTS_DETAILS/UPDATE_BILLING_DOCUMENTS_STATE_REQUEST',
  '@BILLING_DOCUMENTS_DETAILS/UPDATE_BILLING_DOCUMENTS_STATE_SUCCESS',
  '@BILLING_DOCUMENTS_DETAILS/UPDATE_BILLING_DOCUMENTS_STATE_FAILURE'
)<BillingDocumentStatusEnum, undefined, Error>();
