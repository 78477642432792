import { put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { COEFFICIENTS_ADJUSTMENTS } from 'src/config/clearingUrls';
import { getDataSuccess } from 'src/components/Tables/Table/store/actions';
import { config } from 'src/views/clearing/coefficientsAdjustments/const';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getCoefficientAdjustments(): SagaIterator {
  const id = yield select(selectors.selectCoefficientsAdjustmentsId);

  const { data } = yield call(
    apiClient.get,
    `${COEFFICIENTS_ADJUSTMENTS}/${id}`,
    {
      retryAction: actions.getCoefficientAdjustments.request
    }
  );
  yield put(getDataSuccess(config, { list: data.timeCoefficients }));
  yield put(actions.getCoefficientAdjustments.success(data));
}

export default function* coefficientAdjustmentsDetailsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getCoefficientAdjustments.request),
    safe(getCoefficientAdjustments, actions.getCoefficientAdjustments.failure)
  );
}
