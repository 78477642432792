import { ActionType, createReducer } from 'typesafe-actions';
import { Row } from 'src/components/Tables/types';
import * as actions from './actions';
import { getSorters } from '../../TableWithPagination/utils';
import { getComparator, stableSort } from '../utils';
import { TableState } from '../types';

type TableActions = ActionType<typeof actions>;

export const initialState: TableState = {
  list: [],
  sorters: []
};

const tableReducers = createReducer<TableState, TableActions>(initialState)
  .handleAction(actions.getDataSuccess, (state, { payload }) => ({
    ...state,
    ...payload
  }))
  .handleAction(
    actions.sortRows,
    (state, { payload: { column, rows, sorterFunc } }) => {
      let newRows: Row[];
      const { sorters } = state;
      const newSorters = getSorters(sorters, column);
      const [{ order, propertyName }] = newSorters;

      if (sorterFunc) {
        newRows = sorterFunc({
          order,
          key: propertyName,
          list: rows
        });
      } else {
        newRows = stableSort(rows, getComparator(order, propertyName));
      }

      return {
        ...state,
        list: newRows,
        sorters: newSorters
      };
    }
  );

export default tableReducers;
