import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
import { enableES5 } from 'immer';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { ConnectedRouter } from 'connected-react-router';
import { restoreSettings } from 'src/utils/settings';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { axiosInterceptor } from 'src/utils/api/apiClient';
import userManager from 'src/utils/userManager';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store, { browserHistory } from './store';
import { SettingsProvider } from './context/SettingsContext';
import ErrorBoundary from './views/errors/ErrorBoundary';
import { OPEN_TRIP_PLANNER } from './config';

const client = new ApolloClient({
  uri: OPEN_TRIP_PLANNER,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    }
  }
});

enableES5();

const settings = restoreSettings();

// We need Redux store dispatch method in our interceptor.
// Direct Redux store import inside of apiClient will cause dependency cycle.
// The interceptor initialized here, because Redux store also initialized here.
axiosInterceptor(store);

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <SettingsProvider settings={settings}>
        <ConnectedRouter history={browserHistory}>
          <ApolloProvider client={client}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </ApolloProvider>
        </ConnectedRouter>
      </SettingsProvider>
    </OidcProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
