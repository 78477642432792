import { FC } from 'react';
import { Row } from 'src/components/Tables/types';
import { StatusEnum } from 'src/constants';
import SwitchEdit from 'src/components/SwitchEdit';
import { isToggleDateRestricted, TOGGLE_DISABLED_TEXT } from '../const';

interface Props {
  data: Row;
  onChange: (row: Row) => Promise<void>;
}

const TransferSwitchColumn: FC<Props> = ({ data, onChange }) => {
  const { status, dateTransfer, documentNumber } = data;

  const disabled =
    status === StatusEnum.Completed && isToggleDateRestricted(dateTransfer);

  const handleChangeSwitch = async () => {
    if (status !== StatusEnum.Draft) {
      await onChange(data);
    }
  };

  const confirmMessage = `Ви впевнені, що хочете розфіксувати документ ${documentNumber}? Все обладнання з цього документа буде повернуте на попередній підрозділ`;

  return (
    <SwitchEdit
      checked={status === StatusEnum.Draft}
      disabled={disabled}
      tooltip={TOGGLE_DISABLED_TEXT}
      onChange={handleChangeSwitch}
      confirmMessage={confirmMessage}
    />
  );
};

export default TransferSwitchColumn;
