import { MAIN_MENU } from 'src/constants';
import { ADMINISTRATION_PATH } from 'src/routes/administrationRoutes';
import { Action, Column, Schema } from './types';

const COLUMN_BASE_NAME = 'entityFrameworkEvent.entries[0]';

export const columns: Column[] = [
  {
    label: 'Дата і час',
    width: '13%',
    value: 'modifiedAtUtc',
    sorterKey: 'startDate'
  },
  {
    label: 'Користувач',
    width: '20%',
    value: 'email',
    sorterKey: 'Email'
  },
  {
    label: 'Дія',
    width: '15%',
    value: 'action',
    sorterKey: `${COLUMN_BASE_NAME}.action`
  },
  { label: 'Розділ', width: '15%', value: 'schema', sorterKey: 'eventType' },
  {
    label: "Об'єкт",
    width: '25%',
    value: 'name',
    sorterKey: `${COLUMN_BASE_NAME}.name`
  }
];

export const SECTIONS_MAP: Record<Schema, string> = {
  [Schema.AvlDbContext]: 'Маршрути',
  [Schema.AssetManagementDBContext]: 'Обладнання',
  [Schema.ClearingDBContext]: 'Нарахування'
};

export const ACTIONS_MAP: Record<Action, string> = {
  [Action.Insert]: 'Створення',
  [Action.Update]: 'Зміна',
  [Action.Delete]: 'Видалення'
};

export const breadcrumbs = [
  {
    text: MAIN_MENU.administration,
    url: `/${ADMINISTRATION_PATH}`
  }
];

export const FILTER_ALL_VALUE = 'All';

export const stringify = (value: unknown) => JSON.stringify(value, null, 2);
