import { FC } from 'react';
import { StatusEnum } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import SwitchEdit from 'src/components/SwitchEdit';
import { isToggleDateRestricted, TOGGLE_DISABLED_TEXT } from '../const';

interface InspectionSwitchProps {
  data: Row;
  onChange: (row: Row) => Promise<void>;
}

const InspectionSwitch: FC<InspectionSwitchProps> = ({ data, onChange }) => {
  const { status, documentNumber, inspectionDate } = data;
  const isFixed = status !== StatusEnum.Draft;
  const disabled = isFixed && isToggleDateRestricted(inspectionDate);

  const handleChangeSwitch = async () => {
    if (isFixed) {
      await onChange(data);
    }
  };

  const confirmMessage = `Ви впевнені, що хочете розфіксувати документ ${documentNumber}? Всі інспекції обладнання з цього документа не будуть відображатись в Моніторингу та Звітах`;

  return (
    <SwitchEdit
      checked={status === StatusEnum.Draft}
      disabled={disabled}
      tooltip={TOGGLE_DISABLED_TEXT}
      onChange={handleChangeSwitch}
      confirmMessage={confirmMessage}
    />
  );
};

export default InspectionSwitch;
