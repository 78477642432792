import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { RouteUrls } from 'src/components/Tables/types';
import { generateActionCrud } from 'src/components/Guards';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.avl,
    url: '/avl'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.routes,
    url: '/avl/routes'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'avl',
    sub: 'routes'
  },
  ['download']
);

export enum TenderStatusEnum {
  Absent = 1,
  InProgress = 2,
  Completed = 3
}

export enum PassportStatusEnum {
  Draft = 0,
  Approved = 1
}

export const passportStatusMap = {
  [PassportStatusEnum.Draft]: 'Draft',
  [PassportStatusEnum.Approved]: 'Approved'
};

export enum StepType {
  Settings = 'settings',
  Scheme = 'scheme',
  Timetable = 'timetable'
}

export const ROUTE_URLS: RouteUrls = {
  details: id => `/avl/routes/details/settings/${id}`,
  edit: id => `/avl/routes/edit/settings/${id}`
};

export enum RoutePassportStatus {
  Draft = 'Draft',
  Approved = 'Approved',
  ApprovedUA = 'Затверджено',
  DraftUA = 'Чернетка'
}

export const detailsActions = {
  edit: {
    action: crudActions.update
  },
  delete: {
    action: crudActions.delete
  }
};

export const UNSAFE_CHANGES_MESSAGE_SCHEME =
  'Ви впевнені, що хочете покинути сторінку? Зміни не будуть збережені.';
