import { generateActionCrud } from 'src/components/Guards';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { getDateDifference } from 'src/utils/date';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.stateChanges,
    url: '/asset/state-changes'
  }
];

export const MAX_DAYS_AFTER_STATE_CHANGE = 40;
export const TOGGLE_DISABLED_TEXT = `Документ зафіксовано понад ${MAX_DAYS_AFTER_STATE_CHANGE} днів тому. Редагування заборонене.`;
export const isToggleDateRestricted = (date: string) =>
  getDateDifference(date) > MAX_DAYS_AFTER_STATE_CHANGE;

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'stateChanges'
  },
  ['download', 'resendDocument', 'stateChangeAllowEditing']
);

export enum StateChangeStatusEnum {
  Draft = 0,
  Fixed = 1
}

export const stateChangeStatusEnumStatusMap = {
  [StateChangeStatusEnum.Draft]: 'Draft',
  [StateChangeStatusEnum.Fixed]: 'Fixed'
};

export const STATE_CHANGE_STEPS = ['Зміна стану', 'Обладнання'];

export const stateChangeDocumentPdf = [
  {
    label: 'Підрозділ',
    value: 'counteragentFullName'
  },
  {
    label: 'Адреса підрозділу',
    value: 'counteragentAddress'
  },
  {
    label: 'Змінив стан',
    value: 'employeeFullName'
  },
  {
    label: 'Примітка',
    value: 'notes'
  }
];

export const stateChangeColumnsPdf = [
  {
    label: '№',
    value: 'index'
  },
  {
    label: 'Штрихкод',
    value: 'barcode'
  },
  {
    label: 'Назва',
    value: 'name'
  },
  {
    label: 'Модель',
    value: 'productType'
  },
  {
    label: 'Виробник',
    value: 'manufacturer'
  },
  {
    label: 'IMEI',
    value: 'imei'
  },
  {
    label: 'Попередній стан',
    value: 'previousState'
  },
  {
    label: 'Новий стан',
    value: 'newState'
  },
  {
    label: 'Запчастини',
    value: 'spareParts'
  }
];

export const stateChangeFieldsJson = [
  'documentNumber',
  'stateChangeDate',
  'employeeName',
  'counteragentFullName',
  'counteragentAddress',
  'notes',
  'equipment',
  {
    label: 'equipment',
    fields: [
      'name',
      'productType',
      'manufacturer',
      'barcode',
      'imei',
      'previousState',
      'newState',
      'spareParts'
    ]
  }
];
