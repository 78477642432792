import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { apiClient } from 'src/utils/api';
import * as actions from './actions';
import { ReportDataByReportType } from './types';
import { reportDataMockByReportType } from './tests/mocks';
import { ReportType } from '../types';
import { GetReportDataRequestPayload } from './actions';
import { avlReportsLinks } from '../../const';

export const fetchReportData = <TReportType extends ReportType>(
  reportType: TReportType,
  filters: GetReportDataRequestPayload['filters']
): Promise<{ data: ReportDataByReportType[TReportType] }> => {
  if (!avlReportsLinks[reportType]) {
    return Promise.resolve({ data: reportDataMockByReportType[reportType] });
  }

  return apiClient.get(avlReportsLinks[reportType], {
    params: {
      counteragentId: filters.counteragent,
      date: filters.date,
      routePassportId: filters.route?.id,
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
      driverId: filters.driver?.id || null
    }
  });
};

export function* getReportDataSaga({
  payload
}: {
  payload: GetReportDataRequestPayload;
}): SagaIterator {
  const { data } = yield call(
    fetchReportData,
    payload.reportType,
    payload.filters
  );

  yield put(
    actions.getReportData.success({
      [payload.reportType]: data
    })
  );
}

export default function* DownloadReportsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getReportData.request),
    safe(getReportDataSaga, actions.getReportData.failure)
  );
}
