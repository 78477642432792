import { put, takeEvery, call, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { TRANSFERS } from 'src/config/assetUrls';
import * as actions from './actions';
import { TransferDetailsPayload } from '../../types';

export function* getTransfer({
  payload
}: ReturnType<typeof actions.getTransfer.request>): SagaIterator {
  const { id } = payload;

  const { data } = yield call(apiClient.get, `${TRANSFERS}/${id}`, {
    retryAction: actions.getTransfer.request
  });

  yield put(actions.getTransfer.success(data));
}

export function* getSelectedTransfersData({
  payload
}: ReturnType<typeof actions.getSelectedTransfersData.request>): SagaIterator {
  const requests = payload.map(({ id }) =>
    call(apiClient.get, `${TRANSFERS}/${id}`, {
      retryAction: actions.getSelectedTransfersData.request(payload)
    })
  );

  const response = yield all(requests);

  const transfersData = response.map(
    ({ data }: { data: TransferDetailsPayload[] }) => data
  );

  yield put(actions.getSelectedTransfersData.success(transfersData));
}

export default function* TransferDetailsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getTransfer.request),
    safe(getTransfer, actions.getTransfer.failure)
  );
  yield takeEvery(
    getType(actions.getSelectedTransfersData.request),
    safe(getSelectedTransfersData, actions.getSelectedTransfersData.failure)
  );
}
