import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { safe } from 'src/utils/sagas';
import { BREAKDOWNS_TYPES } from 'src/config/assetUrls';
import * as actions from './actions';

export function* createBreakdownsTypes({
  payload
}: ReturnType<typeof actions.createBreakdownsTypes.request>): SagaIterator {
  yield call(apiClient.post, BREAKDOWNS_TYPES, payload, {
    retryAction: actions.createBreakdownsTypes.request(payload)
  });
  yield put(navigateTo({ url: '/asset/dictionaries/breakdownsTypes' }));
  yield put(
    enqueueSnackbar({
      key: 'breakdown_types_create',
      message: 'Несправність створено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.createBreakdownsTypes.success());
}

export default function* BreakdownsTypesCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createBreakdownsTypes.request),
    safe(createBreakdownsTypes, actions.createBreakdownsTypes.failure)
  );
}
