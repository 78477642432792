import { createReducer, ActionType } from 'typesafe-actions';
import { Values } from 'src/views/clearing/tariffs/types';
import * as actions from './actions';

type TariffEditActions = ActionType<typeof actions>;

const initialState: Values = {
  code: '',
  name: '',
  notes: '',
  passengerCapacity: [],
  tariffs: []
};

const tariffEditReducers = createReducer<Values, TariffEditActions>(
  initialState
).handleAction(actions.getTariff.success, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default tariffEditReducers;
