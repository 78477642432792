import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { SagaIterator } from 'redux-saga';
import { KPI_PARAMETERS_GROUPS } from 'src/config/clearingUrls';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import * as selectors from './selectors';
import * as actions from './actions';

export function* getKPIParametersGroup(): SagaIterator {
  const id = yield select(selectors.selectKPIParametersGroupId);
  const { data } = yield call(apiClient.get, `${KPI_PARAMETERS_GROUPS}/${id}`, {
    retryAction: actions.getKPIParametersGroup.request()
  });
  yield put(actions.getKPIParametersGroup.success(data));
}

export function* updateKPIParametersGroup({
  payload
}: ReturnType<typeof actions.updateKPIParametersGroup.request>): SagaIterator {
  const id = yield select(selectors.selectKPIParametersGroupId);
  yield call(apiClient.put, `${KPI_PARAMETERS_GROUPS}/${id}`, payload, {
    retryAction: actions.updateKPIParametersGroup.request(payload)
  });
  yield put(navigateTo({ url: '/clearing/kpiParameters' }));
  yield put(
    enqueueSnackbar({
      key: 'kpi_parameters_update',
      message: 'Групу параметрів КПЕ оновлено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.updateKPIParametersGroup.success());
}

export default function* KPIParametersEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.updateKPIParametersGroup.request),
      safe(updateKPIParametersGroup, actions.updateKPIParametersGroup.failure)
    ),
    takeEvery(
      getType(actions.getKPIParametersGroup.request),
      safe(getKPIParametersGroup, actions.getKPIParametersGroup.failure)
    )
  ]);
}
