import { createAction } from 'typesafe-actions';
import { DialogInitialState, SpecialModeDialogState } from '../../../../types';

export const toggleChangeoverDialog = createAction(
  '@TRAFFIC_STADIUM/TOGGLE_CHANGEOVER_DIALOG',
  (payload: DialogInitialState) => payload
)();

export const toggleTerminationDialog = createAction(
  '@TRAFFIC_STADIUM/TOGGLE_TERMINATION_DIALOG',
  (payload: DialogInitialState) => payload
)();

export const toggleSpecialModeDialog = createAction(
  '@TRAFFIC_STADIUM/TOGGLE_RIDE_SPECIAL_MODE_DIALOG',
  (payload: SpecialModeDialogState) => payload
)();
