import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { selectConfirmDialog } from './store/selectors';
import { toggleConfirmDialog } from './store/actions';

const ConfirmDialog = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const {
    open,
    onCancel,
    onConfirm,
    title,
    content,
    confirmText,
    cancelText
  } = useSelector(selectConfirmDialog);

  const closeDialog = useCallback(
    () => dispatch(toggleConfirmDialog({ open: false })),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      open && closeDialog();
    };
  }, [closeDialog, open, pathname]);

  return (
    <Dialog open={open} onClose={closeDialog}>
      {title && (
        <DialogTitle>
          <Typography variant="h5">{title}</Typography>
        </DialogTitle>
      )}
      <DialogContent dividers>
        {typeof content === 'string' ? (
          <Typography>{content}</Typography>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel && onCancel();
            closeDialog();
          }}
        >
          {cancelText || 'Ні'}
        </Button>
        <Button
          onClick={() => {
            onConfirm && onConfirm();
            closeDialog();
          }}
          color="secondary"
          variant="contained"
        >
          {confirmText || 'Так'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
