import { createReducer, ActionType } from 'typesafe-actions';
import moment from 'moment';
import { DATEPICKER_DATE_FORMAT } from 'src/config';
import { EquipmentTypeEnum } from 'src/constants';
import * as actions from './actions';
import { InitialState } from './types';

export const initialState: InitialState = {
  documentId: '',
  installDate: moment().format(DATEPICKER_DATE_FORMAT),
  installingEmployee: null,
  acceptingEmployee: null,
  equipmentType: EquipmentTypeEnum.Mobile,
  counteragent: null,
  counteragentHolder: null,
  address: '',
  employee: null,
  vehicleNumbers: null,
  vehicleModelName: '',
  notes: ''
};

const installationStepReducer = createReducer<
  InitialState,
  ActionType<typeof actions>
>(initialState)
  .handleAction(actions.getCounteragentSuccess, (state, { payload }) => ({
    ...state,
    address: payload.address
  }))
  .handleAction(actions.getVehicleModelSuccess, (state, { payload }) => ({
    ...state,
    vehicleModelName: payload.vehicleModelName
  }))
  .handleAction(actions.setFormValues, (state, { values }) => ({
    ...state,
    ...values
  }))
  .handleAction(actions.getInstallationDataSuccess, (_, { payload }) => {
    const parseToAutocompleteValue = (name?: string, id = 0) =>
      name ? { name, id } : null;

    return {
      documentId: `${payload.documentNumber}`,
      installDate: payload.installationDate,
      installingEmployee: parseToAutocompleteValue(
        payload.installingEmployeeFullName,
        1
      ),
      acceptingEmployee: parseToAutocompleteValue(
        payload.acceptingEmployeeFullName,
        2
      ),
      equipmentType: payload.equipmentGroupId,
      counteragent: parseToAutocompleteValue(payload.counteragentName),
      counteragentHolder: parseToAutocompleteValue(
        payload.counteragentHolderName,
        payload.counteragentHolderId
      ),
      address: payload.counteragentAddress,
      employee: parseToAutocompleteValue(payload.employeeFullName),
      vehicleNumbers: parseToAutocompleteValue(payload.licensePlate),
      vehicleModelName: payload.vehicleModelName,
      notes: payload.notes,
      equipments: payload.equipments
    };
  });

export default installationStepReducer;
