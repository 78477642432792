import { Tooltip, Typography } from '@material-ui/core';
import React, { FC } from 'react';

interface Props {
  text: string;
  maxLength?: number;
}

const TextWithEllipsis: FC<Props> = ({ text, maxLength = 15 }) => {
  if (text?.length > maxLength) {
    return (
      <Tooltip title={text}>
        <Typography variant="body2">
          {text.substring(0, maxLength)}...
        </Typography>
      </Tooltip>
    );
  }

  return <Typography variant="body2">{text}</Typography>;
};

export default TextWithEllipsis;
