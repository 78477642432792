import { createAsyncAction } from 'typesafe-actions';
import { RouteSchemeDetails, RouteDataPdf } from '../types';

export const getSchemeDetails = createAsyncAction(
  '@ROUTE_SCHEMES/GET_SCHEME_DETAILS_REQUEST',
  '@ROUTE_SCHEMES/GET_SCHEME_DETAILS_SUCCESS',
  '@ROUTE_SCHEMES/GET_SCHEME_DETAILS_FAILURE'
)<undefined, RouteSchemeDetails, Error>();

export const getSchemeDetailsPdf = createAsyncAction(
  '@ROUTE_SCHEMES/GET_SCHEME_DETAILS_PDF_REQUEST',
  '@ROUTE_SCHEMES/GET_SCHEME_DETAILS_PDF_SUCCESS',
  '@ROUTE_SCHEMES/GET_SCHEME_DETAILS_PDF_FAILURE'
)<string, RouteDataPdf, Error>();
