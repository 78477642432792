import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  Tabs,
  Select,
  Hidden,
  useTheme,
  makeStyles,
  MenuItem
} from '@material-ui/core';
import { MAIN_MENU } from 'src/constants';
import { getFirstPathPart } from 'src/utils/path';
import { useSelector } from 'react-redux';
import { selectUserPermissions } from 'src/store/selectors';
import { isActionPresent } from 'src/hooks/useActionAllowed';
import { TABS_CONFIG } from './const';
import TabView from './TabView';

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  selectRoot: {
    '&:focus': {
      background: 'none'
    }
  },
  selectIcon: {
    color: palette.common.white
  },
  tabs: {
    [breakpoints.up('lg')]: {
      marginLeft: spacing(8)
    }
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: `calc(100% - ${spacing(3)}px)`,
      width: '100%',
      backgroundColor: palette.background.default
    }
  },
  tabsRoot: {
    border: 'none'
  }
}));

function Menu() {
  const classes = useStyles();
  const { palette } = useTheme();
  const location = useLocation();
  const permissions = useSelector(selectUserPermissions);

  const currentView = getFirstPathPart(location);

  const tabs = TABS_CONFIG.map(
    props =>
      isActionPresent(props.moduleAction, permissions) && (
        <TabView key={props.value} permissions={permissions} {...props} />
      )
  );

  const options = TABS_CONFIG.map(
    ({ label, link, linkAction, moduleAction, value }) => {
      const isDirectoryAllowed = isActionPresent(linkAction, permissions);
      const to = `/${value}${isDirectoryAllowed ? `/${link}` : ''}`;

      return isActionPresent(moduleAction, permissions) ? (
        <MenuItem
          // @ts-ignore cause of material ui issue with component prop
          component={Link}
          key={value}
          value={value}
          to={to}
        >
          {label}
        </MenuItem>
      ) : null;
    }
  );

  return (
    <>
      <Hidden smDown>
        <Box className={classes.tabs}>
          <Tabs
            classes={{
              root: classes.tabsRoot,
              indicator: classes.indicator
            }}
            TabIndicatorProps={{
              children: <span />
            }}
            value={currentView}
            variant="scrollable"
            textColor="inherit"
            onChange={() => {}}
          >
            {tabs}
          </Tabs>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box mt={0.5} display="flex" justifyContent="center">
          <Select
            classes={{ root: classes.selectRoot, icon: classes.selectIcon }}
            disableUnderline
            value={currentView}
            renderValue={value => (
              <Box color={palette.common.white}>
                {MAIN_MENU[value as keyof typeof MAIN_MENU] as string}
              </Box>
            )}
          >
            {options}
          </Select>
        </Box>
      </Hidden>
    </>
  );
}

export default Menu;
