import { InspectionPayload } from './types';

export const parseInspectionFormPayload = (values: InspectionPayload) => ({
  id: values.id,
  documentNumber: values.documentNumber,
  inspectionDate: values.inspectionDate.split('T')[0],
  inspectorEmployee: {
    id: values.inspectorId,
    name: values.inspectorFullName
  },
  equipmentGroupId: values.equipmentGroupId,
  vehicle: values.vehicleId
    ? {
        id: values.vehicleId,
        name: values.licensePlate,
        vehicleType: values.vehicleType,
        vehicleModelName: values.vehicleModel
      }
    : null,
  counteragent: {
    id: values.counteragentId,
    name: values.counteragentName,
    address: values.counteragentAddress
  },
  notes: values.notes
});

export const parseInspectionTablePayload = (
  rows: InspectionPayload['equipments']
) =>
  rows.map(el => ({
    ...el,
    id: el.assetId,
    state: el.status
  }));
