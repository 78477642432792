export interface FiltersValues {
  counteragentId: number | '';
  route: number[] | null;
}

export interface TrafficStadiumDataRequest {
  routeId?: number;
  counteragentId: number | string;
}

export interface TrafficStadiumsDataRequest {
  routeIds?: number[];
  vehicleId?: number;
  counteragentId: number | string;
  counteragentTreeCode?: string;
}

export interface UpdateTrafficStadiumDataByRouteIdRequest {
  routeId: number;
}

export interface Segment {
  fromStopId: number;
  toStopId: number;
  distanceMeters: number;
}

interface TimeStamp {
  seconds: number;
  nanoseconds?: number;
}

export interface CancelStopUnavailabilityEventData {
  busStopId: number;
}

export interface CancelStopUnavailabilityByRouteId
  extends CancelStopUnavailabilityEventData {
  routeId: number;
}

export interface CancelTerminateDepartureEventData {
  routeDepartureId: number;
}

export interface CancelTerminateDepartureByRouteId
  extends CancelTerminateDepartureEventData {
  routeId: number;
}

export interface StopUnavailabilityEventData {
  busStopId: number;
  unavailableFrom: TimeStamp;
  unavailableTo: TimeStamp;
  rideDisruptionEventReasonId: number | '';
  notes: string;
}

export interface SetStopUnavailabilityByRouteId
  extends StopUnavailabilityEventData {
  routeId: number;
}

export interface UpdateVehicleByRouteId extends Partial<Ride> {
  routeId: number;
}

export type StopUnavailabilityRequest = {
  unavailableFrom: string;
  unavailableTo: string;
  rideDisruptionEventReasonId: number | '';
  notes: string;
} | null;

export type Stop = {
  id: number;
  name: string;
  code?: number;
  isTurningPoint: boolean;
  unavailabilityDetails: StopUnavailabilityRequest;
};

export enum RideStatus {
  OnRoute = 'OnRoute',
  OffRoute = 'OffRoute',
  Depot = 'Depot'
}

export enum DeviationType {
  NoDeviation = 0,
  TrivialDeviation = 1,
  CriticalDeviation = 2
}

export interface Driver {
  id: number;
  name: string;
  phoneNumber: string;
}

interface RideChangeoverBasic {
  notes: string;
  rideDisruptionEventReasonId: number | '';
  routeIterationPointId: number | '';
}

export interface RideChangeover extends RideChangeoverBasic {
  driverId: number;
  driverName: string;
  vehicleId: number;
  vehicleName?: string;
  licensePlate: string;
  busStopId: number;
  busStopName: string;
  phoneNumber: string;
  id: number;
  changeAtUtc: string;
}

export interface RideChangeoverValues extends RideChangeoverBasic {
  busStop: AutocompleteOption | null;
  driver: AutocompleteOption | null;
  vehicle: AutocompleteOption | null;
}

export type RideChangeoverDetails = RideChangeover | null;

export interface RideChangeoverRequest extends RideChangeover {
  rideId: number;
  routeId: number;
}

export interface RideChangeoverEventData
  extends Omit<RideChangeover, 'changeAtUtc' | 'phoneNumber'> {
  rideScheduleId: number;
  changeAtUtc: TimeStamp;
  phoneNumber: ProtoValue<string>;
  changeoverId: number;
}

export interface RideChangeoverByRouteIdByRouteId
  extends RideChangeoverEventData {
  routeId: number;
}

export interface WorkingPeriod {
  from: string;
  to: string;
}

export interface Location {
  latitude: number;
  longitude: number;
  azimuth: number;
  speed?: number;
}

export enum VehicleStatus {
  Unplanned = 'Unplanned',
  Planned = 'Planned',
  NoGps = 'NoGps'
}

export interface ProtoValue<Value = unknown> {
  value: Value;
}

export interface Deviation {
  seconds: string;
  nanos: number;
}

export interface GpsMessage {
  rideId: ProtoValue;
  createdAt: {
    seconds: number;
  };
  routeDepartureId: ProtoValue;
  rideStatus: RideStatus;
  driverId: ProtoValue<number>;
  vehicleId: number;
  fromStopId: ProtoValue<number>;
  toStopId: ProtoValue<number>;
  distanceMeters: ProtoValue<number>;
  deviation: Deviation;
  deviationTypeId: ProtoValue<DeviationType>;
  Location: Location;
  vehicleRegistrationNumber: string;
  calendarId: ProtoValue;
  routeNumber: string;
  generalStatus: GeneralStatus;
  vehicleRegistrationNumberFromBarcode: string;
}

export interface GetGpsMessageSuccessByRouteId extends GpsMessage {
  routeId: number;
}

export interface SetGpsLoadingByRouteId {
  routeId: number;
  gpsLoading: boolean;
}

export type Ride = {
  id?: number;
  rideId: number;
  driverId?: ProtoValue;
  vehicleId?: number;
  routeDepartureId: number;
  depotCounteragentId: number;
  workingPeriod: WorkingPeriod;
  sequenceNumber: number;
  status: RideStatus;
  rideStatus?: RideStatus;
  rideMode: RideMode;
  deviation: Deviation;
  deviationTime?: Deviation;
  deviationTypeId: DeviationType;
  fromStopId: number | null;
  toStopId: number | null;
  distanceMeters: number;
  vehicle: {
    id: number;
    expiresAt?: string;
    licensePlate: string;
  };
  vehicleRegistrationNumber?: string;
  driver: Driver;
  plannedTermination: TerminateDepartureType;
  Location?: Location;
  location?: Location;
  gps?: {
    isActual?: boolean;
    hasSignal?: boolean;
    createdAt?: number;
    receivedAt?: string;
  };
  createdAt?: {
    seconds: number;
  };
  incorrectSchedule?: boolean;
  changeover: RideChangeoverDetails;
  vehicleStatus: VehicleStatus;
  generalStatus: GeneralStatus;
  vehicleRegistrationNumberFromBarcode?: string;
  isIdle?: boolean;
  isLastShift?: boolean;
  isTerminated?: boolean;
  isActive?: boolean;
};

export interface GeneralStatus {
  IsConsoleIdValid?: boolean;
  IsScheduleExists: boolean;
  IsRoutePassportExists?: boolean;
  IsTimetableExists?: boolean;
  IsSchemaExists?: boolean;
  IsDepartureExists?: boolean;
  IsRouteNumberExists?: boolean;
}

export interface NormalizedState<T> {
  byId: { [key: string]: T };
  allIds: string[];
}

export interface TrafficStadiumData {
  id: number | null;
  counteragentId: number | string | null;
  counteragentTreeCode?: string;
  stops: Stop[];
  segments: Segment[];
  gpsLoading: boolean;
  traffic: {
    vehicleCategoryName: string;
    vehicleCategoryId: number | null;
    rideMode: RideMode;
    rides: NormalizedState<Ride>;
  };
}

export interface TrafficStadiumsState {
  counteragentId?: number | string;
  counteragentTreeCode?: string;
  routes: {
    allIds: (number | null)[];
    byId: { [key: string]: TrafficStadiumData };
  };
}

export interface UpdateTrafficStadiumDataByRouteId {
  routeId: number;
  trafficStadiumData: TrafficStadiumData;
}

export interface SpecialModeBase {
  rideDisruptionEventReasonId?: number | '';
  notes: string;
}

export interface SpecialModeDetails extends SpecialModeBase {
  startsAtUtc: string;
  endsAtUtc: string;
}

export interface SpecialModeRouteDetails extends SpecialModeDetails {
  routeId?: number;
}

export interface RideSpecialModeDetails extends SpecialModeDetails {
  rideId: number;
  routeId: number;
}

export interface SpecialModeEventDetails extends SpecialModeBase {
  startsAtUtc: Timestamp;
  endsAtUtc: Timestamp | null;
}

export interface SetRouteRideModeDetailsByRouteId
  extends SpecialModeEventDetails {
  routeId: number;
}

export interface CancelRouteRideModeByRouteId {
  routeId: number;
}

export interface SetRideSpecialModeByRouteId extends Partial<RideMode> {
  rideId: number;
  routeId: number;
}

export interface RideSpecialModeEventDetails extends SpecialModeEventDetails {
  rideScheduleId: number;
}

export interface SetRideSpecialModeDetailsByRouteId
  extends RideSpecialModeEventDetails {
  routeId: number;
}

export interface CancelRideSpecialModeByRouteId
  extends Pick<RideSpecialModeEventDetails, 'rideScheduleId'> {
  routeId: number;
}

export interface Timestamp extends Object {
  seconds: number;
}

export interface RideMode {
  specialModeDetails: SpecialModeDetails;
  rideMode: RideModeType;
}

export interface SetRouteRideModeByRouteId {
  routeId: number;
  specialModeDetails?: SpecialModeDetails;
  rideMode: RideModeType;
}

export enum RideModeType {
  Regular = 'Regular',
  Special = 'Special'
}

export type SegmentOrientation = 'bottom' | 'top';

export interface DialogProps {
  isOpen?: boolean;
  onClose: () => void;
}

export enum Topics {
  RidePositionChangedEvent = 'RidePositionChangedEvent',
  RideChangeoverEvent = 'RideChangeoverEvent',
  CancelRideChangeoverEvent = 'CancelRideChangeoverEvent',
  RouteSpecialModeEvent = 'RouteSpecialModeEvent',
  BusStopUnavailableEvent = 'BusStopUnavailableEvent',
  CancelBusStopUnavailableEvent = 'CancelBusStopUnavailableEvent',
  CancelRouteSpecialModeEvent = 'CancelRouteSpecialModeEvent',
  TerminateDepartureEvent = 'TerminateDepartureEvent',
  CancelTerminateDepartureEvent = 'CancelTerminateDepartureEvent',
  RideSpecialModeEvent = 'RideSpecialModeEvent',
  CancelRideSpecialModeEvent = 'CancelRideSpecialModeEvent',
  RouteRideScheduleChangedEvent = 'RouteRideScheduleChangedEvent'
}

interface TerminateDepartureBasic {
  depotCounterAgentId: number | '';
  rideDisruptionEventReasonId: number | '';
  notes: string;
}

export interface TerminateDeparture extends TerminateDepartureBasic {
  terminatesAtUtc: string;
}

export type TerminateDepartureType = TerminateDeparture | null;

export interface TerminateDepartureRequest extends TerminateDeparture {
  id: number;
  routeId: number;
}

export interface UpdateRideWithChangeoverByRouteId {
  id: number;
  routeId: number;
}

export interface RemoveDepartureByRouteId {
  id: number;
  routeId: number;
}

export interface TerminateDepartureEventData extends TerminateDepartureBasic {
  id: number;
  terminatesAt: TimeStamp;
}

export interface SetTerminateDepartureByRouteId
  extends TerminateDepartureEventData {
  routeId: number;
}

export type StopDialogValues = StopUnavailabilityRequest & {
  stop: Stop | null;
  routeId: number;
};

export interface ChangeoverDriver extends AutocompleteOption {
  isAssignedToRide: boolean;
}

export interface DialogState<Values> {
  isOpen: boolean;
  initialValues: Values | null;
}

export type DialogInitialState = DialogState<Ride & { routeId: number }>;

export type SpecialModeDialogState = DialogState<{
  details: SpecialModeDetails;
  ride: Ride;
  routeId: number;
}>;

export interface RouteOption extends AutocompleteOption {
  number: string;
  routeSchemaId: number;
}
