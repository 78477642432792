import { createAsyncAction } from 'typesafe-actions';
import { VehicleModelEditDTO } from '@eticket/avl-api-contracts';
import { Values } from '../../types';

export const getVehiclesModel = createAsyncAction(
  '@VEHICLES_MODEL_EDIT/GET_VEHICLES_MODEL_REQUEST',
  '@VEHICLES_MODEL_EDIT/GET_VEHICLES_MODEL_SUCCESS',
  '@VEHICLES_MODEL_EDIT/GET_VEHICLES_MODEL_FAILURE'
)<undefined, VehicleModelEditDTO, Error>();

export const updateVehiclesModel = createAsyncAction(
  '@VEHICLES_MODEL_EDIT/UPDATE_VEHICLES_MODEL_REQUEST',
  '@VEHICLES_MODEL_EDIT/UPDATE_VEHICLES_MODEL_SUCCESS',
  '@VEHICLES_MODEL_EDIT/UPDATE_VEHICLES_MODEL_FAILURE'
)<Values, undefined, Error>();
