import { createAsyncAction } from 'typesafe-actions';
import { InboundDetailsState } from '../types';
import { Payload } from '../../InboundListView/store/actions';

export const getInbound = createAsyncAction(
  '@INBOUNDS_DETAILS/GET_INBOUND_REQUEST',
  '@INBOUNDS_DETAILS/GET_INBOUND_SUCCESS',
  '@INBOUNDS_DETAILS/GET_INBOUND_FAILURE'
)<undefined, InboundDetailsState, Error>();

export const updateInboundState = createAsyncAction(
  '@INBOUNDS_DETAILS/UPDATE_INBOUND_STATE_REQUEST',
  '@INBOUNDS_DETAILS/UPDATE_INBOUND_STATE_SUCCESS',
  '@INBOUNDS_DETAILS/UPDATE_INBOUND_STATE_FAILURE'
)<Payload, undefined, Error>();
