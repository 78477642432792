import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import {
  SCHEDULES,
  SCHEDULES_AVAILABLE_DATES,
  EXPORT_DRIVERS_TO_AFC
} from 'src/config/avlUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import { selectSchedulesConfig } from './selectors';

export function* getSchedules({
  payload
}: ReturnType<typeof actions.getSchedules.request>): SagaIterator {
  const { data } = yield call(apiClient.post, SCHEDULES, payload, {
    retryAction: actions.getSchedules.request(payload)
  });

  yield put(actions.getSchedules.success(data));
}

export function* saveSchedules({
  payload
}: ReturnType<typeof actions.saveSchedules.request>): SagaIterator {
  try {
    yield call(apiClient.put, SCHEDULES, payload, {
      retryAction: actions.saveSchedules.request(payload)
    });

    // fetch new schedules to update the data in the store. Needed for copying,
    // since the data is taken from there, not formik
    const config = yield select(selectSchedulesConfig);
    yield put(actions.getSchedules.request(config));

    yield put(
      enqueueSnackbar({
        key: 'schedules_saved',
        message: 'Зміни збережено',
        options: {
          variant: 'success'
        }
      })
    );

    yield put(actions.saveSchedules.success());
  } catch (error) {
    yield put(
      enqueueSnackbar({
        key: 'schedules_not_saved',
        message: 'Перевірте правильність планування графіку',
        options: {
          variant: 'error'
        }
      })
    );

    yield put(actions.saveSchedules.failure(error as Error));
  }
}

export function* getSchedulesAvailableDates({
  payload
}: ReturnType<
  typeof actions.getSchedulesAvailableDates.request
>): SagaIterator {
  const config = yield select(selectSchedulesConfig);

  const { data } = yield call(
    apiClient.post,
    SCHEDULES_AVAILABLE_DATES,
    { ...config, routePassportId: payload },
    {
      retryAction: actions.getSchedulesAvailableDates.request(payload)
    }
  );

  yield put(actions.getSchedulesAvailableDates.success(data));
}

export function* exportDriversToAFC(): SagaIterator {
  yield call(apiClient.get, EXPORT_DRIVERS_TO_AFC);
  yield put(
    enqueueSnackbar({
      key: 'export_drivers',
      message: 'Експортування завершено успішно',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.exportDriversToAFC.success());
}

export default function* SchedulesSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getSchedules.request),
      safe(getSchedules, actions.getSchedules.failure)
    ),
    takeEvery(getType(actions.saveSchedules.request), saveSchedules),
    takeEvery(
      getType(actions.getSchedulesAvailableDates.request),
      safe(
        getSchedulesAvailableDates,
        actions.getSchedulesAvailableDates.failure
      )
    ),
    takeEvery(
      getType(actions.exportDriversToAFC.request),
      safe(exportDriversToAFC, actions.exportDriversToAFC.failure)
    )
  ]);
}
