import { FC } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { useField } from 'formik';
import { InputFieldProps } from './types';

const useStyles = makeStyles(({ palette }) => ({
  errorDisableInput: {
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.error.main
    }
  }
}));

const FormInput: FC<InputFieldProps> = props => {
  const classes = useStyles();
  const { variant, name, fullWidth, disabled, tooltipText } = props;
  const [field, { error, touched }] = useField(name);
  const showError = !!((touched || props.onBlur) && error);

  return (
    <TextField
      className={showError && disabled ? classes.errorDisableInput : ''}
      fullWidth={fullWidth}
      variant={variant}
      error={showError}
      helperText={((touched || props.onBlur) && error) || tooltipText}
      {...field}
      value={field.value ?? ''}
      {...props}
    />
  );
};

FormInput.defaultProps = {
  variant: 'outlined',
  fullWidth: true
};

export default FormInput;
