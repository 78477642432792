import { get } from 'lodash';
import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import {
  RouteSchedules,
  Schedule,
  ScheduleShift,
  SchedulesActionsConfig,
  DisableRules,
  ScheduledVehicleCount
} from '../types';
import { SCHEDULES_LOADING_KEY } from '../const';
import { isDateInRange } from '../../trafficStadium/utils';

export const selectRoutesSchedules = (state: RootState): RouteSchedules[] =>
  state.ui.avl.schedules.list;

export const selectSchedules = createSelector(
  selectRoutesSchedules,
  (routeSchedules: RouteSchedules[]) => {
    const schedules: Schedule[] = [];

    routeSchedules.forEach(route => schedules.push(...route.schedules));

    return schedules;
  }
);

export const selectScheduleShifts = createSelector(
  selectRoutesSchedules,
  (routeSchedules: RouteSchedules[]) => {
    const data: Record<number, ScheduleShift[]> = {};

    routeSchedules.forEach(({ id, schedules }) => {
      data[id] = [];

      schedules.forEach(({ departureId, workShifts }) => {
        workShifts.forEach(
          ({
            workShiftId,
            workShiftType,
            driverId,
            driverName,
            vehicleId,
            vehicleLicensePlate,
            departureTime,
            arrivalTime
          }) => {
            data[id].push({
              departureId,
              workShiftId,
              workShiftType,
              departureTime,
              arrivalTime,
              driver:
                driverId && driverName
                  ? {
                      id: driverId,
                      name: driverName
                    }
                  : null,
              vehicle:
                vehicleId && vehicleLicensePlate
                  ? {
                      id: vehicleId,
                      name: vehicleLicensePlate
                    }
                  : null
            });
          }
        );
      });
    });

    return data;
  }
);

export const selectSchedulesConfig = (
  state: RootState
): SchedulesActionsConfig =>
  state.ui.avl.schedules.config || {
    counteragentId: 0,
    routePassportId: [],
    date: ''
  };

export const selectSchedulesLoading = (state: RootState): boolean =>
  get(state, `loading.${SCHEDULES_LOADING_KEY}`);

export const selectSchedulesDisableRules = (state: RootState): DisableRules =>
  state.ui.avl.schedules.disableRules;

export const selectScheduledVehicleCountLastUpdated = (
  state: RootState
): number | undefined => state.ui.avl.schedules.lastUpdated;

export const selectScheduledVehicleCount = (routeId: number) =>
  createSelector(
    selectRoutesSchedules,
    (routesSchedules: RouteSchedules[]): ScheduledVehicleCount => {
      const vehicleCount = { vehiclesByTimetable: 0, vehiclesPlanned: 0 };
      const routeSchedules = routesSchedules.find(item => item.id === routeId);

      if (routeSchedules) {
        let vehiclesPlanned = 0;

        const schedules = routeSchedules.schedules.filter(item => {
          const workShift = item.workShifts.find(shift =>
            isDateInRange(new Date(), shift.departureTime, shift.arrivalTime)
          );

          if (workShift?.vehicleId !== null && workShift?.driverId !== null) {
            vehiclesPlanned += 1;
          }

          return workShift;
        });

        vehicleCount.vehiclesByTimetable = schedules.length;
        vehicleCount.vehiclesPlanned = vehiclesPlanned;
      }

      return vehicleCount;
    }
  );
