export const columns = [
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '40%'
  },
  {
    label: 'Джерело даних',
    value: 'source',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Тип',
    value: 'type',
    sortable: true,
    width: '20%'
  }
];

export const KPIS_TABLE_PATH = 'clearing.kpis.table';
export const KPIS_TABLE_NAME = 'KPIS';
