import { createAsyncAction } from 'typesafe-actions';
import { Values } from 'src/views/clearing/tariffs/types';

export const getTariff = createAsyncAction(
  '@TARIFF_EDIT/GET_TARIFF_REQUEST',
  '@TARIFF_EDIT/GET_TARIFF_SUCCESS',
  '@TARIFF_EDIT/GET_TARIFF_FAILURE'
)<undefined, Values, Error>();

export const updateTariff = createAsyncAction(
  '@TARIFF_EDIT/UPDATE_TARIFF_REQUEST',
  '@TARIFF_EDIT/UPDATE_TARIFF_SUCCESS',
  '@TARIFF_EDIT/UPDATE_TARIFF_FAILURE'
)<Values, undefined, Error>();
