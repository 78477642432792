import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { safe } from 'src/utils/sagas';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { EQUIPMENT } from 'src/config/assetUrls';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getEquipment(): SagaIterator {
  const id = yield select(selectors.selectEquipmentId);

  const { data } = yield call(apiClient.get, `${EQUIPMENT}/${id}/edit`, {
    retryAction: actions.getEquipment.request()
  });

  yield put(actions.getEquipment.success(data));
}

export function* updateEquipment({
  payload
}: ReturnType<typeof actions.updateEquipment.request>): SagaIterator {
  const id = yield select(selectors.selectEquipmentId);

  yield call(apiClient.put, `${EQUIPMENT}/${id}`, payload, {
    retryAction: actions.updateEquipment.request(payload)
  });
  yield put(navigateTo({ url: '/asset/dictionaries/equipment' }));
  yield put(
    enqueueSnackbar({
      key: 'equipment_edit',
      message: 'Обладнання відредаговано',
      options: { variant: 'success' }
    } as UINotification)
  );
  yield put(actions.updateEquipment.success());
}

export default function* EquipmentEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getEquipment.request),
      safe(getEquipment, actions.getEquipment.failure)
    ),
    takeEvery(
      getType(actions.updateEquipment.request),
      safe(updateEquipment, actions.updateEquipment.failure)
    )
  ]);
}
