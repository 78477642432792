import {
  RouteStop,
  Segment,
  TurningStopId
} from 'src/components/Maps/MapRoute/types';

interface RouteScheme {
  id?: number;
  name: string;
  status: RouteSchemeStatus;
  stops: RouteStop[];
  turningStopId: TurningStopId;
}

export interface RouteSchemeRow {
  id: number;
  name: string;
  status: string;
  count: number;
  isDeletable: boolean;
  isObsolete: boolean;
}

export interface RouteSchemeValues extends RouteScheme {
  segments: Segment[];
}

export interface RouteSchemeDetails extends RouteScheme {
  segments: string[];
  routeName: string;
  isDeletable?: boolean;
}

export interface RouteSchemeState {
  routeName: string;
  schemeCount: number;
}

export enum RouteSchemeStatus {
  Draft = 'Draft',
  Approved = 'Approved'
}

type Facility = {
  name: string;
  stopsCount: number;
};

type DangerousZone = {
  name: string;
  categoryName: string;
};

export interface RouteSchemeSummary {
  directDirectionDistance: number | null;
  reverseDirectionDistance: number | null;
  busStopCountDirect: number | null;
  busStopCountReverse: number | null;
  facilities: Facility[];
  dangerousZones: DangerousZone[];
}

interface ListItems {
  rows: string[][];
  columns?: string[];
}

export interface SummaryLists {
  [key: string]: ListItems;
}

export enum RouteSchemeFormTab {
  Settings,
  Details
}

type FacilityType = {
  id: number;
  name: string;
};

type BusStop = {
  name: string;
  facilityTypes: FacilityType[];
};

export interface RouteDataPdf {
  routeNumber: string;
  routeName: string;
  schemaName: string;
  directDirectionDistance: string;
  reverseDirectionDistance: string;
  busStopCountDirect: number;
  busStopCountReverse: number;
  facilities: Facility[];
  dangerousZones: DangerousZone[];
  directBusStops: BusStop[];
  reverseBusStops: BusStop[];
}
