import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar, SnackbarKey } from 'notistack';
import * as actions from 'src/store/actions/snackbar';
import { selectSnackbar } from 'src/store/selectors';
import { Color } from '@material-ui/lab';
import Snackbar from './Snackbar/index';

let displayed: Array<SnackbarKey> = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectSnackbar);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(
      ({
        key,
        message,
        retryAction,
        options = {},
        dismissed = false
      }: UINotification) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          content: () => (
            <Snackbar
              retryAction={retryAction}
              message={message}
              variant={options?.variant as Color}
              closeAction={() => dispatch(actions.closeSnackbar(key))}
            />
          ),
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey: SnackbarKey) => {
            // remove this snackbar from redux store
            dispatch(actions.removeSnackbar(myKey));
            removeDisplayed(myKey);
          }
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
