import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { PENALTIES } from 'src/config/clearingUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import * as selectors from './selectors';
import { safe } from '../../../../../utils/sagas';

export function* getPenalty(): SagaIterator {
  const id = yield select(selectors.selectPenaltyId);

  const { data } = yield call(apiClient.get, `${PENALTIES}/${id}`, {
    retryAction: actions.getPenalty.request()
  });
  yield put(actions.getPenalty.success(data));
}

export function* updatePenalty({
  payload
}: ReturnType<typeof actions.updatePenalty.request>): SagaIterator {
  const id = yield select(selectors.selectPenaltyId);

  yield call(apiClient.put, `${PENALTIES}/${id}`, payload, {
    retryAction: actions.updatePenalty.request(payload)
  });
  yield put(navigateTo({ url: '/clearing/penalties' }));
  yield put(
    enqueueSnackbar({
      key: 'penalties_edit',
      message: 'Штраф відредаговано',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.updatePenalty.success());
}

export default function* PenaltyEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getPenalty.request),
      safe(getPenalty, actions.getPenalty.failure)
    ),
    takeEvery(
      getType(actions.updatePenalty.request),
      safe(updatePenalty, actions.updatePenalty.failure)
    )
  ]);
}
