import { createAsyncAction } from 'typesafe-actions';
import {
  FiltersValues,
  VehicleTrack
} from 'src/views/avl/dispatching/trafficMap/components/Tracking/types';

export const getVehicleTrack = createAsyncAction(
  '@TRAFFIC_MAP/GET_VEHICLE_TRACK_REQUEST',
  '@TRAFFIC_MAP/GET_VEHICLE_TRACK_SUCCESS',
  '@TRAFFIC_MAP/GET_VEHICLE_TRACK_FAILURE'
)<FiltersValues, VehicleTrack, Error>();
