import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { apiClient } from 'src/utils/api';
import { PAYMENTS_SETTINGS } from 'src/config/administrationUrls';
import * as actions from './actions';

export function* getPaymentsSettings(): SagaIterator {
  const { data } = yield call(apiClient.get, PAYMENTS_SETTINGS, {
    retryAction: actions.getPaymentsSettings.request()
  });
  yield put(actions.getPaymentsSettings.success(data));
}

export function* updatePaymentsSettings({
  payload
}: ReturnType<typeof actions.updatePaymentsSettings.request>): SagaIterator {
  yield call(apiClient.put, PAYMENTS_SETTINGS, payload, {
    retryAction: actions.updatePaymentsSettings.request(payload)
  });
  yield put(
    enqueueSnackbar({
      key: 'payments_settings_edit',
      message: 'Налаштування нарахувань оновлено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.updatePaymentsSettings.success());
}

export default function* PaymentsSettingsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getPaymentsSettings.request),
      safe(getPaymentsSettings, actions.getPaymentsSettings.failure)
    ),
    takeEvery(
      getType(actions.updatePaymentsSettings.request),
      safe(updatePaymentsSettings, actions.updatePaymentsSettings.failure)
    )
  ]);
}
