import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { Result } from '../types';

interface State {
  options: Array<Result>;
  loading: boolean;
}

export const initialState: State = {
  options: [],
  loading: false
};

const formAutocompleteReducers = createReducer<
  State,
  ActionType<typeof actions>
>(initialState)
  .handleAction(
    actions.getAutocompleteOptionsSuccess,
    (state, { payload }) => ({
      ...state,
      options: payload
    })
  )
  .handleAction(actions.clearAutocompleteOptions, state => ({
    ...state,
    options: []
  }))
  .handleAction(actions.setLoading, (state, { payload }) => ({
    ...state,
    loading: payload
  }));

export default formAutocompleteReducers;
