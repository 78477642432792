import { RootState } from 'typesafe-actions';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { Row } from 'src/components/Tables/types';
import { convertUtcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL } from 'src/config';
import { generateSendEmailAttachmentProps } from 'src/components/SendEmailModalForm/utils/generateSendEmailAttachmentProps';
import { generatePdfOfTransfer } from '../../utils/generatePdfOfTransfer';
import { generateJsonOfTransfer } from '../../utils/generateJsonOfTransfer';
import { TRANSFER_EMAIL_DATA_PATH } from '../../const';

const selectSelf = (state: RootState) => state;

export const selectTransferEquipment = (state: RootState) =>
  state.ui.asset.transfer.table;

export const normalizeEquipment = ({ barcode, imei, id, spareParts }: Row) => ({
  barcode,
  imei,
  assetId: id,
  spareParts
});

export const selectListResult = (reducerPath: string) => (
  state: RootState
): Row[] => get(state, `ui.${reducerPath}.list`, []);

export const selectNormalizedList = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list =>
    list.filter(el => el.isLocal).map(normalizeEquipment)
  );

export const selectBarcodes = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list =>
    list.map(el => el.barcode)
  );

const selectEmailDataDetailsView = (reducerPath: string) =>
  createSelector(selectSelf, state => get(state, `ui.${reducerPath}`, {}));

export const selectEmailData = createSelector(
  selectEmailDataDetailsView(TRANSFER_EMAIL_DATA_PATH),
  data => {
    const {
      id,
      documentNumber,
      dateTransfer,
      acceptingPerson,
      performingPerson,
      counteragentHolderName,
      counteragentHolderAddress,
      counteragentTransferName,
      counteragentTransferAddress,
      notes,
      equipment
    } = data;

    const date = convertUtcToLocal(String(dateTransfer), DATE_FORMAT_FULL);

    const subject = `Переміщення №${documentNumber} ${performingPerson} ${date}`;
    const bodyText = `Здійснено переміщення обладнання.\nДокумент переміщення №${documentNumber} від ${date}`;
    const tags = [`transfer:${id}`];

    let attachments;
    if (data.id) {
      const pdfData = {
        ...data,
        equipment,
        counteragentFrom: counteragentHolderName,
        counteragentFromAdress: counteragentHolderAddress,
        acceptingPerson,
        counteragentTo: counteragentTransferName,
        counteragentToAdress: counteragentTransferAddress,
        notes
      };

      const pdfFile = generatePdfOfTransfer(pdfData);
      const jsonFile = generateJsonOfTransfer(pdfData);

      attachments = generateSendEmailAttachmentProps(
        subject,
        pdfFile,
        jsonFile
      );
    }

    return { subject, bodyText, tags, attachments };
  }
);
