import { PdfGenerator, TypeTable } from 'src/services/pdfGenerator';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL } from 'src/config';
import { transferDocumentPdf, transferColumnsPdf } from '../const';

export function generatePdfOfTransfer(data: any): PdfGenerator {
  const { documentNumber, equipment, dateTransfer, acceptingPerson } = data;

  const dateTitle = utcToLocal(dateTransfer, DATE_FORMAT_FULL);
  const dateSubject = utcToLocal(dateTransfer, DATE_FORMAT_FULL);

  const title = `Переміщення №${documentNumber} ${acceptingPerson} ${dateTitle}`;
  const subject = `Акт переміщення № ${documentNumber} від ${dateSubject}`;

  return new PdfGenerator(title, 13, { orientation: 'landscape' })
    .setHeader(subject, true)
    .setTable(transferDocumentPdf, data, TypeTable.InfoTable)
    .setHeader('Переміщене обладнання', false)
    .setTable(transferColumnsPdf, equipment, TypeTable.ListTable);
}
