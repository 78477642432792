export const CREATE_STATE_CHANGE_FORM_NAME = 'CREATE_STATE_CHANGE_FORM';
export const CREATE_STATE_CHANGE_FORM_PATH = 'asset.stateChange.create.data';
export const CREATE_STATE_CHANGE_FORM_CONFIG = {
  reducerName: CREATE_STATE_CHANGE_FORM_NAME,
  reducerPath: CREATE_STATE_CHANGE_FORM_PATH
};

export const STATE_CHANGING_EQUIPMENT_NAME = 'STATE_CHANGING_EQUIPMENT_NAME';
export const STATE_CHANGING_EQUIPMENT_PATH = 'asset.stateChange.create.table';

export const STATE_CHANGING_TABLE_CONFIG = {
  reducerName: STATE_CHANGING_EQUIPMENT_NAME,
  reducerPath: STATE_CHANGING_EQUIPMENT_PATH,
  apiUrl: ``,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно видалено'
  }
};
