import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { EMPLOYEES } from 'src/config/avlUrls';
import * as actions from './actions';

export function* createEmployeeSaga({
  payload
}: ReturnType<typeof actions.createEmployee.request>): SagaIterator {
  yield call(apiClient.post, EMPLOYEES, payload, {
    retryAction: actions.createEmployee.request(payload)
  });
  yield put(navigateTo({ url: '/avl/employees/' }));
  yield put(
    enqueueSnackbar({
      key: 'employee_create',
      message: 'Створено нового працівника',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.createEmployee.success());
}

export default function* EmployeeCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createEmployee.request),
    safe(createEmployeeSaga, actions.createEmployee.failure)
  );
}
