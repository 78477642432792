import { ActionType, createReducer } from 'typesafe-actions';
import { RoutePassportStatus } from 'src/views/avl/Routes/const';
import * as actions from './actions';
import { GetTimetablePayload } from '../../types';

type TimetableEditActions = ActionType<typeof actions>;

const initialState: GetTimetablePayload = {
  canDelete: false,
  routeSchemaId: '',
  routeSchemaName: '',
  depotCounteragentId: '',
  depotCounteragentName: '',
  id: 0,
  parentTimetableId: '',
  name: '',
  status: RoutePassportStatus.Draft,
  startsAt: null,
  endsOn: null,
  calendarSettings: [],
  calendarExceptions: [],
  distanceToDepotInKm: null,
  distanceFromDepotInKm: null
};

const timetableEditReducer = createReducer<
  GetTimetablePayload,
  TimetableEditActions
>(initialState).handleAction(
  actions.getTimetable.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default timetableEditReducer;
