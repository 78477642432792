import { ActionType, createReducer } from 'typesafe-actions';
import { VehicleTrack } from '../components/Tracking/types';
import * as actions from './actions';

type Actions = ActionType<typeof actions>;
type State = Readonly<VehicleTrack>;

export const initialState: VehicleTrack = {
  track: [],
  routes: []
};

const trafficMapReducer = createReducer<State, Actions>(
  initialState
).handleAction(actions.getVehicleTrack.success, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default trafficMapReducer;
