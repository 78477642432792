import { createReducer, ActionType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import * as actions from './actions';
import { BreakdownsListState } from '../types';
import { BreakdownDetailsState } from '../../BreakdownDetailsView/types';

type BreakdownListViewActions = ActionType<typeof actions>;

const initialState: BreakdownsListState | BreakdownDetailsState = {
  id: 0,
  documentNumber: 0,
  registrationDate: '',
  registeredByUserName: 'string',
  equipmentGroupName: 'string',
  amountOfEquipment: 1,
  equipmentGroupId: 2,
  breakdownItems: [],
  registrationEmployeeName: '',
  reportingEmployeeName: '',
  counteragentName: '',
  licensePlate: '',
  vehicleModelName: '',
  vehicleCategoryName: '',
  counteragentAddress: '',
  employeeName: '',
  notes: '',
  status: StatusEnum.Draft,
  equipments: [],
  registeredByEmployeeId: 0,
  counteragentId: 0,
  vehicleId: 0
};

const breakdownListViewReducers = createReducer<
  BreakdownsListState | BreakdownDetailsState,
  BreakdownListViewActions
>(initialState)
  .handleAction(actions.getBreakdown.success, (state, { payload }) => ({
    ...state,
    ...payload
  }))
  .handleAction(actions.getBreakdownRowData.success, (state, { payload }) => ({
    ...state,
    ...payload
  }));

export default breakdownListViewReducers;
