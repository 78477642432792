import { RootState } from 'typesafe-actions';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { createSelector } from 'reselect';
import { INSPECTION_DOCUMENTS_DETAILS_URL } from 'src/routes/clearingRoutes';
import { DetailsRow } from 'src/components/Tables/DetailsTable/types';
import { convertUtcToLocal } from 'src/utils/date';
import { DATE_FORMAT_SLASH } from 'src/config';
import { InspectionDocument } from '../../types';
import InspectionDocumentLabel from '../../InspectionDocumentsListView/InspectionDocumentLabel';

const matchSpaceParams = createMatchSelector<RouterRootState, { id: string }>(
  INSPECTION_DOCUMENTS_DETAILS_URL
);

export const selectInspectionDocumentId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectInspectionDocument = (state: RootState) =>
  state.ui.clearing.inspectionDocuments.details;

export const selectInspectionDocumentRows = createSelector(
  selectInspectionDocument,
  (inspectionDocumentDetails: InspectionDocument): DetailsRow[] => [
    {
      label: 'Дата документа',
      value: convertUtcToLocal(
        inspectionDocumentDetails.documentDate,
        DATE_FORMAT_SLASH
      )
    },
    {
      label: 'Назва документа',
      value: inspectionDocumentDetails.documentName
    },
    {
      label: 'Перевізник',
      value: inspectionDocumentDetails.counteragentName
    },
    {
      label: 'Транспортний засіб',
      value: inspectionDocumentDetails.licensePlate
    },
    {
      label: 'Інспектор',
      value: inspectionDocumentDetails.employeeName
    },
    {
      label: 'Статус',
      value: (
        <InspectionDocumentLabel status={inspectionDocumentDetails.status} />
      )
    }
  ]
);
