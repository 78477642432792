import { FC } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select
} from '@material-ui/core';
import { useField } from 'formik';
import { SelectFieldProps } from './types';

const FormSelect: FC<SelectFieldProps> = ({
  name,
  children,
  nativeProps,
  variant
}) => {
  const [field, { error, touched }] = useField(name);
  const selectError = !!(touched && error);

  // multiple select requires values as an array
  const value = field.value ?? (nativeProps.multiple ? [] : '');

  return (
    <FormControl
      variant={variant}
      fullWidth
      disabled={nativeProps?.disabled}
      required={nativeProps?.required}
    >
      <InputLabel error={selectError}>{nativeProps.label}</InputLabel>
      <Select
        error={selectError}
        {...field}
        value={value}
        {...nativeProps}
        onChange={nativeProps.onChange ?? field.onChange}
      >
        {children}
      </Select>
      {selectError && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

FormSelect.defaultProps = {
  variant: 'outlined'
};

export default FormSelect;
