import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import {
  BILLING_DOCUMENT,
  BILLING_DOCUMENTS_NEXT_DATE
} from 'src/config/clearingUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { createBillingDocument, getNextBillingDocumentDate } from './actions';

export function* billingDocumentCreate({
  payload
}: ReturnType<typeof createBillingDocument.request>): SagaIterator {
  try {
    const data = {
      ...payload,
      depotCounteragentId: payload.counteragentId
    };

    yield call(apiClient.post, BILLING_DOCUMENT, data, {
      retryAction: createBillingDocument.request(data)
    });
    yield put(navigateTo({ url: '/clearing/billing-documents' }));
    yield put(
      enqueueSnackbar({
        key: 'billing_document_create',
        message: 'Нарахування створено',
        options: {
          variant: 'success'
        }
      })
    );
    yield put(createBillingDocument.success());
  } catch (error) {
    yield put(
      enqueueSnackbar({
        key: 'billing_document_not_saved',
        message: 'Перевірте правильність налаштувань документа',
        options: {
          variant: 'error'
        }
      })
    );

    yield put(createBillingDocument.failure(error as Error));
  }
}

export function* billingDocumentNextDateGet({
  payload
}: ReturnType<typeof getNextBillingDocumentDate.request>): SagaIterator {
  try {
    const { data } = yield call(
      apiClient.get,
      BILLING_DOCUMENTS_NEXT_DATE(payload.id),
      {
        retryAction: getNextBillingDocumentDate.request(payload)
      }
    );

    yield put(getNextBillingDocumentDate.success(data));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        key: 'billing_document_date_fetch',
        message: 'По даному перевізнику немає аналітики',
        options: {
          variant: 'error'
        }
      })
    );

    yield put(getNextBillingDocumentDate.failure(error as Error));
  }
}

export default function* BillingDocumentCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(createBillingDocument.request),
    billingDocumentCreate
  );

  yield takeEvery(
    getType(getNextBillingDocumentDate.request),
    billingDocumentNextDateGet
  );
}
