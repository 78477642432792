import { put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { STATES } from 'src/config/assetUrls';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getState(): SagaIterator {
  const id = yield select(selectors.selectStateId);

  const { data } = yield call(apiClient.get, `${STATES}/${id}`, {
    retryAction: actions.getState.request
  });

  yield put(actions.getState.success(data));
}

export default function* StateDetailsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getState.request),
    safe(getState, actions.getState.failure)
  );
}
