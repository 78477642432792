export interface InspectionDocumentKpiParameter {
  name: string;
  gradeValue: number;
  maxValue: number;
}

export interface InspectionDocumentAvgKpiGrade {
  changedAt: string;
  grade: number;
  kpiParameterId: number;
  kpiParameterName: string;
}

export interface InspectionDocument {
  id: number;
  documentDate: string;
  documentName: string;
  counteragentId: number;
  counteragentName: string;
  vehicleId: number;
  licensePlate: string;
  status: InspectionDocumentStatus;
  employeeId: number;
  employeeName: string;
  canDelete: boolean;
  kpiParamsId?: number;
  kpiParams: InspectionDocumentKpiParameter[];
  avgKpiGrades: InspectionDocumentAvgKpiGrade[];
}

export enum InspectionDocumentStatus {
  Completed = 'Completed',
  Billed = 'Billed'
}
