import { Row } from 'src/components/Tables/types';
import EquipmentStateLabel from 'src/components/Labels/EquipmentStateLabel';
import TextWithEllipsis from 'src/components/TextWithEllipsis';

export const stationaryColumns = [
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '15%'
  },
  {
    label: 'IMEI',
    value: 'imei',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Запчастини',
    value: 'spareParts',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => <TextWithEllipsis text={row.spareParts} />
  },
  {
    label: 'Стан',
    value: 'status',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => <EquipmentStateLabel state={row.status} />
  },
  {
    label: 'Підрозділ',
    value: 'counteragentName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Адреса підрозділу',
    value: 'address',
    sortable: true,
    width: '15%'
  }
];

export const INBOUND_EQUIPMENT_TABLE_PATH = 'asset.inbound.create.equipment';
export const INBOUND_EQUIPMENT_TABLE_NAME = 'EQUIPMENT';

export const tableConfig = {
  reducerName: INBOUND_EQUIPMENT_TABLE_NAME,
  reducerPath: INBOUND_EQUIPMENT_TABLE_PATH,
  routeUrl: '/asset/inbound',
  rowActions: {},
  toolbarActions: {},
  filters: [],
  isRowClickEnabled: false,
  snackbarMessages: {
    deleteRowSuccess: ''
  }
};
