import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { BILLING_DOCUMENT } from 'src/config/clearingUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';
import { selectBillingDocumentId } from './selectors';

export function* getBillingDocument(): SagaIterator {
  const id = yield select(selectBillingDocumentId);

  const { data } = yield call(apiClient.get, `${BILLING_DOCUMENT}/${id}`, {
    retryAction: actions.getBillingDocument.request()
  });

  yield put(actions.getBillingDocument.success(data));
}

export function* editBillingDocument({
  payload
}: ReturnType<typeof actions.editBillingDocument.request>): SagaIterator {
  const id = yield select(selectBillingDocumentId);

  yield call(apiClient.put, `${BILLING_DOCUMENT}/${id}`, payload, {
    retryAction: actions.editBillingDocument.request(payload)
  });
  yield put(navigateTo({ url: '/clearing/billing-documents' }));
  yield put(
    enqueueSnackbar({
      key: 'billing_document_edit',
      message: 'Нарахування відредаговано',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.editBillingDocument.success());
}

export default function* BillingDocumentEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getBillingDocument.request),
      safe(getBillingDocument, actions.getBillingDocument.failure)
    ),
    takeEvery(
      getType(actions.editBillingDocument.request),
      safe(editBillingDocument, actions.editBillingDocument.failure)
    )
  ]);
}
