import { createAsyncAction } from 'typesafe-actions';
import { KPIType } from '../../types';

export const getKPIs = createAsyncAction(
  '@KPI_PARAMETERS/GET_KPIS_REQUEST',
  '@KPI_PARAMETERS/GET_KPIS_SUCCESS',
  '@KPI_PARAMETERS/GET_KPIS_FAILURE'
)<undefined, KPIType[], Error>();

export const getKPIParametersTotalWeight = createAsyncAction(
  '@KPI_PARAMETERS/GET_KPI_PARAMETERS_TOTAL_WEIGHT_REQUEST',
  '@KPI_PARAMETERS/GET_KPI_PARAMETERS_TOTAL_WEIGHT_SUCCESS',
  '@KPI_PARAMETERS/GET_KPI_PARAMETERS_TOTAL_WEIGHT_FAILURE'
)<undefined, number, Error>();
