import { FC } from 'react';
import { getDateDifference } from 'src/utils/date';
import { StatusEnum } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import SwitchEdit from 'src/components/SwitchEdit';

const MAX_DAYS_AFTER_INBOUND = 200;

interface InboundSwitchProps {
  data: Row;
  onChange: (row: Row) => Promise<void>;
}

const InboundSwitch: FC<InboundSwitchProps> = ({ data, onChange }) => {
  const { dateInbound, status, documentNumber } = data;

  const disabled =
    status === StatusEnum.Fixed &&
    getDateDifference(dateInbound) > MAX_DAYS_AFTER_INBOUND;

  const handleChangeSwitch = async () => {
    if (status !== StatusEnum.Draft) {
      await onChange(data);
    }
  };

  const tooltip = `Документ зафіксовано понад ${MAX_DAYS_AFTER_INBOUND} днів тому. Редагування заборонене.`;
  const confirmMessage = `Ви впевнені, що хочете розфіксувати документ ${documentNumber}? Все обладнання з цього документа не буде відображатись в Моніторингу та Звітах`;

  return (
    <SwitchEdit
      checked={status === StatusEnum.Draft}
      disabled={disabled}
      tooltip={tooltip}
      onChange={handleChangeSwitch}
      confirmMessage={confirmMessage}
    />
  );
};

export default InboundSwitch;
