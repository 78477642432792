import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  form: {
    backgroundColor: palette.background.default,
    display: 'grid',
    gridGap: spacing(3),
    padding: spacing(2),
    width: 392,
    [breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  message: {
    display: 'flex'
  },
  amountMessage: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  warningIcon: {
    color: palette.warning.dark
  },
  formHelperText: {
    marginLeft: 14
  }
}));
