import { SagaIterator } from 'redux-saga';
import { takeEvery, call, put, select, all } from 'redux-saga/effects';
import { selectRouteParams } from 'src/views/avl/Routes/RoutesPassportView/store/selectors';
import { apiClient } from 'src/utils/api';
import {
  ROUTE_SCHEMES_DETAILS,
  ROUTE_SCHEMES_DETAILS_PDF
} from 'src/config/avlUrls';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';
import { PassportMode } from '../../Settings/types';

export function* getSchemeDetails(): SagaIterator {
  const { id, subId, subMode } = yield select(selectRouteParams);
  const apiUrl = `${ROUTE_SCHEMES_DETAILS(id, subId)}/${
    subMode === PassportMode.Edit ? 'edit' : ''
  }`;

  const { data } = yield call(apiClient.get, apiUrl, {
    retryAction: actions.getSchemeDetails.request
  });

  yield put(actions.getSchemeDetails.success(data));
}

export function* getSchemeDetailsPdf({
  payload: routeSchemaId
}: ReturnType<typeof actions.getSchemeDetailsPdf.request>): SagaIterator {
  const { id } = yield select(selectRouteParams);

  const { data } = yield call(
    apiClient.get,
    ROUTE_SCHEMES_DETAILS_PDF(id, routeSchemaId),
    { retryAction: actions.getSchemeDetailsPdf.request }
  );

  yield put(actions.getSchemeDetailsPdf.success(data));
}

export default function* RouteSchemeDetailsViewSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getSchemeDetails.request),
      safe(getSchemeDetails, actions.getSchemeDetails.failure)
    ),
    takeEvery(
      getType(actions.getSchemeDetailsPdf.request),
      safe(getSchemeDetailsPdf, actions.getSchemeDetailsPdf.failure)
    )
  ]);
}
