import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { safe } from 'src/utils/sagas';
import { STATES } from 'src/config/assetUrls';
import * as actions from './actions';

export function* createStates({
  payload
}: ReturnType<typeof actions.createStates.request>): SagaIterator {
  yield call(apiClient.post, STATES, payload, {
    retryAction: actions.createStates.request(payload)
  });
  yield put(navigateTo({ url: '/asset/dictionaries/states' }));
  yield put(
    enqueueSnackbar({
      key: 'state_types_create',
      message: 'Стан створено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.createStates.success());
}

export default function* StateCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createStates.request),
    safe(createStates, actions.createStates.failure)
  );
}
