import { createAsyncAction } from 'typesafe-actions';
import { TransferDetailsPayload } from '../../types';

export const getTransfer = createAsyncAction(
  '@TRANSFER/GET_TRANSFER_REQUEST',
  '@TRANSFER/GET_TRANSFER_SUCCESS',
  '@TRANSFER/GET_TRANSFER_FAILURE'
)<{ id: string }, TransferDetailsPayload, Error>();

export const getSelectedTransfersData = createAsyncAction(
  '@TRANSFER/GET_SELECTED_TRANSFERS_REQUEST',
  '@TRANSFER/GET_SELECTED_TRANSFERS_SUCCESS',
  '@TRANSFER/GET_SELECTED_TRANSFERS_FAILURE'
)<{ id: string }[], TransferDetailsPayload[], Error>();
