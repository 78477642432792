import { createAsyncAction } from 'typesafe-actions';

export const confirmInbound = createAsyncAction(
  '@INBOUND_STEP/CONFIRM_INBOUND_REQUEST',
  '@INBOUND_STEP/CONFIRM_INBOUND_SUCCESS',
  '@INBOUND_STEP/CONFIRM_INBOUND_FAILURE'
)<number, undefined, Error>();

export const cancelInbound = createAsyncAction(
  '@INBOUND_STEP/CANCEL_INBOUND_REQUEST',
  '@INBOUND_STEP/CANCEL_INBOUND_SUCCESS',
  '@INBOUND_STEP/CANCEL_INBOUND_FAILURE'
)<undefined, undefined, Error>();
