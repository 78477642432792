import { Tooltip } from '@material-ui/core';
import { Clock as ClockIcon } from 'react-feather';
import { Row } from 'src/components/Tables/types';
import { useStyles } from '../useStyles';
import { CALCULATOR_ICON_TOOLTIP_TITLE } from '../const';

const BillingDocumentsCalculatorIcon = ({ row }: Row) => {
  const classes = useStyles();

  return row.isProcessing ? (
    <Tooltip title={CALCULATOR_ICON_TOOLTIP_TITLE}>
      <ClockIcon size={20} className={classes.warningIcon} />
    </Tooltip>
  ) : (
    row.totalRewardAmount
  );
};

export default BillingDocumentsCalculatorIcon;
