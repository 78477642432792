import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { DANGEROUS_ZONES } from 'src/config/avlUrls';
import * as actions from './actions';
import { selectDangerousZoneId } from './selectors';

export function* getDangerousZone(): SagaIterator {
  const id = yield select(selectDangerousZoneId);

  const { data } = yield call(apiClient.get, `${DANGEROUS_ZONES}/${id}`, {
    retryAction: actions.getDangerousZone.request()
  });

  yield put(actions.getDangerousZone.success(data));
}

export default function* dangerousZonesDetailsSagas(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getDangerousZone.request),
      safe(getDangerousZone, actions.getDangerousZone.failure)
    )
  ]);
}
