import { createReducer, ActionType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import * as actions from './actions';
import { InboundDetailsState } from '../../InboundDetailsView/types';

type InboundDetailsActions = ActionType<typeof actions>;

const initialState: InboundDetailsState = {
  id: 0,
  documentNumber: 0,
  dateInbound: null,
  inboundingEmployeeName: '',
  inboundingEmployeeFullName: '',
  acceptingEmployeeName: '',
  acceptingEmployeeFullName: '',
  counteragentName: '',
  counteragentAddress: '',
  notes: '',
  status: StatusEnum.Fixed,
  equipments: [
    {
      id: 0,
      name: '',
      model: '',
      manufacturer: '',
      productType: '',
      imei: '',
      state: 'Norm',
      barcode: '0',
      supplements: [],
      spareParts: ''
    }
  ]
};

const inboundEmailDataReducers = createReducer<
  InboundDetailsState,
  InboundDetailsActions
>(initialState).handleAction(
  actions.getInboundRowData.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default inboundEmailDataReducers;
