import { FC, useEffect } from 'react';
import { useFormikContext, useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  Checkbox,
  InputAdornment,
  makeStyles,
  MenuItem,
  ListItemText
} from '@material-ui/core';
import { X as XIcon } from 'react-feather';
import { FormSelect } from '../Form';
import { SelectProps } from './types';
import * as selectors from './store/selectors';
import * as actions from './store/actions';
import EditableSelectItem from '../EditableSelect/EditableSelectItem';
import { generateMultipleSelectValue } from '../utils';

const useStyles = makeStyles(({ spacing }) => ({
  menu: {
    maxWidth: 300
  },
  endAdornment: {
    top: '50%',
    marginRight: spacing(2),
    position: 'relative'
  }
}));

const Select: FC<SelectProps> = ({
  name,
  label,
  onChange,
  config,
  transformFunc,
  disabled,
  required,
  defaultItem,
  multiple,
  allSelectedOption = false,
  allSelectedTitle,
  allAutoSelected = false,
  valueKey = 'id',
  nativeProps = {},
  renderMultipleValue
}) => {
  const classes = useStyles();
  const { reducerPath, optionsApiUrl } = config;

  const dispatch = useDispatch();

  const options = useSelector(
    selectors.selectOptions(reducerPath, transformFunc)
  );
  const [{ value: selectValue }] = useField(name);

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (optionsApiUrl) {
      dispatch(actions.getOptionsRequest(config));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsApiUrl]);

  // if by default all shoud be selected, the value needs to be updated
  useEffect(() => {
    if (allAutoSelected) {
      selectAll();
    }
  }, [allAutoSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectAll = () => {
    if (multiple && options.length) {
      if (options.length === selectValue.length) {
        handleClearValue();
      } else {
        setFieldValue(
          name,
          options.map(option => option.id)
        );
      }
    }
  };

  const handleClearValue = () => {
    if (multiple) {
      setFieldValue(name, []);
    }
  };

  return (
    <FormSelect
      name={name}
      nativeProps={{
        label,
        onChange,
        disabled,
        required,
        MenuProps: { className: classes.menu },
        multiple,
        ...nativeProps,
        ...(multiple && {
          renderValue: selected => {
            // if multiple is on and allSelectedTitle is defined
            // and all values are selected then show allSelectedTitle
            const selectedIds = selected as number[];
            if (
              multiple &&
              allSelectedTitle &&
              options.length === selectedIds.length
            ) {
              return allSelectedTitle;
            }

            const selectedOptions = options.filter(o =>
              selectedIds.includes(o.id)
            );

            const renderFunction =
              renderMultipleValue || generateMultipleSelectValue;

            return renderFunction(selectedOptions);
          }
        }),
        endAdornment:
          multiple && selectValue.length ? (
            <InputAdornment
              className={classes.endAdornment}
              position="end"
              onClick={handleClearValue}
            >
              <IconButton size="small">
                <XIcon size={20} />
              </IconButton>
            </InputAdornment>
          ) : null
      }}
    >
      {/* only if there are options and all selector must be shown and multiple select is on */}
      {allSelectedOption && multiple && options.length > 0 && (
        <MenuItem>
          <Checkbox
            checked={selectValue.length === options.length}
            onChange={selectAll}
          />
          <ListItemText
            primary="Всі"
            onClick={event => {
              event.stopPropagation();
              selectAll();
            }}
          />
        </MenuItem>
      )}
      {defaultItem && (
        <MenuItem value={defaultItem.id}>
          <EditableSelectItem name={defaultItem.name} />
        </MenuItem>
      )}
      {options
        .filter(option => option.id !== defaultItem?.id)
        .map(option => (
          <MenuItem key={option.id} value={option[valueKey]}>
            {multiple && (
              <Checkbox checked={selectValue.indexOf(option[valueKey]) > -1} />
            )}
            <EditableSelectItem name={option.name} />
          </MenuItem>
        ))}
      {!defaultItem && !options.length && (
        <MenuItem value="Нічого не знайдено" disabled>
          <EditableSelectItem name="Нічого не знайдено" />
        </MenuItem>
      )}
    </FormSelect>
  );
};

export default Select;
