import { call, put, select, takeEvery, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { INSPECTION_DOCUMENTS } from 'src/config/clearingUrls';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { INSPECTION_DOCUMENTS_URL } from 'src/routes/clearingRoutes';
import * as actions from './actions';
import { selectInspectionDocumentId } from './selectors';

export function* updateInspectionDocument({
  payload
}: ReturnType<typeof actions.updateInspectionDocument.request>): SagaIterator {
  const id = yield select(selectInspectionDocumentId);
  yield call(apiClient.put, `${INSPECTION_DOCUMENTS}/${id}`, payload, {
    retryAction: actions.updateInspectionDocument.request(payload)
  });
  yield put(navigateTo({ url: INSPECTION_DOCUMENTS_URL }));
  yield put(
    enqueueSnackbar({
      key: 'inspection_document_updated',
      message: 'Документ інспекції відредаговано',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.updateInspectionDocument.success());
}

export function* getInspectionDocument(): SagaIterator {
  const id = yield select(selectInspectionDocumentId);
  const { data } = yield call(apiClient.get, `${INSPECTION_DOCUMENTS}/${id}`, {
    retryAction: actions.getInspectionDocument.request()
  });
  yield put(actions.getInspectionDocument.success(data));
}

export default function* InspectionDocumentsEditView(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.updateInspectionDocument.request),
      safe(updateInspectionDocument, actions.updateInspectionDocument.failure)
    ),
    takeEvery(
      getType(actions.getInspectionDocument.request),
      safe(getInspectionDocument, actions.getInspectionDocument.failure)
    )
  ]);
}
