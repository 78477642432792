import { createCustomAction } from 'typesafe-actions';
import { AutocompleteConfig, Result, GetOptionsRequestPayload } from '../types';

export const getAutocompleteOptionsRequest = createCustomAction(
  '@AUTOCOMPLETE/GET_AUTOCOMPLETE_OPTIONS',
  (config: AutocompleteConfig, payload?: GetOptionsRequestPayload) => ({
    config,
    payload
  })
);

export const getAutocompleteOptionsSuccess = createCustomAction(
  '@AUTOCOMPLETE/GET_AUTOCOMPLETE_OPTIONS_SUCCESS',
  (config: AutocompleteConfig, payload: Result[]) => ({
    config,
    payload
  })
);

export const getAutocompleteOptionsFailure = createCustomAction(
  '@AUTOCOMPLETE/GET_AUTOCOMPLETE_OPTIONS_FAILURE',
  (config: AutocompleteConfig, payload: Error) => ({
    payload,
    config
  })
);

export const clearAutocompleteOptions = createCustomAction(
  '@AUTOCOMPLETE/CLEAR_AUTOCOMPLETE_OPTIONS',
  (config: AutocompleteConfig) => ({
    config
  })
);

export const setLoading = createCustomAction(
  '@AUTOCOMPLETE/SET_LOADING',
  (config: AutocompleteConfig, payload: boolean) => ({
    config,
    payload
  })
);
