import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { KPI_PARAMETERS_GROUPS } from 'src/config/clearingUrls';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';

export function* createKPIParameters({
  payload
}: ReturnType<typeof actions.createKPIParameters.request>): SagaIterator {
  yield call(apiClient.post, KPI_PARAMETERS_GROUPS, payload, {
    retryAction: actions.createKPIParameters.request(payload)
  });
  yield put(navigateTo({ url: '/clearing/kpiParameters' }));
  yield put(
    enqueueSnackbar({
      key: 'kpi_parameters_create',
      message: 'Групу параметрів КПЕ створено',
      options: {
        variant: 'success'
      }
    })
  );
  yield put(actions.createKPIParameters.success());
}

export default function* KPIParametersCreateSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.createKPIParameters.request),
      safe(createKPIParameters, actions.createKPIParameters.failure)
    )
  ]);
}
