import { SagaIterator } from 'redux-saga';
import { select, call, put, takeEvery, all } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { ROUTE_PASSPORT } from 'src/config/avlUrls';
import * as selectors from 'src/views/avl/Routes/RoutesPassportView/store/selectors';
import * as actions from './actions';

export function* getRouteSchemeDataSaga(): SagaIterator {
  const id = yield select(selectors.selectPassportId);

  const { data } = yield call(
    apiClient.get,
    `${ROUTE_PASSPORT}/${id}/route-schemes/details`,
    {
      retryAction: actions.getRouteSchemeData.request()
    }
  );

  yield put(actions.getRouteSchemeData.success(data));
}

export function* createRouteSchemeSaga({
  payload
}: ReturnType<typeof actions.createRouteScheme.request>): SagaIterator {
  const { id, mode } = yield select(selectors.selectRouteParams);

  yield call(apiClient.post, `${ROUTE_PASSPORT}/${id}/route-schemes`, payload, {
    retryAction: actions.createRouteScheme.request(payload)
  });

  yield put(navigateTo({ url: `/avl/routes/${mode}/scheme/${id}` }));
  yield put(
    enqueueSnackbar({
      key: 'route_scheme_create',
      message: 'Створено нову схему',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );

  yield put(actions.createRouteScheme.success());
}

export default function* RouteSchemeCreateSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.createRouteScheme.request),
      safe(createRouteSchemeSaga, actions.createRouteScheme.failure)
    ),
    takeEvery(
      getType(actions.getRouteSchemeData.request),
      safe(getRouteSchemeDataSaga, actions.getRouteSchemeData.failure)
    )
  ]);
}
