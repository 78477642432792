import React, { FC } from 'react';
import {
  Snackbar as SnackbarComponent,
  Button,
  IconButton,
  makeStyles
} from '@material-ui/core';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import { X as CloseIcon } from 'react-feather';

const useStyles = makeStyles(() => ({
  root: {
    position: 'static'
  }
}));

interface SnackbarProps {
  retryAction: () => void;
  closeAction: () => void;
  message: string;
  variant?: Color;
}

const Snackbar: FC<SnackbarProps> = React.forwardRef(
  ({ retryAction, closeAction, message, variant }, ref) => {
    const classes = useStyles();

    return (
      <SnackbarComponent
        open
        autoHideDuration={6000}
        message={message}
        ref={ref}
        className={classes.root}
        data-testid="snackbar-test-id"
      >
        <MuiAlert
          severity={variant}
          elevation={6}
          variant="filled"
          action={
            <>
              {retryAction && (
                <Button color="inherit" size="small" onClick={retryAction}>
                  Повторити
                </Button>
              )}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeAction}
              >
                <CloseIcon size={20} />
              </IconButton>
            </>
          }
        >
          {message}
        </MuiAlert>
      </SnackbarComponent>
    );
  }
);

export default Snackbar;
