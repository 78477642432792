import * as L from 'leaflet';
import { LatLngLiteral } from 'leaflet';
import * as turf from '@turf/turf';
import { last } from 'lodash';
import { LAT_LON_REGEX, NEAREST_POINTS_RADIUS } from '../constants/map';
import { RouteStop } from '../components/Maps/MapRoute/types';

export const parseCoordinates = (coordinates: string): string[] =>
  coordinates.split(',').map((item: string) => item.trim());

export const stringifyCoordinates = (coordinates: LatLngLiteral) =>
  `${coordinates.lat}, ${coordinates.lng}`;

export const getOptionalCoordinates = (
  coordinates: string,
  defaultCoordinates: LatLngLiteral
) => {
  if (LAT_LON_REGEX.test(coordinates)) {
    const [lat, lng] = parseCoordinates(coordinates);

    return { lat: +lat, lng: +lng };
  }

  return defaultCoordinates;
};

export function getBorders<T>(arr: T[]) {
  return [arr[0], arr[arr.length - 1]];
}

export function pointsPartialEqual(
  first: LatLngLiteral,
  second: LatLngLiteral,
  radius?: number
) {
  const point = turf.point([first.lat, first.lng]);
  const polygon = turf.circle(
    [second.lat, second.lng],
    radius || NEAREST_POINTS_RADIUS
  );

  return turf.booleanPointInPolygon(point, polygon);
}

function areCoordinatesClose(
  coord1: LatLngLiteral,
  coord2: LatLngLiteral,
  threshold: number = 10
): boolean {
  const point1 = turf.point([coord1.lng, coord1.lat]);
  const point2 = turf.point([coord2.lng, coord2.lat]);
  const distance = turf.distance(point1, point2, { units: 'meters' });
  return distance <= threshold;
}

export function validateConnections(
  stops: RouteStop[],
  segments: L.LatLng[][]
) {
  const missedConnections: [number, number][] = [];
  const unconnectedStops: { [id: number]: true } = {};

  for (let i = 0; i < stops.length; i++) {
    const currentStop = stops[i];
    const nextStopIdx = (i + 1) % stops.length; // Get next stop, loop back to the first stop if at the end
    const nextStop = stops[nextStopIdx];

    const hasConnection = segments.some(segment => {
      const segmentStart = segment[0];
      const segmentEnd = last(segment)!;

      return (
        (areCoordinatesClose(currentStop.coordinates!, segmentStart) &&
          areCoordinatesClose(nextStop.coordinates!, segmentEnd)) ||
        (areCoordinatesClose(currentStop.coordinates!, segmentEnd) &&
          areCoordinatesClose(nextStop.coordinates!, segmentStart))
      );
    });

    if (!hasConnection) {
      unconnectedStops[currentStop.id!] = true;
      unconnectedStops[nextStop.id!] = true;
      missedConnections.push([i + 1, nextStopIdx + 1]);
    }
  }

  return { missedConnections, unconnectedStops };
}

export function buildUnconnectedStopsErrorMessage(
  missingConnections: [number, number][]
): string {
  if (missingConnections.length === 0) {
    return '';
  }

  const errorParts: string[] = [];

  for (const [first, second] of missingConnections) {
    errorParts.push(`${first} та ${second}`);
  }

  return `Зупинки ${errorParts.join(', ')} не зʼєднані`;
}

type PointCoordinate = LatLngLiteral & {
  key: string;
};

export const findClosestCoordinate = (
  coordinates: PointCoordinate[],
  targetCoordinate: LatLngLiteral
) => {
  const targetPoint = turf.point([targetCoordinate.lat, targetCoordinate.lng]);
  let minDistance = Infinity;
  let closestPoint: PointCoordinate;

  for (const coordinate of coordinates) {
    const point = turf.point([coordinate.lat, coordinate.lng]);
    const distance = turf.distance(targetPoint, point);

    if (distance < minDistance) {
      minDistance = distance;
      closestPoint = coordinate;
    }
  }

  return closestPoint!;
};
