import { createAsyncAction } from 'typesafe-actions';
import { RouteSettingsValues } from '../types';

export const updateRouteSettings = createAsyncAction(
  '@ROUTE_SETTINGS/UPDATE_ROUTE_SETTINGS_REQUEST',
  '@ROUTE_SETTINGS/UPDATE_ROUTE_SETTINGS_SUCCESS',
  '@ROUTE_SETTINGS/UPDATE_ROUTE_SETTINGS_FAILURE'
)<RouteSettingsValues, undefined, Error>();

export const getRouteSettings = createAsyncAction(
  '@ROUTE_SETTINGS/GET_ROUTE_SETTINGS_REQUEST',
  '@ROUTE_SETTINGS/GET_ROUTE_SETTINGS_SUCCESS',
  '@ROUTE_SETTINGS/GET_ROUTE_SETTINGS_FAILURE'
)<undefined, RouteSettingsValues, Error>();
