import { EmployeeEditDTO } from '@eticket/avl-api-contracts';
import { createAsyncAction } from 'typesafe-actions';
import { EmployeeValues } from '../../types';

export const getEmployee = createAsyncAction(
  '@EMPLOYEES_EDIT/GET_EMPLOYEE_REQUEST',
  '@EMPLOYEES_EDIT/GET_EMPLOYEE_SUCCESS',
  '@EMPLOYEES_EDIT/GET_EMPLOYEE_FAILURE'
)<undefined, EmployeeEditDTO, Error>();

export const updateEmployee = createAsyncAction(
  '@EMPLOYEES_EDIT/UPDATE_EMPLOYEE_REQUEST',
  '@EMPLOYEES_EDIT/UPDATE_EMPLOYEE_SUCCESS',
  '@EMPLOYEES_EDIT/UPDATE_EMPLOYEE_FAILURE'
)<EmployeeValues, undefined, Error>();
