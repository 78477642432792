import { createReducer, ActionType } from 'typesafe-actions';
import { KPIParametersGroup } from '../../types';
import * as actions from './actions';

interface KPIParametersGroupEditState {
  kpiParametersGroup: KPIParametersGroup | null;
}

const initialState: KPIParametersGroupEditState = {
  kpiParametersGroup: null
};

const kpiParametersGroupEditReducer = createReducer<
  KPIParametersGroupEditState,
  ActionType<typeof actions>
>(initialState).handleAction(
  actions.getKPIParametersGroup.success,
  (state, { payload }) => ({
    ...state,
    kpiParametersGroup: payload
  })
);

export default kpiParametersGroupEditReducer;
