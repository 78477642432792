import {
  Column,
  TableConfig
} from 'src/components/Tables/TableWithPagination/types';
import { API_KEYS_SETTINGS_PATH } from 'src/routes/administrationRoutes';
import Label from 'src/components/Labels/Label';
import { LabelColors } from 'src/components/Labels/Label/types';
import { object, string } from 'yup';
import { maxLength } from 'src/utils/validation';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { API_MANAGEMENT } from 'src/config/administrationUrls';
import { generateActionCrud } from 'src/components/Guards';
import { ApiKeysTableRow, ApiKeysValues, Status } from './types';

export const API_KEYS_TABLE_NAME = 'API_KEYS_TABLE_NAME';

const STATUS_MAP: Record<Status, { label: string; color: LabelColors }> = {
  [Status.Active]: {
    label: 'Останній запит',
    color: 'success'
  },
  [Status.Terminated]: {
    label: 'Припинено',
    color: 'warning'
  }
};

export const columns: Column<keyof ApiKeysTableRow>[] = [
  {
    value: 'createdAtUtc',
    width: '22%',
    label: 'Дата створення',
    sortable: true
  },
  { value: 'apiKeyName', width: '22%', label: 'Назва ключа', sortable: true },
  {
    value: 'key',
    width: '22%',
    label: 'API-ключ'
  },
  {
    value: 'status',
    width: '22%',
    label: 'Статус',
    sortable: true,
    changeValue: ({ status, requestedAtUtc }) => {
      const { color, label } = STATUS_MAP[status as Status];

      return (
        <Label color={color}>{`${label} ${
          status === Status.Active ? requestedAtUtc || '- -' : ''
        }`}</Label>
      );
    }
  }
];

export const filters = [
  {
    name: 'status',
    label: 'Статус',
    options: [
      {
        label: 'Активні',
        value: Status.Active
      },
      {
        label: 'Припинені',
        value: Status.Terminated
      }
    ]
  }
];

export const tableConfig: TableConfig = {
  reducerName: API_KEYS_TABLE_NAME,
  reducerPath: 'administration.apiKeysSettings.table',
  routeUrl: API_KEYS_SETTINGS_PATH,
  apiUrl: API_MANAGEMENT,
  toolbarActions: {},
  filters
};

export const FORM_ID = 'api-keys-form';

export const validationSchema = object<Partial<ApiKeysValues>>({
  name: string()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(...maxLength(100))
});

export const API_KEY_VALUE = '****************';

export const crudActions = generateActionCrud({
  module: 'administration',
  sub: 'apiKeysSettings'
});
