import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { selectRouteParams } from 'src/views/avl/Routes/RoutesPassportView/store/selectors';
import { ROUTE_SCHEME_STOPS } from 'src/config/avlUrls';
import * as actions from './actions';

export function* getSchemeStops(): SagaIterator {
  const { id, subId } = yield select(selectRouteParams);

  const { data } = yield call(apiClient.get, ROUTE_SCHEME_STOPS(id, subId), {
    retryAction: actions.getSchemeStops.request
  });

  yield put(actions.getSchemeStops.success(data));
}

export default function* IterationsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getSchemeStops.request),
    safe(getSchemeStops, actions.getSchemeStops.failure)
  );
}
