import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { INSPECTION_DOCUMENTS_URL } from 'src/routes/clearingRoutes';
import { generateActionCrud } from 'src/components/Guards';
import { InspectionDocumentStatus } from './types';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.clearing,
    url: '/clearing'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.inspections,
    url: INSPECTION_DOCUMENTS_URL
  }
];

export const crudActions = generateActionCrud({
  module: 'clearing',
  sub: 'inspectionDocuments'
});

export const inspectionDocumentStatuses: Record<
  InspectionDocumentStatus,
  string
> = {
  Completed: 'Проведено',
  Billed: 'Обраховано'
};
