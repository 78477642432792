import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { get, isBoolean, isNil } from 'lodash';
import { RootState } from 'typesafe-actions';
import { TariffDetailDTO } from '@eticket/clearing-api-contracts/build';
import { DATE_FORMAT_FULL } from 'src/config';
import { convertUtcToLocal } from 'src/utils/date';
import { TariffEffectiveDateMap } from '../types';

const matchSpaceParams = createMatchSelector('/clearing/tariffs/:id/details');

export const selectTariffId = createSelector(
  matchSpaceParams,
  (match: any) => match?.params.id || ''
);

export const selectIsLoadingTariff = (state: RootState) => {
  const loading = get(state, `loading.@TARIFFS_DETAILS/GET_TARIFF`);
  return isBoolean(loading) ? loading : true;
};

export const selectIsDeleteDisabled = (state: RootState) =>
  !get(state, `ui.clearing.tariffs.details.canDelete`);

export const selectTariffErrors = (state: RootState) =>
  get(state, `error.@DETAILS_ACTIONS/DELETE_ITEM`, []);

export const selectTariffName = (state: RootState): string =>
  get(state, `ui.clearing.tariffs.details.name`);

export const selectTariffRows = (state: RootState) => {
  const { name, passengerCapacity, code, notes } = get(
    state,
    `ui.clearing.tariffs.details`
  );
  const [capacityFrom, capacityTo] = passengerCapacity;
  return [
    { label: 'Назва', value: name },
    {
      label: 'Пасажиромісткість',
      value: `${capacityFrom}-${capacityTo}`
    },
    { label: 'Код', value: code },
    { label: 'Примітка', value: notes }
  ];
};

export const selectTariffEffectiveDatesRows = (
  state: RootState
): TariffEffectiveDateMap => {
  const { activeTariffs, futureTariffs, pastTariffs } = get(
    state,
    `ui.clearing.tariffs.details`
  );

  return [
    [
      'Поточний тариф',
      activeTariffs?.map(
        ({ amountPerKm, effectiveDateUtc }: TariffDetailDTO) => ({
          label: 'Початок дії',
          amountPerKm: `${amountPerKm} грн/км`,
          effectiveDate: convertUtcToLocal(
            String(effectiveDateUtc),
            DATE_FORMAT_FULL
          )
        })
      ),
      true
    ],
    [
      'Майбутні тарифи',
      futureTariffs?.map(
        ({ amountPerKm, effectiveDateUtc }: TariffDetailDTO) => ({
          label: 'Початок Дії',
          amountPerKm: `${amountPerKm} грн/км`,
          effectiveDate: convertUtcToLocal(
            String(effectiveDateUtc),
            DATE_FORMAT_FULL
          )
        })
      )
    ],
    [
      'Минулі тарифи',
      pastTariffs?.map(
        ({ amountPerKm, effectiveDateUtc, endDateUtc }: TariffDetailDTO) => ({
          amountPerKm: `${amountPerKm} грн/км`,
          effectiveDate: !isNil(endDateUtc)
            ? `${convertUtcToLocal(
                String(effectiveDateUtc),
                DATE_FORMAT_FULL
              )} - ${convertUtcToLocal(String(endDateUtc), DATE_FORMAT_FULL)}`
            : convertUtcToLocal(String(effectiveDateUtc), DATE_FORMAT_FULL)
        })
      )
    ]
  ];
};
