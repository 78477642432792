import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ROUTE_PASSPORT_DETAILS } from 'src/config/avlUrls';
import { safe } from 'src/utils/sagas';
import { apiClient } from 'src/utils/api';
import * as actions from './actions';

export function* getPassportPdfData({
  payload: passportId
}: ReturnType<typeof actions.getPassportPdfData.request>): SagaIterator {
  const { data } = yield call(
    apiClient.get,
    ROUTE_PASSPORT_DETAILS(passportId),
    {
      retryAction: actions.getPassportPdfData.request
    }
  );

  yield put(actions.getPassportPdfData.success(data));
}

export default function* RoutesPassportPdf(): SagaIterator {
  yield takeEvery(
    getType(actions.getPassportPdfData.request),
    safe(getPassportPdfData, actions.getPassportPdfData.failure)
  );
}
