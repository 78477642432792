import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { navigateTo } from 'src/store/actions/app';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { TARIFFS } from 'src/config/clearingUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getTariff(): SagaIterator {
  const id = yield select(selectors.selectTariffId);
  const { data } = yield call(apiClient.get, `${TARIFFS}/${id}/edit`, {
    retryAction: actions.getTariff.request()
  });
  yield put(actions.getTariff.success(data));
}

export function* updateTariff({
  payload
}: ReturnType<typeof actions.updateTariff.request>): SagaIterator {
  const id = yield select(selectors.selectTariffId);

  yield call(apiClient.put, `${TARIFFS}/${id}`, payload, {
    retryAction: actions.updateTariff.request(payload)
  });
  yield put(navigateTo({ url: '/clearing/tariffs' }));
  yield put(
    enqueueSnackbar({
      key: 'tariff_edit',
      message: 'Тариф відредаговано',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.updateTariff.success());
}

export default function* tariffEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getTariff.request),
      safe(getTariff, actions.getTariff.failure)
    ),
    takeEvery(
      getType(actions.updateTariff.request),
      safe(updateTariff, actions.updateTariff.failure)
    )
  ]);
}
