import { ActionType, createReducer } from 'typesafe-actions';
import { SystemStatusDTO } from '@eticket/avl-api-contracts';
import * as actions from './actions';
import { UserManagementValues, UserRole } from '../types';
import { UserStatus } from '../../usersStatusColumn/types';

export const initialState: UserManagementValues = {
  id: '',
  userName: '',
  status: UserStatus.Inactive,
  roleIds: [],
  employee: null,
  hasAccessToAllCounteragents: false,
  employeeName: '',
  profession: '',
  department: ''
};

const userManagementReducer = createReducer<
  UserManagementValues,
  ActionType<typeof actions>
>(initialState).handleAction(
  actions.getUserDetails.success,
  (state, { payload }) => ({
    ...state,
    ...payload,
    roleIds: payload?.userRoles?.map((item: UserRole) => item.id),
    hasAccessToAllCounteragents: payload.hasAccessToAllCounteragents,
    employee: {
      id: payload.employeeId,
      status: payload.employeeStatus || SystemStatusDTO.Enabled,
      phoneNumber: payload.phoneNumber ?? '',
      profession: payload.profession ?? '',
      department: payload.counteragentName ?? '',
      employeeName: payload.employeeName,
      contacts: [{ phoneNumber: (payload.phoneNumber as string) ?? '' }]
    }
  })
);

export default userManagementReducer;
