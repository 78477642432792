import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { safe } from 'src/utils/sagas';
import { VEHICLES_MODELS } from 'src/config/avlUrls';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getVehiclesModel(): SagaIterator {
  const id = yield select(selectors.selectVehiclesModelId);

  const { data } = yield call(apiClient.get, `${VEHICLES_MODELS}/${id}/edit`, {
    retryAction: actions.getVehiclesModel.request()
  });

  yield put(actions.getVehiclesModel.success(data));
}

export function* updateVehiclesModel({
  payload
}: ReturnType<typeof actions.updateVehiclesModel.request>): SagaIterator {
  const id = yield select(selectors.selectVehiclesModelId);

  yield call(apiClient.put, `${VEHICLES_MODELS}/${id}`, payload, {
    retryAction: actions.updateVehiclesModel.request(payload)
  });
  yield put(navigateTo({ url: '/avl/vehicles-models/' }));
  yield put(
    enqueueSnackbar({
      key: 'vehicles_model_edit',
      message: 'Модель ТЗ відредаговано',
      options: { variant: 'success' }
    } as UINotification)
  );
  yield put(actions.updateVehiclesModel.success());
}

export default function* vehiclesModelEditSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getVehiclesModel.request),
      safe(getVehiclesModel, actions.getVehiclesModel.failure)
    ),
    takeEvery(
      getType(actions.updateVehiclesModel.request),
      safe(updateVehiclesModel, actions.updateVehiclesModel.failure)
    )
  ]);
}
