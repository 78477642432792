import { put, takeEvery, call, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { STATE_CHANGES } from 'src/config/assetUrls';
import * as actions from './actions';
import { StateChangeDetailsPayload } from '../../types';

export function* getStateChange({
  payload
}: ReturnType<typeof actions.getStateChange.request>): SagaIterator {
  const { id } = payload;

  const { data } = yield call(apiClient.get, `${STATE_CHANGES}/${id}`, {
    retryAction: actions.getStateChange.request
  });

  yield put(actions.getStateChange.success(data));
}

export function* getSelectedStateChangesData({
  payload
}: ReturnType<
  typeof actions.getSelectedStateChangesData.request
>): SagaIterator {
  const requests = payload.map(({ id }) =>
    call(apiClient.get, `${STATE_CHANGES}/${id}`, {
      retryAction: actions.getSelectedStateChangesData.request(payload)
    })
  );

  const response = yield all(requests);

  const stateChangesData = response.map(
    ({ data }: { data: StateChangeDetailsPayload[] }) => data
  );

  yield put(actions.getSelectedStateChangesData.success(stateChangesData));
}

export default function* StateChangeDetailsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getStateChange.request),
      safe(getStateChange, actions.getStateChange.failure)
    ),
    takeEvery(
      getType(actions.getSelectedStateChangesData.request),
      safe(
        getSelectedStateChangesData,
        actions.getSelectedStateChangesData.failure
      )
    )
  ]);
}
