import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { USER_MANAGEMENT } from 'src/config/administrationUrls';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';
import { selectUserManagementId } from './selectors';
import * as selectors from './selectors';

export function* getUser(): SagaIterator {
  const id = yield select(selectors.selectUserManagementId);
  const { data } = yield call(apiClient.get, `${USER_MANAGEMENT}/${id}`, {
    retryAction: actions.getUserDetails.request()
  });
  yield put(actions.getUserDetails.success(data));
}

export function* updateUser({
  payload
}: ReturnType<typeof actions.updateUserSettings.request>): SagaIterator {
  const id = yield select(selectUserManagementId);
  const { status, roleIds, hasAccessToAllCounteragents } = payload;
  yield call(
    apiClient.put,
    `${USER_MANAGEMENT}/${id}`,
    {
      status,
      roleIds,
      hasAccessToAllCounteragents,
      employeeId: payload?.employee?.id || undefined
    },
    {
      retryAction: actions.updateUserSettings.request(payload)
    }
  );
  yield put(actions.updateUserSettings.success());
  yield put(navigateTo({ url: `/administration/users` }));
  yield put(
    enqueueSnackbar({
      key: 'users',
      message: 'Користувача відредаговано',
      options: {
        variant: 'success'
      }
    })
  );
}

export default function* userManagementSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getUserDetails.request),
      safe(getUser, actions.getUserDetails.failure)
    ),
    takeEvery(
      getType(actions.updateUserSettings.request),
      safe(updateUser, actions.updateUserSettings.failure)
    )
  ]);
}
