import { Box, LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0.7,
    display: 'flex',
    alignItems: 'center',
    zIndex: 1000,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 256
    }
  }
}));

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.backdrop}>
      <Box width={400} ml="calc(50% - 200px)">
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
