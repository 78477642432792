import * as Yup from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { NAV_BAR_HEADERS } from 'src/constants';
import { generateActionCrud } from 'src/components/Guards';
import { maxLength } from 'src/utils/validation';

export enum StateEnum {
  Norm = 'Norm',
  Inspection = 'Inspection',
  Suspicious = 'Suspicious',
  Substitute = 'Substitute',
  Repair = 'Repair',
  Outbound = 'Outbound',
  Uninstalled = 'Uninstalled',
  Custom = 'Custom'
}

export const listBreadcrumbs = [
  {
    text: NAV_BAR_HEADERS.equipment,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: 'Стани',
    url: '/asset/dictionaries/states'
  }
];

export const crudActions = generateActionCrud({
  module: 'assets',
  sub: 'states'
});

export const statesValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(...maxLength(100))
    .required(ERROR_MESSAGES.REQUIRED),
  description: Yup.string()
    .max(...maxLength(350))
    .required(ERROR_MESSAGES.REQUIRED),
  notes: Yup.string().max(...maxLength(1000))
});

export const isDeleteDisabledText = (isDeleteDisabled: boolean | undefined) =>
  isDeleteDisabled ? 'Неможливо видалити' : 'Видалити';

export const isEditDisabledText = (isEditDisabled: boolean | undefined) =>
  isEditDisabled ? 'Неможливо редагувати' : 'Редагувати';
