import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { selectBillingTableParams } from '../store/selectors';
import { BillingDetailsLevels } from '../../types';
import { getLevelLink } from '../utils';

interface Props {
  label: string;
  id?: number;
  routeId?: number;
}

const useStyles = makeStyles(({ palette }) => ({
  link: {
    textDecoration: 'none',
    borderBottom: `2px dashed ${palette.primary.light}`,
    color: palette.primary.light
  }
}));

const BillingDetailsLink = ({ label, id, routeId }: Props) => {
  const classes = useStyles();

  const { level } = useSelector(selectBillingTableParams);

  let newUrl = '';

  if (level === BillingDetailsLevels.Carrier) {
    newUrl = getLevelLink(BillingDetailsLevels.Routes, id);
  }

  if (level === BillingDetailsLevels.Routes) {
    newUrl = getLevelLink(BillingDetailsLevels.Vehicles, id, routeId);
  }

  return (
    <Link to={newUrl} className={classes.link}>
      {label}
    </Link>
  );
};

export default BillingDetailsLink;
