import { RootState } from 'typesafe-actions';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { Row } from 'src/components/Tables/types';

export const selectListResult = (reducerPath: string) => (
  state: RootState
): Row[] => get(state, `ui.${reducerPath}.list`, []);

export const selectListIds = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), (list): number[] =>
    list.map(el => el.id)
  );

export const selectListCreateData = (reducerPath: string) =>
  createSelector(selectListResult(reducerPath), list =>
    list.map(el => ({
      assetId: el.id,
      equipmentStateId: el.equipmentStateId
    }))
  );
