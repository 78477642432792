import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { Vehicle } from '../types';

type VehicleDetailsActions = ActionType<typeof actions>;

const initialState: Vehicle = {
  id: 0,
  vehicleLicensePlate: '',
  vehicleModelName: '',
  counteragentName: '',
  driverName: '',
  imei: '',
  status: 'Enabled'
};

const vehicleDetailsReducer = createReducer<Vehicle, VehicleDetailsActions>(
  initialState
).handleAction(actions.getVehicle.success, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default vehicleDetailsReducer;
