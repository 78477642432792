import { generateActionCrud } from 'src/components/Guards';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { INSTALLATIONS, UNINSTALLATIONS } from '../../../config/assetUrls';

export const CREATE_REPLACEMENT_FORM_NAME = 'CREATE_REPLACEMENT_FORM';
export const CREATE_REPLACEMENT_FORM_PATH = 'asset.replacement.create.data';
export const CREATE_REPLACEMENT_FORM_CONFIG = {
  reducerName: CREATE_REPLACEMENT_FORM_NAME,
  reducerPath: CREATE_REPLACEMENT_FORM_PATH
};

export const REPLACING_UNINSTALLING_EQUIPMENT_NAME =
  'REPLACING_UNINSTALLING_EQUIPMENT_NAME';
export const REPLACING_UNINSTALLING_EQUIPMENT_PATH =
  'asset.replacement.create.replacementUninstallingTable';

export const getReplacementUninstallingTableViewConfig = (id: string) => ({
  reducerName: REPLACING_UNINSTALLING_EQUIPMENT_NAME,
  reducerPath: REPLACING_UNINSTALLING_EQUIPMENT_PATH,
  apiUrl: `${UNINSTALLATIONS}/${id}/equipment`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено реєстрацію'
  },
  enableSetRowsIsLocalProperty: true
});

export const REPLACING_INSTALLING_EQUIPMENT_NAME =
  'REPLACING_INSTALLING_EQUIPMENT_NAME';
export const REPLACING_INSTALLING_EQUIPMENT_PATH =
  'asset.replacement.create.replacementInstallingTable';

export const getReplacementInstallingTableViewConfig = (id: string) => ({
  reducerName: REPLACING_INSTALLING_EQUIPMENT_NAME,
  reducerPath: REPLACING_INSTALLING_EQUIPMENT_PATH,
  apiUrl: `${INSTALLATIONS}/${id}/equipment`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено реєстрацію'
  },
  enableSetRowsIsLocalProperty: true
});

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.replacement,
    url: '/asset/uninstallation'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'installation'
  },
  ['download', 'resendDocument', 'installationAllowEditing']
);

export enum InstallationStatusEnum {
  Draft = 0,
  Fixed = 1
}

export const REPLACEMENT_STEPS = [
  'Заміна',
  'Обладнання для демонтажу',
  'Обладнання для монтажу',
  'Перевірка статусу'
];

export const installationDocumentPdf = [
  {
    label: 'Cклад',
    value: 'counteragentHolderName'
  },
  {
    label: 'Адреса складу',
    value: 'counteragentHolderAddress'
  },
  {
    label: 'Підрозділ монтажу',
    value: 'counteragentName'
  },
  {
    label: 'Адреса підрозділу монтажу',
    value: 'counteragentAddress'
  },
  {
    label: 'Виконавець',
    value: 'installingEmployeeName'
  },
  {
    label: 'Прийняв роботу',
    value: 'acceptingEmployeeName'
  },
  {
    label: 'Група обладнання',
    value: 'equipmentGroupName'
  },
  {
    label: 'Тип ТЗ',
    value: 'vehicleCategoryName'
  },
  {
    label: 'Номерний знак ТЗ',
    value: 'licensePlate'
  },
  {
    label: 'Модель ТЗ',
    value: 'vehicleModelName'
  },
  {
    label: 'Працівник підрозділу монтажу - відповідальна особа',
    value: 'employeeName'
  }
];

export const installationColumnsPdf = [
  {
    label: '№',
    value: 'index'
  },
  {
    label: 'Штрихкод',
    value: 'barcode'
  },
  {
    label: 'Назва',
    value: 'name'
  },
  {
    label: 'Модель',
    value: 'model'
  },
  {
    label: 'Виробник',
    value: 'manufacturer'
  },
  {
    label: 'ІМЕІ',
    value: 'imei'
  },
  {
    label: 'Запчастини',
    value: 'spareParts'
  },
  {
    label: 'Стан',
    value: 'state'
  }
];

export const installationFieldsJson = [
  'documentNumber',
  'installationDate',
  'employeeName',
  'acceptingEmployeeName',
  'counteragentName',
  'equipmentGroupName',
  'vehicleModelName',
  'counteragentAddress',
  'licensePlate',
  'vehicleModelName',
  'vehicleCategoryName',
  'installingEmployeeName',
  'notes',
  {
    label: 'equipments',
    fields: ['name', 'model', 'manufacturer', 'barcode', 'imei']
  }
];
