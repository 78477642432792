import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from '../ReportsDownloadView/store/actions';

export const downloadReportsInitialState = {
  results: []
};

type ReportsDownloadAction = ActionType<typeof actions>;

const downloadReportsReducer = createReducer<{}, ReportsDownloadAction>(
  downloadReportsInitialState
)
  .handleAction(actions.getReportData.success, (state, { payload }) => ({
    ...state,
    ...payload
  }))
  .handleAction(actions.getReportData.failure, state => ({
    ...state
  }));

export default downloadReportsReducer;
