import { SagaIterator } from 'redux-saga';
import { put, call, takeEvery, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import {
  EQUIPMENT,
  INSTALLATIONS_EQUIPMENT_VALIDATION
} from 'src/config/assetUrls';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';
import { EquipmentTypeEnum } from '../../../../../../constants';

export function* getEquipments({
  payload
}: ReturnType<typeof actions.getEquipments.request>): SagaIterator {
  const { barcode, counteragentId, counteragentName, equipmentType } = payload;

  const {
    data: { mobileEquipment, stationaryEquipment, inspectorEquipment }
  } = yield call(
    apiClient.get,
    `${EQUIPMENT}/barcode/${barcode}/${counteragentId}`
  );

  const mobileEquipmentItem = mobileEquipment[0];
  const stationaryEquipmentItem = stationaryEquipment[0];
  const inspectorEquipmentItem = inspectorEquipment[0];

  if (
    mobileEquipmentItem === undefined &&
    stationaryEquipmentItem === undefined &&
    inspectorEquipmentItem === undefined
  ) {
    throw new Error(
      `Даного обладнання не знайдено на підрозділі ${counteragentName}.`
    );
  } else if (
    mobileEquipmentItem !== undefined &&
    stationaryEquipmentItem !== undefined &&
    inspectorEquipmentItem !== undefined
  ) {
    throw new Error(
      `Знайдено кілька одиниць обладнання з однаковим штрихкодом (${barcode}). Перевірте обране обладнання.`
    );
  } else if (
    (equipmentType === EquipmentTypeEnum.Mobile &&
      mobileEquipmentItem === undefined) ||
    (equipmentType === EquipmentTypeEnum.Stationary &&
      stationaryEquipmentItem === undefined) ||
    (equipmentType === EquipmentTypeEnum.Inspector &&
      inspectorEquipmentItem === undefined)
  ) {
    throw new Error(
      'Обладнання з даним штрихкодом не відповідає групі, яку вибрано на кроці 1. Перевірте штрихкод'
    );
  } else {
    const equipmentItemByType = {
      [EquipmentTypeEnum.Mobile]: mobileEquipmentItem,
      [EquipmentTypeEnum.Stationary]: stationaryEquipmentItem,
      [EquipmentTypeEnum.Inspector]: inspectorEquipmentItem
    };

    yield put(
      actions.getEquipments.success(equipmentItemByType[equipmentType])
    );
  }
}

export function* validateEquipment({
  payload
}: ReturnType<typeof actions.validateEquipment.request>): SagaIterator {
  yield call(apiClient.post, INSTALLATIONS_EQUIPMENT_VALIDATION, payload, {
    retryAction: actions.validateEquipment.request(payload)
  });
  yield put(actions.validateEquipment.success());
}

export default function* EquipmentStepSaga(): SagaIterator {
  yield all([
    yield takeEvery(
      getType(actions.validateEquipment.request),
      safe(validateEquipment, actions.validateEquipment.failure)
    ),
    takeEvery(
      getType(actions.getEquipments.request),
      safe(getEquipments, actions.getEquipments.failure)
    )
  ]);
}
