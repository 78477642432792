import { INBOUNDS } from 'src/config/assetUrls';

export const CREATE_INBOUND_FORM_NAME = 'CREATE_INBOUND_FORM';
export const CREATE_INBOUND_FORM_PATH = 'asset.inbound.create.data';
export const CREATE_INBOUND_FORM_CONFIG = {
  reducerName: CREATE_INBOUND_FORM_NAME,
  reducerPath: CREATE_INBOUND_FORM_PATH
};

export const INBOUNDING_EQUIPMENT_NAME = 'INBOUND_EQUIPMENT_TABLE_VIEW';
export const INBOUNDING_EQUIPMENT_PATH = 'asset.inbound.create.table';

export const getTableViewConfig = (id: string) => ({
  reducerName: INBOUNDING_EQUIPMENT_NAME,
  reducerPath: INBOUNDING_EQUIPMENT_PATH,
  apiUrl: `${INBOUNDS}/${id}/equipment`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено реєстрацію'
  },
  enableSetRowsIsLocalProperty: true
});
