import { ReactElement, ReactNode } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TableCellProps } from '@material-ui/core';
import { SorterFunc } from 'src/components/Tables/Table/types';
import { SelectFilterConfig } from 'src/components/Select/types';
import { Error } from 'src/utils/validation';
import { Row, RouteUrls, RowActionsType } from '../types';
import { AutocompleteConfig } from '../../Form/FormAutocomplete/types';

export interface FilterConfigOption {
  label: string;
  value: string | number;
}

interface FilterOption extends AutocompleteOption {
  [k: string]: string | number | undefined;
}

export interface FilterConfig {
  name: string;
  label: string;
  options?: FilterConfigOption[];
  optionsURL?: string;
  value?: string;
  type?: FilterType;
  comparison?: string;
  placeholder?: string;
  autocompleteConfig?: AutocompleteConfig;
  filterByField?: string;
  renderOption?: (option: FilterOption) => ReactNode;
  width?: number;
  datePickerProps?: { [key: string]: any };
  responseFieldName?: string;
  selectConfig?: SelectFilterConfig;
}

export type ToolbarActionDisabled = boolean | ((rows: Row[]) => boolean);

export interface ToolbarAction {
  action: string;
  disabled?: ToolbarActionDisabled;
  disabledTooltip?: string;
  onClick?: (rows: Row[]) => void;
}

export interface ToolbarActions {
  [key: string]: ToolbarAction;
}

export interface TableConfig {
  action?: string;
  reducerPath: string;
  reducerName: string;
  apiUrl: string;
  apiUrlParams?: {
    [key: string]: string | number | undefined | null;
  };
  routeUrl: string | RouteUrls;
  rowActions?: RowActionsType;
  rowActionsAsMenu?: boolean;
  toolbarActions: ToolbarActions;
  filters?: FilterConfig[];
  isRowClickEnabled?: boolean;
}

export interface Filter {
  properties: string;
  value: string | number | Date | MaterialUiPickersDate;
  comparison?: string;
}

export interface TableViewProps {
  config: TableConfig;
  columns: Column[];
  rows: Row[];
  enableCheckboxes?: boolean;
  EmptyState?: ReactElement;
  elevation?: number;
  getRowName?: (row: Row) => string;
  deleteWarningAppendix?: string;
  prohibitInitialLoad?: boolean;
  deleteConfirmationText?: string;
  defaultSorters?: Sorter[];
}

export interface FilterSelectProps {
  data: FilterConfig;
  addFilter: (filter: Filter) => void;
  removeFilter: (name: string) => void;
  reducerPath: string;
  config: TableConfig;
}

export interface GetDataRequestPayload {
  page: number;
  pageSize: number;
  sorters?: Sorter[];
  filters?: Filter[];
  resetSelection?: boolean;
}

export interface GetDataSuccessPayload {
  list: List;
  sorters?: Sorter[];
  filters?: Filter[];
  resetSelection?: boolean;
}

export interface SelectedPayload {
  checked: boolean;
  id: number;
}

export interface Column<T = string> {
  sorterFunc?: SorterFunc;
  label: string;
  value: T;
  sortable?: boolean;
  width?: string;
  changeValue?: (value: Row, idx: number) => ReactElement | null | string;
  formatValue?: (row: Row, idx?: number) => string;
}

export interface ColumnGroup {
  label: string;
  value: Column[];
  changeValue?: (value: Row, idx: number) => ReactElement | null | string;
  width?: string;
  sortable?: boolean;
}

export interface Sorter {
  propertyName: string;
  order: 'ASC' | 'DESC';
}

export interface Selection {
  selectAll: boolean;
  excluded: number[];
  included: number[];
}

export interface List {
  results: Row[];
  currentPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  firstRowOnPage: number;
  lastRowOnPage: number;
}

export interface TableRowViewProps {
  rows: Row[];
  columns: Column[];
  selection: Selection;
  rowActions?: RowActionsType;
  rowActionsAsMenu: boolean;
  handleCheckboxClick: (payload: SelectedPayload) => void;
  handleEditClick: (id: number) => void;
  handleRowClick: (id: number) => void;
  handleDeleteClick: (row: Row, onError?: (errors: Error[]) => void) => void;
  enableCheckboxes: boolean;
  isRowClickEnabled?: boolean;
}

export interface RowStylesProps {
  isRowClickEnabled: boolean | undefined;
}

export interface RowActionsProps {
  handleDeleteClick: (row: Row, onError?: (errors: Error[]) => void) => void;
  handleEditClick: (id: number) => void;
  rowActions?: RowActionsType;
  rowActionsAsMenu: boolean;
  row: Row;
}

export interface TableHeadCellProps {
  sorterFunc?: SorterFunc;
  sorters: Sorter[];
  value: string;
  sortable?: boolean;
  handleSort: (value: string, sorterFunc?: SorterFunc) => void;
  label: string;
  width?: string;
  cellProps?: Partial<TableCellProps>;
}

export interface AppliedFilterOption {
  id: string | number;
  name: string;
}

export interface AppliedFiltersProps {
  [key: string]: AppliedFilterOption[];
}

export interface TableWithPaginationState {
  readonly sorters: Sorter[];
  readonly list: List;
  readonly allResults: Row[];
  readonly filters: Filter[];
  readonly selection: {
    selectAll: boolean;
    included: number[];
    excluded: number[];
  };
  readonly appliedFilters: AppliedFiltersProps;
}

export interface GetFilterOptionsRequestPayload {
  optionsURL: string;
  filterName: string;
}

export interface GetFilterOptionsSuccessPayload {
  filterName: string;
  options: AppliedFilterOption[];
}

export interface OptionsData {
  id: number;
  name: string;
  [key: string]: string | number;
}

export type IconProps = {
  name: string;
  color?: string;
  size?: string | number;
};

export enum FilterType {
  Select,
  Date,
  Autocomplete
}
