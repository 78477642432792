import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INSTALLATIONS } from 'src/config/assetUrls';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getInstallation(): SagaIterator {
  const id = yield select(selectors.selectInstallationId);

  const { data } = yield call(apiClient.get, `${INSTALLATIONS}/${id}`, {
    retryAction: actions.getInstallation.request()
  });

  yield put(actions.getInstallation.success(data));
}

export default function* InstallationDetailsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getInstallation.request),
      safe(getInstallation, actions.getInstallation.failure)
    )
  ]);
}
