import { FC } from 'react';
import { Box, makeStyles, Theme, Typography, colors } from '@material-ui/core';
import { Lens as CircleIcon } from '@material-ui/icons';
import { TenderStatusEnum } from '../const';

const colorTenderStatusMap = {
  [TenderStatusEnum.Completed]: colors.green[600],
  [TenderStatusEnum.InProgress]: colors.yellow[600],
  [TenderStatusEnum.Absent]: colors.grey[500]
};

const useStyles = makeStyles<Theme, { tenderStatusId: TenderStatusEnum }>(
  ({ spacing }) => ({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    circleIcon: ({ tenderStatusId }) => ({
      marginRight: spacing(1.25),
      fontSize: 8,
      color: colorTenderStatusMap[tenderStatusId]
    })
  })
);

interface TenderStatusProps {
  tenderStatus: string;
  tenderStatusId: number;
}

const RoutesTenderStatusColumn: FC<TenderStatusProps> = ({
  tenderStatus,
  tenderStatusId
}) => {
  const classes = useStyles({ tenderStatusId });

  return (
    <Box className={classes.root}>
      <CircleIcon className={classes.circleIcon} />
      <Typography variant="h6">{tenderStatus}</Typography>
    </Box>
  );
};

export default RoutesTenderStatusColumn;
