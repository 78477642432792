import { createAsyncAction } from 'typesafe-actions';
import { EquipmentsRequest } from './types';

export const getEquipments = createAsyncAction(
  '@TRANSFER/GET_EQUIPMENTS_REQUEST',
  '@TRANSFER/GET_EQUIPMENTS_SUCCESS',
  '@TRANSFER/GET_EQUIPMENTS_FAILURE'
)<EquipmentsRequest, undefined, Error>();

export const approveTransfer = createAsyncAction(
  '@TRANSFER/APPROVE_TRANSFER_REQUEST',
  '@TRANSFER/APPROVE_TRANSFER_SUCCESS',
  '@TRANSFER/APPROVE_TRANSFER_FAILURE'
)<number, undefined, Error>();
