import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';

const matchSpaceParams = createMatchSelector('/avl/employees/:id');

export const selectEmployee = (state: RootState) => {
  const { departmentName, departmentId, ...rest } = get(
    state,
    `ui.avl.employees.edit`
  );

  return {
    ...rest,
    department: {
      id: departmentId,
      name: departmentName
    }
  };
};

export const selectIsLoadingEmployee = (state: RootState) => {
  const loading = get(state, `loading.@EMPLOYEES_EDIT/GET_EMPLOYEE`);

  return isBoolean(loading) ? loading : true;
};

export const selectEmployeeId = createSelector(
  matchSpaceParams,
  (match: any) => match?.params.id || ''
);
