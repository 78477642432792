import { string, array, object, number, date, addMethod } from 'yup';
import {
  ERROR_MESSAGES,
  CLEARING_ERROR_MESSAGES,
  amountRegExp
} from 'src/constants/validation';
import moment from 'moment';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { isPastDate, isTodayDate } from 'src/utils/date';
import { generateActionCrud } from 'src/components/Guards';
import { valueFromTo, maxLength } from 'src/utils/validation';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.clearing,
    url: '/clearing'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.tariffs,
    url: '/clearing/tariffs'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'clearing',
    sub: 'tariff'
  },
  ['download']
);

declare module 'yup' {
  interface ObjectSchema<T> {
    uniqueDate(propertyName: string, message: string): ArraySchema<T>;
  }
}

addMethod(object, 'uniqueDate', function uniqueDate(propertyName, message) {
  return this.test('unique', message, function unique(value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (
      this.parent
        .filter((v: any) => v !== value)
        .some((v: any) =>
          moment(v[propertyName]).isSame(moment(value[propertyName]), 'day')
        )
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`
      });
    }

    return true;
  });
});

const capacityRangeMessage = valueFromTo(1, 300);

export const tariffFormValidationSchema = object().shape({
  name: string()
    .max(...maxLength(100))
    .required(ERROR_MESSAGES.REQUIRED),
  passengerCapacity: array()
    .of(
      number()
        .integer(ERROR_MESSAGES.TYPE_ERROR)
        .min(1, capacityRangeMessage)
        .max(300, capacityRangeMessage)
    )
    // eslint-disable-next-line func-names
    .test('passengerCapacity', valueFromTo(1, 300), function([from, to]) {
      if (from >= to) {
        throw this.createError({
          path: 'passengerCapacity[0]',
          message: 'Введіть значення менше від максимального'
        });
      }
      return true;
    }),
  code: string()
    .max(...maxLength(20))
    .when('$editMode', {
      is: true,
      then: string().required(ERROR_MESSAGES.REQUIRED)
    }),
  notes: string().max(...maxLength(255)),
  tariffs: array().of(
    object()
      .shape({
        amountPerKm: string()
          .typeError(ERROR_MESSAGES.AMOUNT_TYPE_ERROR)
          .required(ERROR_MESSAGES.REQUIRED)
          // eslint-disable-next-line func-names
          .test('amountPerKm', ERROR_MESSAGES.AMOUNT_TYPE_ERROR, function(
            value
          ) {
            if (value && isNaN(value)) {
              throw this.createError({
                path: this.path,
                message: ERROR_MESSAGES.TYPE_ERROR
              });
            }
            if (Number(value) === 0) {
              throw this.createError({
                path: this.path,
                message: ERROR_MESSAGES.AMOUNT_GREATER_ERROR
              });
            }
            return amountRegExp.test(String(value).trim());
          }),
        effectiveDateUtc: date()
          .required(ERROR_MESSAGES.REQUIRED)
          .nullable()
          .when('$editMode', {
            is: true,
            then: date()
              .typeError(ERROR_MESSAGES.TYPE_ERROR)
              .test({
                message: ERROR_MESSAGES.MIN_DATE_TOMORROW,
                test(value) {
                  // the first ACTIVE tariff should not be validated, since it
                  // is an active one and can have it's date in the past
                  const { isPast } = this.parent;

                  return isPast || !(isPastDate(value) || isTodayDate(value));
                }
              }),
            otherwise: date()
              .typeError(ERROR_MESSAGES.TYPE_ERROR)
              .test(
                'date is not past',
                ERROR_MESSAGES.MIN_DATE_TOMORROW,
                value => !(isPastDate(value) || isTodayDate(value))
              )
          })
      })
      .uniqueDate('effectiveDateUtc', ERROR_MESSAGES.UNIQUE)
  )
});

export const CLEARING_TARIFFS_ERROR_MESSAGES = {
  ...CLEARING_ERROR_MESSAGES
};

export const TARIFFS_TABLE_PATH = 'clearing.tariffs.table';
export const TARIFFS_TABLE_NAME = 'TARIFFS';
export const DELETE_TARIFF_ERROR =
  'Неможливо видалити оскільки тарифна група використовується в паспорті маршруту/моделі ТЗ';
