import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { DANGEROUS_ZONES } from 'src/config/avlUrls';
import * as actions from './actions';

export function* createDangerousZoneSaga({
  payload
}: ReturnType<typeof actions.createDangerousZone.request>): SagaIterator {
  yield call(apiClient.post, DANGEROUS_ZONES, payload, {
    retryAction: actions.createDangerousZone.request(payload)
  });
  yield put(navigateTo({ url: '/avl/geozones/dangerous-zones' }));
  yield put(
    enqueueSnackbar({
      key: 'dangerous_zone_create',
      message: 'Небезпечну зону створено',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.createDangerousZone.success());
}

export default function* DangerousZoneCreateSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.createDangerousZone.request),
      safe(createDangerousZoneSaga, actions.createDangerousZone.failure)
    )
  ]);
}
