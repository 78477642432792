import { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import ProtectedRoute from 'src/components/Guards/ProtectedRoute';
import { LoginGuard } from 'src/components/Guards';
import avlRoutes from './avlRoutes';
import assetRoutes from './assetRoutes';
import clearingRoutes from './clearingRoutes';
import { administrationRoutes } from './administrationRoutes';

const routesConfig = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/Error404View'))
  },
  {
    exact: true,
    path: '/503',
    component: lazy(() => import('src/views/errors/Error503View'))
  },
  {
    exact: true,
    path: '/login',
    component: <></>,
    guard: LoginGuard
  },
  {
    exact: true,
    path: '/silent_renew.html',
    component: lazy(() => import('src/views/auth/SilentRenew'))
  },
  {
    exact: true,
    path: '/signin-callback',
    component: lazy(() => import('src/views/auth/Callback'))
  },
  {
    path: '*',
    guard: LoginGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/avl" />
      },
      {
        exact: true,
        path: '/(avl|asset|clearing|administration)',
        component: lazy(() => import('src/views/emptyState/'))
      },
      ...avlRoutes,
      ...assetRoutes,
      ...clearingRoutes,
      ...administrationRoutes,
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes: any[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <ProtectedRoute
              // eslint-disable-next-line react/no-array-index-key
              key={route.action || i}
              path={route.path}
              exact={route.exact}
              action={route.action}
              render={(props: any) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
