import { API_VERSION } from '.';

export const SUB_DOMAIN = `/avl/${API_VERSION}`;

// stops
export const STOPS = `${SUB_DOMAIN}/bus-stops`;
export const STOPS_PAGE = `${STOPS}/page`;
export const STOPS_BULK = `${STOPS}/bulk`;
export const GENERATE_STOP_CODE = `${STOPS}/generate-code`;
export const STOPS_AUTOCOMPLETE = `${STOPS}/autocomplete-geozones`;
export const STOPS_BY_CODE_AUTOCOMPLETE = `${STOPS}/autocomplete`;

// routes
export const ROUTES = `${SUB_DOMAIN}/routes`;
export const ROUTES_STOP_UNAVAILABILITY = (routeId: string, stopId: number) =>
  `${STOPS}/${routeId}/rides/${stopId}/stop-unavailability`;

// traffic stadium
export const RIDE_MODE = (routeId: number) =>
  `${ROUTES}/${routeId}/ride-mode/special`;
export const RIDE_CHANGEOVER = (routeId: number, rideId: number) =>
  `${ROUTES}/${routeId}/rides/${rideId}/changeover`;

export const RIDE_TERMINATION = (routeId: number, rideId: number) =>
  `${ROUTES}/${routeId}/departures/${rideId}/termination`;

// dangerous zones
export const DANGEROUS_ZONES = `${SUB_DOMAIN}/dangerous-zones`;
export const DANGEROUS_ZONES_AUTOCOMPLETE = `${DANGEROUS_ZONES}/autocomplete`;
export const DANGEROUS_ZONES_CATEGORIES = `${SUB_DOMAIN}/dangerous-zone-categories`;
export const DANGEROUS_ZONES_CUSTOM_CATEGORIES = `${DANGEROUS_ZONES_CATEGORIES}/custom-categories`;

// employees
export const EMPLOYEES = `${SUB_DOMAIN}/employees`;
export const EMPLOYEES_ALL = `${EMPLOYEES}/all`;
export const EMPLOYEES_AUTOCOMPLETE = `${EMPLOYEES}/autocomplete`;
export const EMPLOYEES_AUTOCOMPLETE_ABBREVIATED = `${EMPLOYEES}/autocomplete-abbreviated`;
export const CURRENT_EMPLOYEES_AUTOCOMPLETE = `${EMPLOYEES}/current/autocomplete`;

// employees
export const EMPLOYEE_USERS = `${SUB_DOMAIN}/employees-users`;

// professions
export const PROFESSIONS = `${SUB_DOMAIN}/employee-professions`;
export const PROFESSIONS_AUTOCOMPLETE = `${PROFESSIONS}/autocomplete`;
export const CUSTOM_PROFESSIONS = `${PROFESSIONS}/custom-professions`;
export const PROFESSIONS_ALL = `${PROFESSIONS}/all`;

// counteragents
export const COUNTERAGENTS = `${SUB_DOMAIN}/counteragents`;
export const COUNTERAGENTS_TYPES = `${SUB_DOMAIN}/counteragent-types`;
export const COUNTERAGENTS_TYPES_ALL = `${COUNTERAGENTS_TYPES}/all`;
export const COUNTERAGENTS_CUSTOM_TYPES = `${COUNTERAGENTS_TYPES}/custom`;
export const COUNTERAGENTS_AUTOCOMPLETE = `${COUNTERAGENTS}/autocomplete`;
export const PARENT_COUNTERGAGENTS = (id?: string) =>
  `${COUNTERAGENTS}/${id}/available-parents`;
export const COUNTERAGENT_CHILDREN = (id: number) =>
  `${COUNTERAGENTS}/${id}/children`;

// vehicles models
export const VEHICLES_MODELS = `${SUB_DOMAIN}/vehicle-models`;
export const VEHICLE_MODELS_AUTOCOMPLETE = `${VEHICLES_MODELS}/autocomplete`;
export const VEHICLES_CATEGORIES = `${SUB_DOMAIN}/vehicle-categories`;
export const VEHICLES_CUSTOM_CATEGORIES = `${VEHICLES_CATEGORIES}/custom-categories`;

// vehicles
export const VEHICLES = `${SUB_DOMAIN}/vehicles`;
export const VEHICLES_AUTOCOMPLETE = `${VEHICLES}/autocomplete`;

// additional equipment
export const ADDITIONAL_EQUIPMENT = `${SUB_DOMAIN}/equipment`;
export const ADDITIONAL_CUSTOM_EQUIPMENT = `${ADDITIONAL_EQUIPMENT}/custom-equipment`;

// route passports
export const ROUTE_PASSPORT = `${SUB_DOMAIN}/route-passports`;
export const ROUTE_NUMBER_AUTOCOMPLETE = `${ROUTE_PASSPORT}/route-number-autocomplete`;
export const ROUTE_TIMETABLE = `${ROUTE_PASSPORT}/timetables`;
export const ROUTE_PASSPORT_DEPARTURES = (passportId: number, onDate: string) =>
  `${ROUTE_PASSPORT}/${passportId}/departures?onDate=${onDate}`;
export const ROUTE_SCHEME_GEOZONES = `${ROUTE_PASSPORT}/geozones`;
export const ROUTE_TIMETABLES = `route-timetables`;
export const ROUTE_PASSPORT_AUTOCOMPLETE = `${ROUTE_PASSPORT}/autocomplete`;
export const ROUTE_SCHEMES_HISTORY = (id: string, subId: string) =>
  `${SUB_DOMAIN}/route-passports/${id}/route-schemes/${subId}/history`;
export const ROUTE_SCHEMES_SUMMARY = (id: string, subId: string) =>
  `${SUB_DOMAIN}/route-passports/${id}/route-schemes/${subId}/summary`;
export const RIDE_SPECIAL_MODE = (
  id: string | number,
  subId: string | number
) => `${ROUTES}/${id}/rides/${subId}/ride-mode/special`;
export const ROUTE_PASSPORT_DETAILS = (id: number) =>
  `${ROUTE_PASSPORT}/${id}/details`;

// disruption reasons
export const DISRUPTIONS = `${SUB_DOMAIN}/ride-disruption-event-reasons?status=enabled`;
export const DISRUPTIONS_CUSTOM_CATEGORIES = `${SUB_DOMAIN}/ride-disruption-event-reasons?isPredefined=false`;

export const ROUTE_SCHEMES_LIST = (id: string) =>
  `${ROUTE_PASSPORT}/${id}/route-schemes`;

export const ROUTE_SCHEMES_DETAILS = (id: string, subId: string) =>
  `${ROUTE_SCHEMES_LIST(id)}/${subId}`;

export const ROUTE_SCHEMES_DETAILS_PDF = (id: string, subId: string) =>
  `${ROUTE_SCHEMES_LIST(id)}/${subId}/summary`;

export const ROUTE_SCHEMES_TIMETABLE_HISTORY_CHANGES_LIST = (
  id: string,
  subId: string
) => `${ROUTE_SCHEMES_TIMETABLE_LIST(id)}/${subId}/history`;

export const ROUTE_SCHEMES_TIMETABLE_LIST = (id: string) =>
  `${SUB_DOMAIN}/route-passports/${id}/route-timetables`;

export const ROUTE_HISTORY_CHANGES_LIST = (routePassportId: string) =>
  `${ROUTE_PASSPORT}/${routePassportId}/history`;

export const ROUTES_CHANGES_LIST = `${ROUTE_PASSPORT}/all/history`;

// departures
export const ROUTE_DEPARTURES = (id: string | number) =>
  `${ROUTE_TIMETABLE}/${id}/route-departures`;
export const STOP_DEPARTURE_TIMES = (
  timetableId: string,
  departureId: number,
  busStopId: number
) => `${ROUTE_DEPARTURES(timetableId)}/${departureId}/bus-stop/${busStopId}`;
export const IMPORT_DEPARTURE = (timetableId: string) =>
  `${ROUTE_DEPARTURES(timetableId)}/import`;

export const ROUTE_SCHEME_STOPS = (id: string, subId: string) =>
  `${ROUTE_SCHEMES_TIMETABLE_LIST(id)}/${subId}/schema-stops`;

// map configuration
export const MAP_CONFIGURATION = `${SUB_DOMAIN}/map-configuration`;

// schedules
export const SCHEDULES = `${SUB_DOMAIN}/route-ride-schedules`;
export const SCHEDULES_COPY = `${SCHEDULES}/copy`;
export const SCHEDULES_AVAILABLE_DATES = `${SCHEDULES}/available-dates`;
export const SCHEDULES_STATUS = `${SUB_DOMAIN}/route-ride-schedules/statuses`;
export const SCHEDULES_STATUS_EXPORT = `${SCHEDULES}/export`;

// export drivers to AFC
export const EXPORT_DRIVERS_TO_AFC = `${SUB_DOMAIN}/afc/export`;

// avl reports
export const AVL_REPORTS = `${SUB_DOMAIN}/reports`;
export const AVL_REPORTS_TYPES = `${AVL_REPORTS}/types`;

export const AVL_REPORTS_1 = `${AVL_REPORTS}/vehicle-performance`;
export const AVL_REPORTS_2 = `${AVL_REPORTS}/hourly-vehicle-release`;
export const AVL_REPORTS_3 = `${AVL_REPORTS}/drivers-performance`;
export const AVL_REPORTS_4 = `${AVL_REPORTS}/counteragent-performance`;
export const AVL_REPORTS_5 = `${AVL_REPORTS}/schedule-performance`;
export const AVL_REPORTS_7 = `${AVL_REPORTS}/morning-evening-rides`;
export const AVL_REPORTS_8 = `${AVL_REPORTS}/conducting-route`;
export const AVL_REPORTS_9 = `${AVL_REPORTS}/route-attendance`;
export const AVL_REPORTS_10 = `${AVL_REPORTS}/unidentified-gps-data`;
