import { UninstallationPayload } from '../../UninstallationDetailsView/store/types';

export const normalizeFormResponse = (payload: UninstallationPayload) => ({
  id: payload.id,
  documentNumber: payload.documentNumber,
  uninstallationDate: (payload.uninstallationDate as string).split('T')[0],
  uninstallingEmployee: {
    name: payload.uninstallingEmployeeName,
    id: payload.uninstallingEmployeeId
  },
  counteragentHolder: {
    id: payload.counteragentHolderId,
    name: payload.counteragentHolderName
  },
  notes: payload.notes,
  acceptingEmployee: {
    id: payload.acceptingEmployeeId,
    name: payload.acceptingEmployeeName
  }
});
