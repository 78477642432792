import { SystemStatusDTO } from '@eticket/avl-api-contracts/build';
import { changeStatusColumn } from 'src/components/Tables/TableWithPagination/utils';
import {
  COUNTERAGENTS,
  EMPLOYEES_AUTOCOMPLETE_ABBREVIATED,
  PROFESSIONS_AUTOCOMPLETE
} from 'src/config/avlUrls';
import { FilterType } from 'src/components/Tables/TableWithPagination/types';

export const columns = [
  {
    label: 'ПІБ',
    value: 'shortName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Підрозділ',
    value: 'department',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Професія',
    value: 'profession',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Контакт',
    value: 'contact',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Статус',
    value: 'status',
    sortable: true,
    width: '10%',
    changeValue: changeStatusColumn
  },
  {
    label: 'Код',
    value: 'code',
    sortable: true,
    width: '10%'
  }
];

export const EMPLOYEES_TABLE_PATH = 'avl.employees.table';
export const EMPLOYEES_TABLE_NAME = 'EMPLOYEES';

export const PROFESSIONS_AUTOCOMPLETE_NAME = 'PROFESSIONS_AUTOCOMPLETE_NAME';
export const EMPLOYEES_AUTOCOMPLETE_ABBREVIATED_NAME =
  'EMPLOYEES_AUTOCOMPLETE_ABBREVIATED_NAME';

export const filters = [
  {
    name: 'status',
    label: 'Статус',
    options: [
      {
        label: 'Не активний',
        value: SystemStatusDTO.Disabled
      },
      {
        label: 'Активний',
        value: SystemStatusDTO.Enabled
      }
    ]
  },
  {
    name: 'departmentId',
    label: 'Підрозділ',
    optionsURL: COUNTERAGENTS
  },
  {
    name: 'professionId',
    label: 'Професія',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerPath: 'avl.employees.professionsAutocomplete',
      reducerName: PROFESSIONS_AUTOCOMPLETE_NAME,
      optionsApiUrl: PROFESSIONS_AUTOCOMPLETE
    }
  },
  {
    name: 'shortName',
    label: 'ПІБ',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      reducerPath: 'avl.employees.employeeAutocompleteAbbreviated',
      reducerName: EMPLOYEES_AUTOCOMPLETE_ABBREVIATED_NAME,
      optionsApiUrl: EMPLOYEES_AUTOCOMPLETE_ABBREVIATED
    },
    optionsURL: EMPLOYEES_AUTOCOMPLETE_ABBREVIATED,
    value: 'shortName',
    responseFieldName: 'shortName'
  }
];
