import { FC } from 'react';
import { Clock as ClockIcon } from 'react-feather';
import { makeStyles } from '@material-ui/core';
import FormInput from './FormInput';
import { InputFieldProps } from './types';

const useStyles = makeStyles(({ palette }) => ({
  clockIcon: {
    position: 'absolute',
    right: 15,
    backgroundColor: palette.common.white,
    pointerEvents: 'none',
    '@supports ( -moz-appearance:none )': {
      pointerEvents: 'all',
      '&:hover': {
        cursor: 'default'
      }
    }
  }
}));

const FormTimePicker: FC<InputFieldProps> = props => {
  const classes = useStyles();

  return (
    <FormInput
      type="time"
      InputProps={{
        endAdornment: <ClockIcon className={classes.clockIcon} size={18} />
      }}
      InputLabelProps={{
        shrink: true
      }}
      {...props}
    />
  );
};

export default FormTimePicker;
