import { createAsyncAction } from 'typesafe-actions';

interface UpdateTransferRequestPayload {
  id: number;
  formPath: string;
  equipmentPath: string;
}

export const updateTransfer = createAsyncAction(
  '@TRANSFER/UPDATE_TRANSFER_REQUEST',
  '@TRANSFER/UPDATE_TRANSFER_SUCCESS',
  '@TRANSFER/UPDATE_TRANSFER_FAILURE'
)<UpdateTransferRequestPayload, undefined, Error>();

export const getTransferData = createAsyncAction(
  '@TRANSFER/GET_TRANSFER_DATA_REQUEST',
  '@TRANSFER/GET_TRANSFER_DATA_SUCCESS',
  '@TRANSFER/GET_TRANSFER_DATA_FAILURE'
)<undefined, undefined, Error>();
