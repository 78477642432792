import { cloneElement, forwardRef } from 'react';
import useActionAllowed from 'src/hooks/useActionAllowed';

type Props = {
  action: string;
  children: JSX.Element;
};
export type Ref = HTMLElement;

const Protected = forwardRef<Ref, Props>(({ action, children }, ref) => {
  const isAllowed = useActionAllowed(action);

  // need to pass ref to elements like MenuItem
  return isAllowed ? cloneElement(children, { ref }) : null;
});

export default Protected;
