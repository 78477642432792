import { createReducer, ActionType } from 'typesafe-actions';
import { KPIType } from '../../types';
import * as actions from './actions';

type KPIParametersFormActions = ActionType<typeof actions>;

export type KPIParametersFormState = Readonly<{
  kpis: KPIType[];
  totalWeight: number;
}>;

export const initialState: KPIParametersFormState = {
  kpis: [],
  totalWeight: 0
};

const kpiParametersFormReducer = createReducer<
  KPIParametersFormState,
  KPIParametersFormActions
>(initialState)
  .handleAction(actions.getKPIs.success, (state, { payload }) => ({
    ...state,
    kpis: payload
  }))
  .handleAction(
    actions.getKPIParametersTotalWeight.success,
    (state, { payload }) => ({
      ...state,
      totalWeight: payload
    })
  );

export default kpiParametersFormReducer;
