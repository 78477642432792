import { TABLE_ACTIONS_CONFIG } from 'src/constants';
import { COEFFICIENTS_ADJUSTMENTS } from 'src/config/clearingUrls';
import { COUNTERAGENTS, ROUTE_PASSPORT_AUTOCOMPLETE } from 'src/config/avlUrls';
import { Row } from 'src/components/Tables/types';
import {
  FilterConfig,
  FilterType
} from 'src/components/Tables/TableWithPagination/types';
import { getRouteName } from 'src/utils/routes';
import CheckMinusIcon from './utils/CheckMinusIcon';
import { crudActions } from '../const';
import {
  ROUTE_NUMBERS_SELECT_NAME,
  ROUTE_NUMBERS_SELECT_PATH
} from '../../penaltyDocuments/const';

export const columns = [
  {
    label: 'Перевізник',
    value: 'counteragentName',
    sortable: true,
    width: '14%'
  },
  {
    label: 'Маршрут',
    value: 'routePassportName',
    sortable: true,
    width: '14%'
  },
  {
    label: 'Тендерний коефіцієнт',
    value: 'tenderCoefficient',
    sortable: true,
    width: '11%'
  },
  {
    label: 'Коефіцієнт складності',
    value: 'complexityCoefficient',
    sortable: true,
    width: '11%'
  },
  {
    label: 'Коефіцієнт спецрежиму',
    value: 'specialModeCoefficient',
    sortable: true,
    width: '11%'
  },
  {
    label: 'Маршрут з/до депо',
    value: 'isDepotDistanceIncluded',
    sortable: true,
    width: '13%',
    changeValue: (row: Row) =>
      row.isDepotDistanceIncluded ? 'Враховується' : 'Не враховується'
  },
  {
    label: 'Часовий коефіцієнт',
    value: 'hasTimeCoefficient',
    sortable: true,
    width: '10%',
    changeValue: (row: Row) => (
      <CheckMinusIcon checked={row.hasTimeCoefficient} />
    )
  }
];

export const COEFFICIENTS_ADJUSTMENT_TABLE_PATH =
  'clearing.coefficientsAdjustments.table';
export const COEFFICIENTS_ADJUSTMENT_TABLE_NAME = 'COEFFICIENTS_ADJUSTMENT';

export const filters: FilterConfig[] = [
  {
    name: 'CounteragentName',
    label: 'Перевізник',
    optionsURL: COUNTERAGENTS,
    value: 'name'
  },
  {
    name: 'routePassportName',
    label: 'Маршрут',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      optionsApiUrl: ROUTE_PASSPORT_AUTOCOMPLETE,
      reducerPath: ROUTE_NUMBERS_SELECT_PATH,
      reducerName: ROUTE_NUMBERS_SELECT_NAME
    },
    renderOption: getRouteName,
    value: 'name'
  }
];

export const tableConfig = {
  apiUrl: COEFFICIENTS_ADJUSTMENTS,
  reducerPath: COEFFICIENTS_ADJUSTMENT_TABLE_PATH,
  reducerName: COEFFICIENTS_ADJUSTMENT_TABLE_NAME,
  routeUrl: '/clearing/coefficients-adjustments/',
  rowActions: {
    [TABLE_ACTIONS_CONFIG.EDIT]: {
      isActive: true,
      action: crudActions.update,
      disabledTooltip: 'Неможливо редагувати'
    },
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      isActive: (row: Row) => row.canDeleted,
      action: crudActions.delete,
      disabledTooltip: 'Неможливо видалити'
    }
  },
  toolbarActions: {
    [TABLE_ACTIONS_CONFIG.DELETE]: {
      action: crudActions.delete
    }
  },
  isRowClickEnabled: true,
  filters
};
