import { PdfGenerator } from 'src/services/pdfGenerator';
import { SendEmailAttachment } from '../types';

export const generateSendEmailAttachmentProps = (
  fileName: string,
  pdfFile: PdfGenerator,
  jsonView: string
): SendEmailAttachment[] => {
  const openJsonFile = (): void => {
    const win = window.open();
    win?.document.write(`<pre>${jsonView}</pre>`);
    win?.document.close();
  };

  return [
    {
      id: 'json',
      fileName: `${fileName}.json`,
      openInNewWindow: () => openJsonFile(),
      icon: 'FileText',
      file: new Blob([jsonView], { type: 'application/json' })
    },
    {
      id: 'pdf',
      fileName: `${fileName}.pdf`,
      openInNewWindow: () => pdfFile?.openFileInNewWindow(),
      icon: 'Paperclip',
      file: pdfFile?.getBlobPdf() || ''
    }
  ];
};
