import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { ApiKeysValues } from '../types';

export const initialState = {
  name: '',
  key: ''
};

const apiKeysCreateReducer = createReducer<
  ApiKeysValues,
  ActionType<typeof actions>
>(initialState).handleAction(
  actions.createApiKey.success,
  (state, { payload }) => ({
    ...payload
  })
);

export default apiKeysCreateReducer;
