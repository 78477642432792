import { FC } from 'react';
import moment from 'moment';
import { getDateDifference } from 'src/utils/date';
import { StatusEnum } from 'src/constants';
import { Row } from 'src/components/Tables/types';
import SwitchEdit from 'src/components/SwitchEdit';

const MAX_DAYS_AFTER_INSTALLATION = 200;

interface InstallationSwitchProps {
  data: Row;
  onChange: (row: Row) => Promise<void>;
}

const InstallationSwitch: FC<InstallationSwitchProps> = ({
  data,
  onChange
}) => {
  const { status, dateInstallation, documentNumber } = data;
  const date = moment(dateInstallation, 'DD.MM.YYYY');

  const disabled =
    status === StatusEnum.Fixed &&
    getDateDifference(date) > MAX_DAYS_AFTER_INSTALLATION;

  const handleChangeSwitch = async () => {
    if (status !== StatusEnum.Draft) {
      await onChange(data);
    }
  };

  const tooltip = `Документ зафіксовано понад ${MAX_DAYS_AFTER_INSTALLATION} днів тому. Редагування заборонене.`;
  const confirmMessage = `Ви впевнені, що хочете розфіксувати документ ${documentNumber}? Все встановлене обладнання буде повернуте на попередній підрозділ`;

  return (
    <SwitchEdit
      checked={status === StatusEnum.Draft}
      disabled={disabled}
      tooltip={tooltip}
      onChange={handleChangeSwitch}
      confirmMessage={confirmMessage}
    />
  );
};

export default InstallationSwitch;
