import { createReducer, ActionType } from 'typesafe-actions';
import { SystemStatusDTO, VehicleModelDTO } from '@eticket/avl-api-contracts';
import * as actions from './actions';

type VehicleModelsDetailsActions = ActionType<typeof actions>;

const initialState: VehicleModelDTO = {
  id: 0,
  name: '',
  numberOfSeats: 0,
  numberOfStandPlaces: 0,
  numberOfDisabilitySeats: 0,
  status: SystemStatusDTO.Enabled,
  vehicleCategory: '',
  tariffGroupName: '',
  code: '',
  notes: '',
  equipment: []
};

const vehicleModelsDetailsReducers = createReducer<
  VehicleModelDTO,
  VehicleModelsDetailsActions
>(initialState).handleAction(
  actions.getVehicleModels.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default vehicleModelsDetailsReducers;
