import { createSelector } from 'reselect';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { convertUtcToLocal } from 'src/utils/date';
import { DATE_FORMAT_SLASH } from 'src/config';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/clearing/billing-documents/:id/details'
);

export const selectBillingDocumentId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectBillingDocumentDetails = (state: RootState) => {
  const {
    dateFrom,
    dateTo,
    documentDate,
    totalAmount,
    ...details
  } = state.ui.clearing.billingDocuments.details;

  return {
    ...details,
    totalAmount: `${Number(totalAmount)}`,
    dateFrom: convertUtcToLocal(dateFrom, DATE_FORMAT_SLASH),
    dateTo: convertUtcToLocal(dateTo, DATE_FORMAT_SLASH),
    documentDate: convertUtcToLocal(documentDate, DATE_FORMAT_SLASH)
  };
};

export const selectCharacteristicRows = createSelector(
  selectBillingDocumentDetails,
  details => [
    {
      label: 'Дата документа',
      value: details.documentDate
    },
    { label: 'Назва документа', value: details.documentName },
    {
      label: 'За період',
      value: `${details.dateFrom} - ${details.dateTo}`
    },
    { label: 'Перевізник', value: details.counteragent.name },
    { label: 'Сума, грн', value: details.totalAmount }
  ]
);

export const selectIsLoadingBillingDocument = (state: RootState): boolean => {
  const loading = get(
    state,
    `loading.@BILLING_DOCUMENTS_DETAILS/GET_BILLING_DOCUMENT`
  );

  return isBoolean(loading) ? loading : true;
};

export const selectDocumentName = createSelector(
  selectBillingDocumentDetails,
  ({ documentName }) => documentName
);
