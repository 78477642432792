import { LatLng } from 'leaflet';
import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { SHAPES } from 'src/constants/map';
import { Values, BusStopFacility } from '../../types';

const matchSpaceParams = createMatchSelector('/avl/geozones/stops/:id');

export const selectStop = (state: RootState): Values => {
  const { facilities, lat, lng, ...rest } = get(state, `ui.avl.stops.edit`);

  return {
    ...rest,
    facilityIds: facilities
      ? facilities.map((facility: BusStopFacility) => facility.id)
      : [],
    coordinates: `${lat}, ${lng}`
  };
};

export const selectMapData = (state: RootState) => {
  const stop = get(state, `ui.avl.stops.edit`);
  const { lat, lng, points } = stop;

  return {
    name: points ? SHAPES.POLYGON : SHAPES.CIRCLE,
    coordinates: { lat, lng } as LatLng,
    points
  };
};

export const selectIsLoadingStop = (state: RootState) => {
  const loading = get(state, `loading.@STOPS_EDIT/GET_STOP`);

  return isBoolean(loading) ? loading : true;
};

export const selectStopId = createSelector(matchSpaceParams, (match: any) => {
  return match ? match.params.id : '';
});
