import { RootState } from 'typesafe-actions';
import { get } from 'lodash';

export const selectSorters = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.sorters`);

export const selectList = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.list`);

export const selectIsLoading = (name: string) => (state: RootState) =>
  get(state, `loading.@TABLE_VIEW/GET_DATA/${name}`);

export const selectSelection = (reducerPath: string) => (state: RootState) =>
  get(state, `ui.${reducerPath}.selection`);
