import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { STATE_CHANGES } from 'src/config/assetUrls';
import * as actions from './actions';

export function* updateTableItemState({
  payload
}: ReturnType<typeof actions.updateTableItemStatus.request>): SagaIterator {
  const { id } = payload;

  yield call(
    apiClient.put,
    `${STATE_CHANGES}/${id}/change-status`,
    { statusId: 0 },
    { retryAction: actions.updateTableItemStatus.request(payload) }
  );
  yield put(actions.updateTableItemStatus.success());
}

export default function* StateChangeListViewSaga() {
  yield takeEvery(
    getType(actions.updateTableItemStatus.request),
    safe(updateTableItemState, actions.updateTableItemStatus.failure)
  );
}
