import { date, number, object, string } from 'yup';
import moment from 'moment';
import {
  COUNTERAGENTS,
  EMPLOYEES_AUTOCOMPLETE,
  VEHICLES_CATEGORIES
} from 'src/config/avlUrls';
import {
  BREAKDOWNS_AUTOCOMPLETE,
  EQUIPMENT,
  EQUIPMENT_ACTIONS,
  EQUIPMENT_GROUP_NAMES,
  REPORTS,
  REPORTS_BARCODES_AUTOCOMPLETE,
  REPORTS_DOCUMENT_NUMBER_AUTOCOMPLETE,
  REPORTS_IMEI_AUTOCOMPLETE,
  REPORTS_SPARE_PARTS_AUTOCOMPLETE,
  STATES
} from 'src/config/assetUrls';
import { MAIN_MENU } from 'src/constants';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { generateActionCrud } from 'src/components/Guards';
import { AutocompleteConfig } from 'src/components/Form/FormAutocomplete/types';
import TextWithEllipsis from 'src/components/TextWithEllipsis';
import { Row } from 'src/components/Tables/types';
import StateTypeColumn from '../states/StatesListView/StateTypeColumn';

export const breadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset/routes'
  }
];

// Counteragents
export const REPORT_COUNTERAGENT_SELECT_NAME = ' REPORT_COUNTERAGENT_SELECT';
export const REPORT_COUNTERAGENT_SELECT_PATH =
  'asset.reports.reportCounteragentSelect';

export const REPORT_COUNTERAGENT_SELECT_CONFIG = {
  reducerPath: REPORT_COUNTERAGENT_SELECT_PATH,
  reducerName: REPORT_COUNTERAGENT_SELECT_NAME,
  optionsApiUrl: COUNTERAGENTS
};

// Actions
export const REPORT_ACTIONS_SELECT_NAME = ' REPORT_ACTIONS_SELECT';
export const REPORT_ACTIONS_SELECT_PATH = 'asset.reports.reportActionSelect';

export const REPORT_ACTIONS_SELECT_CONFIG = {
  reducerPath: REPORT_ACTIONS_SELECT_PATH,
  reducerName: REPORT_ACTIONS_SELECT_NAME,
  optionsApiUrl: EQUIPMENT_ACTIONS
};

// Equipments
export const REPORT_EQUIPMENTS_SELECT_NAME = 'REPORT_EQUIPMENTS_SELECT';
export const REPORT_EQUIPMENTS_AUTOCOMPLETE_PATH =
  'asset.reports.reportEquipmentSelect';

export const REPORT_EQUIPMENTS_AUTOCOMPLETE_CONFIG = {
  reducerPath: REPORT_EQUIPMENTS_AUTOCOMPLETE_PATH,
  reducerName: REPORT_EQUIPMENTS_SELECT_NAME,
  optionsApiUrl: EQUIPMENT
};

// Equipment Groups
export const REPORT_EQUIPMENT_GROUPS_SELECT_NAME =
  'REPORT_EQUIPMENT_GROUPS_SELECT';
export const REPORT_EQUIPMENT_GROUPS_SELECT_PATH =
  'asset.reports.reportEquipmentGroupSelect';

export const REPORT_EQUIPMENT_GROUPS_SELECT_CONFIG = {
  reducerPath: REPORT_EQUIPMENT_GROUPS_SELECT_PATH,
  reducerName: REPORT_EQUIPMENT_GROUPS_SELECT_NAME,
  optionsApiUrl: EQUIPMENT_GROUP_NAMES
};

export const REPORT_EQUIPMENT_STATE_SELECT_NAME =
  'REPORT_EQUIPMENT_STATE_SELECT';
export const REPORT_EQUIPMENT_STATE_SELECT_PATH =
  'asset.reports.reportEquipmentStateSelect';

export const REPORT_EQUIPMENT_STATE_SELECT_CONFIG = {
  reducerPath: REPORT_EQUIPMENT_STATE_SELECT_PATH,
  reducerName: REPORT_EQUIPMENT_STATE_SELECT_NAME,
  optionsApiUrl: STATES
};

// Vehicle Models
export const REPORT_VEHICLE_CATEGORIES_SELECT_NAME =
  'REPORT_VEHICLE_CATEGORIES_SELECT';
export const REPORT_VEHICLE_CATEGORIES_SELECT_PATH =
  'asset.reports.reportVehicleCategoriesSelect';

export const REPORT_VEHICLE_CATEGORIES_SELECT_CONFIG = {
  reducerPath: REPORT_VEHICLE_CATEGORIES_SELECT_PATH,
  reducerName: REPORT_VEHICLE_CATEGORIES_SELECT_NAME,
  optionsApiUrl: VEHICLES_CATEGORIES
};

// Employees autocomplete
export const REPORT_EMPLOYEES_AUTOCOMPLETE_NAME =
  'REPORT_EMPLOYEES_AUTOCOMPLETE';
export const REPORT_EMPLOYEES_AUTOCOMPLETE_PATH =
  'asset.reports.reportEmployeesAutocomplete';

export const REPORT_EMPLOYEES_AUTOCOMPLETE_CONFIG = {
  reducerPath: REPORT_EMPLOYEES_AUTOCOMPLETE_PATH,
  reducerName: REPORT_EMPLOYEES_AUTOCOMPLETE_NAME,
  optionsApiUrl: EMPLOYEES_AUTOCOMPLETE
};
export const COUNTERAGENTS_PATH = 'asset.reports.reportCounteragentSelect';
export const ACTIONS_PATH = 'asset.reports.reportActionSelect';
export const EQUIPMENTS_GROUP_PATH = 'asset.reports.reportEquipmentGroupSelect';
export const EQUIPMENTS_PATH = 'asset.reports.reportEquipmentSelect';
export const EXECUTANS_EMPLOYEE_PATH =
  'asset.reports.reportEmployeesAutocomplete.options';

export const BARCODE_AUTOCOMPLETE_NAME = 'BARCODE_AUTOCOMPLETE_NAME';
export const BARCODE_AUTOCOMPLETE_PATH = 'asset.reports.barcodeAutocomplete';

export const BARCODE_AUTOCOMPLETE_CONFIG: AutocompleteConfig = {
  reducerName: BARCODE_AUTOCOMPLETE_NAME,
  reducerPath: BARCODE_AUTOCOMPLETE_PATH,
  optionsApiUrl: REPORTS_BARCODES_AUTOCOMPLETE
};

export const DOCUMENT_NUMBER_AUTOCOMPLETE_NAME =
  'DOCUMENT_NUMBER_AUTOCOMPLETE_NAME';
export const DOCUMENT_NUMBER_AUTOCOMPLETE_PATH =
  'asset.reports.documentNumberAutocomplete';

export const DOCUMENT_NUMBER_AUTOCOMPLETE_CONFIG: AutocompleteConfig = {
  reducerName: DOCUMENT_NUMBER_AUTOCOMPLETE_NAME,
  reducerPath: DOCUMENT_NUMBER_AUTOCOMPLETE_PATH,
  optionsApiUrl: REPORTS_DOCUMENT_NUMBER_AUTOCOMPLETE
};

export const IMEI_AUTOCOMPLETE_NAME = 'IMEI_AUTOCOMPLETE_NAME';
export const IMEI_AUTOCOMPLETE_PATH = 'asset.reports.imeiAutocomplete';
export const IMEI_AUTOCOMPLETE_CONFIG: AutocompleteConfig = {
  reducerName: IMEI_AUTOCOMPLETE_NAME,
  reducerPath: IMEI_AUTOCOMPLETE_PATH,
  optionsApiUrl: REPORTS_IMEI_AUTOCOMPLETE
};

export const SPARE_PARTS_AUTOCOMPLETE_NAME = 'SPARE_PARTS_AUTOCOMPLETE_NAME';
export const SPARE_PARTS_AUTOCOMPLETE_PATH =
  'asset.reports.sparePartsAutocomplete';
export const SPARE_PARTS_AUTOCOMPLETE_CONFIG: AutocompleteConfig = {
  reducerName: SPARE_PARTS_AUTOCOMPLETE_NAME,
  reducerPath: SPARE_PARTS_AUTOCOMPLETE_PATH,
  optionsApiUrl: REPORTS_SPARE_PARTS_AUTOCOMPLETE
};

export const BREAKDOWNS_AUTOCOMPLETE_NAME = 'BREAKDOWNS_AUTOCOMPLETE_NAME';
export const BREAKDOWNS_AUTOCOMPLETE_PATH =
  'asset.reports.breakdownsAutocomplete';
export const BREAKDOWNS_AUTOCOMPLETE_CONFIG: AutocompleteConfig = {
  reducerName: BREAKDOWNS_AUTOCOMPLETE_NAME,
  reducerPath: BREAKDOWNS_AUTOCOMPLETE_PATH,
  optionsApiUrl: BREAKDOWNS_AUTOCOMPLETE
};

export const filterValidationSchema = object().shape({
  dateFrom: date()
    .nullable()
    .typeError(ERROR_MESSAGES.TYPE_ERROR),
  dateTo: date()
    .typeError(ERROR_MESSAGES.TYPE_ERROR)
    .nullable()
    .test({
      message: ERROR_MESSAGES.TYPE_ERROR,
      test(value: string) {
        // if dates are not valid, this validation shouldn't be run
        return (
          !value ||
          !moment(value).isValid() ||
          !this.parent.dateFrom ||
          !moment(value).isBefore(moment(this.parent.dateFrom))
        );
      }
    }),
  documentNumber: object()
    .nullable()
    .shape({ id: number(), name: string() }),
  counteragentId: number().nullable(),
  equipmentGroupId: number().nullable(),
  equipmentId: number().nullable(),
  vehicleCategoryId: number().nullable(),
  actionTypeId: number().nullable(),
  barcode: object()
    .nullable()
    .shape({ id: number(), name: string() }),
  imei: object()
    .nullable()
    .shape({ id: number(), name: string() }),
  spareParts: object()
    .nullable()
    .shape({ id: number(), name: string() }),
  equipmentStateId: number().nullable(),
  breakdowns: object()
    .nullable()
    .shape({ id: number(), name: string() }),
  licensePlate: object()
    .nullable()
    .shape({ id: number(), name: string() }),
  vehicleModel: object()
    .nullable()
    .shape({ id: number(), name: string() }),
  responsiblePerson: object()
    .nullable()
    .shape({ id: number(), name: string() }),
  executantEmployee: object()
    .nullable()
    .shape({ id: number(), name: string() })
});

export const DOWNLOAD_REPORTS = 'DOWNLOAD_REPORTS';

export const REPORTS_TABLE_PATH = 'asset.reports.table';
export const REPORTS_TABLE_NAME = 'REPORTS';

export const tableConfig = {
  reducerName: REPORTS_TABLE_NAME,
  reducerPath: REPORTS_TABLE_PATH,
  apiUrl: REPORTS,
  routeUrl: '/asset/reports/',
  deleteUrl: '',
  rowActions: {},
  toolbarActions: {},
  isRowClickEnabled: false
};

export const columns = [
  {
    label: 'Дата',
    value: 'date',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Номер документа',
    value: 'documentNumber',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Документ',
    value: 'actionType',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '8%'
  },
  {
    label: 'IMEI',
    value: 'imei',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Запчастини',
    value: 'notes',
    sortable: false,
    width: '8%',
    changeValue: (row: Row) => <TextWithEllipsis text={row.spareParts} />
  },
  {
    label: 'Обладнання',
    value: 'equipmentName',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Група обладнання',
    value: 'equipmentGroupName',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Стан',
    value: 'equipmentState',
    sortable: true,
    width: '8%',
    changeValue: (row: Row) => (
      <StateTypeColumn id={row.equipmentStateId} name={row.equipmentState} />
    )
  },
  {
    label: 'Несправності',
    value: 'breakdowns',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Підрозділ',
    value: 'counteragentName',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Тип ТЗ',
    value: 'vehicleCategory',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Реєстраційний номер ТЗ',
    value: 'vehicleLicensePlate',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Модель ТЗ',
    value: 'vehicleModelName',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Відповідальна особа',
    value: 'responsibleEmployeeName',
    sortable: true,
    width: '8%'
  },
  {
    label: 'Виконавець',
    value: 'executantEmployeeName',
    sortable: true,
    width: '8%'
  }
];

export const MIN_PAGE_SIZE = 1000;

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'reports'
  },
  ['download']
);
export const defaultItem = {
  id: 0,
  name: 'Всі'
};
export const MOBILE_EQUIPMENT = 'Мобільне обладнання';
const STATIC_EQUIPMENT = 'Стаціонарне обладнання';
const CONTROLLER_EQUIPMENT = 'Обладнання для контролерів';
export const EQUIPMENT_ORDER = [
  MOBILE_EQUIPMENT,
  STATIC_EQUIPMENT,
  CONTROLLER_EQUIPMENT
];
