import { Row } from 'src/components/Tables/types';
import EquipmentStateLabel from 'src/components/Labels/EquipmentStateLabel';
import TextWithEllipsis from 'src/components/TextWithEllipsis';
import { EquipmentTypeEnum } from 'src/constants';

export const mobileColumns = [
  {
    label: 'Номерний знак ТЗ',
    value: 'licensePlate',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Модель ТЗ',
    value: 'vehicleModelName',
    sortable: true,
    width: '15%'
  }
];

export const stationaryColumns = [
  {
    label: 'Адреса підрозділу',
    value: 'counteragentAddress',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Відповідальна особа',
    value: 'employeeName',
    sortable: true,
    width: '15%'
  }
];

export const getColumns = (equipmentType: EquipmentTypeEnum) => [
  {
    label: 'Штрихкод',
    value: 'barcode',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '15%'
  },
  {
    label: 'IMEI',
    value: 'imei',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Запчастини',
    value: 'spareParts',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => <TextWithEllipsis text={row.spareParts} />
  },
  {
    label: 'Стан',
    value: 'status',
    sortable: true,
    width: '15%',
    changeValue: (row: Row) => <EquipmentStateLabel state={row.status} />
  },
  {
    label: 'Підрозділ від',
    value: 'counteragentHolderName',
    sortable: true,
    width: '15%'
  },
  {
    label: 'Підрозділ',
    value: 'counteragentName',
    sortable: true,
    width: '15%'
  },
  ...(equipmentType === EquipmentTypeEnum.Mobile
    ? mobileColumns
    : stationaryColumns)
];

export const INSTALLATION_EQUIPMENT_TABLE_PATH =
  'asset.installation.create.equipment';
export const INSTALLATION_EQUIPMENT_TABLE_NAME = 'EQUIPMENT';

export const tableConfig = {
  reducerName: INSTALLATION_EQUIPMENT_TABLE_NAME,
  reducerPath: INSTALLATION_EQUIPMENT_TABLE_PATH,
  routeUrl: '/asset/installation',
  rowActions: {},
  toolbarActions: {},
  filters: [],
  isRowClickEnabled: false,
  snackbarMessages: {
    deleteRowSuccess: ''
  }
};
