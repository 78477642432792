import {
  ModuleTypes,
  AvlModuleData,
  AvlModuleActionData,
  AssetsModuleData,
  AssetsModuleActionData,
  ClearingModuleData,
  ClearingModuleActionData,
  PermissionsList,
  ActionTypes,
  AdministrationActionData,
  AdministrationModuleData,
  UsersModuleActionData,
  UsersModuleData
} from './types';

import { modules, actions, SUB_MODULES_MAP } from './const';

export const generateModuleID = (module: ModuleTypes) => {
  const numberModule = modules[module];
  return `${numberModule}.`;
};
export const generateActionID = ({
  module,
  sub,
  action
}:
  | AvlModuleActionData
  | AssetsModuleActionData
  | ClearingModuleActionData
  | AdministrationActionData
  | UsersModuleActionData) => {
  const numberModule = modules[module];
  const subModule = SUB_MODULES_MAP[module];
  const numberAction = actions[action];
  const numberSub = subModule[sub] || 0;

  return `${numberModule}.${numberSub}.${numberAction}`;
};

export const generateActionCrud = (
  moduleData:
    | AvlModuleData
    | AssetsModuleData
    | ClearingModuleData
    | AdministrationModuleData
    | UsersModuleData,
  extra: Array<ActionTypes> = []
): PermissionsList => {
  const permissions: PermissionsList = {
    create: generateActionID({
      ...moduleData,
      action: 'create'
    }),
    read: generateActionID({
      ...moduleData,
      action: 'read'
    }),
    update: generateActionID({
      ...moduleData,
      action: 'update'
    }),
    delete: generateActionID({
      ...moduleData,
      action: 'delete'
    })
  };

  extra.forEach(action => {
    permissions[action] = generateActionID({
      ...moduleData,
      action
    });
  });

  return permissions;
};
