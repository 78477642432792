import { Row } from 'src/components/Tables/types';
import Label from 'src/components/Labels/Label';
import { FC } from 'react';

interface Props {
  row: Row;
}

const EmployeeNameColumn: FC<Props> = ({ row }) => {
  const color = 'draft';
  const label = 'не налаштований';
  return row.employeeName.toLowerCase() === label ? (
    <Label color={color}>{label}</Label>
  ) : (
    row.employeeName
  );
};

export default EmployeeNameColumn;
