import { createAsyncAction } from 'typesafe-actions';
import {
  EditBillingDocumentPayload,
  GetBillingDocumentPayload
} from '../../types';

export const editBillingDocument = createAsyncAction(
  '@BILLING_DOCUMENTS/EDIT_BILLING_DOCUMENT_REQUEST',
  '@BILLING_DOCUMENTS/EDIT_BILLING_DOCUMENT_SUCCESS',
  '@BILLING_DOCUMENTS/EDIT_BILLING_DOCUMENT_FAILURE'
)<EditBillingDocumentPayload, undefined, Error>();

export const getBillingDocument = createAsyncAction(
  '@BILLING_DOCUMENTS/GET_BILLING_DOCUMENT_REQUEST',
  '@BILLING_DOCUMENTS/GET_BILLING_DOCUMENT_SUCCESS',
  '@BILLING_DOCUMENTS/GET_BILLING_DOCUMENT_FAILURE'
)<undefined, GetBillingDocumentPayload, Error>();
