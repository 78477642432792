import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { apiClient } from 'src/utils/api';
import { TableConfigBase } from '../types';

type Action<Payload = undefined> = (
  ...args: any[]
) => Payload extends Object
  ? {
      type: string;
      config: TableConfigBase;
      payload: Payload;
    }
  : {
      type: string;
      config: TableConfigBase;
    };

export const getDataListSaga = (
  getDataRequest: Action,
  getDataSuccess: Action
) =>
  function* getDataList({
    config
  }: ReturnType<typeof getDataRequest>): SagaIterator {
    const { data } = yield call(apiClient.get, `${config.apiUrl}`, {
      retryAction: getDataRequest(config)
    });

    yield put(
      getDataSuccess(config, {
        list: data
      })
    );
  };

export const getDeleteRowSaga = (
  deleteRowRequest: Action<{ id: number; name: string }>,
  deleteRowSuccess: Action,
  getDataRequest: Action
) =>
  function* deleteRow({
    payload,
    config
  }: ReturnType<typeof deleteRowRequest>): SagaIterator {
    const { id, name } = payload;
    const { apiUrl } = config;
    yield call(apiClient.delete, `${apiUrl}/${id}`, {
      retryAction: deleteRowRequest(config, payload)
    });
    yield put(
      enqueueSnackbar({
        key: 'delete_row_success',
        message: `${name || ''} видалено`,
        options: {
          variant: 'success'
        }
      })
    );

    yield put(deleteRowSuccess(config));
    yield put(getDataRequest(config));
  };
