export type ReportFilterType =
  | 'counteragent'
  | 'route'
  | 'date'
  | 'driver'
  | 'dateFrom'
  | 'dateTo';

export enum ReportType {
  busWorkOnRouteReport = 1,
  hourlyDeparture = 2,
  workOfDriver = 3,
  workOfCarrier = 4,
  graphicityPerformance = 5,
  morningAndEveningRide = 7,
  routePerforming = 8,
  presenceOnRouteNetwork = 9,
  unmappedGPSData = 10
}
