import { createAsyncAction } from 'typesafe-actions';

interface UpdateUninstallationRequestPayload {
  id: number;
  formPath: string;
  mobilePath: string;
  stationaryPath: string;
  inspectorPath: string;
}

export const updateUninstallation = createAsyncAction(
  '@UNINSTALLATION/UPDATE_UNINSTALLATION_REQUEST',
  '@UNINSTALLATION/UPDATE_UNINSTALLATION_SUCCESS',
  '@UNINSTALLATION/UPDATE_UNINSTALLATION_FAILURE'
)<UpdateUninstallationRequestPayload, undefined, Error>();

export const getUninstallationData = createAsyncAction(
  '@UNINSTALLATION/GET_UNINSTALLATION_DATA_REQUEST',
  '@UNINSTALLATION/GET_UNINSTALLATION_DATA_SUCCESS',
  '@UNINSTALLATION/GET_UNINSTALLATION_DATA_FAILURE'
)<undefined, undefined, Error>();
