/* eslint-disable func-names */
import { number, object, string } from 'yup';
import { ERROR_MESSAGES } from 'src/constants/validation';
import { maxLength } from 'src/utils/validation';
import { COUNTERAGENTS, STOP_DEPARTURE_TIMES } from 'src/config/avlUrls';
import { EmployeeProfessionEnum } from 'src/constants';
import { RideChangeoverValues, TerminateDeparture } from '../../types';

export const changeoverInitialValues = {
  routeIterationPointId: '',
  vehicle: null,
  driver: null,
  busStop: null,
  notes: '',
  rideDisruptionEventReasonId: -1
} as RideChangeoverValues;

export const terminationInitialValues = {
  rideDisruptionEventReasonId: -1,
  depotCounterAgentId: '',
  terminatesAtUtc: '',
  notes: ''
} as TerminateDeparture;

export const rideChangeoverValidationSchema = object().shape({
  busStop: object()
    .required(ERROR_MESSAGES.REQUIRED)
    .nullable(),
  driver: object()
    .nullable()
    .test('test', ERROR_MESSAGES.REQUIRED, function(driver) {
      return driver || this.parent.vehicle;
    }),
  vehicle: object()
    .nullable()
    .test('test', ERROR_MESSAGES.REQUIRED, function(vehicle) {
      return vehicle || this.parent.driver;
    }),
  rideDisruptionEventReasonId: number().required(ERROR_MESSAGES.REQUIRED),
  routeIterationPointId: string().required(ERROR_MESSAGES.REQUIRED),
  notes: string().max(...maxLength(255))
});

export const rideTerminationValidationSchema = object<TerminateDeparture>({
  depotCounterAgentId: number().required(ERROR_MESSAGES.REQUIRED),
  rideDisruptionEventReasonId: number().required(ERROR_MESSAGES.REQUIRED),
  terminatesAtUtc: string().required(ERROR_MESSAGES.REQUIRED),
  notes: string().max(...maxLength(255))
});

export const STADIUM_DRIVERS_AUTOCOMPLETE_NAME = 'STADIUM_DRIVERS_AUTOCOMPLETE';
export const STADIUM_DRIVERS_AUTOCOMPLETE_PATH =
  'avl.trafficStadium.driversAutocomplete';

export const STADIUM_DEPOT_SELECT_NAME = 'STADIUM_DEPOT_SELECT';
export const STADIUM_DEPOT_SELECT_PATH = 'avl.trafficStadium.depotSelect';

export const getDriversConfig = ({
  from,
  to,
  counteragentId
}: {
  counteragentId: number | string | null;
  from?: string;
  to?: string;
}) => ({
  reducerPath: STADIUM_DRIVERS_AUTOCOMPLETE_PATH,
  reducerName: STADIUM_DRIVERS_AUTOCOMPLETE_NAME,
  optionsApiUrl: `${COUNTERAGENTS}/${counteragentId}/employees-autocomplete?from=${from}&to=${to}&professionId=${EmployeeProfessionEnum.Driver}&bySchedule=false`,
  prohibitInitialLoad: true
});

export const DEPARTURE_TIMES_NAME = `RIDE_DEPARTURE_TIMES`;
export const CHANGEOVER_TIMES_PATH = 'avl.trafficStadium.departureTimesSelect';

export const getDepartureTimesConfig = (
  departureId: number,
  busStopId?: number
) => ({
  optionsApiUrl: busStopId
    ? STOP_DEPARTURE_TIMES('0', departureId, busStopId)
    : '',
  reducerName: DEPARTURE_TIMES_NAME,
  reducerPath: CHANGEOVER_TIMES_PATH
});

export const STADIUM_VEHICLES_AUTOCOMPLETE_NAME =
  'STADIUM_VEHICLES_AUTOCOMPLETE';
export const STADIUM_VEHICLES_AUTOCOMPLETE_PATH =
  'avl.trafficStadium.vehiclesAutocomplete';

export const getVehiclesConfig = ({
  counteragentId,
  vehicleCategoryId,
  from,
  to
}: {
  counteragentId: number | string | null;
  vehicleCategoryId: number | null;
  from?: string;
  to?: string;
}) => ({
  reducerPath: STADIUM_VEHICLES_AUTOCOMPLETE_PATH,
  reducerName: STADIUM_VEHICLES_AUTOCOMPLETE_NAME,
  prohibitInitialLoad: true,
  optionsApiUrl: `${COUNTERAGENTS}/${counteragentId}/vehicles-autocomplete?vehicleCategoryId=${vehicleCategoryId}&status=Enabled&from=${from}&to=${to}&bySchedule=false`
});

export const DEPOT_SELECT_CONFIG = {
  reducerPath: STADIUM_DEPOT_SELECT_PATH,
  reducerName: STADIUM_DEPOT_SELECT_NAME,
  optionsApiUrl: COUNTERAGENTS
};

export const GPS_THRESHOLD_IN_SECONDS = 300;
export const IDLE_THRESHOLD_IN_MILISECONDS = 300000;
export const IDLE_THRESHOLD_IN_MINUTES =
  IDLE_THRESHOLD_IN_MILISECONDS / 1000 / 60;
