import { createAsyncAction } from 'typesafe-actions';
import { VehicleEditState } from '../types';
import { VehiclesValues } from '../../types';

export const getVehicle = createAsyncAction(
  '@VEHICLES_EDIT/GET_VEHICLE_REQUEST',
  '@VEHICLES_EDIT/GET_VEHICLE_SUCCESS',
  '@VEHICLES_EDIT/GET_VEHICLE_FAILURE'
)<undefined, VehicleEditState, Error>();

export const updateVehicle = createAsyncAction(
  '@VEHICLES_EDIT/UPDATE_VEHICLE_REQUEST',
  '@VEHICLES_EDIT/UPDATE_VEHICLE_SUCCESS',
  '@VEHICLES_EDIT/UPDATE_VEHICLE_FAILURE'
)<VehiclesValues, undefined, Error>();
