import { all, put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { TARIFFS } from 'src/config/clearingUrls';
import * as actions from './actions';
import * as selectors from './selectors';

export function* getTariff(): SagaIterator {
  const id = yield select(selectors.selectTariffId);

  const { data } = yield call(apiClient.get, `${TARIFFS}/${id}`, {
    retryAction: actions.getTariff.request
  });
  yield put(actions.getTariff.success(data));
}

export default function* tariffDetailsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getTariff.request),
      safe(getTariff, actions.getTariff.failure)
    )
  ]);
}
