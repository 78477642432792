import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { BillingDocumentValues, BillingDocumentStatusEnum } from '../../types';

type BillingDocumentEditActions = ActionType<typeof actions>;

const initialState: BillingDocumentValues = {
  documentDate: null,
  documentName: '',
  dateFrom: null,
  dateTo: null,
  counteragent: null,
  status: BillingDocumentStatusEnum.Unfixed,
  totalAmount: null
};

const billingDocumentEditReducers = createReducer<
  BillingDocumentValues,
  BillingDocumentEditActions
>(initialState).handleAction(
  actions.getBillingDocument.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default billingDocumentEditReducers;
