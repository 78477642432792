import { FC } from 'react';
import { makeStyles, Tab } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { isActionPresent } from 'src/hooks/useActionAllowed';
import { TabProps } from './const';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    minHeight: 63,
    minWidth: 0,
    margin: spacing(0, 1)
  }
}));

const TabView: FC<TabProps> = ({
  linkAction,
  link,
  value,
  label,
  permissions
}) => {
  const classes = useStyles();
  const isDirectoryAllowed = isActionPresent(linkAction, permissions);
  const to = `/${value}${isDirectoryAllowed ? `/${link}` : ''}`;

  return (
    <Tab
      classes={{
        root: classes.root
      }}
      component={Link}
      key={value}
      label={label}
      to={to}
      value={value}
    />
  );
};

export default TabView;
