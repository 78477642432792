import { createCustomAction } from 'typesafe-actions';
import { SelectConfig, Option } from '../types';

export const getOptionsRequest = createCustomAction(
  '@SELECT/GET_OPTIONS_REQUEST',
  (config: SelectConfig) => ({
    config
  })
);

export const getOptionsSuccess = createCustomAction(
  '@SELECT/GET_OPTIONS_SUCCESS',
  (config: SelectConfig, payload: Option[]) => ({
    config,
    payload
  })
);

export const getOptionsFailure = createCustomAction(
  '@SELECT/GET_OPTIONS_FAILURE',
  (config: SelectConfig, payload: Error) => ({
    payload,
    config
  })
);
