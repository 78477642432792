import { createAsyncAction } from 'typesafe-actions';
import { Equipment } from '../types';

interface ValidationPayload {
  barcode: string;
  imei: string;
  equipmentId: number;
}

interface GetEquipmentsPayload {
  barcode: string;
  counteragentId: number;
  counteragentName: string;
  checkEquipmentWithCounteragent: boolean;
}

export const getEquipments = createAsyncAction(
  '@REPLACEMENT/GET_EQUIPMENTS_REQUEST',
  '@REPLACEMENT/GET_EQUIPMENTS_SUCCESS',
  '@REPLACEMENT/GET_EQUIPMENTS_FAILURE'
)<GetEquipmentsPayload, Equipment, Error>();

export const validateEquipment = createAsyncAction(
  '@REPLACEMENT/VALIDATE_EQUIPMENT_REQUEST',
  '@REPLACEMENT/VALIDATE_EQUIPMENT_SUCCESS',
  '@REPLACEMENT/VALIDATE_EQUIPMENT_FAILURE'
)<ValidationPayload, undefined, Error>();
