import { BillingDetailsLevels } from '../types';

export const getLevelLink = (
  level: BillingDetailsLevels,
  id?: number,
  routeId?: number
): string =>
  `/clearing/billing-details/${level}/${id || ''}${
    routeId ? `/${routeId}` : ''
  }`;

export const formatEmptyValue = (value?: number | string) => value ?? '-';

export const formatDistanceValue = (value?: number) => {
  if (value === 0) {
    return '0';
  }

  return formatEmptyValue(value?.toFixed(3));
};
