import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { ROUTE_SETTINGS } from 'src/config/administrationUrls';
import * as actions from './actions';

export function* getRouteSettings(): SagaIterator {
  const { data } = yield call(apiClient.get, ROUTE_SETTINGS, {
    retryAction: actions.getRouteSettings.request()
  });
  yield put(actions.getRouteSettings.success(data));
}

export function* updateRouteSettings({
  payload
}: ReturnType<typeof actions.updateRouteSettings.request>): SagaIterator {
  yield call(apiClient.put, ROUTE_SETTINGS, payload, {
    retryAction: actions.updateRouteSettings.request(payload)
  });

  yield put(actions.updateRouteSettings.success());
}

export default function* RouteSettingsSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getRouteSettings.request),
      safe(getRouteSettings, actions.getRouteSettings.failure)
    ),
    takeEvery(
      getType(actions.updateRouteSettings.request),
      safe(updateRouteSettings, actions.updateRouteSettings.failure)
    )
  ]);
}
