import { ActionType, createReducer } from 'typesafe-actions';
import { EmployeeInfoDTO, SystemStatusDTO } from '@eticket/avl-api-contracts';
import * as actions from './actions';

type EmployeeDetailsActions = ActionType<typeof actions>;

const initialState = {
  contacts: [],
  code: '',
  department: '',
  description: '',
  id: 0,
  position: '',
  profession: '',
  shortName: '',
  status: SystemStatusDTO.Enabled
};

const employeeDetailsReducers = createReducer<
  EmployeeInfoDTO,
  EmployeeDetailsActions
>(initialState).handleAction(
  actions.getEmployee.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default employeeDetailsReducers;
