import { put, takeEvery, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { COUNTERAGENTS } from 'src/config/avlUrls';
import * as actions from './actions';
import { selectCounteragentId } from './selectors';

export function* getCounteragent(): SagaIterator {
  const id = yield select(selectCounteragentId);

  const { data } = yield call(apiClient.get, `${COUNTERAGENTS}/${id}`, {
    retryAction: actions.getCounteragent.request()
  });

  yield put(actions.getCounteragent.success(data));
}

export default function* CounteragentDetailsSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.getCounteragent.request),
    safe(getCounteragent, actions.getCounteragent.failure)
  );
}
