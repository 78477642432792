import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { apiClient } from 'src/utils/api';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import { ROUTE_PASSPORT } from 'src/config/avlUrls';
import { navigateTo } from 'src/store/actions/app';
import * as actions from './actions';

export function* createRoutePassportSaga({
  payload
}: ReturnType<typeof actions.createPassport.request>): SagaIterator {
  const { data: passportId } = yield call(
    apiClient.post,
    ROUTE_PASSPORT,
    payload,
    {
      retryAction: actions.createPassport.request(payload)
    }
  );
  yield put(navigateTo({ url: `/avl/routes/details/settings/${passportId}` }));
  yield put(
    enqueueSnackbar({
      key: 'passport_create',
      message: 'Паспорт маршруту створено',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.createPassport.success());
}

export default function* PassportCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createPassport.request),
    safe(createRoutePassportSaga, actions.createPassport.failure)
  );
}
