import { createAsyncAction } from 'typesafe-actions';

interface UpdateBreakdownRequestPayload {
  id: number;
  formPath: string;
  breakdownItemsPath: string;
}

export const updateBreakdown = createAsyncAction(
  '@BREAKDOWN/UPDATE_BREAKDOWN_REQUEST',
  '@BREAKDOWN/UPDATE_BREAKDOWN_SUCCESS',
  '@BREAKDOWN/UPDATE_BREAKDOWN_FAILURE'
)<UpdateBreakdownRequestPayload, undefined, Error>();
