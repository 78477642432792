import { createAsyncAction } from 'typesafe-actions';
import { KPIParametersGroup } from '../../types';

export const updateKPIParametersGroup = createAsyncAction(
  '@KPI_PARAMETERS/UPDATE_KPI_PARAMETERS_GROUP_REQUEST',
  '@KPI_PARAMETERS/UPDATE_KPI_PARAMETERS_GROUP_SUCCESS',
  '@KPI_PARAMETERS/UPDATE_KPI_PARAMETERS_GROUP_FAILURE'
)<KPIParametersGroup, undefined, Error>();

export const getKPIParametersGroup = createAsyncAction(
  '@KPI_PARAMETERS/GET_KPI_PARAMETERS_GROUP_REQUEST',
  '@KPI_PARAMETERS/GET_KPI_PARAMETERS_GROUP_SUCCESS',
  '@KPI_PARAMETERS/GET_KPI_PARAMETERS_GROUP_FAILURE'
)<undefined, KPIParametersGroup | null, Error>();
