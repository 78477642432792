import { SagaIterator } from 'redux-saga';
import { all, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';
import { getDataListSaga, getDeleteRowSaga } from '../../store/sagas';

export const getDataList = getDataListSaga(
  actions.getDataRequest,
  actions.getDataSuccess
);

export const deleteRow = getDeleteRowSaga(
  actions.deleteRowRequest,
  actions.deleteRowSuccess,
  actions.getDataRequest
);

export default function* LocalTableSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.getDataRequest),
      safe(getDataList, actions.getDataFailure)
    ),
    takeEvery(
      getType(actions.deleteRowRequest),
      safe(deleteRow, actions.deleteRowFailure)
    )
  ]);
}
