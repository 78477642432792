import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { DepartureDetailsPayload } from '../../../types';

type DepartureDetailsActions = ActionType<typeof actions>;

const initialState: DepartureDetailsPayload = {
  id: 0,
  calendarSettings: [],
  calendarExceptions: [],
  arrivalTime: '',
  breaks: [],
  departureTime: '',
  depotCounteragentId: '',
  disabilityPlaces: '',
  iterations: [],
  sittingPlaces: '',
  standingPlaces: '',
  tariffGroupId: '',
  workShifts: [],
  sequenceNumber: 1,
  distanceFromDepotInKm: null,
  distanceToDepotInKm: null
};

const departureDetailsReducer = createReducer<
  DepartureDetailsPayload,
  DepartureDetailsActions
>(initialState).handleAction(
  actions.getDeparture.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default departureDetailsReducer;
