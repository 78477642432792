import { createCustomAction } from 'typesafe-actions';

interface RegisterInboundConfig {
  dataReducerPath: string;
  tableReducerPath: string;
}

export const registerInboundRequest = createCustomAction(
  '@INBOUND/REGISTER_INBOUND_REQUEST',
  (config: RegisterInboundConfig, resolve: (id: string) => void) => ({
    config,
    resolve
  })
);

export const registerInboundSuccess = createCustomAction(
  '@INBOUND/REGISTER_INBOUND_SUCCESS',
  (config: RegisterInboundConfig, payload: number) => ({
    config,
    payload
  })
);

export const registerInboundFailure = createCustomAction(
  '@INBOUND/REGISTER_INBOUND_SUCCESS',
  (config: RegisterInboundConfig, payload: Error) => ({
    config,
    payload
  })
);
