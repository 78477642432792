import { INSTALLATIONS } from 'src/config/assetUrls';

export const CREATE_INSTALLATION_FORM_NAME = 'CREATE_INSTALLATION_FORM';
export const CREATE_INSTALLATION_FORM_PATH = 'asset.installation.create.data';
export const CREATE_INSTALLATION_FORM_CONFIG = {
  reducerName: CREATE_INSTALLATION_FORM_NAME,
  reducerPath: CREATE_INSTALLATION_FORM_PATH
};

export const INSTALLING_EQUIPMENT_NAME = 'INSTALLATION_EQUIPMENT_TABLE_VIEW';
export const INSTALLING_EQUIPMENT_PATH = 'asset.installation.create.table';

export const getTableViewConfig = (id: string) => ({
  reducerName: INSTALLING_EQUIPMENT_NAME,
  reducerPath: INSTALLING_EQUIPMENT_PATH,
  apiUrl: `${INSTALLATIONS}/${id}/equipment`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено реєстрацію'
  },
  enableSetRowsIsLocalProperty: true
});
