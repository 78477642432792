import { createAsyncAction } from 'typesafe-actions';
import { DetailsActionsConfig } from '../types';

export const deleteItem = createAsyncAction(
  '@DETAILS_ACTIONS/DELETE_ITEM_REQUEST',
  '@DETAILS_ACTIONS/DELETE_ITEM_SUCCESS',
  '@DETAILS_ACTIONS/DELETE_ITEM_FAILURE'
)<DetailsActionsConfig, undefined, Error[]>();

export const changeItemStatusToDraft = createAsyncAction(
  '@DETAILS_ACTIONS/CHANGE_ITEM_STATUS_TO_DRAFT_REQUEST',
  '@DETAILS_ACTIONS/CHANGE_ITEM_STATUS_TO_DRAFT_SUCCESS',
  '@DETAILS_ACTIONS/CHANGE_ITEM_STATUS_TO_DRAFT_FAILURE'
)<DetailsActionsConfig, undefined, Error>();
