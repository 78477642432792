import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { Option } from '../types';

type SelectActions = ActionType<typeof actions>;

const selectReducers = createReducer<Option[], SelectActions>(
  []
).handleAction(actions.getOptionsSuccess, (state, { payload }) => [...payload]);

export default selectReducers;
