import { RouterRootState, createMatchSelector } from 'connected-react-router';
import { RootState } from 'typesafe-actions';
import { createSelector } from 'reselect';
import { get, isBoolean } from 'lodash';
import { StatesDetailsState } from '../../types';

const matchSpaceParams = createMatchSelector<RouterRootState, { id: string }>(
  '/asset/dictionaries/states/:id'
);

export const selectStatesId = createSelector(matchSpaceParams, match =>
  match ? match.params.id : ''
);

export const selectStates = (state: RootState): StatesDetailsState =>
  get(state, 'ui.asset.states.edit');

export const selectIsLoadingStates = (state: RootState) => {
  const loading = get(state, `loading.@STATES_EDIT/GET_STATE`);
  return isBoolean(loading) ? loading : true;
};
