import { generateActionCrud } from 'src/components/Guards';
import { MAIN_MENU } from 'src/constants';
import { BreakdownStateEnum } from '@eticket/asset-management-api-contracts';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [...listBreadcrumbs];

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'tracking'
  },
  ['download']
);

export enum EquipmentStateStatusEnum {
  Norm = 'Норма',
  Inspection = 'Інспекція',
  Suspicious = 'Підозрілий',
  Substitute = 'Заміна',
  Repair = 'Ремонт',
  Uninstalled = 'Демонтований'
}

export const equipmentStateEnumStatusMap = {
  [EquipmentStateStatusEnum.Norm]: BreakdownStateEnum.Norm,
  [EquipmentStateStatusEnum.Inspection]: BreakdownStateEnum.Inspection,
  [EquipmentStateStatusEnum.Suspicious]: BreakdownStateEnum.Suspicious,
  [EquipmentStateStatusEnum.Substitute]: BreakdownStateEnum.Substitute,
  [EquipmentStateStatusEnum.Repair]: BreakdownStateEnum.Repair
};
