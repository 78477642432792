import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { INBOUNDS } from 'src/config/assetUrls';
import { apiClient } from 'src/utils/api';
import { navigateTo } from 'src/store/actions/app';
import { safe } from 'src/utils/sagas';
import * as actions from './actions';
import { selectInboundId } from '../../InboundStepView/store/selectors';

export function* confirmInbound({
  payload: inboundId
}: ReturnType<typeof actions.confirmInbound.request>): SagaIterator {
  yield call(apiClient.put, `${INBOUNDS}/approve`, {
    inboundId
  });
  yield put(actions.confirmInbound.success());
}

export function* cancelInbound(): SagaIterator {
  const inboundId = yield select(selectInboundId);

  yield call(apiClient.delete, `${INBOUNDS}/${inboundId}`);
  yield put(actions.cancelInbound.success());

  yield put(navigateTo({ url: '/asset/inbound' }));
}

export default function* ConfirmInboundSaga(): SagaIterator {
  yield all([
    takeEvery(
      getType(actions.confirmInbound.request),
      safe(confirmInbound, actions.confirmInbound.failure)
    ),
    takeEvery(
      getType(actions.cancelInbound.request),
      safe(cancelInbound, actions.cancelInbound.failure)
    )
  ]);
}
