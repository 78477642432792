import { INBOUNDS } from 'src/config/assetUrls';

export const EDIT_INBOUND_FORM_NAME = 'EDIT_INBOUND_FORM';
export const EDIT_INBOUND_FORM_PATH = 'asset.inbound.edit.data';
export const EDIT_INBOUND_FORM_CONFIG = {
  reducerName: EDIT_INBOUND_FORM_NAME,
  reducerPath: EDIT_INBOUND_FORM_PATH
};

export const EDIT_INBOUNDING_EQUIPMENT_NAME =
  'EDIT_INBOUND_EQUIPMENT_TABLE_VIEW';
export const EDIT_INBOUNDING_EQUIPMENT_PATH = 'asset.inbound.edit.table';

export const getEditTableViewConfig = (id: string) => ({
  reducerName: EDIT_INBOUNDING_EQUIPMENT_NAME,
  reducerPath: EDIT_INBOUNDING_EQUIPMENT_PATH,
  apiUrl: `${INBOUNDS}/${id}/equipment`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено реєстрацію'
  },
  enableSetRowsIsLocalProperty: true
});
