import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { COUNTERAGENTS } from 'src/config/avlUrls';
import { safe } from 'src/utils/sagas';
import { navigateTo } from 'src/store/actions/app';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';

export function* createCounteragent({
  payload
}: ReturnType<typeof actions.createCounteragent.request>): SagaIterator {
  yield call(apiClient.post, COUNTERAGENTS, payload, {
    retryAction: actions.createCounteragent.request(payload)
  });
  yield put(navigateTo({ url: '/avl/geozones/counteragents' }));
  yield put(
    enqueueSnackbar({
      key: 'counter_agent_create',
      message: 'Підрозділ створено',
      options: {
        variant: 'success'
      }
    } as UINotification)
  );
  yield put(actions.createCounteragent.success());
}

export default function* CounteragentCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createCounteragent.request),
    safe(createCounteragent, actions.createCounteragent.failure)
  );
}
