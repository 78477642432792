import { createReducer, ActionType } from 'typesafe-actions';
import { SystemStatusDTO, DepartmentDTO } from '@eticket/avl-api-contracts';
import * as actions from './actions';

type CounteragentsDetailsActions = ActionType<typeof actions>;

export const initialState: DepartmentDTO = {
  id: 0,
  code: '',
  name: '',
  shortName: '',
  counteragentType: '',
  description: '',
  status: SystemStatusDTO.Disabled,
  parentDepartmentName: '',
  contacts: [
    {
      phoneNumber: '',
      email: '',
      description: ''
    }
  ],
  address: '',
  lat: 0,
  lng: 0,
  points: ''
};

const counteragentDetailsReducers = createReducer<
  DepartmentDTO,
  CounteragentsDetailsActions
>(initialState).handleAction(
  actions.getCounteragent.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default counteragentDetailsReducers;
