import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { BREAKDOWNS } from 'src/config/assetUrls';
import { StatusEnum } from 'src/constants';
import * as actions from './actions';
import {
  selectBreakdownRowsForEdit,
  selectBreakdownsEditData
} from '../../BreakdownsCreateView/EquipmentStepView/store/selectors';
import { normalizeBreakdownItem } from '../helpers/helpers';

export function* updateBreakdown({
  payload
}: ReturnType<typeof actions.updateBreakdown.request>): SagaIterator {
  const { breakdownItemsPath, formPath, id } = payload;

  const [breakdown, breakdownItem] = yield all([
    select(selectBreakdownsEditData(formPath)),
    select(selectBreakdownRowsForEdit(breakdownItemsPath))
  ]);

  yield call(
    apiClient.put,
    `${BREAKDOWNS}/${id}`,
    {
      registrationDate: breakdown.installDate,
      reportedBy: breakdown.installingEmployee,
      notes: breakdown.notes,
      registeredByEmployeeName: breakdown.acceptingEmployee?.name,
      registeredByEmployeeId: breakdown.acceptingEmployee?.id,
      breakdownItems: normalizeBreakdownItem(breakdownItem),
      status: StatusEnum.Draft
    },
    { retryAction: actions.updateBreakdown.request(payload) }
  );

  yield put(actions.updateBreakdown.success());
}

export default function* BreakdownEditSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.updateBreakdown.request),
    safe(updateBreakdown, actions.updateBreakdown.failure)
  );
}
