import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from '../actions/app';

type FormActions = ActionType<typeof actions>;

interface FormReducerValues {
  isDirty: boolean;
}

const initialValues: FormReducerValues = {
  isDirty: false
};

const formReducer = createReducer<FormReducerValues, FormActions>(
  initialValues
).handleAction(actions.setIsFormDirty, (state, { payload }) => ({
  ...state,
  isDirty: payload
}));

export default formReducer;
