import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SCHEDULES_STATUS_EXPORT } from 'src/config/avlUrls';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { getType } from 'typesafe-actions';
import { PdfData } from 'src/views/avl/dispatching/schedulesStatus/types';
import { isEmpty } from 'lodash';
import { enqueueSnackbar } from 'src/store/actions/snackbar';
import * as actions from './actions';

export function* getPdfData({
  payload
}: ReturnType<typeof actions.getPdfData.request>) {
  const { data }: AxiosResponse<PdfData> = yield call(
    apiClient.get,
    SCHEDULES_STATUS_EXPORT,
    {
      params: {
        ...payload,
        routeId: payload.route?.id,
        onDate: payload.date
      }
    }
  );

  if (isEmpty(data)) {
    yield put(
      enqueueSnackbar({
        key: 'schedules-status-pdf',
        message: 'На вказану дату планування відсутнє',
        options: {
          variant: 'error'
        }
      })
    );
  }
  yield put(actions.getPdfData.success(data));
}

export function* SchedulesStatusSaga() {
  yield takeEvery(
    getType(actions.getPdfData.request),
    safe(getPdfData, actions.getPdfData.failure)
  );
}
