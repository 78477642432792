import { flattenDeep } from 'lodash';
import { EquipmentTypeEnum, equipmentTypes } from 'src/constants';
import { installationFieldsJson } from '../const';
import {
  EquipmentDetail,
  InstallationDetailsState
} from '../InstallationDetailsView/types';
import { generateJsonFile } from '../../../../utils/jsonFile';

export function generateJsonOfInstallation(
  data: InstallationDetailsState
): string {
  const installationFields: any[] = [];

  const equipments = flattenDeep(
    data.equipments.map((item: EquipmentDetail) => {
      const { supplements, ...obj } = item;
      if (supplements?.length) {
        return [
          obj,
          ...supplements.map(suppl => {
            return {
              name: suppl
            };
          })
        ];
      }
      return obj;
    })
  );

  if (
    data.equipmentGroupName === equipmentTypes[EquipmentTypeEnum.Stationary] ||
    data.equipmentGroupName === equipmentTypes[EquipmentTypeEnum.Inspector]
  ) {
    installationFieldsJson.forEach(item => {
      if (
        (typeof item === 'string' &&
          !['licensePlate', 'vehicleModelName', 'vehicleCategoryName'].includes(
            item
          )) ||
        typeof item === 'object'
      ) {
        installationFields.push(item);
      }
    });
  }

  if (data.equipmentGroupName === equipmentTypes[EquipmentTypeEnum.Mobile]) {
    installationFieldsJson.forEach(item => {
      if (
        (typeof item === 'string' && item !== 'employeeName') ||
        typeof item === 'object'
      ) {
        installationFields.push(item);
      }
    });
  }

  return generateJsonFile(installationFields, { ...data, equipments });
}
