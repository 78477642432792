import { RouterRootState, createMatchSelector } from 'connected-react-router';
import { RootState } from 'typesafe-actions';
import { BreakdownPageItemDTO } from '@eticket/asset-management-api-contracts';
import { createSelector } from 'reselect';
import { get, isBoolean } from 'lodash';

const matchSpaceParams = createMatchSelector<RouterRootState, { id: string }>(
  '/asset/dictionaries/breakdownsTypes/:id'
);

export const selectBreakdownsTypesId = createSelector(matchSpaceParams, match =>
  match ? match.params.id : ''
);

export const selectBreakdownsTypes = (state: RootState): BreakdownPageItemDTO =>
  get(state, 'ui.asset.breakdownsTypes.edit');

export const selectIsLoadingBreakdownsTypes = (state: RootState) => {
  const loading = get(state, `loading.@BREAKDOWNS_TYPES_EDIT/GET_BREAKDOWN`);
  return isBoolean(loading) ? loading : true;
};
