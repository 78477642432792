import { SagaIterator } from 'redux-saga';
import { takeEvery, call, select, put, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { StatusEnum } from 'src/constants';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { INSPECTIONS } from 'src/config/assetUrls';
import * as actions from './actions';
import {
  CREATE_INSPECTION_FORM_PATH,
  INSPECTIONS_EQUIPMENT_PATH
} from '../const';
import { selectFormCreateData } from '../../InspectionFormStep/store/selectors';
import { selectListCreateData } from '../../InspectionsEquipmentStep/store/selectors';

export function* createInspection(): SagaIterator {
  const [formData, equipment] = yield all([
    select(selectFormCreateData(CREATE_INSPECTION_FORM_PATH)),
    select(selectListCreateData(INSPECTIONS_EQUIPMENT_PATH))
  ]);

  const { data: inspectionId } = yield call(apiClient.post, INSPECTIONS, {
    status: StatusEnum.Draft,
    equipment,
    ...formData
  });
  yield put(actions.createInspection.success(inspectionId));
}

export default function* InspectionsCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createInspection.request),
    safe(createInspection, actions.createInspection.failure)
  );
}
