import { ElementType } from 'react';
import { InputBaseComponentProps } from '@material-ui/core';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

export interface MaskInputProps extends InputBaseComponentProps {
  mask?: MaskedInputProps['mask'];
}

const TimeMaskedInput: ElementType<MaskInputProps> = ({
  inputRef,
  mask = [/\d/, /\d/, ':', /\d/, /\d/],
  ...other
}) => (
  <MaskedInput
    {...other}
    ref={ref => inputRef(ref?.inputElement || null)}
    mask={mask}
    placeholderChar="-"
    showMask
  />
);

export default TimeMaskedInput;
