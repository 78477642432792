import { combineReducers } from 'redux';
import routeSettingsReducer from 'src/views/administration/routeSettings/store/reducer';
import autocompleteReducers from 'src/components/Form/FormAutocomplete/store/reducer';
import {
  COUNTERAGENTS_NAME,
  EMPLOYEES_NAME,
  ROLES_SELECT_NAME
} from 'src/views/administration/usersSettings/components/UserEditView/const';
import userManagementReducer from 'src/views/administration/usersSettings/components/UserEditView/store/reducer';
import { createNamedWrapperReducer } from 'src/utils/reducers';
import tableWithPaginationReducers from 'src/components/Tables/TableWithPagination/store/reducers';
import {
  USERS_AUTOCOMPLETE_NAME,
  USERS_LIST_TABLE_NAME
} from 'src/views/administration/usersSettings/const';
import paymentsSettingsReducer from 'src/views/administration/paymentsSettings/store/reducer';
import selectReducers from 'src/components/Select/store/reducers';
import usersLogsReducer from 'src/views/administration/usersLogs/store/reducer';
import { API_KEYS_TABLE_NAME } from 'src/views/administration/apiKeysSettings/const';
import apiKeysCreateReducer from 'src/views/administration/apiKeysSettings/store/reducer';

export const administrationReducers = combineReducers({
  routeSettings: routeSettingsReducer,
  usersSettings: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      USERS_LIST_TABLE_NAME
    ),
    counteragentsAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      COUNTERAGENTS_NAME
    ),
    employeesAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      EMPLOYEES_NAME
    ),
    rolesSelect: createNamedWrapperReducer(selectReducers, ROLES_SELECT_NAME),
    edit: userManagementReducer,
    usersAutocomplete: createNamedWrapperReducer(
      autocompleteReducers,
      USERS_AUTOCOMPLETE_NAME
    )
  }),
  paymentsSettings: paymentsSettingsReducer,
  usersLogs: usersLogsReducer,
  apiKeysSettings: combineReducers({
    table: createNamedWrapperReducer(
      tableWithPaginationReducers,
      API_KEYS_TABLE_NAME
    ),
    create: apiKeysCreateReducer
  })
});
