import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { BillingDocumentDetailsState } from '../types';
import { BillingType, BillingDocumentStatusEnum } from '../../types';

type BillingDocumentDetailsActions = ActionType<typeof actions>;

const initialState: BillingDocumentDetailsState = {
  id: 0,
  documentDate: '',
  documentName: '',
  dateFrom: '',
  dateTo: '',
  counteragent: { id: 0, name: '' },
  totalAmount: 0,
  billingType: BillingType.Manual,
  status: BillingDocumentStatusEnum.Unfixed,
  canUpdate: false
};

const billingDocumentDetailsReducers = createReducer<
  BillingDocumentDetailsState,
  BillingDocumentDetailsActions
>(initialState).handleAction(
  actions.getBillingDocument.success,
  (state, { payload }) => ({
    ...state,
    ...payload
  })
);

export default billingDocumentDetailsReducers;
