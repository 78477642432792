import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { State } from '../../types';
import { StateEnum } from '../../const';

type StateDetailsActions = ActionType<typeof actions>;

const initialState: State = {
  id: 0,
  name: '',
  description: '',
  date: '',
  stateType: StateEnum.Custom,
  employeeId: 0,
  employeeName: '',
  canModify: false
};

const stateDetailsReducer = createReducer<State, StateDetailsActions>(
  initialState
).handleAction(actions.getState.success, (state, { payload }) => ({
  ...state,
  ...payload
}));

export default stateDetailsReducer;
