import { SagaIterator } from 'redux-saga';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { apiClient } from 'src/utils/api';
import { safe } from 'src/utils/sagas';
import { BREAKDOWNS } from 'src/config/assetUrls';
import { StatusEnum } from 'src/constants';
import * as actions from './actions';
import { selectNormalizeData } from '../BreakdownsStepView/store/selectors';

export function* createBreakdown({
  payload
}: ReturnType<typeof actions.createBreakdown.request>): SagaIterator {
  const breakdownData = yield select(
    selectNormalizeData(payload.dataReducerPath)
  );

  const { data: breakdownId } = yield call(
    apiClient.post,
    BREAKDOWNS,
    { ...breakdownData, status: StatusEnum.Draft },
    { retryAction: actions.createBreakdown.request(payload) }
  );

  yield put(actions.createBreakdown.success(breakdownId));
}

export default function* BreakdownCreateSaga(): SagaIterator {
  yield takeEvery(
    getType(actions.createBreakdown.request),
    safe(createBreakdown, actions.createBreakdown.failure)
  );
}
