import { BREAKDOWNS } from 'src/config/assetUrls';

export const EDIT_BREAKDOWN_TABLE_PATH =
  'asset.breakdowns.edit.breakdownsTable.list';
export const EDIT_FORM_PATH = 'asset.breakdowns.edit';
export const EDIT_BREAKDOWN_FORM_NAME = 'EDIT_BREAKDOWN_FORM';
export const EDIT_BREAKDOWN_FORM_PATH = 'asset.breakdowns.edit.data';
export const EDIT_BREAKDOWN_FORM_CONFIG = {
  reducerName: EDIT_BREAKDOWN_FORM_NAME,
  reducerPath: EDIT_BREAKDOWN_FORM_PATH
};

export const EDIT_BREAKDOWNS_EQUIPMENT_NAME =
  'EDIT_BREAKDOWNS_EQUIPMENT_TABLE_VIEW';
export const EDIT_BREAKDOWNS_EQUIPMENT_PATH =
  'asset.breakdowns.edit.breakdownsTable';

export const EDIT_BREAKDOWNS_TABLE_CONFIG = {
  reducerName: EDIT_BREAKDOWNS_EQUIPMENT_NAME,
  reducerPath: EDIT_BREAKDOWNS_EQUIPMENT_PATH,
  apiUrl: `${BREAKDOWNS}/1`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено реєстрацію'
  },
  enableSetRowsIsLocalProperty: true
};
