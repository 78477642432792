import {
  FilterConfig,
  FilterType
} from 'src/components/Tables/TableWithPagination/types';
import {
  DANGEROUS_ZONES_AUTOCOMPLETE,
  DANGEROUS_ZONES_CATEGORIES
} from 'src/config/avlUrls';

export const columns = [
  {
    label: 'Назва',
    value: 'name',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Адреса',
    value: 'address',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Категорія',
    value: 'CategoryId',
    sortable: true,
    width: '20%'
  },
  {
    label: 'Код',
    value: 'code',
    sortable: true,
    width: '10%'
  }
];

export const DANGEROUS_ZONES_NAME = 'DANGEROUS_ZONES_AUTOCOMPLETE';

export const filters: FilterConfig[] = [
  {
    name: 'id',
    label: 'Назва',
    type: FilterType.Autocomplete,
    autocompleteConfig: {
      optionsApiUrl: DANGEROUS_ZONES_AUTOCOMPLETE,
      reducerName: DANGEROUS_ZONES_NAME,
      reducerPath: 'avl.dangerousZones.autocomplete'
    }
  },
  {
    name: 'category.id',
    label: 'Категорія',
    optionsURL: DANGEROUS_ZONES_CATEGORIES
  }
];

export const DANGEROUS_ZONES_TABLE_PATH = 'avl.dangerousZones.table';
export const DANGEROUS_ZONES_TABLE_NAME = 'DANGEROUS_ZONES';
