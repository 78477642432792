import { createAsyncAction } from 'typesafe-actions';
import {
  RouteSchedules,
  SchedulesActionsConfig,
  ScheduleSaveData,
  DisableRules
} from '../types';

export const getSchedules = createAsyncAction(
  '@SCHEDULES_DETAILS/GET_SCHEDULES_REQUEST',
  '@SCHEDULES_DETAILS/GET_SCHEDULES_SUCCESS',
  '@SCHEDULES_DETAILS/GET_SCHEDULES_FAILURE'
)<SchedulesActionsConfig, RouteSchedules[], Error>();

export const saveSchedules = createAsyncAction(
  '@SCHEDULES_DETAILS/SAVE_SCHEDULES_REQUEST',
  '@SCHEDULES_DETAILS/SAVE_SCHEDULES_SUCCESS',
  '@SCHEDULES_DETAILS/SAVE_SCHEDULES_FAILURE'
)<ScheduleSaveData, undefined, Error>();

export const getSchedulesAvailableDates = createAsyncAction(
  '@SCHEDULES_DETAILS/GET_SCHEDULES_AVAILABLE_DATES_REQUEST',
  '@SCHEDULES_DETAILS/GET_SCHEDULES_AVAILABLE_DATES_SUCCESS',
  '@SCHEDULES_DETAILS/GET_SCHEDULES_AVAILABLE_DATES_FAILURE'
)<number, DisableRules, Error>();

export const exportDriversToAFC = createAsyncAction(
  '@SCHEDULES_DETAILS/EXPORT_DERIVERS_REQUEST',
  '@SCHEDULES_DETAILS/EXPORT_DERIVERS_SUCCESS',
  '@SCHEDULES_DETAILS/EXPORT_DERIVERS_FAILURE'
)<undefined, undefined, Error>();
