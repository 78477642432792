import { createAsyncAction } from 'typesafe-actions';
import { PaymentsSettingsValues } from '../types';

export const updatePaymentsSettings = createAsyncAction(
  '@PAYMENTS_SETTINGS/UPDATE_PAYMENTS_SETTINGS_REQUEST',
  '@PAYMENTS_SETTINGS/UPDATE_PAYMENTS_SETTINGS_SUCCESS',
  '@PAYMENTS_SETTINGS/UPDATE_PAYMENTS_SETTINGS_FAILURE'
)<PaymentsSettingsValues, undefined, Error>();

export const getPaymentsSettings = createAsyncAction(
  '@PAYMENTS_SETTINGS/GET_PAYMENTS_SETTINGS_REQUEST',
  '@PAYMENTS_SETTINGS/GET_PAYMENTS_SETTINGS_SUCCESS',
  '@PAYMENTS_SETTINGS/GET_PAYMENTS_SETTINGS_FAILURE'
)<undefined, PaymentsSettingsValues, Error>();
