import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from 'src/store/selectors';

export const isActionPresent = (
  action: string,
  permissions: Array<string>
): boolean =>
  // find the permission that has the action in it. Also works for partial
  // actions like avl or avl.routes
  permissions.length > 0 &&
  permissions.find(permission => permission.indexOf(action) === 0) !==
    undefined;

// IMPORTANT! this function will work for partial routes,
// e.g. avl.routes will be true and will allow edit/delete etc even
// if user has no access to it. So make sure to always pass the full action!
const useActionAllowed = (action: string) => {
  const permissions = useSelector(selectors.selectUserPermissions);

  return useMemo(() => {
    let isPermitted: boolean = false;

    // | symbol means one of the actions should be allowed
    if (action && action.indexOf('|') > -1) {
      const actions = action.split('|');

      actions.forEach(currentAction => {
        isPermitted =
          isPermitted || isActionPresent(currentAction, permissions);
      });
    } else {
      isPermitted = isActionPresent(action, permissions);
    }

    return isPermitted;
  }, [action, permissions]);
};

export default useActionAllowed;
