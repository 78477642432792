import { createSelector } from 'reselect';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { DATE_FORMAT_SLASH } from 'src/config';
import { utcToLocal } from 'src/utils/date';
import {
  CoefficientAdjustmentsItemMap,
  CoefficientItem,
  TimeCoefficientsItem
} from '../types';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/clearing/coefficients-adjustments/:id/details'
);

export const selectCoefficientsAdjustmentsId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectIsLoadingCoefficientAdjustments = (
  state: RootState
): boolean => {
  const loading = get(
    state,
    `loading.@COEFFICIENT_ADJUSTMENTS/GET_COEFFICIENT_ADJUSTMENTS`
  );

  return isBoolean(loading) ? loading : true;
};

export const selectCoefficientAdjustmentsName = (state: RootState): string =>
  state.ui.clearing.coefficientsAdjustments.details.routePassportName;

export const selectCoefficientAdjustmentsCanDelete = (
  state: RootState
): boolean => state.ui.clearing.coefficientsAdjustments.details.canDelete;

export const selectCoefficientTimingRows = (state: RootState) => {
  const { list, sorters } = state.ui.clearing.coefficientsAdjustments.timeTable;

  if (sorters.length) return list;

  return list?.map((item: TimeCoefficientsItem) => ({
    endDate: utcToLocal(item.endDate as Date, DATE_FORMAT_SLASH),
    startDate: utcToLocal(item.startDate as Date, DATE_FORMAT_SLASH),
    timeCoefficient: item.timeCoefficient
  }));
};

export const selectCoefficientRouteRows = (
  state: RootState
): CoefficientAdjustmentsItemMap => {
  const {
    currentRouteCoefficients,
    futureRouteCoefficients,
    pastRouteCoefficients
  } = state.ui.clearing.coefficientsAdjustments.details;

  return [
    [
      'Поточні коефіцієнти',
      currentRouteCoefficients?.map((currentCoefficient: CoefficientItem) => ({
        ...currentCoefficient,
        effectiveDate: utcToLocal(
          currentCoefficient.effectiveDate as Date,
          DATE_FORMAT_SLASH
        )
      })),
      true
    ],
    [
      'Майбутні коефіцієнти',
      futureRouteCoefficients?.map((currentCoefficient: CoefficientItem) => ({
        ...currentCoefficient,
        effectiveDate: utcToLocal(
          currentCoefficient.effectiveDate as Date,
          DATE_FORMAT_SLASH
        )
      }))
    ],
    [
      'Минулі коефіцієнти',
      pastRouteCoefficients?.map((currentCoefficient: CoefficientItem) => ({
        ...currentCoefficient,
        effectiveDate: `${utcToLocal(
          currentCoefficient.effectiveDate as Date,
          DATE_FORMAT_SLASH
        )} - ${utcToLocal(
          currentCoefficient.endDateUtc as Date,
          DATE_FORMAT_SLASH
        )}`
      }))
    ]
  ];
};
