import { BusWorkOnRouteReport } from '../types';

export const busWorkOnRouteReportMock: BusWorkOnRouteReport = {
  summary: {
    graphicity: 40,
    regularity: 90,
    distanceOnRoute: {
      fact: 100,
      planned: 100
    }
  },
  data: [
    {
      departureSequenceNumber: 1,
      startOfWorkTime: { fact: 1, planned: 74 },
      endOfWorkTime: { fact: 35, planned: 73 },
      durationOfWorkTime: { fact: 53, planned: 53 },
      visitedBusStops: {
        fact: 1,
        planned: 2,
        onSchedule: 3,
        inSpecialMode: 4
      },
      numberOfRides: { fact: 4, planned: 44 },
      regularity: 43,
      graphicity: 12,
      distanceOnRoute: { fact: 1, planned: 3 },
      workShifts: [
        {
          driver: 'Ivanchenko',
          name: 'Zmina 1',
          licensePlate: 'CC3323'
        },
        {
          driver: 'Ivanchenko',
          name: 'Pidmina 1',
          licensePlate: 'DD4353'
        }
      ]
    },
    {
      departureSequenceNumber: 2,
      startOfWorkTime: { fact: 5, planned: 7 },
      endOfWorkTime: { fact: 5, planned: 7 },
      durationOfWorkTime: { fact: 5, planned: 7 },
      visitedBusStops: {
        fact: 5,
        planned: 7,
        onSchedule: 3,
        inSpecialMode: 5
      },
      numberOfRides: { fact: 5, planned: 7 },
      regularity: 80,
      graphicity: 40,
      distanceOnRoute: { fact: 5, planned: 7 },
      workShifts: [
        {
          driver: 'Bohdanobych',
          name: 'Zmina 1',
          licensePlate: 'AAAA'
        },
        {
          driver: 'Bohdanobych',
          name: 'Pidmina 1',
          licensePlate: 'BBBB'
        }
      ]
    }
  ]
};
