import { createSelector } from 'reselect';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { convertUtcToLocal, utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL, DATE_FORMAT_SLASH } from 'src/config';
import { generateSendEmailAttachmentProps } from 'src/components/SendEmailModalForm/utils/generateSendEmailAttachmentProps';
import { StatusEnum } from 'src/constants';
import { EquipmentDetail } from '../types';
import { generatePdfOfInbound } from '../../utils/generatePdfOfInbound';
import { generateJsonOfInbound } from '../../utils/generateJsonOfInbound';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/asset/inbound/:id/details'
);

export const selectEquipmentRows = (state: RootState): EquipmentDetail[] =>
  state.ui.asset.inbound.details.equipments;

export const selectEquipmentDetails = (state: RootState) =>
  state.ui.asset.inbound.details;

export const selectCharacteristicRows = (
  state: RootState
): Array<{ label: string; value: string | number }> => {
  const { ...inbound } = state.ui.asset.inbound.details;

  return [
    { label: 'Номер документа', value: inbound.documentNumber },
    {
      label: 'Дата надходження',
      value: convertUtcToLocal(
        String(inbound.dateInbound),
        DATE_FORMAT_SLASH
      ) as string
    },
    { label: 'Хто передав', value: inbound.inboundingEmployeeFullName || '-' },
    { label: 'Хто прийняв', value: inbound.acceptingEmployeeFullName },
    { label: 'Підрозділ', value: inbound.counteragentName },
    { label: 'Адреса', value: inbound.counteragentAddress },
    {
      label: 'Примітка',
      value: inbound.notes || '-'
    }
  ];
};

export const selectDocumentName = (state: RootState): number | string => {
  return state.ui.asset.inbound.details.documentNumber;
};

export const selectIsLoadingInbound = (state: RootState): boolean => {
  const loading = get(state, `loading.@INBOUNDS_DETAILS/GET_INBOUND`);

  return isBoolean(loading) ? loading : true;
};

export const selectIsEditDisabled = (state: RootState) =>
  state.ui.asset.inbound.details.status !== StatusEnum.Draft;

export const selectInboundId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectEmailData = createSelector(
  selectInboundId,
  selectEquipmentDetails,
  (inboundId, details) => {
    const {
      documentNumber,
      counteragentName,
      acceptingEmployeeName,
      dateInbound
    } = details;

    const date = utcToLocal(dateInbound as Date, DATE_FORMAT_FULL) as string;

    const subject = `Надходження №${documentNumber} ${counteragentName} ${acceptingEmployeeName} ${date}`;
    const bodyText = `Здійснено надходження.\nДокумент надходження №${documentNumber} від ${date}`;
    const tags = [`inbound:${inboundId}`];
    const pdfFile = generatePdfOfInbound(details);
    const jsonFile = generateJsonOfInbound(details);

    const attachments = generateSendEmailAttachmentProps(
      subject,
      pdfFile,
      jsonFile
    );

    return { subject, bodyText, tags, attachments };
  }
);
