import { object, string, number } from 'yup';
import {
  DANGEROUS_ZONES_CATEGORIES,
  DANGEROUS_ZONES_CUSTOM_CATEGORIES
} from 'src/config/avlUrls';
import { AVL_ERROR_MESSAGES, ERROR_MESSAGES } from 'src/constants/validation';
import { LAT_LON_REGEX } from 'src/constants/map';
import { MAIN_MENU, NAV_BAR_ITEMS } from 'src/constants';
import { generateActionCrud } from 'src/components/Guards';
import { maxLength } from 'src/utils/validation';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.avl,
    url: '/avl'
  },
  {
    text: NAV_BAR_ITEMS.geozones,
    url: '/avl/geozones/dangerous-zones'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_ITEMS.dangerousZones,
    url: '/avl/geozones/dangerous-zones'
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'avl',
    sub: 'dangerousZones'
  },
  ['download']
);

export const DANGEROUS_ZONES_CATEGORIES_NAME = 'DANGEROUS_ZONES_CATEGORIES';
export const DANGEROUS_ZONES_CATEGORIES_PATH =
  'avl.dangerousZones.create.editableSelect';

export const DANGEROUS_ZONES_CATEGORIES_CONFIG = {
  reducerPath: DANGEROUS_ZONES_CATEGORIES_PATH,
  reducerName: DANGEROUS_ZONES_CATEGORIES_NAME,
  activeOptionsApiUrl: DANGEROUS_ZONES_CATEGORIES,
  customOptionsApiUrl: DANGEROUS_ZONES_CUSTOM_CATEGORIES
};

export const DANGEROUS_ZONES_DIALOG_ERRORS = {
  ...AVL_ERROR_MESSAGES,
  UniqueError: 'Введіть унікальне значення'
};

export const dangerousZonesValidationSchema = object().shape({
  name: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(...maxLength(100)),
  categoryId: number().required(ERROR_MESSAGES.REQUIRED),
  notes: string().max(...maxLength(255)),
  coordinates: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED)
    .matches(
      LAT_LON_REGEX,
      'Невірний формат координат. Для збереження точки введіть дані у форматі ХХ.ХХХХХХ (широта), ХХ.ХХХХХХ (довгота) або вкажіть точку безпосередньо на мапі'
    ),
  address: string()
    .trim()
    .required(ERROR_MESSAGES.REQUIRED)
    .max(...maxLength(255))
});
