import { createSelector } from 'reselect';
import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { convertUtcToLocal, utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL, DATE_FORMAT_SLASH } from 'src/config';
import { generateSendEmailAttachmentProps } from 'src/components/SendEmailModalForm/utils/generateSendEmailAttachmentProps';
import { EquipmentTypeEnum, equipmentTypes, StatusEnum } from 'src/constants';
import { EquipmentDetail } from '../types';
import { generatePdfOfInstallation } from '../../utils/generatePdfOfInstallation';
import { generateJsonOfInstallation } from '../../utils/generateJsonOfInstallation';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  '/asset/installation/:id/details'
);

export const selectEquipmentRows = (state: RootState): EquipmentDetail[] =>
  state.ui.asset.installation.details.equipments;

export const selectEquipmentDetails = (state: RootState) =>
  state.ui.asset.installation.details;

export const selectCharacteristicRows = (
  state: RootState
): Array<{ label: string; value: string | number }> => {
  const {
    equipmentGroupId,
    ...installation
  } = state.ui.asset.installation.details;

  const mobileItems = [
    { label: 'Тип ТЗ', value: installation.vehicleCategoryName },
    { label: 'Номерний знак ТЗ', value: installation.licensePlate },
    { label: 'Модель ТЗ', value: installation.vehicleModelName }
  ];

  const nonMobileItems = [
    {
      label: 'Працівник підрозділу монтажу - відповідальна особа',
      value: installation.employeeFullName
    }
  ];

  return [
    { label: 'Номер документа', value: installation.documentNumber },
    {
      label: 'Дата монтажу',
      value: convertUtcToLocal(
        String(installation.installationDate),
        DATE_FORMAT_SLASH
      ) as string
    },
    { label: 'Хто монтував', value: installation.installingEmployeeFullName },
    {
      label: 'Хто прийняв роботу',
      value: installation.acceptingEmployeeFullName
    },
    {
      label: 'Група обладнання',
      value: equipmentTypes[equipmentGroupId as EquipmentTypeEnum]
    },
    {
      label: 'Підрозділ від',
      value: installation.counteragentHolderName
    },
    {
      label: 'Адреса підрозділу від',
      value: installation.counteragentHolderAddress
    },
    { label: 'Підрозділ монтажу', value: installation.counteragentName },
    {
      label: 'Адреса підрозділу монтажу',
      value: installation.counteragentAddress
    }
  ].concat(
    equipmentGroupId === EquipmentTypeEnum.Mobile
      ? mobileItems
      : nonMobileItems,
    {
      label: 'Примітка',
      value: installation.notes || '-'
    }
  );
};

export const selectDocumentName = (state: RootState): number | string => {
  return state.ui.asset.installation.details.documentNumber;
};

export const selectIsLoadingInstallation = (state: RootState): boolean => {
  const loading = get(state, `loading.@INSTALLATIONS_DETAILS/GET_INSTALLATION`);

  return isBoolean(loading) ? loading : true;
};

export const selectIsEditDisabled = (state: RootState) =>
  state.ui.asset.installation.details.installationStatusId !== StatusEnum.Draft;

export const selectInstallationId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectEmailData = createSelector(
  selectInstallationId,
  selectEquipmentDetails,
  (installationId, details) => {
    const {
      documentNumber,
      installingEmployeeName,
      installationDate,
      licensePlate,
      equipmentGroupId
    } = details;

    const date = utcToLocal(
      installationDate as Date,
      DATE_FORMAT_FULL
    ) as string;

    let title = '';
    if (equipmentGroupId === EquipmentTypeEnum.Mobile) {
      title = ` ${licensePlate}`;
    }

    const subject = `Монтаж №${documentNumber}${title} ${installingEmployeeName} ${date}`;
    const bodyText = `Здійснено монтаж обладнання.\nДокумент монтажу №${documentNumber} від ${date}`;
    const tags = [`installation:${installationId}`];
    const pdfFile = generatePdfOfInstallation(details);
    const jsonFile = generateJsonOfInstallation(details);

    const attachments = generateSendEmailAttachmentProps(
      subject,
      pdfFile,
      jsonFile
    );

    return { subject, bodyText, tags, attachments };
  }
);
