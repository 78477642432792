import { createAsyncAction } from 'typesafe-actions';
import { EquipmentsRequest } from './types';

export const getEquipments = createAsyncAction(
  '@UNINSTALLATION/GET_EQUIPMENTS_REQUEST',
  '@UNINSTALLATION/GET_EQUIPMENTS_SUCCESS',
  '@UNINSTALLATION/GET_EQUIPMENTS_FAILURE'
)<EquipmentsRequest, undefined, Error>();

export const approveUninstallation = createAsyncAction(
  '@UNINSTALLATION/APPROVE_UNINSTALLATION_REQUEST',
  '@UNINSTALLATION/APPROVE_UNINSTALLATION_SUCCESS',
  '@UNINSTALLATION/APPROVE_UNINSTALLATION_FAILURE'
)<number, undefined, Error>();
