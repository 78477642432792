import { createAsyncAction } from 'typesafe-actions';
import { CreateBillingDocumentPayload } from '../../types';

export const createBillingDocument = createAsyncAction(
  '@BILLING_DOCUMENTS/CREATE_PENALTY_DOCUMENT_REQUEST',
  '@BILLING_DOCUMENTS/CREATE_PENALTY_DOCUMENT_SUCCESS',
  '@BILLING_DOCUMENTS/CREATE_PENALTY_DOCUMENT_FAILURE'
)<CreateBillingDocumentPayload, undefined, Error>();

export const getNextBillingDocumentDate = createAsyncAction(
  '@BILLING_DOCUMENTS/GET_NEXT_BILLING_DOCUMENT_REQUEST',
  '@BILLING_DOCUMENTS/GET_NEXT_BILLING_DOCUMENT_SUCCESS',
  '@BILLING_DOCUMENTS/GET_NEXT_BILLING_DOCUMENT_FAILURE'
)<{ id: number }, string | null, Error>();
