import { createReducer, ActionType } from 'typesafe-actions';
import moment from 'moment';
import { DATEPICKER_DATE_FORMAT } from 'src/config';
import * as actions from './actions';
import { BreakdownsStepViewConfig } from './types';
import { EquipmentTypeEnum } from '../../const';

export const initialState: BreakdownsStepViewConfig = {
  documentId: '',
  installDate: moment().format(DATEPICKER_DATE_FORMAT),
  installingEmployee: '',
  acceptingEmployee: null,
  equipmentType: EquipmentTypeEnum.Mobile,
  counteragent: null,
  counteragentId: null,
  vehicleId: null,
  address: '',
  vehicleNumbers: null,
  vehicleModelName: '',
  vehicleType: '',
  notes: '',
  breakdownItems: []
};

const breakdownStepReducer = createReducer<
  BreakdownsStepViewConfig,
  ActionType<typeof actions>
>(initialState)
  .handleAction(actions.getCounteragentSuccess, (state, { payload }) => ({
    ...state,
    address: payload.address
  }))
  .handleAction(actions.getVehicleModelSuccess, (state, { payload }) => ({
    ...state,
    vehicleModelName: payload.vehicleModelName
  }))
  .handleAction(actions.getVehicleTypeSuccess, (state, { payload }) => ({
    ...state,
    vehicleType: payload.vehicleType
  }))
  .handleAction(actions.setFormValues, (state, { values }) => ({
    ...state,
    ...values
  }));

export default breakdownStepReducer;
