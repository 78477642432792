import { getTime, isCurrentTimeBetween, isTimeBefore } from 'src/utils/date';
import moment from 'moment';
import {
  DETAILED_DATE_FORMAT,
  DETAILED_TIME_FORMAT,
  TIME_FORMAT
} from 'src/config';
import { Option } from 'src/components/Select/types';
import store from 'src/store';
import { selectRouteSettingsValues } from 'src/views/administration/routeSettings/store/selectors';
import { sortBy } from 'lodash';
import { GPS_THRESHOLD_IN_SECONDS } from 'src/views/avl/dispatching/trafficStadium/components/VehicleCard/const';
import {
  NormalizedState,
  Ride,
  SpecialModeDetails,
  WorkingPeriod
} from './types';
import { defaultRideModeDetailsValues } from './components/SpecialMode/const';

export const getTimeRange = (first: string, second: string) => [
  formatTimeToDate(first),
  isTimeBefore(second, first)
    ? moment(second, TIME_FORMAT)
        .add(1, 'd')
        .format()
    : formatTimeToDate(second)
];

export const formatSecondsToTimestamp = (
  seconds: number,
  format: string = DETAILED_DATE_FORMAT
) => moment.unix(seconds).format(format);

export const isRideActiveWithThreshold = (ride: Ride) => {
  const period = alignWorkingPeriod(ride);
  const { plannedTermination } = ride;
  let isActive = isCurrentTimeBetween(period?.from, period?.to);

  if (plannedTermination) {
    const alignedTermination = addTimeoutThreshold(
      plannedTermination.terminatesAtUtc,
      true
    );
    const isTerminationOccurred = moment(
      alignedTermination,
      TIME_FORMAT
    ).isBefore(new Date());
    isActive = isActive && !isTerminationOccurred;
  }

  return isActive;
};

export const transformDepartureTimes = (
  options: Option[],
  workingPeriod: WorkingPeriod
) => {
  const newOptions: Option[] = [];

  options.forEach(({ routeIterationPointId, departureTime }) => {
    const workingPeriodTo = getTime(workingPeriod.to);

    if (
      !isTimeBefore(departureTime, new Date()) &&
      !isTimeBefore(workingPeriodTo, departureTime)
    ) {
      newOptions.push({ id: routeIterationPointId, name: departureTime });
    }
  });

  return newOptions;
};

export const normalizeSpecialModeDetails = (
  details?: SpecialModeDetails | null
) =>
  details
    ? {
        ...details,
        startsAtUtc: details?.startsAtUtc
          ? formatDateToTime(details.startsAtUtc)
          : '',
        endsAtUtc: details?.endsAtUtc ? formatDateToTime(details.endsAtUtc) : ''
      }
    : defaultRideModeDetailsValues;

export const normalizeRides = (rides: Ride[]) => {
  const normalizedState: NormalizedState<Ride> = {
    byId: {},
    allIds: []
  };
  const orderedRides = sortBy(rides, ['sequenceNumber', 'workingPeriod.from']);

  orderedRides.forEach(({ gps, ...ride }, idx) => {
    normalizedState.byId[ride.rideId] = ride;
    const newRide = normalizedState.byId[ride.rideId];
    const isLastShift = !(
      orderedRides[idx + 1]?.sequenceNumber === ride.sequenceNumber
    );

    if (gps?.createdAt) {
      const receivedAt = moment.unix(gps.createdAt);
      const isYesterdayGps = receivedAt.isBefore(new Date(), 'day');
      const gpsThreshold = moment(new Date()).subtract(
        GPS_THRESHOLD_IN_SECONDS,
        'seconds'
      );
      const hasSignal = receivedAt.isAfter(gpsThreshold);

      newRide.deviation = ride.deviationTime!;
      newRide.gps = {
        ...gps,
        isActual: true,
        receivedAt: receivedAt.format(
          isYesterdayGps ? 'DD.MM.YY HH:mm:ss' : DETAILED_TIME_FORMAT
        ),
        hasSignal
      };
    }

    newRide.isLastShift = isLastShift;
  });

  normalizedState.allIds = Object.keys(normalizedState.byId);

  return normalizedState;
};

export const addTimeoutThreshold = (date: string, isLastShift?: boolean) => {
  const { routeRideTimeoutThreshold } = selectRouteSettingsValues(
    store.getState()
  );
  const threshold = isLastShift ? routeRideTimeoutThreshold : '00:00';
  const [hours, minutes] = threshold.split(':');

  return moment(date)
    .add(hours, 'hour')
    .add(minutes, 'minutes')
    .format(TIME_FORMAT);
};

export function alignWorkingPeriod({
  isLastShift,
  workingPeriod
}: Ride): WorkingPeriod | undefined {
  if (!workingPeriod) return undefined;

  const from = getTime(workingPeriod.from);
  const to = addTimeoutThreshold(workingPeriod.to, isLastShift);

  return { from, to };
}

export const formatTimeToDate = (time: string) =>
  time ? moment(time, TIME_FORMAT).format(DETAILED_DATE_FORMAT) : '';

export const formatDateToTime = (date: string, format: string = TIME_FORMAT) =>
  moment(date).format(format);

export const isDateInRange = (
  date: string | Date,
  departureDate: string,
  arrivalDate: string
) => {
  const input = moment(date, DETAILED_TIME_FORMAT);
  const departure = moment(departureDate, DETAILED_TIME_FORMAT);
  const arrival = moment(arrivalDate, DETAILED_TIME_FORMAT);

  return input.isBetween(departure, arrival, undefined, '[]');
};
