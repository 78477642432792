import { createCustomAction } from 'typesafe-actions';

interface EditInstallationConfig {
  dataReducerPath: string;
  tableReducerPath: string;
}

export const updateInstallationRequest = createCustomAction(
  '@INSTALLATION/UPDATE_INSTALLATION_REQUEST',
  (config: EditInstallationConfig, resolve: (id: string) => void) => ({
    config,
    resolve
  })
);

export const updateInstallationSuccess = createCustomAction(
  '@INSTALLATION/UPDATE_INSTALLATION_SUCCESS',
  (config: EditInstallationConfig) => ({ config })
);

export const updateInstallationFailure = createCustomAction(
  '@INSTALLATION/UPDATE_INSTALLATION_FAILURE',
  (config: EditInstallationConfig, payload: Error) => ({ config, payload })
);
