import { createAsyncAction } from 'typesafe-actions';
import { BreakdownsListState, UpdateItemStatusPayload } from '../types';
import { BreakdownDetailsState } from '../../BreakdownDetailsView/types';

export const getBreakdown = createAsyncAction(
  '@BREAKDOWN_LIST/GET_BREAKDOWN_REQUEST',
  '@BREAKDOWN_LIST/GET_BREAKDOWN_SUCCESS',
  '@BREAKDOWN_LIST/GET_BREAKDOWN_FAILURE'
)<undefined, BreakdownsListState, Error>();

export const getBreakdownRowData = createAsyncAction(
  '@BREAKDOWN_DOWNLOAD/GET_BREAKDOWN_DOWNLOAD_REQUEST',
  '@BREAKDOWN_DOWNLOAD/GET_BREAKDOWN_DOWNLOAD_SUCCESS',
  '@BREAKDOWN_DOWNLOAD/GET_BREAKDOWN_DOWNLOAD_FAILURE'
)<number, BreakdownDetailsState, Error>();

export const getSelectedBreakdownsData = createAsyncAction(
  '@BREAKDOWNS/GET_SELECTED_BREAKDOWNS_REQUEST',
  '@BREAKDOWNS/GET_SELECTED_BREAKDOWNS_SUCCESS',
  '@BREAKDOWNS/GET_SELECTED_BREAKDOWNS_FAILURE'
)<{ id: number }[], BreakdownDetailsState[], Error>();

export const updateBreakdownStatus = createAsyncAction(
  '@BREAKDOWNS/UPDATE_BREAKDOWN_STATUS_REQUEST',
  '@BREAKDOWNS/UPDATE_BREAKDOWN_STATUS_SUCCESS',
  '@BREAKDOWNS/UPDATE_BREAKDOWN_STATUS_FAILURE'
)<UpdateItemStatusPayload, void, Error>();
